import fetchService from "../lib/fetchService";
import {
  CREATE,
  DELETE,
  DELETE_MANY,
  GET_LIST,
  GET_MANY,
  GET_MANY_REFERENCE,
  GET_ONE,
  HttpError,
  UPDATE,
} from "react-admin";
import forIn from "lodash/forIn";
import keyBy from "lodash/keyBy";
import get from "lodash/get";
import { cache, createCacheMeta, prefetching } from "./cacheProvider";
import handleError from "./errorResponseHandler";

export default (apiUrl, httpClient = fetchService) => {
  const HttpMethod = {
    GET: "GET",
    POST: "POST",
  };

  const BACKEND_FILTER_OPERATIONS = [
    "equals",
    "equal",
    "eq",
    "not_equal",
    "notequla",
    "not_equals",
    "neq",
    "less_than",
    "lessthan",
    "lt",
    "greater_than",
    "greaterthan",
    "gt",
    "like",
    "lk",
    "contains",
    "null",
  ];

  /**
   * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
   * @param {String} resource Name of the resource to fetch, e.g. 'posts'
   * @param {Object} params The data request params, depending on the type
   * @returns {Object} { url, options } The HTTP request parameters
   */
  const convertDataRequestToHTTP = (type, resource, params) => {
    let url = "";
    const options = {
      method: HttpMethod.GET,
      qs: {},
      body: {},
    };

    if (type === GET_LIST || type === GET_MANY_REFERENCE) {
      const { page, perPage } = params.pagination;
      const { field, order } = params.sort;
      const { target: referenceTarget, id: referenceId } = params;

      url = `${apiUrl}/${resource}`;
      options.qs["page"] = page - 1;
      options.qs["pagesize"] = perPage;
      options.qs["sort"] = field;
      options.qs["sortDirection"] = order;

      const filters = { ...params.filter };
      if (referenceTarget && referenceId !== undefined) {
        filters[referenceTarget] = referenceId;
      }

      forIn(filters, (k, v) => {
        while (typeof k === "object" && !Array.isArray(k)) {
          const keys = Object.keys(k);
          if (keys.length === 1) {
            const key = keys[0];
            v += "." + key;
            k = k[key];
            if (BACKEND_FILTER_OPERATIONS.includes(key)) {
              break;
            }
          } else {
            break;
          }
        }
        if (typeof v === "string") {
          v = v.replace(/#/g, ".");
        }
        if (BACKEND_FILTER_OPERATIONS.some(op => v.endsWith(`.${op}`))) {
          options.qs[v] = k;
        } else if (v === "prefetch" && Array.isArray(k)) {
          options.qs[v] = k;
          k.forEach(prefetch => {
            if (!prefetching.includes(prefetch)) prefetching.push(prefetch);
          });
        } else {
          options.qs[v + ".like"] = `%${k}%`;
        }
      });
    } else if (type === GET_ONE) {
      url = `${apiUrl}/${resource}/${params.id}`;
    } else if (type === GET_MANY) {
      url = `${apiUrl}/${resource}`;
      options.qs["id.equals"] = params.ids;
      options.qs["pagesize"] = params.ids.length;
    } else if (type === CREATE) {
      url = `${apiUrl}/${resource}`;
      options.body = params.data;
      options.method = HttpMethod.POST;
    } else if (type === DELETE) {
      url = `${apiUrl}/${resource}/${params.id}/delete`;
      options.method = HttpMethod.POST;
    } else if (type === UPDATE) {
      url = `${apiUrl}/${resource}/${params.id}/update`;
      options.method = HttpMethod.POST;
      options.body = params.data;
    } else throw new Error(`Unsupported fetch action type ${type}`);

    return { url, options };
  };

  /**
   * @param {Object} response HTTP response from fetch()
   * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
   * @param {String} resource Name of the resource to fetch, e.g. 'posts'
   * @param {Object} params The data request params, depending on the type
   * @returns {Object} Data response
   */
  const convertHTTPResponse = (response, type, resource, params) => {
    const { json, status } = response;

    if (status === 401 || status === 403) {
      throw new HttpError("ra.auth.sign_in_error", status);
    }

    if (status >= 500 && status < 600) {
      const code = get(json, "code", 0);
      throw new HttpError("ra.message.error", status, json, code);
    }

    if (status >= 400 && status < 500) {
      const code = get(json, "code", 0);
      throw new HttpError("ra.message.invalid_form", status, parseFieldErrors(json), code);
    }

    if (type === GET_LIST || type === GET_MANY || type === GET_MANY_REFERENCE) {
      const { items, meta, prefetch = {} } = json;
      if (!items || !meta) {
        throw new HttpError("ra.notification.http_error", 404);
      }

      const prefetchMeta = createCacheMeta(new Date().getTime());
      cache.putMany(resource)(keyBy(items, o => parseInt(o.id)), prefetchMeta);
      forIn(prefetch, (values, res) => {
        cache.putMany(res)(keyBy(values, o => parseInt(o.id)), prefetchMeta);
      });
      get(params, "filter.prefetch", []).forEach(res => {
        const iof = prefetching.indexOf(res);
        if (iof >= 0) {
          prefetching.splice(iof, 1);
        }
      });

      return {
        data: items,
        total: meta.totalCount,
      };
    } else if (type === GET_ONE) {
      return { data: { id: params.id, ...json } };
    } else if (type === CREATE) {
      if (status >= 400 && status < 500) {
        throw new HttpError("ra.message.invalid_form", status, parseFieldErrors(json));
      }

      if (Array.isArray(json)) return { data: json[0] };
      else return { data: json };
    } else if (type === DELETE) {
      cache.deleteOne(resource)(params.id);

      return { data: { ...params.previousData, id: params.id } };
    } else if (type === UPDATE) {
      if (status >= 400 && status < 500) {
        throw new HttpError("ra.message.invalid_form", status, parseFieldErrors(json));
      }

      const prefetchMeta = createCacheMeta(new Date().getTime());
      cache.putOne(resource)(params.id, json, prefetchMeta);

      return { data: json };
    } else throw new Error(`Unsupported fetch action type ${type}`);
  };

  const parseFieldErrors = json => {
    if (!json || !json.fieldErrors) return undefined;
    const errors = {};
    for (const field in json.fieldErrors) {
      errors[field] = " ";
    }
    return errors;
  };

  /**
   * @param {string} type Request type, e.g GET_LIST
   * @param {string} resource Resource name, e.g. "posts"
   * @param {Object} params Request parameters. Depends on the request type
   * @returns {Promise} the Promise for a data response
   */
  const main = (type, resource, params) => {
    if (type === DELETE_MANY) {
      const { ids = [] } = params;
      const resultPromises = ids.map(id => main(DELETE, resource, { id }));
      return Promise.all(resultPromises).then(vals => ({
        data: vals.map(it => it.data),
      }));
    }

    const {
      url,
      options: { method, qs, body },
    } = convertDataRequestToHTTP(type, resource, params);

    const processResponse = response =>
      response
        .text()
        .then(txt => {
          try {
            return JSON.parse(txt);
          } catch (e) {
            console.error("Dataprovider error: Couldn't parse JSON!", txt, txt.length, response);
            throw new HttpError("ra.notification.http_error");
          }
        })
        .then(json => convertHTTPResponse({ ...response, json, status: response.status }, type, resource, params));

    return (method === HttpMethod.POST ? httpClient.post(url, qs, body) : httpClient.get(url, qs))
      .then(handleError)
      .then(processResponse);
  };

  return main;
};
