export default {
  SHIPMENT: {
    key: "SHIPMENT",
  },
  MODEL: {
    key: "MODEL",
  },
  ADDITION: {
    key: "ADDITION",
  },
  NOTICE: {
    key: "NOTICE",
  },
};
