import createReducer, { mergeReducers } from "../../../lib/createReducer";
import {
  FETCH_STORAGESERVICE_PING,
  FETCH_STORAGESERVICE_PING_SUCCESS,
  FETCH_STORAGESERVICE_PING_ERROR,
} from "./action";

const init = {
  isFetching: false,
  fetchSuccess: false,
  hasError: false,
  payload: null,
  error: null,
};

const requestReducer = createReducer(FETCH_STORAGESERVICE_PING, () => ({
  isFetching: true,
}));

const successReducer = createReducer(FETCH_STORAGESERVICE_PING_SUCCESS, ({ response }) => ({
  isFetching: false,
  fetchSuccess: true,
  hasError: false,
  payload: response,
  error: null,
}));

const errorReducer = createReducer(FETCH_STORAGESERVICE_PING_ERROR, ({ error }) => ({
  isFetching: false,
  hasError: true,
  fetchSuccess: false,
  error,
  payload: null,
}));

export default mergeReducers([requestReducer, successReducer, errorReducer], init);
