import { dataSource, sources } from "../config/resources";
import BackendDataProvider from "./backendDataProvider";
import V2BackendDataProvider, { HAL_LINK, HAL_REFERENCE } from "./v2BackendDataProvider";
import fetchService from "../lib/fetchService";
import config from "../config";

/**
 * Main data provider constructor
 *
 * @param httpClient
 * @returns {Function}
 * @constructor
 */
export const DataProvider = (httpClient = fetchService) => {
  //INITIALIZE DATA PROVIDERS HERE
  const backendDataProvider = BackendDataProvider(config.urls.BACKEND, httpClient);
  const v2BackendDataProvider = V2BackendDataProvider(config.urls.BACKEND + "/v2", httpClient);

  /**
   * Main data provider router. Routes each resource to their server-specific data provider.
   *
   * @param {string} type Request type, e.g GET_LIST
   * @param {string} resource Resource name, e.g. "posts"
   * @param {Object} params Request parameters. Depends on the request type
   * @returns {Promise} the Promise for a data response
   */
  return async (type, resource, params) => {
    const source = dataSource[resource];

    if (source === sources.BACKEND || [HAL_LINK, HAL_REFERENCE].includes(type)) {
      return await v2BackendDataProvider(type, resource, params);
    }

    const res = await backendDataProvider(type, resource, params);
    console.log("V1 Request", type, resource, res);
    return res;
  };
};
export default DataProvider;
