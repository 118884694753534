import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  crudCreate,
  crudGetAll,
  crudUpdate,
  required,
  SaveButton,
  SimpleForm,
  TextInput,
  translate,
  usePermissions,
} from "react-admin";
import { CreateController } from "ra-core";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import SaveIcon from "@material-ui/icons/Save";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Chip from "@material-ui/core/Chip";
import get from "lodash/get";

import { createFilterGraphPayload } from "../../lib/filterGraph";
import resources from "../../config/resources";
import FilterGraphSelectInput from "./FilterGraphSelectInput";

const styles = theme => ({
  root: {
    width: "100%",
  },
  icon: {
    marginRight: theme.spacing(2),
  },
  dialogContent: {
    padding: 0,
  },
  hint: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(3),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  count: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  title: {
    padding: theme.spacing(2),
  },
  divider: {
    fontWeight: "bold",
    textTransform: "uppercase",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    color: theme.palette.primary.main,
    padding: theme.spacing(1),
    borderTop: `1px solid ${theme.palette.grey[300]}`,
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
  },
});

const sanitizeRestProps = ({ isLoading, defaultTitle, ...rest }) => rest;
const requiredValidation = required();

const Toolbar = ({ handleDialogClose, translate, ...props }) => (
  <DialogActions>
    <Button onClick={handleDialogClose}>
      <CloseIcon />
      {translate("ra.action.cancel")}
    </Button>
    <SaveButton {...props} />
  </DialogActions>
);

const FilterGraphFormToolbar = translate(Toolbar);

const FilterGraphForm = ({ basePath, formData, handleSubmit, handleDialogClose }) => (
  <CreateController basePath={basePath} resource={resources.FILTER_GRAPHS} location="">
    {controllerProps => (
      <SimpleForm
        {...sanitizeRestProps(controllerProps)}
        record={formData}
        toolbar={<FilterGraphFormToolbar handleDialogClose={handleDialogClose} />}
        redirect={false}
        save={handleSubmit}
      >
        <TextInput source="name" fullWidth={true} validate={requiredValidation} />
      </SimpleForm>
    )}
  </CreateController>
);

const SaveFilterGraphButton = ({
  filterValues,
  resource,
  basePath,
  classes,
  translate,
  dispatchCrudCreate,
  dispatchCrudUpdate,
  dispatchCrudGetAll,
  ...props
}) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [existingFilterId, setExistingFilterId] = useState(null);
  const [formData, setFormData] = useState(createFilterGraphPayload(filterValues, resource));
  const { permissions } = usePermissions();

  const idRegex = new RegExp("([0-9]+)$");

  useEffect(() => {
    setFormData(createFilterGraphPayload(filterValues, resource));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterValues, resource]);

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setFormData({});
    setDialogOpen(false);
  };

  const handleSubmit = data => {
    if (existingFilterId) {
      dispatchCrudUpdate(resources.FILTER_GRAPHS, existingFilterId, data, {}, basePath, false);
    } else {
      dispatchCrudCreate(resources.FILTER_GRAPHS, data, basePath, false);
    }

    setTimeout(() => {
      dispatchCrudGetAll(resources.FILTER_GRAPHS, { field: "id", order: "default" }, {}, 1000);
    }, 300);
    handleDialogClose();
  };

  const handleFilterSelection = ({ id, name }) => {
    if (typeof id === "string") {
      const longId = idRegex.exec(id)[0];

      setExistingFilterId(longId);

      if (formData.name !== name) {
        const prevData = formData;
        setFormData({ ...prevData, name });
      }
    } else if (isFinite(id)) {
      setExistingFilterId(id);

      if (formData.name !== name) {
        const prevData = formData;
        setFormData({ ...prevData, name });
      }
    } else {
      setExistingFilterId(null);
    }
  };

  return (
    <Fragment>
      <Button
        {...props}
        className={classes.root}
        onClick={handleDialogOpen}
        disabled={!formData || !Array.isArray(formData.filterParts) || formData.filterParts.length < 1}
      >
        <SaveIcon className={classes.icon} />
        {translate(`resources.filterGraphs.actions.save`)}
      </Button>
      <Dialog open={dialogOpen} onClose={handleDialogClose}>
        <div className={classes.dialogContent}>
          <Typography variant="h6" className={classes.title} align="center">
            {translate("resources.filterGraphs.actions.update")}
          </Typography>
          <FilterGraphSelectInput
            resource={resource}
            onChangeSelection={handleFilterSelection}
            permissions={permissions}
            isEditForm={true}
            basePath={basePath}
          />
          {!existingFilterId && (
            <div>
              <Typography variant="h6" className={classes.divider} align="center">
                {translate("resources.filterGraphs.or")}
              </Typography>
              <Typography variant="h6" className={classes.title} align="center">
                {translate("resources.filterGraphs.actions.create")}
              </Typography>
            </div>
          )}
          <FilterGraphForm
            basePath={basePath}
            formData={formData}
            handleSubmit={handleSubmit}
            handleDialogClose={handleDialogClose}
          />
          <div className={classes.hint}>
            <Chip
              avatar={<span className={classes.count}>{get(formData, "filterParts.length", 0)}</span>}
              label={translate("resources.filterGraphs.set")}
            />
          </div>
        </div>
      </Dialog>
    </Fragment>
  );
};

SaveFilterGraphButton.propTypes = {
  filterValues: PropTypes.object,
  resource: PropTypes.string.isRequired,
  basePath: PropTypes.string.isRequired,
  dispatchCrudCreate: PropTypes.func.isRequired,
  dispatchCrudUpdate: PropTypes.func.isRequired,
  dispatchCrudGetAll: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  translate: PropTypes.func.isRequired,
};

export default connect(
  undefined,
  {
    dispatchCrudCreate: crudCreate,
    dispatchCrudUpdate: crudUpdate,
    dispatchCrudGetAll: crudGetAll,
  }
)(withStyles(styles)(translate(SaveFilterGraphButton)));
