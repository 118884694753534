import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import withTheme from "@material-ui/core/styles/withTheme";

const styles = () => ({
  root: {
    border: "solid 1px",
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    outline: "none",
    paddingTop: "2px",
    paddingBottom: "2px",
    paddingRight: "8px",
    paddingLeft: "4px",
    whiteSpace: "nowrap",
    borderRadius: "16px",
  },
  icon: {
    height: 20,
  },
  label: {
    paddingLeft: "4px",
    textDecoration: "none",
    fontWeight: "700",
    fontSize: "12px",
  },
});

const IconChipField = ({ style = {}, label, color, theme, classes, icon, onClick = () => {}, ...rest }) => {
  const Icon = icon;
  let textColor;
  try {
    textColor = theme.palette.getContrastText(color);
  } catch (e) {
    textColor = "#000";
  }

  return (
    <div
      className={classes.root}
      style={{
        ...style,
        backgroundColor: color,
        color: textColor,
        borderColor: color,
      }}
      onClick={onClick}
    >
      {Icon && <Icon className={classes.icon} style={{ color: textColor }} />}
      <span className={classes.label} style={{ color: textColor }}>
        {label}
      </span>
    </div>
  );
};

IconChipField.propTypes = {
  style: PropTypes.object,
  label: PropTypes.string,
  color: PropTypes.string,
  theme: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withTheme(IconChipField));
