import React from "react";
import { translate } from "react-admin";
import PropTypes from "prop-types";
import moment from "moment";

import NotificationTypes from "../../config/NotificationTypes";
import SystemPushNotification from "./SystemPushNotification";

const NotificationBody = ({ notification, shouldPushToSystem = false, translate }) => {
  if (!notification) return null;

  let { body, translation } = NotificationTypes[notification.notificationType] || {};

  if (!body) {
    const bodyTranslation = translate(translation);
    if (shouldPushToSystem) {
      const createdAt = moment(notification.createdAt).unix();
      body = <SystemPushNotification open={true} subject={bodyTranslation} timestamp={createdAt} />;
    } else {
      body = bodyTranslation;
    }
  } else if (typeof body === "string") {
    const bodyTranslation = translate(body, notification);
    if (shouldPushToSystem) {
      const createdAt = moment(notification.createdAt).unix();
      body = <SystemPushNotification open={true} subject={bodyTranslation} timestamp={createdAt} />;
    } else {
      body = bodyTranslation;
    }
  } else if (React.isValidElement(body)) {
    body = React.cloneElement(body, { notification, shouldPushToSystem });
  } else if (typeof body === "function") {
    body = body({ notification, shouldPushToSystem });
  }

  return body;
};

NotificationBody.propTypes = {
  notification: PropTypes.shape({
    notificationType: PropTypes.oneOf(Object.keys(NotificationTypes)).isRequired,
    createdAt: PropTypes.string.isRequired,
    // add other props if needed
  }),
  shouldPushToSystem: PropTypes.bool,
};

export default translate(NotificationBody);
