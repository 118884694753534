import React, { useState } from "react";
import PropTypes from "prop-types";
import fallbackImageAsset from "../../assets/model-preview.png";

const ModelPreviewImage = ({ src, title, style, fallbackImage = fallbackImageAsset, className }) => {
  const [useFallback, setUseFallback] = useState(false);

  const addDefaultSrc = () => {
    setUseFallback(true);
  };

  return (
    <img
      src={!useFallback ? src : fallbackImage}
      alt=""
      title={title}
      style={style}
      onError={addDefaultSrc}
      className={className}
    />
  );
};

ModelPreviewImage.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object,
  translate: PropTypes.func,
  src: PropTypes.string,
  style: PropTypes.object,
  fallbackImage: PropTypes.string,
  title: PropTypes.string,
};

export default ModelPreviewImage;
