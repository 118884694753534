import React from "react";
import { Sidebar } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  height: {
    height: "calc(100vh - 4em)",
    position: "sticky",
  },
  drawerPaper: {
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    borderBottomLeftRadius: 0,
    borderTopLeftRadius: 0,
    boxShadow: theme.shadows[2],
    minHeight: "calc(100vh - 90px)",
    marginRight: theme.spacing(1),
  },
}));

const CustomSidebar = ({ ...props }) => {
  const classes = useStyles();
  return (
    <Sidebar
      {...props}
      classes={{
        root: classes.height,
        fixed: classes.height,
        drawerPaper: classes.drawerPaper,
      }}
    />
  );
};
export default CustomSidebar;
