import createAction, { createActionWithMeta } from "../../../lib/createAction";

export const UNASSIGN_MODEL_REQUEST = "UNASSIGN_MODEL_REQUEST";
export const unassignModel = createAction(UNASSIGN_MODEL_REQUEST, "id");

export const UNASSIGN_MODEL_SUCCESS = "UNASSIGN_MODEL_SUCCESS";
export const unassignModelSuccess = createActionWithMeta(
  UNASSIGN_MODEL_SUCCESS,
  {
    uncache: true,
    notification: {
      body: "ra.notification.updated",
      level: "info",
    },
    refresh: true,
  },
  "response"
);

export const UNASSIGN_MODEL_ERROR = "UNASSIGN_MODEL_ERROR";
export const unassignModelError = createActionWithMeta(
  UNASSIGN_MODEL_ERROR,
  {
    uncache: true,
    onFailure: {
      notification: {
        body: "ra.notification.bad_item",
        level: "warning",
      },
    },
    refresh: true,
  },
  "error"
);
