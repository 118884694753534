import createAction, { createActionWithMeta } from "../../lib/createAction";

export const UPLOAD_DOCUMENT_REQUEST = "ROH/UPLOAD_DOCUMENT_REQUEST";
export const uploadDocumentRequest = createAction(UPLOAD_DOCUMENT_REQUEST, "file", "documentMeta");

export const UPLOAD_DOCUMENT_PROGRESS = "ROH/UPLOAD_DOCUMENT/PROGRESS";
export const uploadDocumentProgress = createAction(UPLOAD_DOCUMENT_PROGRESS, "file", "progress");

export const UPLOAD_DOCUMENT_SUCCESS = "ROH/UPLOAD_DOCUMENT/SUCCESS";
export const uploadDocumentSuccess = createAction(UPLOAD_DOCUMENT_SUCCESS, "file", "response");

export const UPLOAD_DOCUMENT_ERROR = "ROH/UPLOAD_DOCUMENT_ERROR";
export const uploadDocumentError = createActionWithMeta(
  UPLOAD_DOCUMENT_ERROR,
  (file, error = {}) => ({
    notification: {
      body: error.message || "ra.message.error",
      level: "warning",
    },
  }),
  "file",
  "error"
);
