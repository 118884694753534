import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { BooleanInput, Filter, ReferenceInput, SelectInput, TextInput, translate } from "react-admin";
import PropTypes from "prop-types";
import resources from "../../config/resources";
import { getKey } from "../../lib/i18nUtils";
import Roles from "../../config/Roles";

const styles = theme => ({
  container: {
    display: "grid !important",
    width: "100%",

    gridTemplateColumns: "repeat(15, 1fr)",
    [theme.breakpoints.down("md")]: {
      gridTemplateColumns: "repeat(9, 1fr)",
    },
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "repeat(6, 1fr)",
    },
    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "repeat(3, 1fr)",
    },

    "& > *": {
      gridColumnEnd: "span 3",
      overflow: "hidden",
    },
  },
});

const UserFilters = ({ translate, classes, ...props }) => (
  <Filter {...props} classes={{ form: classes.container }}>
    <TextInput source="username" resettable />
    <ReferenceInput
      source="contractor"
      label={translate(getKey("contractor", resources.SUPPORTED_MATERIALS))}
      reference={resources.CONTRACTORS}
      fullWidth
    >
      <SelectInput optionText="contractorName" optionValue="id" allowEmpty={false} />
    </ReferenceInput>
    <SelectInput
      source="authority"
      label={translate(getKey("authorities", resources.USERS))}
      choices={Object.values(Roles)}
      optionValue="key"
      optionText={({ translation }) => translate(translation)}
      fullWidth
      allowEmpty={false}
    />
    <BooleanInput source="credentialsExpired" />
    <BooleanInput source="enabled" />
    <BooleanInput source="locked" />
  </Filter>
);

UserFilters.propTypes = {
  translate: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(translate(UserFilters));
