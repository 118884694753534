import React from "react";
import {
  BooleanInput,
  Edit,
  email,
  FormDataConsumer,
  maxLength,
  minLength,
  required,
  SelectArrayInput,
  SelectInput,
  SimpleForm,
  TextInput,
  translate,
  useGetList,
  useQueryWithStore,
  useTranslate,
} from "react-admin";
import resources from "../../config/resources";
import Roles from "../../config/Roles";
import yellow from "@material-ui/core/colors/yellow";
import Title from "../customFields/Title";
import CircularProgress from "@material-ui/core/CircularProgress";
import { HAL_REFERENCE } from "../../dataproviders/v2BackendDataProvider";
import { getKey } from "../../lib/i18nUtils";

const validateRoles = [required()];
const validateDisplayName = [required(), minLength(3), maxLength(80)];
const validateEmail = [required(), email()];

const AuthorityWarningField = ({ translate, ...props }) => (
  <FormDataConsumer {...props}>
    {({ formData }) =>
      typeof formData.contractor === "number" &&
      (!Array.isArray(formData.authorities) ||
        formData.authorities.length !== 1 ||
        formData.authorities[0] !== Roles.ROLE_CONTRACTOR.key) && (
        <div
          style={{
            padding: 16,
            borderRadius: 4,
            backgroundColor: yellow[100],
            border: `solid 3px ${yellow[500]}`,
            minWidth: "100%",
            width: 200,
            boxSizing: "border-box",
            color: "#332e00",
          }}
        >
          {translate("contractorUserWithWrongRolesWarning")}
        </div>
      )
    }
  </FormDataConsumer>
);

const ContractorSelect = ({ record: { contractor, id } }) => {
  const { data: contractors, loading } = useGetList(resources.CONTRACTORS, { page: 1, perPage: 100 });
  const { data: currentContractor, loadingCurrent } = useQueryWithStore({
    type: HAL_REFERENCE,
    resource: resources.CONTRACTORS,
    payload: { link: contractor },
  });

  const translate = useTranslate();

  if (loading || loadingCurrent || !contractors || !currentContractor) {
    return <CircularProgress />;
  }

  const refId = `${id}/contractor`;

  const choices = [];
  if (!currentContractor.length) {
    Object.values(contractors).forEach(it => choices.push(it));
  } else {
    const current = currentContractor[0]; //as of writing, contractor <-> user is 1:n
    current["id"] = refId;
    choices.push(current);

    Object.values(contractors).forEach(it => {
      if (!choices.find(ci => ci.__id === it.__id)) choices.push(it);
    });
  }

  return (
    <SelectInput
      source="contractor"
      label={translate(getKey("contractor", resources.USERS))}
      resource={resources.CONTRACTORS}
      choices={choices}
      optionValue="id"
      optionText="contractorName"
      emptyText=" "
      emptyValue={" "}
      fullWidth
      allowEmpty
      allowNull
      initialValue={refId}
    />
  );
};

const UserEdit = ({ translate, ...props }) => {
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <Edit title={<Title resource={resources.USERS} />} undoable={false} {...props}>
        <SimpleForm initialValues={{ enabled: true }} redirect="list">
          <TextInput source="username" disabled />
          <TextInput source="displayName" validate={validateDisplayName} disabled />
          <TextInput source="email" type="email" validate={validateEmail} />
          <BooleanInput source="enabled" />
          <BooleanInput source="locked" />
          <BooleanInput source="credentialsExpired" />
          <BooleanInput source="accountExpired" />
          <ContractorSelect />
          <SelectArrayInput
            source="authorities"
            choices={Object.values(Roles)}
            optionValue="key"
            optionText={({ translation }) => translate(translation)}
            validate={validateRoles}
          />
          <AuthorityWarningField translate={translate} />
        </SimpleForm>
      </Edit>
    </div>
  );
};

export default translate(UserEdit);
