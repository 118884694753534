import fetchService from "../../lib/fetchService";
import { handleSagaError } from "../../dataproviders/errorResponseHandler";
import { call, put, takeEvery } from "redux-saga/effects";
import {
  ASSIGN_WAREHOUSE_COMPARTMENT_REQUEST,
  assignWarehouseCompartmentError,
  assignWarehouseCompartmentSuccess,
} from "./action";
import config from "../../config/index";

const saga = function*({ payload }) {
  const { compartment, model, shipment } = payload;

  const url = `${config.urls.BACKEND}/warehouse/${!!model ? "assign_model" : "assign_shipment"}`;

  const body = !!model
    ? {
        compartment: compartment,
        target: model,
        swap: false,
      }
    : {
        compartment: compartment,
        target: shipment,
        swap: true,
      };

  try {
    const response = yield call(fetchService.post, url, "", body);

    // why is this weird 'for ... return' pattern in all our sagas?
    for (const action of yield call(handleSagaError, response, assignWarehouseCompartmentError)) {
      yield put(action);
      return;
    }

    if (response.ok) {
      const responseData = yield call(() => response.json());
      yield put(assignWarehouseCompartmentSuccess(responseData));
    } else {
      const statusCode = response.status;
      yield put(assignWarehouseCompartmentError(new Error(`Request failed with error ${statusCode}`)));
    }
  } catch (e) {
    yield put(assignWarehouseCompartmentError(e));
  }
};

export default function*() {
  yield takeEvery(ASSIGN_WAREHOUSE_COMPARTMENT_REQUEST, saga);
}
