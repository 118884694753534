export default {
  CONTRACTOR_OFFER: {
    key: "CONTRACTOR_OFFER",
    translation: "templateType.CONTRACTOR_OFFER",
  },

  CONTRACTOR_RESOLVED_PROBLEM: {
    key: "CONTRACTOR_RESOLVED_PROBLEM",
    translation: "templateType.CONTRACTOR_RESOLVED_PROBLEM",
  },

  JOB_ACCEPTED_NOTIFICATION: {
    key: "JOB_ACCEPTED_NOTIFICATION",
    translation: "templateType.JOB_ACCEPTED_NOTIFICATION",
  },

  JOB_DECLINED_NOTIFICATION: {
    key: "JOB_DECLINED_NOTIFICATION",
    translation: "templateType.JOB_DECLINED_NOTIFICATION",
  },

  JOB_IN_DELIVERY_NOTIFICATION: {
    key: "JOB_IN_DELIVERY_NOTIFICATION",
    translation: "templateType.JOB_IN_DELIVERY_NOTIFICATION",
  },

  JOB_DOCUMENTS_ADDED_NOTIFICATION: {
    key: "JOB_DOCUMENTS_ADDED_NOTIFICATION",
    translation: "templateType.JOB_DOCUMENTS_ADDED_NOTIFICATION",
  },

  JOB_COMMENTED_NOTIFICATION: {
    key: "JOB_COMMENTED_NOTIFICATION",
    translation: "templateType.JOB_COMMENTED_NOTIFICATION",
  },

  SHIPMENT_COMMENTED_NOTIFICATION: {
    key: "SHIPMENT_COMMENTED_NOTIFICATION",
    translation: "templateType.SHIPMENT_COMMENTED_NOTIFICATION",
  },

  MENTIONED_IN_COMMENT_NOTIFICATION: {
    key: "MENTIONED_IN_COMMENT_NOTIFICATION",
    translation: "templateType.MENTIONED_IN_COMMENT_NOTIFICATION",
  },

  PREPAYMENT_PAYED_NOTIFICATION: {
    key: "PREPAYMENT_PAYED_NOTIFICATION",
    translation: "templateType.PREPAYMENT_PAYED_NOTIFICATION",
  },

  CONTRACTOR_JOB_CANCELED: {
    key: "CONTRACTOR_JOB_CANCELED",
    translation: "templateType.CONTRACTOR_JOB_CANCELED",
  },

  PRODUCTION_PORTFOLIO: {
    key: "PRODUCTION_PORTFOLIO",
    translation: "templateType.PRODUCTION_PORTFOLIO",
  },

  MODEL_DESCRIPTION: {
    key: "MODEL_DESCRIPTION",
    translation: "templateType.MODEL_DESCRIPTION",
  },

  EXTERNAL_ADDITION_ORDER: {
    key: "EXTERNAL_ADDITION_ORDER",
    translation: "templateType.EXTERNAL_ADDITION_ORDER",
  },
};
