import React from "react";
import { DeleteButton } from "react-admin";

import switchRecordIds from "../../../lib/switchRecordIds";

/**
 * Use this delete button everywhere instead of react admins DeleteButton
 * in order to work around the __id / id problem, see switchRecordIds
 *
 */
const CustomDeleteButton = ({ record, ...props }) => <DeleteButton {...props} record={switchRecordIds(record)} />;

export default CustomDeleteButton;
