import React from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import { translate } from "react-admin";
import { connect } from "react-redux";

import { JobBulkOperationsType } from "../../config/BulkOperationTypes";
import { jobBulkOperationResultClear } from "../../redux/job/bulkOperation/action";
import { hasAnyRole } from "../../lib/permissionChecks";
import Roles from "../../config/Roles";

const styles = theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    justifyContent: "space-between",
    maxWidth: "25rem",
  },
  button: {
    marginBottom: theme.spacing(2),
  },
  buttons: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
  },
  resetButtons: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
  },
});

const BulkOperations = [
  {
    type: JobBulkOperationsType.ORDER,
    label: "bulkOps.actions.order",
    allowedRoles: [Roles.ROLE_SALES.key, Roles.ROLE_ADMIN.key],
  },
  {
    type: JobBulkOperationsType.SET_IN_TRANSPORT,
    label: "bulkOps.actions.setInTransport",
    allowedRoles: null,
  },
  {
    type: JobBulkOperationsType.SET_RECEIVED,
    label: "bulkOps.actions.setReceived",
    allowedRoles: [Roles.ROLE_SALES.key, Roles.ROLE_ADMIN.key],
  },
  {
    type: JobBulkOperationsType.ACCEPT,
    label: "bulkOps.actions.setAccepted",
    allowedRoles: null,
  },
];

const JobBulkOperationActionBar = ({
  classes,
  translate,
  onBulkOperationButtonClick,
  onResetButtonClick,
  clearState,
  permissions,
}) => (
  <Card className={classes.root}>
    <CardContent className={classes.buttons}>
      {BulkOperations.map(
        ({ type, label, allowedRoles }) =>
          (!allowedRoles || hasAnyRole(permissions, allowedRoles)) && (
            <Button
              onClick={() => onBulkOperationButtonClick(type)}
              variant="contained"
              color="primary"
              className={classes.button}
            >
              {translate(label)}
            </Button>
          )
      )}
    </CardContent>
    <CardContent className={classes.resetButtons}>
      <Button
        variant="contained"
        color="default"
        onClick={() => {
          clearState();
          onResetButtonClick();
        }}
      >
        {translate("bulkOps.actions.reset")}
      </Button>
    </CardContent>
  </Card>
);

export default connect(
  undefined,
  { clearState: jobBulkOperationResultClear }
)(withStyles(styles)(translate(JobBulkOperationActionBar)));
