import merge from "lodash/merge";
import cloneDeep from "lodash/cloneDeep";

/**
 * This function creates a reducer for a specific action.
 *
 * The first parameter is the action type. When the created reducer gets called, it will only run on actions with this type.
 * The second parameter is callback function, which gets the action payload and should return all changes to the state
 *
 * @function createReducer
 * @param actionType - The type of the action which will trigger the reducer
 * @param reducer - A function which receives the payload of the action and returns an object with all changes to the state. The returned object will be recursively merged with the old state.
 * @param [override] - If false, the reducers result will be merged with the old state. If true, the reducers result will override the entire old state
 * @returns reducer - The created reducer
 */
export const createReducer = (actionType, reducer, override = false) => {
  if (override) {
    return (state, action) => {
      if (actionType !== action?.type) return state;
      else return reducer(action?.payload);
    };
  } else {
    return (state, action) => {
      if (actionType !== action?.type) return state;
      else return merge(cloneDeep(state), reducer(action?.payload));
    };
  }
};
export default createReducer;

/**
 * This function gets a list of reducers. When it's called, all the reducers will be recursively called.
 *
 * Example:
 *   const combinedReducers = applyReducers([reducer1, reducer2, reducer3], {foo: "bar"})
 *       ==> combinedReducers equals (state, action) => reducer3(reducer2(reducer1(state || initialState), action), action), action)
 *
 * @param reducers - A list of redux reducers
 * @param [initialState] - The initial redux state
 * @returns reducer - All reducers merged
 */
export const mergeReducers = (reducers = [], initialState) =>
  reducers.reduce((acc, reducer) => (s, a) => reducer(acc(s, a), a), (state = initialState || {}) => state);
