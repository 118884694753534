import React from "react";
import { getKey } from "../../lib/i18nUtils";
import resources from "../../config/resources";
import { AutocompleteInput, ReferenceArrayInput, ReferenceInput, TextInput, translate } from "react-admin";
import { withStyles } from "@material-ui/core/styles";
import RohStatus from "../../config/RohStatus";
import SelectLongArrayInput from "../../SelectLongArrayInput";
import CustomFilter from "../CustomFilter";
import AdditionFilter from "../customFields/filter/AdditionFilter";

const styles = theme => ({
  container: {
    display: "grid !important",
    width: "100%",

    gridTemplateColumns: "repeat(18, 1fr)",
    [theme.breakpoints.down("md")]: {
      gridTemplateColumns: "repeat(9, 1fr)",
    },
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "repeat(6, 1fr)",
    },
    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "repeat(3, 1fr)",
    },
    gridColumnGap: `${theme.spacing(1)}px`,

    "& > *": {
      gridColumnEnd: "span 3",
    },
  },
  autocompleteContainer: {
    width: "100%",
    marginBottom: theme.spacing.unit,
    "& > *": {
      width: "100%",
    },
  },
  singleAutocompleteContainer: {
    width: "100%",
    "& *": {
      width: "100%",
    },
  },
});

const ArchivedJobFilters = ({ translate, classes, permissions, ...props }) => (
  <CustomFilter {...props} className={classes.container} permissions={permissions}>
    <TextInput
      source="externalId"
      alwaysOn
      fullWidth
      resettable
      label={getKey("externalId", resources.ARCHIVED_JOBS)}
    />
    <TextInput
      source="models#order#rapidshopId"
      alwaysOn
      fullWidth
      resettable
      label={getKey("rapidshopId", resources.ARCHIVED_ORDERS)}
    />
    <ReferenceArrayInput
      source="contractor"
      label={translate(getKey("contractor", resources.ARCHIVED_JOBS))}
      reference={resources.CONTRACTORS}
      sort={{ field: "contractorName", order: "ASC" }}
      perPage={100}
      alwaysOn
      fullWidth
    >
      <SelectLongArrayInput
        optionValue="id"
        optionText={({ contractorName } = {}) => contractorName || ""}
        fullWidth
        options={{ fullWidth: true }}
        classes={{ container: classes.autocompleteContainer }}
        translateChoice={false}
        resettable
      />
    </ReferenceArrayInput>
    <SelectLongArrayInput
      source="status"
      label={translate(getKey("status", resources.ARCHIVED_JOBS))}
      choices={Object.values(RohStatus).filter(it => it.filterable && it.jobStatus)}
      optionValue="apiValue"
      optionText={it => translate(it.translationKey || "")}
      translateChoice={false}
      fullWidth
      alwaysOn
      resettable
    />
    <ReferenceInput
      label={translate(getKey("assignee", resources.ARCHIVED_JOBS))}
      source="assignee#user"
      reference={resources.USER_DETAILS}
      perPage={1000}
      filterToQuery={searchText => ({ displayName: searchText })}
      alwaysOn
      fullWidth
      sort={{ field: "displayName", order: "ASC" }}
      /**
       * values from a filtergraph are wrapped inside an array so we have to format them before inserting into a single autocomplete input
       */
      format={val => (Array.isArray(val) ? val[0] : val)}
    >
      <AutocompleteInput
        optionText="displayName"
        allowEmpty
        fullWidth
        options={{ fullWidth: true }}
        classes={{
          container: classes.autocompleteContainer,
          root: classes.autocompleteContainer,
        }}
        className={classes.singleAutocompleteContainer}
        translateChoice={false}
        resettable
      />
    </ReferenceInput>
    <AdditionFilter />
  </CustomFilter>
);

export default withStyles(styles)(translate(ArchivedJobFilters));
