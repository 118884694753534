import createAction from "../../../lib/createAction";

export const NOTE_TO_CONTRACTOR_REQUEST = "NOTE_TO_CONTRACTOR_REQUEST";
export const noteToContractor = createAction(NOTE_TO_CONTRACTOR_REQUEST, "models", "note", "append");

export const NOTE_TO_CONTRACTOR_SUCCESS = "NOTE_TO_CONTRACTOR_SUCCESS";
export const noteToContractorSuccess = createAction(NOTE_TO_CONTRACTOR_SUCCESS, "response");

export const NOTE_TO_CONTRACTOR_ERROR = "NOTE_TO_CONTRACTOR_ERROR";
export const noteToContractorError = createAction(NOTE_TO_CONTRACTOR_ERROR, "error");

export const NOTE_TO_CONTRACTOR_LOCAL_STATE = "NOTE_TO_CONTRACTOR_LOCAL_STATE";
export const noteToContractorLocalState = createAction(NOTE_TO_CONTRACTOR_LOCAL_STATE, "note");
