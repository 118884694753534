import React from "react";
import {
  CreateButton,
  Datagrid,
  EditButton,
  List,
  NumberField,
  Pagination,
  ReferenceField,
  TextField,
  TextInput,
  TopToolbar,
  useGetList,
  useMutation,
  useTranslate,
} from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import { CircularProgress } from "@material-ui/core";
import get from "lodash/get";

import paginationOptions from "../../config/PaginationOptions";
import { getKey } from "../../lib/i18nUtils";
import resources from "../../config/resources";
import DateField from "../customFields/DateField";
import CustomFilter from "../CustomFilter";
import { PrintJobStatus } from "../../config/PrintJobStatus";
import SelectLongArrayInput from "../../SelectLongArrayInput";
import EditableDateField from "../customFields/EditableDateField";
import CustomDropdownField from "../customFields/CustomDropdownField";
import ModelDownloadButton from "../documents/ModelDownloadButton";
import DownloadButton from "../customFields/buttons/DownloadButton";

const useStyles = makeStyles(theme => ({
  customFilter: {
    display: "flex",
    gap: `${theme.spacing(1)}px`,
  },
  horizontalInputsContainer: {
    display: "flex",
    gap: `${theme.spacing()}px`,
  },
  progress: {
    margin: theme.spacing(),
  },
}));

const Actions = ({ basePath, hasCreate }) => (
  <TopToolbar>{hasCreate && <CreateButton basePath={basePath} />}</TopToolbar>
);

const PrintJobsFilter = ({ ...props }) => {
  const translate = useTranslate();
  const classes = useStyles();

  return (
    <CustomFilter {...props} saveFilter={false} className={classes.customFilter}>
      <TextInput source="id" label={translate(getKey("id", resources.PRINT_JOBS))} />
      <SelectLongArrayInput
        source="status"
        label={translate(getKey("status", resources.PRINT_JOBS))}
        optionText="translation"
        optionValue="key"
        choices={Object.values(PrintJobStatus)}
        defaultValue={Object.keys(PrintJobStatus).filter(it => it !== "DONE")}
      />
    </CustomFilter>
  );
};

const DownloadDocument = ({ record: { __id } = {} }) => {
  const translate = useTranslate();

  return (
    <DownloadButton
      id={__id}
      label={translate(getKey("downloadButton", resources.PRINT_JOBS))}
      downloadPath="/pdf/printjob"
      fileName={translate(getKey("downloadedFileName", resources.PRINT_JOBS), { id: __id })}
    />
  );
};

const IndividualModelDownloadButton = ({ record }) => {
  const classes = useStyles();
  const { data: jobs, loading } = useGetList(resources.JOBS, { page: 1, perPage: 100 }, {}, { printJob: record.__id });
  const ids = Object.values(jobs).map(it => it.id);

  if (loading) {
    return <CircularProgress className={classes.progress} />;
  }

  if (!Array.isArray(ids) || !ids.length) {
    return null;
  }

  return <ModelDownloadButton ids={ids} />;
};

const PrintJobsList = ({ ...props }) => {
  const translate = useTranslate();
  const [mutate, { loading }] = useMutation();

  const handlePrintJobStatusChange = (record, status, idField, source) => {
    mutate({
      type: "update",
      resource: resources.PRINT_JOBS,
      payload: {
        id: get(record, idField),
        data: { [source]: status },
      },
    });
  };

  return (
    <List
      {...props}
      actions={<Actions />}
      bulkActionButtons={false}
      pagination={<Pagination {...paginationOptions} />}
      sort={{ field: "completedAt", order: "ASC" }}
      filters={<PrintJobsFilter />}
    >
      <Datagrid>
        <NumberField source="__id" label={translate(getKey("id", resources.PRINT_JOBS))} sortBy="id" />
        <EditableDateField source="printedAt" idField="id" format={translate("format.date.dateTimeFormat")} dateTime />
        <NumberField source="height" />
        <NumberField source="printDuration" label={translate(getKey("printDuration", resources.PRINT_JOBS))} />
        <DateField source="completedAt" displayFormat="DD. MMMM YYYY HH:mm" />
        <DateField source="packedAt" />
        <ReferenceField link={false} reference={resources.USERS} source="assignedTo" sortBy="assignedTo.username">
          <TextField source="username" />
        </ReferenceField>
        <ReferenceField link={false} reference={resources.PRINTERS} source="printer" sortBy="printer.name">
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField link={false} reference={resources.BUILD_UNITS} source="buildUnit" sortBy="buildUnit.name">
          <TextField source="name" />
        </ReferenceField>
        <TextField source="notes" />
        <CustomDropdownField
          source="status"
          idField="id"
          dropdownOptionsObject={PrintJobStatus}
          changeHandler={handlePrintJobStatusChange}
          isLoading={loading}
        />
        <DownloadDocument />
        <IndividualModelDownloadButton record={props.record} />
        <EditButton />
      </Datagrid>
    </List>
  );
};

export default PrintJobsList;
