import React, { useCallback } from "react";
import PropTypes from "prop-types";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { withStyles } from "@material-ui/core/styles";
import { fade } from "@material-ui/core/styles/colorManipulator";
import ActionCheck from "@material-ui/icons/CheckCircle";
import AlertError from "@material-ui/icons/ErrorOutline";
import { translate } from "react-admin";

const styles = theme => ({
  contentText: {
    minWidth: 400,
  },
  confirmPrimary: {
    color: theme.palette.primary.main,
  },
  confirmWarning: {
    color: theme.palette.error.main,
    "&:hover": {
      backgroundColor: fade(theme.palette.error.main, 0.12),
      // Reset on mouse devices
      "@media (hover: none)": {
        backgroundColor: "transparent",
      },
    },
  },
  iconPaddingStyle: {
    paddingRight: "0.5em",
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
});

const Confirm = ({
  classes,
  translate,
  isOpen,
  loading,
  title,
  content,
  confirm,
  cancel,
  confirmColor,
  ConfirmIcon,
  CancelIcon,
  onClose,
  onConfirm,
  disabled,
}) => {
  const handleConfirm = useCallback(
    e => {
      e.stopPropagation();
      onConfirm();
    },
    [onConfirm]
  );

  const handleClick = useCallback(e => {
    e.stopPropagation();
  }, []);

  return (
    <Dialog open={isOpen} onClose={onClose} onClick={handleClick} aria-labelledby="alert-dialog-title">
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText className={classes.contentText}>{content}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button disabled={loading} onClick={onClose}>
          <CancelIcon className={classes.iconPaddingStyle} />
          {cancel ? cancel : translate("ra.action.cancel")}
        </Button>
        <Button
          disabled={loading || disabled}
          onClick={handleConfirm}
          className={`ra-confirm ${confirmColor ? confirmColor : ""}`}
          autoFocus
        >
          {loading ? (
            <CircularProgress size={24} className={classes.buttonProgress} />
          ) : (
            <ConfirmIcon className={classes.iconPaddingStyle} />
          )}
          {confirm ? confirm : translate("ra.action.confirm")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

Confirm.propTypes = {
  classes: PropTypes.object.isRequired,
  translate: PropTypes.func.isRequired,

  cancel: PropTypes.string,
  confirm: PropTypes.string,
  confirmColor: PropTypes.string,
  ConfirmIcon: PropTypes.elementType.isRequired,
  CancelIcon: PropTypes.elementType.isRequired,
  content: PropTypes.string.isRequired,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  disabled: PropTypes.bool.isRequired,
};

Confirm.defaultProps = {
  classes: {},
  ConfirmIcon: ActionCheck,
  CancelIcon: AlertError,
  isOpen: false,
  loading: false,
  disabled: false,
};

export default withStyles(styles)(translate(Confirm));
