import amber from "@material-ui/core/colors/amber";
import red from "@material-ui/core/colors/red";
import lightBlue from "@material-ui/core/colors/lightBlue";
import blue from "@material-ui/core/colors/lightBlue";
import deepOrange from "@material-ui/core/colors/deepOrange";
import grey from "@material-ui/core/colors/grey";

const translationPrefix = "notificationStyles.";

export const NotificationStyles = {
  LOWEST: {
    key: "LOWEST",
    translation: `${translationPrefix}LOWEST`,
    color: grey[100],
  },
  INFO: {
    key: "INFO",
    translation: `${translationPrefix}INFO`,
    color: lightBlue[100],
  },
  UPDATE: {
    key: "UPDATE",
    translation: `${translationPrefix}UPDATE`,
    color: blue[500],
  },
  WARNING: {
    key: "WARNING",
    translation: `${translationPrefix}WARNING`,
    color: amber[500],
  },
  ALERT: {
    key: "ALERT",
    translation: `${translationPrefix}ALERT`,
    color: deepOrange[600],
  },
  CRITICAL: {
    key: "CRITICAL",
    translation: `${translationPrefix}CRITICAL`,
    color: red[900],
  },
};

export default NotificationStyles;
