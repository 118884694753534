import createAction, { createActionWithMeta } from "../../../lib/createAction";

export const ACTION_ON_MODEL = "ACTION_ON_MODEL";
export const actionOnModel = createAction(ACTION_ON_MODEL, "modelId", "action", "body", "qs", "subpath");

export const ACTION_ON_MODEL_SUCCESS = "ACTION_ON_MODEL_SUCCESS";
export const actionOnModelSuccess = createActionWithMeta(
  ACTION_ON_MODEL_SUCCESS,
  {
    uncache: true,
    refresh: true,
    notification: {
      body: "ra.notification.updated",
      level: "info",
    },
  },
  "response"
);

export const ACTION_ON_MODEL_ERROR = "ACTION_ON_MODEL_ERROR";
export const actionOnModelError = createActionWithMeta(
  ACTION_ON_MODEL_ERROR,
  {
    uncache: true,
    refresh: true,
    notification: {
      body: "ra.notification.bad_item",
      level: "warning",
    },
  },
  "error"
);

export const MODEL_ADDITION_DONE = "MODEL_ADDITION_DONE";
export const modelAdditionDone = modelId => actionOnModel(modelId, "additionDone");

export const MODEL_QA_DONE = "MODEL_QA_DONE";
export const modelQaDone = (modelId, problemFound = false, message) =>
  actionOnModel(modelId, "qaDone", { problemFound, message });
