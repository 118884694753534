import createAction, { createActionWithMeta } from "../../../lib/createAction";

export const FETCH_FILES_REQUEST = "FETCH_FILES_REQUEST";
export const fetchFile = createAction(FETCH_FILES_REQUEST, "fileUuid", "fileToken");

export const FETCH_FILES_SUCCESS = "FETCH_FILES_SUCCESS";
export const fetchFileSuccess = createAction(FETCH_FILES_SUCCESS, "fileUuid", "response");

export const FETCH_FILES_ERROR = "FETCH_FILES_ERROR";
export const fetchFileError = createActionWithMeta(
  FETCH_FILES_ERROR,
  {
    notification: {
      body: "ra.message.error",
      level: "warning",
    },
  },
  "fileUuid",
  "error"
);
