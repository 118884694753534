import createReducer, { mergeReducers } from "../../lib/createReducer";
import { DOWNLOAD_FILE_REQUEST, DOWNLOAD_FILE_SUCCESS, DOWNLOAD_FILE_ERROR } from "./action";

const init = {
  isFetching: false,
  fetchSuccess: false,
  hasError: false,
  payload: null,
  error: null,
};

const requestReducer = createReducer(
  DOWNLOAD_FILE_REQUEST,
  () => ({
    ...init,
    isFetching: true,
  }),
  true
);

const successReducer = createReducer(DOWNLOAD_FILE_SUCCESS, ({ response }) => ({
  isFetching: false,
  fetchSuccess: true,
  payload: response,
}));

const errorReducer = createReducer(DOWNLOAD_FILE_ERROR, ({ error }) => ({
  isFetching: false,
  hasError: true,
  error,
}));

export default mergeReducers([requestReducer, successReducer, errorReducer], init);
