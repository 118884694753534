import map from "lodash/map";
import red from "@material-ui/core/colors/red";

export const validate = (code = "", parameters = {}) => {
  const mappedParams = map(parameters, (value, key) =>
    value === null || value === undefined
      ? key
      : isNaN(parseFloat(value)) && typeof value !== "function"
      ? `${key}="${value}"`
      : `${key}=(${value.toString()})`
  );

  let codePrefix = "";
  if (mappedParams.length > 0) codePrefix = "let " + mappedParams.join(",") + ";";

  try {
    // eslint-disable-next-line no-eval
    const result = eval(codePrefix + code);

    return {
      hasError: false,
      errorLineNumber: null,
      errorColumnNumber: null,
      result,
    };
  } catch (e) {
    try {
      const data = e.stack.match(/<anonymous>:([0-9]+):([0-9]+)/);
      return {
        hasError: true,
        error: e,
        errorLineNumber: Math.max(parseInt(data[1]) - 1, 0),
        errorColumnNumber: parseInt(data[2]) - 1,
        result: null,
      };
    } catch (ignored) {
      return {
        hasError: true,
        error: e,
        errorLineNumber: null,
        errorColumnNumber: null,
        result: null,
      };
    }
  }
};

export const highlight = ({ hasError = false, errorLineNumber, codeParameters = {} }) => (val = "") => {
  let result = val;

  if (hasError && typeof errorLineNumber === "number" && errorLineNumber >= 0) {
    const lines = val.split("\n");
    const errorLine = lines[errorLineNumber];
    if (!errorLine) return val;
    lines[errorLineNumber] = `<u style="color: ${red[500]}; text-decoration-style: wavy;">${errorLine}</u>`;
    result = lines.join("\n");
  }

  const varPattern = Object.keys(codeParameters)
    .map(it => `${it}(\\(.*?\\))?`)
    .join("|");
  result = result.replace(
    new RegExp(varPattern, "g"),
    match => `<b style="background-color: #FFFFFF; border-radius: 3px; color: #000000">${match}</b>`
  );

  result = result.replace(/var|let|const|if|else|return|Math/g, match => `<b>${match}</b>`);

  return result;
};
