import React, { Fragment } from "react";
import {
  Button,
  ChipField,
  Datagrid,
  FunctionField,
  Labeled,
  ReferenceField,
  ReferenceManyField,
  Show,
  SimpleShowLayout,
  SingleFieldList,
  TextField,
  translate,
} from "react-admin";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import resources from "../../config/resources";
import StatusField from "../customFields/StatusField";
import { getKey } from "../../lib/i18nUtils";
import DeclineModelButton from "../models/DeclineModelButton";
import RohStatus from "../../config/RohStatus";
import get from "lodash/get";
import DeclineModelReasons from "../../config/DeclineModelReasons";
import Chip from "@material-ui/core/Chip";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import DateField from "../customFields/DateField";
import ConfirmJobButton from "./ConfirmJobButton";
import DownloadAllModelsButton from "../models/DownloadAllModelsButton";
import IntransportJobButton from "./IntransportJobButton";
import PriceField from "../customFields/PriceField";
import DownloadModelButton from "../models/DownloadModelButton";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import blue from "@material-ui/core/colors/lightBlue";
import DocumentsBox from "../documents/DocumentsBox";
import ClickableImageField from "../customFields/ClickableImageField";
import Title from "../customFields/Title";
import sanitizeButton from "../sanitizeButton";
import red from "@material-ui/core/colors/red";
import PortfolioButton from "../documents/PortfolioButton";

const flForm = (str, digits = 2) => (isNaN(str) ? "" : parseFloat(str).toFixed(digits));

const Action = ({ translate, ...props }) => {
  if (!props.record) return null;
  switch (props.record.rohStatus) {
    case RohStatus.OFFER_SENT.apiValue:
      return <DeclineModelButton {...props} />;
    case RohStatus.NEEDS_UPDATE.apiValue:
      return (
        <ReferenceField {...props} source="lastStatusUpdate" reference={resources.MODEL_STATUS_UPDATES} link={false}>
          <FunctionField
            render={record => {
              const key = get(record, "reasonFlat.key");
              if (!key) return null;
              const transl = get(DeclineModelReasons, `${key}.translation`);
              if (!transl) return null;

              const lbl = record.message ? `${translate(transl)} (${record.message})` : translate(transl);

              return (
                <Fragment>
                  <StatusField record={record} source="status" useChip={true} />
                  <Chip
                    label={lbl}
                    style={{
                      paddingRight: 0,
                      backgroundColor: RohStatus.DECLINED.primaryColor,
                      color: RohStatus.DECLINED.textColor,
                      border: `solid 2px ${RohStatus.DECLINED.borderColor}`,
                    }}
                  />
                </Fragment>
              );
            }}
          />
        </ReferenceField>
      );
    default:
      return null;
  }
};

const Head = ({ translate, ...props }) => (
  <Grid container>
    <Grid item xs={12} sm={6} md={3} lg={2}>
      <Labeled label={getKey("externalId", resources.JOBS)}>
        <TextField {...props} source="externalId" style={{ fontWeight: 700, fontSize: "1.4em" }} />
      </Labeled>
    </Grid>
    <Grid item xs={12} sm={6} md={3} lg={2}>
      <Labeled label={getKey("dateEstimate", resources.JOBS)}>
        <DateField {...props} source="dateEstimate" />
      </Labeled>
    </Grid>
    <Grid item xs={12} sm={6} md={3} lg={2}>
      <Labeled label={getKey("contractorPrice", resources.JOBS)}>
        <PriceField
          style={{
            fontWeight: 700,
            fontSize: "1.4em",
            borderBottom: "double 3px #000",
          }}
          currencySource="price.currency"
          valueSource="price.amount"
          {...props}
        />
      </Labeled>
    </Grid>
    <Grid item xs={12} sm={6} md={3} lg={2}>
      <Labeled label={getKey("status", resources.JOBS)}>
        <StatusField {...props} source="status" useChip={true} addLabel={true} isContractorView={true} />
      </Labeled>
    </Grid>
    <Grid item xs={12} sm={6} md={3} lg={2}>
      <Labeled label="downloadAll" resource={props.resource}>
        <ReferenceManyField {...props} perPage={1000} source="__id" reference={resources.MODELS} target="job#id">
          <DownloadAllModelsButton
            variant="contained"
            color="default"
            uuidSource="storageServiceUuid"
            tokenSource="storageServiceToken"
            legacyUrlSource="modelFileUrl"
          />
        </ReferenceManyField>
      </Labeled>
    </Grid>
    {get(props, "record.contractorIssueResolveNote") && (
      <Grid item xs={12} style={{ marginBottom: 24 }}>
        <Paper
          style={{
            padding: 16,
            backgroundColor: blue[50],
            border: `solid 3px ${blue[600]}`,
            borderRadius: 8,
          }}
          elevation={2}
        >
          <Typography variant="subtitle1">{translate("contractorIssueResolveNoteExternalView")}</Typography>
          <Typography variant="body1">
            {(get(props, "record.contractorIssueResolveNote") || "")
              .split("\n")
              .flatMap((txt, i) => [<br key={`issueResolveNote-${i}`} />, txt])}
          </Typography>
        </Paper>
      </Grid>
    )}
  </Grid>
);

const SubActions = ({ permissions, ...props }) => (
  <div style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
    <div style={{ display: "flex", justifyContent: "flex-start", width: "50%" }}>
      <ReferenceField {...props} source="contractor" reference={resources.CONTRACTORS} link={false}>
        <FunctionField
          render={contractor =>
            contractor &&
            contractor.contractorType === "INTERNAL" && <PortfolioButton style={{ justifySelf: "start" }} {...props} />
          }
        />
      </ReferenceField>
    </div>
    <div style={{ display: "flex", justifyContent: "flex-end", width: "50%" }}>
      <ConfirmAction {...props} />
      <IntransportAction {...props} />
    </div>
  </div>
);

const _ConfirmAction = ({ translate, record, classes, ...props }) => {
  if (record.awaitContractorOrderConfirmation) {
    return (
      <div style={{ textAlign: "right" }}>
        <Button
          {...sanitizeButton(props)}
          className={classes.ocMissingButton}
          label={translate(getKey("orderConfirmationMissing", resources.JOBS))}
          variant="raised"
          align="right"
          disabled
        />
      </div>
    );
  }

  return (
    <div style={{ textAlign: "right" }}>
      <ConfirmJobButton
        {...props}
        record={record}
        label={translate(getKey("accept", resources.JOBS))}
        disabled={record.status !== RohStatus.OFFER_SENT.apiValue || record.awaitContractorOrderConfirmation}
        variant="raised"
        color="default"
        align="right"
      />
    </div>
  );
};

const IntransportAction = ({ translate, record, ...props }) => (
  <div style={{ textAlign: "right" }}>
    <IntransportJobButton
      {...props}
      record={record}
      label={translate(getKey("setInTransport", resources.JOBS))}
      variant="raised"
      disabled={record.status !== RohStatus.IN_PRODUCTION.apiValue}
      color="primary"
      align="right"
    />
  </div>
);

const ModelPriceField = ({ jobProps, ...props }) => (
  <ReferenceManyField
    {...props}
    reference={resources.PRICE_ESTIMATES}
    target="model#id"
    filter={{ "job.id": jobProps.record.__id }}
  >
    <SingleFieldList linkType={false}>
      <PriceField
        currencySource="price.currency"
        valueSource="price.amount"
        style={{
          fontWeight: 700,
          textDecoration: "underline",
        }}
      />
    </SingleFieldList>
  </ReferenceManyField>
);

const _ModelsField = ({ translate, classes, ...props }) => (
  <ReferenceManyField target="job#id" {...props} reference={resources.MODELS} perPage={1000} source="__id">
    <Datagrid>
      <TextField source="rapidshopId" />
      <ClickableImageField
        classes={{ image: classes.image, container: classes.imageContainer }}
        source="modelPreviewUrl"
        stlSource="modelFileUrl"
      />
      <ReferenceField
        source="order"
        reference={resources.ORDERS}
        link={false}
        label={translate(getKey("contactorRapidshopId", resources.ORDERS))}
      >
        <ChipField source="rapidshopId" style={{ fontWeight: 700, backgroundColor: blue[100] }} />
      </ReferenceField>
      <ReferenceField source="material" reference={resources.MATERIALS} link={false}>
        <TextField source="methodName" />
      </ReferenceField>
      <ReferenceField source="material" reference={resources.MATERIALS} link={false}>
        <FunctionField source="name" render={({ name = "" }) => name.replace(/\(.*?Preis.*?\)/, "").trim()} />
      </ReferenceField>
      <FunctionField
        label={translate(getKey("sizeHWL", resources.MODELS))}
        render={({ height, width, length }) => `${flForm(length)} x ${flForm(width)} x ${flForm(height)} mm`}
      />
      <FunctionField
        label={translate(getKey("volume", resources.MODELS))}
        render={({ volume }) => `${flForm(volume, 4)} mm³`}
      />
      <TextField source="amount" style={{ fontWeight: 700 }} />
      <ModelPriceField jobProps={props} label={translate(getKey("priceValueContractor", resources.PRICE_ESTIMATES))} />
      <FunctionField
        label={translate(getKey("noteToContractorCV", resources.MODELS))}
        render={({ noteToContractor }) =>
          noteToContractor ? (
            <span
              style={{
                fontWeight: 700,
                backgroundColor: "#FFFFAA",
                padding: 2,
              }}
            >
              {(noteToContractor || "").split("\n").flatMap((it, i) => [it, <br key={`noteToContractor-${i}`} />])}
            </span>
          ) : null
        }
      />
      <DownloadModelButton
        {...props}
        variant="contained"
        color="default"
        uuidSource="storageServiceUuid"
        tokenSource="storageServiceToken"
        legacyUrlSource="modelFileUrl"
        size="small"
        label={translate(getKey("modelFileUrl", resources.MODELS))}
        textAlign="right"
      >
        <CloudDownloadIcon fontSize="inherit" />
        &nbsp;{translate(getKey("download", resources.MODELS))}
      </DownloadModelButton>
      <Action jobId={props.record.__id} translate={translate} textAlign="right" label={translate("action")} />
    </Datagrid>
  </ReferenceManyField>
);

const styles = theme => ({
  commentBox: {
    marginTop: theme.spacing(4),
  },
  root: {
    padding: theme.spacing(2),
  },
  column: {
    display: "flex",
    flexDirection: "column",
  },
  image: {
    maxWidth: 50,
  },
  imageContainer: {
    marginTop: 10,
  },
  ocMissingButton: {
    backgroundColor: `${red[500]} !important`,
    color: theme.palette.getContrastText(red[500]),
  },
});

const ModelsField = withStyles(styles)(translate(_ModelsField));
const ConfirmAction = withStyles(styles)(translate(_ConfirmAction));

//goto /login?redir=/jobs/0/show&auth=Y29udHJhY3Rvcl9pbnRlcm5hbDp0ZXN0
const ContractorJobView = ({ translate, classes, ...props }) => {
  //TODO: remove me in 2021 pls
  const id = window.location.hash.replace("#/jobs/", "").replace("/show", "");
  if (!isNaN(Number(id))) {
    const uriPart = "%2Fjobs%2F";
    const redir = uriPart + id;
    window.location.hash = "#/jobs/" + redir + "/show";
  }

  return (
    <Show title={<Title resource={resources.CONTRACTORS} />} {...props}>
      <SimpleShowLayout>
        <Head translate={translate} />
        <DocumentsBox {...props} style={{ marginBottom: 16 }} target="job#id" />
        <Fragment>
          <Typography variant="subtitle1">{translate(getKey("models", resources.JOBS))}:</Typography>
        </Fragment>
        <ModelsField translate={translate} />
        <SubActions translate={translate} {...props} />
      </SimpleShowLayout>
    </Show>
  );
};

export default withStyles(styles)(translate(ContractorJobView));
