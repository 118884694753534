import React, { useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import get from "lodash/get";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/ErrorOutline";
import Grid from "@material-ui/core/Grid";
import {
  Datagrid,
  FunctionField,
  ListContextProvider,
  ReferenceField,
  ReferenceManyField,
  SingleFieldList,
  TextField,
  translate,
} from "react-admin";
import resources from "../../config/resources";
import { getKey } from "../../lib/i18nUtils";
import AssignModelButton from "../models/AssignModelButton";
import GroupingDatagridBody from "../GroupingDataGrid/GroupingDataGridBody";
import AssignIcon from "@material-ui/icons/AddShoppingCart";
import SaveIcon from "@material-ui/icons/Save";
import NoNoteWarningIcon from "@material-ui/icons/SpeakerNotesOff";
import KeepOrientationIcon from "@material-ui/icons/ScreenLockRotation";
import DontKeepOrientationIcon from "@material-ui/icons/ScreenRotation";
import AllowHollowIcon from "@material-ui/icons/StopOutlined";
import DoNotHollowIcon from "@material-ui/icons/Stop";
import PriceField from "../customFields/PriceField";
import MUITextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { noteToContractor, noteToContractorLocalState } from "../../redux/model/noteToContractor/action";
import Tooltip from "@material-ui/core/Tooltip";
import red from "@material-ui/core/colors/red";
import uniq from "lodash/uniq";
import compact from "lodash/compact";
import ReferenceGroupArrayFieldController from "../ReferenceGroupArrayFieldController";
import GroupedIndexColumn from "./GroupedIndexColumn";
import ReestimatePricesButton from "../models/ReestimatePricesButton";
import TimeLeftField from "./TimeLeftField";
import ErrorsList from "./ErrorsList";
import {
  getLocalNoteToContractor,
  getNoteToContractorFetchError,
  getNoteToContractorFetching,
  getNoteToContractorFetchSuccess,
} from "../../redux/model/noteToContractor/selector";
import { isEmpty } from "lodash";
import Switch from "@material-ui/core/Switch";

const styles = theme => ({
  dialogTitle: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  iconPaddingStyle: {
    paddingRight: "0.5em",
  },
  noteField: {
    width: "100%",
    marginTop: theme.spacing(2),
  },
  noteFieldRoot: {
    padding: 0,
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  noteFieldInput: {
    borderRadius: 4,
    backgroundColor: theme.palette.common.white,
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "10px 12px",
    width: "calc(100% - 24px)",
  },
  noteFieldLabel: {
    fontSize: 20,
  },
  addCustomerNoteButton: {
    marginTop: theme.spacing(2),
  },
  addNoteButton: {
    margin: theme.spacing(1),
    marginLeft: 0,
  },
});

const AssignMaterialDialog = ({
  translate,
  classes,
  isOpen,
  onClose,
  noteToContractorAction,
  permissions,
  modelIds,
  noteToContractorIsFetching,
  noteToContractorFetchSuccess,
  noteToContractorFetchError,
  setNoteState,
  noteToContractorLocalState,
  ...props
}) => {
  const [disableTextInputAndButton, setDisableTextInputAndButton] = useState(false);
  const [noteToContractorSaved, setNoteToContractorSaved] = useState(true);
  const [filter, setFilter] = useState({ keepOrientation: false, allowHollow: false });
  const [materialiseEstimates, setMaterialiseEstimates] = useState(false);

  const noteToContractor = noteToContractorLocalState;
  const setNoteToContractor = setNoteState;

  const resetState = () => {
    setNoteToContractor("");
  };

  const handleContractorNoteChange = evt => {
    setNoteToContractor(evt.target.value);
    setNoteToContractorSaved(false);
  };

  const addNoticeToNoteToContractor = notice => {
    if (noteToContractor === "") {
      setNoteToContractor(notice);
      setNoteToContractorSaved(false);
    } else {
      setNoteToContractor(noteToContractor + "\n" + notice);
      setNoteToContractorSaved(false);
    }
  };

  const getNoticesFromModels = models => {
    if (!models || typeof models !== "object" || Object.values(models).length < 1) {
      return [];
    } else {
      return uniq(compact(Object.values(models).map(model => model.notice)));
    }
  };

  const saveNoteToContractor = (modelIds = [], note = noteToContractor) => {
    noteToContractorAction(modelIds, note, true);
    setNoteToContractorSaved(true);
  };

  const onAssignButtonClick = () => {
    setDisableTextInputAndButton(true);
  };

  const priceEstimates = props.data && typeof props.data == "object" ? compact(Object.values(props.data)) : [];

  return (
    <Dialog maxWidth="lg" open={isOpen} onClose={onClose} onExited={resetState} aria-labelledby="assign-dialog-title">
      <DialogTitle className={classes.dialogTitle} disableTypography>
        <Typography id="assign-dialog-title" variant="h6">
          {translate(getKey("assign", resources.PRICE_ESTIMATES))}
        </Typography>
        <div>
          {materialiseEstimates && (
            <span style={{ fontSize: "0.8em", color: "rgba(0, 0, 0, 0.54)" }}>
              {translate("showOtherMaterialiseEstimates")}
            </span>
          )}
          {materialiseEstimates && (
            <Switch
              checked={!filter}
              onChange={({ target }) => {
                target.checked ? setFilter(null) : setFilter({ keepOrientation: false, allowHollow: false });
              }}
            />
          )}
          {permissions && Array.isArray(permissions.authorities) && permissions.authorities.includes("ROLE_SALES") && (
            <ReestimatePricesButton
              {...props}
              label="reestimatePrices"
              variant="outlined"
              color="default"
              fullWidth={true}
              alwaysOn={true}
              className={classes.button}
              source="reestimatePricesButton"
              modelIds={modelIds}
            />
          )}
        </div>
      </DialogTitle>
      <DialogContent>
        <ListContextProvider value={props}>
          <Datagrid
            {...props}
            body={
              <GroupingDatagridBody
                groupBy={["contractorId", "keepOrientation", "allowHollow"]}
                customFilter={filter}
              />
            }
            hover={false}
          >
            <GroupedIndexColumn sortBy="contractorId" label="Nr." />
            <ReferenceField
              source="contractor"
              groupingValue="contractorId"
              reference={resources.CONTRACTORS}
              link={false}
            >
              <TextField source="contractorName" />
            </ReferenceField>
            <ReferenceField
              source="model"
              reference={resources.MODELS}
              label={getKey("model", resources.PRICE_ESTIMATES)}
              link={false}
            >
              <TextField source="rapidshopId" />
            </ReferenceField>
            <ReferenceField
              source="model"
              reference={resources.MODELS}
              label={getKey("modelPriceValue", resources.PRICE_ESTIMATES)}
              link={false}
            >
              <FunctionField
                render={({ netPrice, amount }) => (
                  <span>
                    {isFinite(netPrice) && isFinite(amount)
                      ? (parseFloat(netPrice).toFixed(2) * Math.floor(amount)).toFixed(2)
                      : parseFloat(netPrice).toFixed(2)}
                    &nbsp;EUR
                  </span>
                )}
              />
            </ReferenceField>
            <PriceField label={getKey("priceValue", resources.PRICE_ESTIMATES)} source="price" />
            <FunctionField
              source="contractor"
              textAlign="right"
              groupingValue="contractorId"
              label={getKey("sumValue", resources.PRICE_ESTIMATES)}
              render={record =>
                Object.values(record["_GROUP"])
                  .reduce((acc, it) => acc + parseFloat(get(it, "price.amount")), 0.0)
                  .toFixed(2) +
                " " +
                get(record, "price.currency", "EUR")
              }
            />
            <FunctionField
              source="productionDays"
              textAlign="right"
              render={({ productionDays }) =>
                productionDays > 1 ? `${productionDays} Tage` : productionDays === 1 ? "1 Tag" : ""
              }
            />
            <TimeLeftField target="model#id" label=" " textAlign="right" translate={translate} />
            <FunctionField
              label=" "
              textAlign="right"
              render={({ keepOrientation }) =>
                keepOrientation === null ? null : keepOrientation === true ? (
                  <Tooltip title={<span style={{ fontSize: 16 }}>{translate("keepOrientation")}</span>}>
                    <KeepOrientationIcon />
                  </Tooltip>
                ) : (
                  <Tooltip title={<span style={{ fontSize: 16 }}>{translate("dontKeepOrientation")}</span>}>
                    <DontKeepOrientationIcon />
                  </Tooltip>
                )
              }
            />
            <FunctionField
              label=" "
              render={({ allowHollow }) =>
                allowHollow === null ? null : allowHollow === true ? (
                  <Tooltip title={<span style={{ fontSize: 16 }}>{translate("allowHollow")}</span>}>
                    <AllowHollowIcon />
                  </Tooltip>
                ) : (
                  <Tooltip title={<span style={{ fontSize: 16 }}>{translate("doNotHollow")}</span>}>
                    <DoNotHollowIcon />
                  </Tooltip>
                )
              }
            />
            <ReferenceField
              groupingValue="contractorId"
              source="contractor"
              label=" "
              textAlign="right"
              reference={resources.CONTRACTORS}
              link={false}
            >
              <FunctionField
                render={({ isMaterialise = false }) =>
                  isMaterialise
                    ? setMaterialiseEstimates(true) || (
                        <Tooltip
                          title={
                            <span style={{ fontSize: 16 }}>
                              {translate(getKey("noteToContractorNotSupported", resources.MODELS))}
                            </span>
                          }
                        >
                          <NoNoteWarningIcon />
                        </Tooltip>
                      )
                    : null
                }
              />
            </ReferenceField>
            <AssignModelButton
              groupingValue="contractorId"
              source="contractor"
              estimates={props.data}
              onClick={onAssignButtonClick}
              textAlign="right"
              handleAssigned={onClose}
              variant="contained"
              style={{ width: "100%" }}
              disabled={noteToContractorIsFetching}
            >
              <AssignIcon />
            </AssignModelButton>
          </Datagrid>
        </ListContextProvider>
        {modelIds && modelIds.length > 0 && (
          <ReferenceGroupArrayFieldController
            target="model#id"
            reference={resources.MATERIALISE_EXCEPTIONS}
            addLabel={false}
            targetIds={modelIds}
          >
            {controllerProps => <ErrorsList {...controllerProps} translate={translate} modelIds={modelIds} />}
          </ReferenceGroupArrayFieldController>
        )}
        {!noteToContractorIsFetching && !noteToContractorFetchSuccess && noteToContractorFetchError && (
          <Typography variant="subtitle1" style={{ color: red[900], marginTop: 8 }}>
            {translate(getKey("noteToContractorFailed", resources.MODELS))}
          </Typography>
        )}
        <Grid container direction="row">
          {Array.isArray(modelIds) && modelIds.length === 1 && priceEstimates.length > 0 && (
            <ReferenceField
              record={{ model: modelIds[0] }}
              basePath={props.basePath}
              source="model"
              reference={resources.MODELS}
              addLabel={false}
              link={false}
            >
              <FunctionField
                render={({ notice }) =>
                  notice &&
                  notice.length > 0 && (
                    <Button className={classes.addNoteButton} onClick={() => addNoticeToNoteToContractor(notice)}>
                      {translate(getKey("addNoticeToNoteToContractor", resources.MODELS))}
                    </Button>
                  )
                }
              />
            </ReferenceField>
          )}
          {Array.isArray(modelIds) && modelIds.length > 1 && priceEstimates.length > 0 && (
            <ReferenceGroupArrayFieldController
              {...props}
              reference={resources.MODELS}
              target="id"
              targetIds={modelIds}
            >
              {({ data }) => {
                const notices = getNoticesFromModels(data);
                return (
                  notices.length > 0 && (
                    <Button
                      className={classes.addNoteButton}
                      onClick={() => addNoticeToNoteToContractor(notices.join("\n\n"))}
                    >
                      {translate(getKey("addAllNoticesToNoteToContractor", resources.MODELS))}
                    </Button>
                  )
                );
              }}
            </ReferenceGroupArrayFieldController>
          )}
          {priceEstimates.length > 0 && (
            <ReferenceManyField
              {...props}
              reference={resources.CUSTOMERS}
              record={priceEstimates[0]}
              basePath={props.basePath}
              target="orders#shipments#models#priceEstimates#id"
              perPage={1}
            >
              <SingleFieldList linkType={false}>
                <FunctionField
                  render={({ note }) =>
                    note && (
                      <Button
                        className={classes.addCustomerNoteButton}
                        onClick={() => addNoticeToNoteToContractor(note)}
                      >
                        {translate(getKey("addCustomerNoteToNoteToContractor", resources.MODELS))}
                      </Button>
                    )
                  }
                />
              </SingleFieldList>
            </ReferenceManyField>
          )}
        </Grid>
        {modelIds && modelIds.length > 0 && priceEstimates.length > 0 && (
          <ReferenceGroupArrayFieldController
            {...props}
            reference={resources.MODELS}
            target="id"
            addLabel={false}
            targetIds={modelIds}
          >
            {({ data }) => {
              if (isEmpty(data)) return null;

              return (
                data && (
                  <MUITextField
                    label={translate(getKey("noteToContractor", resources.MODELS))}
                    multiline
                    rows={5}
                    className={classes.noteField}
                    InputProps={{
                      disableUnderline: true,
                      classes: {
                        root: classes.noteFieldRoot,
                        input: classes.noteFieldInput,
                      },
                    }}
                    InputLabelProps={{
                      shrink: true,
                      className: classes.noteFieldLabel,
                    }}
                    value={noteToContractor}
                    onChange={handleContractorNoteChange}
                    disabled={disableTextInputAndButton}
                  />
                )
              );
            }}
          </ReferenceGroupArrayFieldController>
        )}
        {priceEstimates.length === 0 && (
          <Typography variant="subtitle1" style={{ color: red[900], marginTop: 8 }}>
            {translate(getKey("missingPriceEstimates", resources.MODELS))}
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => saveNoteToContractor(modelIds)}
          variant="contained"
          color="secondary"
          disabled={disableTextInputAndButton || noteToContractorSaved}
        >
          <SaveIcon className={classes.iconPaddingStyle} />
          {translate(getKey("appendNoteToContractor", resources.MODELS))}
        </Button>
        <Button onClick={onClose}>
          <CloseIcon className={classes.iconPaddingStyle} />
          {translate("ra.action.cancel")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

AssignMaterialDialog.propTypes = {
  translate: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,

  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  permissions: PropTypes.object.isRequired,
};

AssignMaterialDialog.defaultProps = {
  isOpen: false,
  classes: {},
};

const mapStateToProps = state => ({
  noteToContractorIsFetching: getNoteToContractorFetching(state),
  noteToContractorFetchSuccess: getNoteToContractorFetchSuccess(state),
  noteToContractorFetchError: getNoteToContractorFetchError(state),
  noteToContractorLocalState: getLocalNoteToContractor(state),
});

const mapDispatchToProps = {
  noteToContractorAction: noteToContractor,
  setNoteState: noteToContractorLocalState,
};

export default withStyles(styles)(
  translate(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(AssignMaterialDialog)
  )
);
