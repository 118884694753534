export default {
  INTERNAL: {
    key: "INTERNAL",
    translation: "contractorType.INTERNAL",
    requiresUser: true,
  },
  EXTERNAL: {
    key: "EXTERNAL",
    translation: "contractorType.EXTERNAL",
    requiresUser: true,
  },
  DUMMY: {
    key: "DUMMY",
    translation: "contractorType.DUMMY",
    requiresUser: false,
  },
  MATERIALISE: {
    key: "MATERIALISE",
    translation: "contractorType.MATERIALISE",
    requiresUser: false,
  },
  MATERIALISE_FASTLANE: {
    key: "MATERIALISE_FASTLANE",
    translation: "contractorType.MATERIALISE_FASTLANE",
    requiresUser: false,
  },
};
