import { call, put, takeEvery } from "redux-saga/effects";
import resources from "../../../config/resources";
import config from "../../../config";
import fetchService from "../../../lib/fetchService";
import { SHARE_FILTER_GRAPH_REQUEST, shareFilterGraphError, shareFilterGraphSuccess } from "./action";
import { crudGetOne } from "react-admin";
import { handleSagaError } from "../../../dataproviders/errorResponseHandler";

const requestSaga = function*({ payload }) {
  const { id, sharedWith, authorities } = payload || {};

  if (!isFinite(id) || !Array.isArray(sharedWith) || !Array.isArray(authorities)) {
    yield put(shareFilterGraphError());
    return;
  }

  const url = `${config.urls.BACKEND}/v2/${resources.FILTER_GRAPHS}/${id}/share`;
  const body = { sharedWith, authorities };
  const response = yield call(fetchService.post, url, {}, body);

  for (const action of yield call(handleSagaError, response, shareFilterGraphError)) {
    yield put(action);
    return;
  }

  try {
    if (response.ok) {
      yield put(shareFilterGraphSuccess());
      yield put(crudGetOne(resources.FILTER_GRAPHS, id));
    } else {
      const statusCode = response.status;
      yield put(shareFilterGraphError(new Error(`Request failed with error ${statusCode}!`)));
    }
  } catch (e) {
    yield put(shareFilterGraphError(e));
  }
};

export default function*() {
  yield takeEvery(SHARE_FILTER_GRAPH_REQUEST, requestSaga);
}
