import React from "react";
import { Create } from "react-admin";
import { Container } from "@material-ui/core";

import PrintJobForm from "./PrintJobForm";

const PrintJobCreate = ({ translate, ...props }) => {
  return (
    <Container maxWidth="md">
      <Create {...props}>
        <PrintJobForm />
      </Create>
    </Container>
  );
};

export default PrintJobCreate;
