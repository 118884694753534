import React, { Fragment } from "react";
import { Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { green, lightBlue, red, yellow } from "@material-ui/core/colors";
import { AddCircle, Assignment, CloudDownload, PanTool, SentimentSatisfied } from "@material-ui/icons";
import { withRouter } from "react-router-dom";
import { get, orderBy } from "lodash";
import {
  FunctionField,
  ListButton,
  ReferenceField,
  ReferenceManyField,
  TextField,
  translate,
  useQueryWithStore,
  SingleFieldList,
} from "react-admin";

import resources from "../../config/resources";
import RohStatus from "../../config/RohStatus";
import AssignModelButton from "../AssignDialog/AssignModelButton";
import AssignMaterialButton from "../AssignDialog/AssignMaterialButton";
import ModelFilesDialogButton from "../files/ModelFilesDialogButton";
import UnassignModelButton from "../models/UnassignModelButton";
import { getKey } from "../../lib/i18nUtils";
import DeclineModelReasons from "../../config/DeclineModelReasons";
import MultiChip from "../MultiChip";
import AutoAssignJobStatusField from "../autoAssignLogs/AutoAssignJobStatusField";
import ReferenceManyMapper from "../ReferenceManyMapper";
import OrderWarnings from "../customFields/OrderWarnings";
import StatusField from "../customFields/StatusField";
import ClickableImageField from "../customFields/ClickableImageField";
import DownloadModelButton from "../models/DownloadModelButton";
import DownloadAllModelsButton from "../models/DownloadAllModelsButton";
import FixModelDataButton from "../models/FixModelDataButton";
import ModelActionButton from "../models/ModelActionButton";
import { modelAdditionDone, modelQaDone } from "../../redux/model/modelActions/action";
import PriceColumn from "./PriceColumn";
import WarehouseCompartmentChip from "../warehouseComparments/WarehouseCompartmentChip";
import PrintJobChip from "../printJobs/PrintJobChip";

const styles = theme => ({
  root: {
    padding: theme.spacing(1),
  },
  column: {
    display: "flex",
    flexDirection: "column",
  },
  image: {
    maxWidth: 50,
  },
  imageContainer: {
    marginTop: 10,
  },
  button: {
    width: "100%",
    marginBottom: theme.spacing(1),
  },
  contractorPanel: {
    marginBottom: theme.spacing(1),
    backgroundColor: lightBlue[100],
    color: "#000000",
    padding: theme.spacing(1),
    fontSize: "1.3em",
    border: `solid 2px ${lightBlue[500]}`,
    textAlign: "center",
  },
  // this is neccessary to override the default material ui styles for disabled buttons
  buttonDisabled: {},
  hlButton: {
    width: "100%",
    marginBottom: theme.spacing(1),
    backgroundColor: yellow[700],
    color: theme.palette.getContrastText(yellow[700]),
    "&:hover": {
      backgroundColor: yellow[800],
    },
  },
  label: {
    fontWeight: 700,
  },
  indexField: {
    fontWeight: 700,
  },
  stretchChildren: {
    width: "100%",
    "& > *": {
      width: "100%",
    },
  },
  modelPreviewSpacing: {
    marginTop: theme.spacing(1),
  },
  form: {
    display: "flex",
    flexWrap: "wrap",
  },
  cellWrapper: {
    display: "flex",
    alignItems: "center",
    height: 50,
    width: 125,
  },
  checkSum: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    fontWeight: 700,
    fontSize: "18px",
    marginLeft: 7,
  },
  input: {
    width: "40%",
    height: "60%",
    marginLeft: 5,
    textAlign: "center",
  },
  epsilon: {
    marginRight: 115,
  },
});

const enhance = cmp => withStyles(styles)(translate(cmp));

const _MaterialColumn = ({
  classes,
  translate,
  paymentComplete,
  groupIndex,
  permissions,
  showAssignButton = true,
  ...props
}) => (
  <div className={classes.root}>
    <div style={{ marginBottom: 8 }}>
      <ReferenceField {...props} source="material" reference={resources.MATERIALS} link={false}>
        <FunctionField
          className={classes.button}
          render={({ name, methodKey }) => (
            <span>
              <b>{name}</b>
              <br />({methodKey})
            </span>
          )}
        />
      </ReferenceField>
    </div>
    <FunctionField
      {...props}
      render={record => {
        const group = record["_GROUP"];
        if (!group || typeof group !== "object") return null;
        return (
          <DownloadAllModelsButton
            ids={Object.keys(group)}
            data={group}
            resource={props.resource}
            uuidSource="storageServiceUuid"
            tokenSource="storageServiceToken"
            legacyUrlSource="modelFileUrl"
            variant="contained"
            color="default"
            className={classes.button}
            alignIcon="left"
          />
        );
      }}
    />
    <FunctionField
      {...props}
      addLabel={false}
      render={record =>
        Object.values(record["_GROUP"]).reduce(
          (acc, it) => acc && it.rohStatus !== RohStatus.UNASSIGNED.apiValue,
          true
        ) || !showAssignButton ? null : (
          <ReferenceField {...props} source="order" reference={resources.ORDERS} link={false}>
            <FunctionField
              render={({ waitForPaymentComplete }) => (
                <AssignMaterialButton
                  {...props}
                  variant="contained"
                  color="default"
                  label={translate(getKey("assign", resources.MATERIALS))}
                  className={classes.button}
                  paymentWarning={!paymentComplete && waitForPaymentComplete}
                  permissions={permissions}
                />
              )}
            />
          </ReferenceField>
        )
      }
    />
  </div>
);
const MaterialColumn = enhance(_MaterialColumn);

const _PreviewColumn = ({ classes, translate, groupIndex, permissions, ...props }) => (
  <div label={translate(getKey("modelPreviewUrl", resources.MODELS))} className={`${classes.root} ${classes.column}`}>
    <div>
      <StatusField {...props} source="rohStatus" />
      <ReferenceField
        {...props}
        source="lastStatusUpdate"
        reference={resources.MODEL_STATUS_UPDATES}
        link={false}
        allowEmpty={true}
      >
        <FunctionField
          render={({ status, reason, reasonFlat, message }) => {
            if (!reason || typeof reason !== "object" || !reason.hasOwnProperty("key")) {
              reason = reasonFlat;
            }

            const rohStatus = get(props, "record.rohStatus");
            if (!status || !reason || status !== rohStatus || !reason.key) return null;

            const statusDetails = RohStatus[status];
            const reasonDetails = DeclineModelReasons[reason.key];
            if (!reasonDetails || !statusDetails) return null;

            const values = [
              {
                label: translate(reasonDetails.translation),
                color: statusDetails.textColor,
                backgroundColor: statusDetails.primaryColor,
              },
            ];

            if (message) {
              values.splice(0, 0, {
                label: message,
                color: statusDetails.textColor,
                backgroundColor: statusDetails.primaryColor,
                styles: { maxWidth: 200 },
              });
            }

            return <MultiChip values={values} unlimitedHeight={true} />;
          }}
        />
      </ReferenceField>
      <ReferenceManyField
        {...props}
        record={props.record}
        reference={resources.AUTO_ASSIGN_LOGS}
        target="model#id"
        sort={{ field: "created", order: "DESC" }}
      >
        <ReferenceManyMapper>
          {({ data }) => {
            const currentAutoAssignLog = data && orderBy(Object.values(data), "created", "desc")[0];

            return currentAutoAssignLog ? (
              <AutoAssignJobStatusField
                rohStatus={get(props, "record.rohStatus")}
                logStatus={currentAutoAssignLog.status}
              />
            ) : null;
          }}
        </ReferenceManyMapper>
      </ReferenceManyField>
    </div>
    <div className={classes.modelPreviewSpacing}>
      <ClickableImageField
        {...props}
        classes={{ image: classes.image, container: classes.imageContainer }}
        source="modelPreviewUrl"
        stlSource="modelFileUrl"
      />
    </div>
    <WarehouseCompartmentChip record={props.record} source="warehouseCompartment" sourceResource={resources.MODELS} />
    <ReferenceManyField
      {...props}
      record={props.record}
      perPage={1000}
      reference={resources.PRINT_JOB_MODELS}
      target="model#id"
    >
      <SingleFieldList style={{ marginTop: 0, width: "100%" }} linkType={false}>
        <PrintJobChip style={{ width: "100%" }} />
      </SingleFieldList>
    </ReferenceManyField>
    <OrderWarnings record={props.record} translate={translate} />
  </div>
);
const PreviewColumn = enhance(_PreviewColumn);

const _ActionColumn = ({
  classes,
  translate,
  location,
  staticContext,
  paymentComplete,
  groupIndex,
  permissions,
  isArchiveView = false,
  ...props
}) => {
  const { data, error, loaded } = useQueryWithStore({
    type: "getOne",
    resource: resources.JOBS,
    payload: { id: props.record.job },
  });

  return (
    <Grid container direction="column" className={classes.root}>
      <FunctionField
        {...props}
        addLabel={false}
        render={record => (
          <Fragment>
            {record.contractorName && <div className={classes.contractorPanel}>{record.contractorName}</div>}
            {record.rohStatus === RohStatus.UNASSIGNED.apiValue && (
              <ReferenceField {...props} source="order" reference={resources.ORDERS} link={false} allowEmpty={true}>
                <FunctionField
                  render={({ waitForPaymentComplete }) =>
                    !isArchiveView && (
                      <AssignModelButton
                        {...props}
                        className={classes.button}
                        variant="contained"
                        color="default"
                        label={getKey("assign", resources.MODELS)}
                        paymentComplete={paymentComplete || !waitForPaymentComplete}
                        permissions={permissions}
                      />
                    )
                  }
                />
              </ReferenceField>
            )}
            {record.rohStatus === RohStatus.NEEDS_UPDATE.apiValue && !isArchiveView && (
              <FixModelDataButton
                {...props}
                className={classes.hlButton}
                variant="contained"
                label={getKey("markAsFixed", resources.MODELS)}
              />
            )}
            {(record.rohStatus === RohStatus.ASSIGNED.apiValue ||
              record.rohStatus === RohStatus.NEEDS_UPDATE.apiValue) &&
              !isArchiveView && (
                <UnassignModelButton
                  {...props}
                  className={classes.button}
                  variant="contained"
                  color="default"
                  label={getKey("unassign", resources.MODELS)}
                />
              )}
            {(record.rohStatus === RohStatus.QUALITY_CONTROL.apiValue ||
              record.rohStatus === RohStatus.QUALITY_CONTROL_FAILED.apiValue) &&
              !isArchiveView && (
                <ModelActionButton
                  {...props}
                  className={classes.button}
                  variant="contained"
                  color="default"
                  label={getKey("qaApproved", resources.MODELS)}
                  reduxActionCreator={modelId => modelQaDone(modelId, false)}
                  style={{ backgroundColor: green[600], color: "#fff" }}
                >
                  <SentimentSatisfied />
                </ModelActionButton>
              )}
            {record.rohStatus === RohStatus.QUALITY_CONTROL.apiValue && !isArchiveView && (
              <ModelActionButton
                {...props}
                className={classes.button}
                variant="contained"
                color="default"
                label={getKey("qaFailed", resources.MODELS)}
                reduxActionCreator={modelId => modelQaDone(modelId, true)}
                style={{ backgroundColor: red[600], color: "#fff" }}
              >
                <PanTool />
              </ModelActionButton>
            )}
            {record.rohStatus === RohStatus.ADDITIONAL_WORK.apiValue && !isArchiveView && (
              <ModelActionButton
                {...props}
                className={classes.button}
                variant="contained"
                color="default"
                label={getKey("additionDone", resources.MODELS)}
                reduxActionCreator={modelId => modelAdditionDone(modelId)}
                style={{ backgroundColor: green[600], color: "#fff" }}
              >
                <AddCircle />
              </ModelActionButton>
            )}
            {data &&
              !error &&
              loaded &&
              !(location && location.pathname.startsWith(`/${resources.JOBS}`)) &&
              !isArchiveView && (
                <ListButton
                  className={classes.button}
                  variant="contained"
                  color={record.rohStatus === RohStatus.ASSIGNED.apiValue ? "primary" : "default"}
                  basePath={
                    "/" + resources.JOBS + `?filter=%7B"id"%3A"${data.__id}"%7D&order=DESC&page=1&perPage=10&sort=id`
                  }
                  label={getKey("showJob", resources.MODELS)}
                  icon={<Assignment />}
                />
              )}
          </Fragment>
        )}
      />
      <FunctionField
        {...props}
        render={record =>
          !isArchiveView && (
            <ModelFilesDialogButton
              record={record}
              {...props}
              variant="contained"
              color="default"
              classes={{
                button:
                  get(props, "record.rohStatus") === RohStatus.NEEDS_UPDATE.apiValue
                    ? classes.hlButton
                    : classes.button,
              }}
              label={getKey("editFiles", resources.MODELS)}
              uuidSource="storageServiceUuid"
              tokenSource="storageServiceToken"
              errorLabel={
                get(props, "record.rohStatus") === RohStatus.NEEDS_UPDATE.apiValue &&
                translate(getKey("modelErrorLbl", resources.MODELS), props.record)
              }
              activeFileHasError={get(props, "record.rohStatus") === RohStatus.NEEDS_UPDATE.apiValue}
            />
          )
        }
      />

      <DownloadModelButton
        {...props}
        variant="contained"
        color="default"
        className={classes.button}
        uuidSource="storageServiceUuid"
        tokenSource="storageServiceToken"
        legacyUrlSource="modelFileUrl"
        size="small"
      >
        <CloudDownload fontSize="inherit" />
        &nbsp;{translate(getKey("download", resources.MODELS))}
      </DownloadModelButton>
    </Grid>
  );
};
const ActionColumn = withRouter(enhance(_ActionColumn));

const _GroupedColumn = ({ classes, translate, children, groupIndex, ...props }) => (
  <Grid container direction="column">
    <Grid container direction="row">
      {children.map((child, i) =>
        child.props.row === 1 ? React.cloneElement(child, { ...props, key: `grouped-item--${i}` }) : null
      )}
    </Grid>
    <Grid container className={classes.root} style={{ paddingTop: 0 }}>
      {children.map((child, i) =>
        child.props.row === 2 ? React.cloneElement(child, { ...props, key: `grouped-item--${i}` }) : null
      )}
    </Grid>
  </Grid>
);
const GroupedColumn = enhance(_GroupedColumn);

const _IndexColumn = ({ classes, translate, groupIndex, ...props }) => (
  <TextField source="rapidshopId" {...props} className={classes.indexField} />
);
const IndexColumn = enhance(_IndexColumn);

export { MaterialColumn, PriceColumn, PreviewColumn, ActionColumn, GroupedColumn, IndexColumn };
