import get from "lodash/get";

export const isAnyFetching = () => state => get(state, "fileVersionsOfFile.fetching.length", 0) > 0;
export const isFetching = uuid => state => !!get(state, "fileVersionsOfFile.fetching", []).includes(uuid);
export const hasUuid = uuid => state => Object.keys(get(state, "fileVersionsOfFile.data", {})).includes(uuid);
export const getByUuid = uuid => state => get(state, "fileVersionsOfFile.data", {})[uuid];
export const getAll = () => state => get(state, "fileVersionsOfFile.data", {});
export const hasError = () => state => get(state, "fileVersionsOfFile.hasError", false);
export const getError = () => state => get(state, "fileVersionsOfFile.error");
export const getByUuidWithUuidAsId = uuid => state => {
  const data = getByUuid(uuid)(state);
  if (!data) return null;
  const result = {};
  for (const key in data) {
    result[key] = { ...data[key], id: data[key].uuid };
  }
  return result;
};
