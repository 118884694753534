import createReducer, { mergeReducers } from "../../lib/createReducer";
import {
  ASSIGN_WAREHOUSE_COMPARTMENT_ERROR,
  ASSIGN_WAREHOUSE_COMPARTMENT_REQUEST,
  ASSIGN_WAREHOUSE_COMPARTMENT_SUCCESS,
} from "./action";

const init = {
  compartment: null,
  model: null,
  shipment: null,

  isFetching: false,
  success: false,
  error: null,
};

const requestReducer = createReducer(ASSIGN_WAREHOUSE_COMPARTMENT_REQUEST, ({ compartment, model, shipment }) => ({
  ...init,
  compartment,
  model,
  shipment,
  isFetching: true,
}));

const successReducer = createReducer(ASSIGN_WAREHOUSE_COMPARTMENT_SUCCESS, () => ({
  isFetching: false,
  success: true,
}));

const errorReducer = createReducer(ASSIGN_WAREHOUSE_COMPARTMENT_ERROR, ({ error }) => ({
  isFetching: false,
  error,
}));

export default mergeReducers([requestReducer, successReducer, errorReducer], init);
