import get from "lodash/get";

import {
  CUSTOM_REF_FETCH_REQUEST,
  CUSTOM_REF_FETCH_SUCCESS,
  CUSTOM_REF_FETCH_FAILURE,
  CUSTOM_SELECTED_REF_FETCH_REQUEST,
  CUSTOM_SELECTED_REF_FETCH_SUCCESS,
  CUSTOM_SELECTED_REF_FETCH_FAILURE,
} from "./action";
import refFetchOccurences from "../../config/CustomRefFetchOccurrences";

const customRefFetchTemplate = {
  all: {
    isFetching: false,
    timeFetched: null,
    timeFetchedSuccess: null,
    error: null,
    data: null,
  },
  selected: {
    isFetching: false,
    timeFetched: null,
    timeFetchedSuccess: null,
    error: null,
    data: null,
  },
};

const initialState = {
  [refFetchOccurences.shareFilterGraphButton]: customRefFetchTemplate,
  [refFetchOccurences.contractorForm]: customRefFetchTemplate,
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;

  const { occurrence } = payload || {};

  if (type === CUSTOM_REF_FETCH_REQUEST) {
    return {
      ...state,
      [occurrence]: {
        ...state[occurrence],
        all: {
          ...state[occurrence].all,
          isFetching: true,
        },
      },
    };
  } else if (type === CUSTOM_REF_FETCH_SUCCESS) {
    return {
      ...state,
      [occurrence]: {
        ...state[occurrence],
        all: {
          ...state[occurrence].all,
          isFetching: false,
          timeFetched: new Date(),
          timeFetchedSuccess: new Date(),
          data: get(payload, "response"),
        },
      },
    };
  } else if (type === CUSTOM_REF_FETCH_FAILURE) {
    return {
      ...state,
      [occurrence]: {
        ...state[occurrence],
        all: {
          ...state[occurrence].all,
          isFetching: false,
          error: get(payload, "error"),
          timeFetched: new Date(),
        },
      },
    };
  } else if (type === CUSTOM_SELECTED_REF_FETCH_REQUEST) {
    return {
      ...state,
      [occurrence]: {
        ...state[occurrence],
        selected: {
          ...state[occurrence].selected,
          isFetching: true,
        },
      },
    };
  } else if (type === CUSTOM_SELECTED_REF_FETCH_SUCCESS) {
    return {
      ...state,
      [occurrence]: {
        ...state[occurrence],
        selected: {
          ...state[occurrence].selected,
          isFetching: false,
          timeFetched: new Date(),
          timeFetchedSuccess: new Date(),
          data: get(payload, "response"),
        },
      },
    };
  } else if (type === CUSTOM_SELECTED_REF_FETCH_FAILURE) {
    return {
      ...state,
      [occurrence]: {
        ...state[occurrence],
        selected: {
          ...state[occurrence].selected,
          isFetching: false,
          error: get(payload, "error"),
          data: null,
          timeFetched: new Date(),
        },
      },
    };
  }
  return state;
};

export default reducer;
