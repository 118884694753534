import React from "react";
import Button from "@material-ui/core/Button";
import GetAppIcon from "@material-ui/icons/GetApp";
import { useTranslate } from "react-admin";
import { connect } from "react-redux";
import { getKey } from "../../lib/i18nUtils";
import resources from "../../config/resources";
import { download } from "../../redux/job/downloadProductionPortfolio/action";

const PortfolioButton = ({ __id, record, download }) => {
  const translate = useTranslate();

  const jobId = __id ? __id : record.__id;

  if (!jobId) {
    return null;
  }

  return (
    <Button
      variant="contained"
      color="default"
      onClick={e => {
        e.stopPropagation();
        download(jobId);
      }}
      startIcon={<GetAppIcon />}
    >
      {translate(getKey("productionPortfolio", resources.JOBS))}
    </Button>
  );
};

export default connect(
  undefined,
  dispatch => ({
    download: jobId => {
      dispatch(download(jobId));
    },
  })
)(PortfolioButton);
