import { call, put, takeEvery } from "redux-saga/effects";
import resources from "../../../config/resources";
import config from "../../../config/index";
import fetchService from "../../../lib/fetchService";
import { ACCEPT_JOB_REQUEST, acceptJobError, acceptJobSuccess } from "./action";
import { handleSagaError } from "../../../dataproviders/errorResponseHandler";

const requestSaga = function*({ payload }) {
  const { job } = payload;

  const url = `${config.urls.BACKEND}/v2/${resources.JOBS}/${encodeURIComponent(job)}/accept`;

  try {
    const response = yield call(fetchService.post, url);

    for (const action of yield call(handleSagaError, response, acceptJobError)) {
      yield put(action);
      return;
    }

    if (response.ok) {
      const responseData = yield call(() => response.json());
      yield put(acceptJobSuccess(responseData));
    } else {
      const statusCode = response.status;
      yield put(acceptJobError(new Error(`Request failed with error ${statusCode}!`)));
    }
  } catch (e) {
    yield put(acceptJobError(e));
  }
};

export default function*() {
  yield takeEvery(ACCEPT_JOB_REQUEST, requestSaga);
}
