import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Filter, translate, TextInput, SelectArrayInput } from "react-admin";
import { getKey } from "../../lib/i18nUtils";
import FormulaTypes from "../../config/FormulaTypes";
import resources from "../../config/resources";

const styles = theme => ({
  container: {
    display: "grid !important",
    width: "100%",

    gridTemplateColumns: "repeat(18, 1fr)",
    [theme.breakpoints.down("md")]: {
      gridTemplateColumns: "repeat(9, 1fr)",
    },
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "repeat(6, 1fr)",
    },
    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "repeat(3, 1fr)",
    },

    "& > *": {
      gridColumnEnd: "span 3",
    },
  },
});

const FormulaFilters = ({ translate, classes, ...props }) => (
  <Filter {...props} classes={{ form: classes.container }}>
    <TextInput source="name" alwaysOn fullWidth resettable />
    <TextInput source="formula" alwaysOn fullWidth resettable />
    <SelectArrayInput
      source="type"
      label={translate(getKey("type", resources.FORMULAS))}
      choices={Object.values(FormulaTypes)}
      optionText={({ translation }) => translate(translation)}
      optionValue="key"
      translateChoice={false}
      fullWidth
      alwaysOn
    />
  </Filter>
);

FormulaFilters.propTypes = {
  classes: PropTypes.object.isRequired,
  translate: PropTypes.func.isRequired,
};

export default withStyles(styles)(translate(FormulaFilters));
