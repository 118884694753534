export const NullableBooleanSelectOptions = {
  TRUE: {
    key: true,
    translation: "ra.boolean.true",
  },
  FALSE: {
    key: false,
    translation: "ra.boolean.false",
  },
  NULL: {
    key: null,
    translation: "ra.boolean.null",
  },
};
