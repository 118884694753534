import React from "react";
import PropTypes from "prop-types";
import { EditButton, FunctionField, SelectField, Show, SimpleShowLayout, TextField, translate } from "react-admin";
import FormulaTypes from "../../config/FormulaTypes";
import { highlight } from "../../lib/codeFormatting";
import FormulaParameters from "../../config/FormulaParameters";
import { withStyles } from "@material-ui/core/styles";
import Title from "../customFields/Title";
import resources from "../../config/resources";

const styles = theme => ({
  root: {
    display: "flex",
    justifyContent: "center",
  },
  actionButton: {
    display: "flex",
    justifyContent: "left",
    float: "center",
    marginTop: theme.spacing(1),
  },
});

const FormulaShow = ({ classes, translate, ...props }) => (
  <Show title={<Title resource={resources.FORMULAS} />} actions={false} className={classes.root} {...props}>
    <SimpleShowLayout>
      <TextField source="name" />
      <SelectField
        source="type"
        optionValue="key"
        choices={Object.values(FormulaTypes)}
        optionText={({ translation }) => translate(translation)}
      />
      <FunctionField
        source="formulaStr"
        render={({ formulaStr = "" }) => {
          const highlighted = highlight({ FormulaParameters })(formulaStr);
          const html = highlighted.replace(/\n/g, "<br/>");
          return <span dangerouslySetInnerHTML={{ __html: html }} />;
        }}
        style={{
          fontFamily: '"Fira code", "Fira Mono", monospace',
          backgroundColor: "#202428",
          color: "#FFFFFF",
          padding: 8,
          width: "100%",
        }}
      />
      <EditButton className={classes.actionButton} />
    </SimpleShowLayout>
  </Show>
);

FormulaShow.propTypes = {
  translate: PropTypes.func.isRequired,
};

export default withStyles(styles)(translate(FormulaShow));
