import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { addField } from "react-admin";
import Fab from "@material-ui/core/Fab";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import CheckIcon from "@material-ui/icons/Check";
import Toolbar from "@material-ui/core/Toolbar";
import PopOver from "@material-ui/core/Popover";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const styles = {
  editor: {
    border: "1px solid gray",
    minHeight: "6em",
  },
  editButtonActiveStyle: {
    backgroundColor: "#032854",
  },
  editIconActiveStyle: {
    color: "#ffffff",
  },
  popOver: {
    padding: "1rem",
  },
  popOverAction: {
    paddingRight: "1rem",
  },
  highlightElements: {
    "& *[th\\:text], & *[th\\:href], & *[th\\:src]": {
      border: "solid 3px #FF0000 !important",
    },
    "& *[th\\:src]": {
      minHeight: 50,
      minWidth: 50,
    },
  },
};

function childOf(c, p) {
  while ((c = c.parentNode) && c !== p);
  return !!c;
}

class CustomRichTextField extends React.Component {
  constructor(props) {
    super(props);
    this.templateBody = null;
    this.state = {
      initialValue: null,
      editMode: false,
      popoverOpen: false,
      popoverAnchorElement: null,
      key: "th:",
      value: "",
      selectedElement: null,
      highlightFilterOn: false,
    };

    this.checkInitialValue();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.input !== prevProps.input) {
      this.checkInitialValue();
    }
  }

  checkInitialValue({ input } = this.props) {
    const { initialValue } = this.state;
    if (!input) return;

    const { value } = input;

    if (typeof value === "string" && value.length > 0 && !initialValue && this.templateBody) {
      this.setState({ initialValue: value });
      this.templateBody.innerHTML = value;
    }
  }

  handleSelect = event => {
    const selection = window.getSelection();

    if (!selection || selection.rangeCount !== 1) return;

    const range = selection.getRangeAt(0);

    const selectedLength = range.endOffset - range.startOffset;
    if (selectedLength < 1) return;

    const baseNode = selection.baseNode;

    if (!childOf(baseNode, this.templateBody)) return;

    this.setState({ selectedElement: range.cloneRange() });
    this.handleEditPopoverOpen();
  };

  handleEditMode = () => {
    let currentEditMode = this.state.editMode;
    if (!currentEditMode) {
      this.templateBody.addEventListener("mouseup", this.handleSelect);
    } else {
      this.templateBody.removeEventListener("mouseup", this.handleSelect);
    }
    this.setState({ editMode: !currentEditMode });
  };

  handleEditPopoverOpen = el => {
    let currentPopoverState = this.state.popoverOpen;
    if (currentPopoverState) {
      this.setState({
        key: "th:",
        value: "",
      });
    }
    this.setState({
      popoverOpen: !currentPopoverState,
    });
  };

  handlePopoverInputChange = event => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  wrapSelection = element => {
    let range = this.state.selectedElement;
    if (!range) return;
    range.surroundContents(element);
    window.getSelection().removeAllRanges();
    window.getSelection().addRange(range);
  };

  handlePopoverSubmit = () => {
    const element = document.createElement("span");
    element.setAttribute(this.state.key, this.state.value);
    this.wrapSelection(element);
    this.setState({
      popoverOpen: false,
      key: "th:",
      value: "",
    });
  };

  handleInput = onChange => e => {
    const html = e.target.outerHTML.replace('<div contenteditable="true">', "<html>").replace(/<\/div>$/g, "</html>");

    onChange(html);
  };

  setTemplateBody(ref) {
    this.templateBody = ref;
    this.checkInitialValue();
  }

  handleHighlightFilter = event => {
    this.setState({
      highlightFilterOn: event.target.checked,
    });
  };

  render() {
    const { id, disabled, name, input, classes } = this.props;

    if (!input) return null;

    const { onChange } = input;

    return (
      <div id={id} name={name} style={styles.editor} onClick={this.focusEditor}>
        <Toolbar>
          <Grid container justify="space-between">
            <Grid item>
              <FormControlLabel
                control={<Checkbox checked={this.state.highlightFilterOn} onChange={this.handleHighlightFilter} />}
                label={"Highlight Sections"}
              />
            </Grid>
            <Grid item>
              <Fab
                onClick={this.handleEditMode}
                size="small"
                variant="extended"
                color={this.state.editMode ? "primary" : "default"}
              >
                <EditIcon />
                &nbsp;Variable hinzufügen
              </Fab>
            </Grid>
          </Grid>
        </Toolbar>
        <div
          contentEditable={!disabled}
          onInput={this.handleInput(onChange)}
          ref={ref => this.setTemplateBody(ref)}
          className={this.state.highlightFilterOn ? classes.highlightElements : ""}
        />
        <PopOver
          open={this.state.popoverOpen ? this.state.popoverOpen : false}
          onClose={this.handleEditPopoverOpen}
          anchorEl={this.state.popoverAnchorElement ? this.state.popoverAnchorElement : null}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          transformOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Grid container spacing={10} className={classes.popOver}>
            <Grid item>
              <TextField
                label="Key"
                value={this.state.key ? this.state.key : ""}
                onChange={this.handlePopoverInputChange}
                name="key"
              />
            </Grid>
            <Grid item>
              <TextField
                label="Value"
                value={this.state.value ? this.state.value : ""}
                onChange={this.handlePopoverInputChange}
                name="value"
              />
            </Grid>
          </Grid>
          <Grid container direction="row-reverse" className={classes.popOverAction}>
            <Grid item>
              <IconButton onClick={this.handlePopoverSubmit}>
                <CheckIcon />
              </IconButton>
            </Grid>
          </Grid>
        </PopOver>
      </div>
    );
  }
}

CustomRichTextField.propTypes = {
  addField: PropTypes.bool.isRequired,
  addLabel: PropTypes.bool.isRequired,
  input: PropTypes.object,
  label: PropTypes.string,
  options: PropTypes.object,
  source: PropTypes.string,
  config: PropTypes.object,
};

CustomRichTextField.defaultProps = {
  addField: true,
  addLabel: true,
  options: {},
  record: {},
};

export default withStyles(styles)(addField(CustomRichTextField));
