import React, { Fragment } from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import Button from "@material-ui/core/Button";
import uniqueId from "lodash/uniqueId";
import Paper from "@material-ui/core/Paper";
import LinearProgress from "@material-ui/core/LinearProgress";
import { translate } from "react-admin";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CancelIcon from "@material-ui/icons/Cancel";

const styles = theme => ({
  input: {
    display: "none",
  },
  cancelButton: {
    margin: 0,
  },
  uploadingLabel: {
    display: "inline-block",
    margin: theme.spacing(1.5),
    verticalAlign: "middle",
  },
  uploadingSpan: {
    display: "flex",
    justifyContent: "space-between",
    verticalAlign: "middle",
    height: "100%",
  },
});

class UploadButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      files: [],
      uploadReady: false,
      isUploading: false,
    };

    this.id = uniqueId("button-file-");
  }

  onChange = evt => {
    const { onSelect } = this.props;
    const { files } = evt.target;

    this.setState({
      files,
      uploadReady: true,
    });

    if (typeof onSelect === "function") onSelect(files);
  };

  startUpload = () => {
    const { onStartUpload } = this.props;

    const { files, uploadReady } = this.state;

    if (!uploadReady) return;

    const parsedFiles = Array.isArray(files) && files.length === 1 ? files[0] : files;
    onStartUpload(parsedFiles);

    this.setState({ uploadReady: false, files: [], isUploading: true });
  };

  abortUpload = () => {
    this.setState({ uploadReady: false, files: [], isUploading: false });
  };

  componentDidUpdate({ isUploading: wasUploading }, { isUploading: wasUploadingState }, snapshot) {
    const { isUploading, onFinishUpload } = this.props;
    if (wasUploading && wasUploadingState && !isUploading) {
      this.setState({ isUploading: false }, () => onFinishUpload());
    }
  }

  render() {
    const {
      classes,
      children,
      translate,
      acceptsMultiple = false,
      acceptsType,
      variant = "contained",
      color = "default",
      buttonProps,
      isUploading = false,
      uploadProgress = null,
      onCancel,
    } = this.props;

    const { files, uploadReady } = this.state;

    if (isUploading)
      return (
        <Paper style={{ textAlign: "left" }} elevation={2}>
          <LinearProgress variant={uploadProgress === null ? "indeterminate" : "determinate"} value={uploadProgress} />
          <div className={classes.uploadingSpan}>
            <Typography variant="body1" className={classes.uploadingLabel}>
              {uploadProgress === null
                ? translate("upload.uploading")
                : translate("upload.uploadingWithProgress", {
                    progress: uploadProgress,
                  })}
            </Typography>
            {onCancel && (
              <IconButton
                className={classes.cancelButton}
                aria-label={translate("upload.cancel")}
                onClick={evt => onCancel(files, evt)}
              >
                <CancelIcon />
              </IconButton>
            )}
          </div>
        </Paper>
      );

    if (!uploadReady)
      return (
        <Fragment>
          <input
            accept={acceptsType}
            className={classes.input}
            id={this.id}
            multiple={acceptsMultiple}
            type="file"
            onChange={this.onChange}
          />
          <label htmlFor={this.id}>
            <Button {...buttonProps} variant={variant} component="span" color={color}>
              {children}
            </Button>
          </label>
        </Fragment>
      );

    return (
      <div>
        <Button variant="text" onClick={this.abortUpload}>
          {translate("upload.cancel")}
        </Button>
        <Button variant="contained" color="primary" onClick={this.startUpload}>
          {files.length === 1
            ? translate("upload.startWithFilename", {
                filename: files.item(0).name,
              })
            : translate("upload.startWithFilecount", {
                filecount: files.length,
              })}
        </Button>
      </div>
    );
  }
}

UploadButton.propTypes = {
  //Connected props:
  classes: PropTypes.object.isRequired,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
  translate: PropTypes.func.isRequired,

  //Component props:
  acceptsMultiple: PropTypes.bool,
  acceptsType: PropTypes.string,
  variant: PropTypes.oneOf(["raised", "flat", "outlined", "fab", "extendedFab", "contained", "text"]),
  color: PropTypes.oneOf(["default", "primary", "secondary", "inherit"]),
  buttonProps: PropTypes.object,
  onSelect: PropTypes.func,
  isUploading: PropTypes.bool,
  onStartUpload: PropTypes.func,
  uploadProgress: PropTypes.number,
  onCancel: PropTypes.func,
  onFinishUpload: PropTypes.func,
};

export default translate(withStyles(styles)(UploadButton));
