/**
 *
 * @param permissions {Object} - Permissions object
 * @param permissions.authorities {Array<string>} - Authorities
 * @param roles {Array<string>} - One of these is required
 * @returns {boolean}
 */
export const hasAnyRole = (permissions, roles = []) => {
  if (!permissions) return false;

  const { authorities } = permissions;
  if (!Array.isArray(authorities)) return false;

  for (const role of roles) {
    if (authorities.includes(role)) {
      return true;
    }
  }

  return false;
};
