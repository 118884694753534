import React from "react";
import PropTypes from "prop-types";
import { crudGetAll, NumberInput, SaveButton, SimpleForm } from "react-admin";
import { CreateController } from "ra-core";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import get from "lodash/get";
import compact from "lodash/compact";
import { connect } from "react-redux";
import { resources } from "../../config/resources";
import RichTextInput from "../../lib/richTextInput/RichTextInput";
import UserAvatar from "../userDetails/UserAvatar";

const styles = theme => ({
  button: {
    margin: theme.spacing(2),
    float: "right",
  },
});

const FormContent = ({ children, permissions, ...props }) => (
  <Grid container alignItems="flex-start" spacing={10}>
    <Grid item>
      <UserAvatar
        displayName={get(permissions, "displayName", " ")}
        imageUrl={get(permissions, "avatarUrl")}
        displayStyle={get(permissions, "displayStyle", 0)}
        size={40}
      />
    </Grid>
    {Array.isArray(children) &&
      compact(children).map(
        (child, i) =>
          !child.props.hidden && (
            <Grid item style={{ display: "flex", flex: 1 }} key={i}>
              {React.cloneElement(child, { ...props })}
            </Grid>
          )
      )}
  </Grid>
);

const sanitizeRestProps = ({ isLoading, defaultTitle, ...rest }) => rest;

const CommentCreate = ({ basePath, shipmentId, jobId, permissions, classes, crudGetAll }) => (
  <CreateController basePath={basePath} resource={resources.COMMENTS} location="">
    {controllerProps => (
      <SimpleForm
        {...sanitizeRestProps(controllerProps)}
        record={{ shipment: shipmentId, job: jobId }}
        toolbar={<SaveButton className={classes.button} label="Kommentieren" />}
        redirect={false}
      >
        <FormContent permissions={permissions}>
          <RichTextInput
            source="content"
            id="contentEditor"
            fullWidth
            modules={{
              mention: {
                allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
                mentionDenotationChars: ["@"],
                source: (searchTerm, renderList) => {
                  crudGetAll(
                    resources.USER_DETAILS,
                    { field: "displayName" },
                    { displayName: `${searchTerm}` },
                    5,
                    ({ payload, requestPayload, error }) => {
                      if (payload && requestPayload && !error) {
                        const { data = [] } = payload;
                        const mapped = data.map(({ id, displayName }) => ({
                          id: id,
                          value: displayName,
                        }));
                        renderList(mapped, searchTerm);
                      }
                    }
                  );
                },
              },
            }}
          />
          <NumberInput source="shipment" hidden />
        </FormContent>
      </SimpleForm>
    )}
  </CreateController>
);

CommentCreate.propTypes = {
  basPath: PropTypes.string,
  shipmentId: PropTypes.number,
  jobId: PropTypes.number,
  permissions: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

export default connect(
  undefined,
  { crudGetAll }
)(withStyles(styles)(CommentCreate));
