import { call, put, takeEvery } from "redux-saga/effects";
import config from "../../../config/index";
import fetchService from "../../../lib/fetchService";
import { CHANGE_PASSWORD_REQUEST, changePasswordError, changePasswordSuccess } from "./action";
import authProvider from "../../../dataproviders/authProvider";
import { handleSagaError } from "../../../dataproviders/errorResponseHandler";
import { acceptJobError } from "../../job/acceptJob/action";

const requestSaga = function*({ payload, meta }) {
  const { oldPassword, newPassword, redirectTo, username } = payload;

  const url = `${config.urls.BACKEND}/${username}/changepw`;
  const data = {};
  const body = { oldPassword, newPassword };

  try {
    const response = yield call(fetchService.post, url, data, body);

    for (const action of yield call(handleSagaError, response, acceptJobError)) {
      yield put(action);
      return;
    }

    if (response.ok) {
      const responseData = yield call(() => response.json());

      yield authProvider.login;
      yield put(changePasswordSuccess(responseData, redirectTo));
    } else {
      const statusCode = response.status;
      const defaultAction = changePasswordError(new Error(`Request failed with error ${statusCode}!`));
      if (!meta || !meta.onFailure) {
        yield put(defaultAction);
      } else {
        yield put({
          ...defaultAction,
          meta: meta.onFailure,
        });
      }
    }
  } catch (e) {
    const defaultAction = changePasswordError(e);
    if (!meta || !meta.onFailure) {
      yield put(defaultAction);
    } else {
      yield put({
        ...defaultAction,
        meta: meta.onFailure,
      });
    }
  }
};

export default function*() {
  yield takeEvery(CHANGE_PASSWORD_REQUEST, requestSaga);
}
