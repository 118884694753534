import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Create, SimpleForm, translate } from "react-admin";
import FormulaForm from "./FormulaForm";

const styles = () => ({
  root: {
    display: "flex",
    justifyContent: "center",
  },
});

const FormulaCreate = ({ translate, classes, ...props }) => (
  <div className={classes.root}>
    <Create {...props}>
      <SimpleForm initialValues={{ enabled: true }} redirect="list">
        <FormulaForm />
      </SimpleForm>
    </Create>
  </div>
);

FormulaCreate.propTypes = {
  classes: PropTypes.object.isRequired,
  translate: PropTypes.func.isRequired,
};

export default withStyles(styles)(translate(FormulaCreate));
