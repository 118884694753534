import {
  UPLOAD_DOCUMENT_REQUEST,
  UPLOAD_DOCUMENT_PROGRESS,
  UPLOAD_DOCUMENT_SUCCESS,
  UPLOAD_DOCUMENT_ERROR,
} from "./action";

const init = {
  file: null,
  isUploading: false,
  progress: 0.0,
  uploaded: false,
  error: null,
  uploadResponse: null,
};

export default (state = init, { type, payload = {} } = {}) => {
  if (!payload) return state;

  const { file, progress, response, error } = payload;

  switch (type) {
    case UPLOAD_DOCUMENT_REQUEST:
      if (state.isUploading) {
        console.error(
          `Action "${UPLOAD_DOCUMENT_REQUEST} doesn't support parallel operation, but was dispatched again while still running!`
        );
        return state;
      }

      return {
        ...init,
        file,
        isUploading: true,
        progress: 0.0,
      };
    case UPLOAD_DOCUMENT_PROGRESS:
      return {
        ...state,
        progress,
      };
    case UPLOAD_DOCUMENT_SUCCESS:
      return {
        ...state,
        isUploading: false,
        progress: 1.0,
        uploaded: true,
        uploadResponse: response,
      };
    case UPLOAD_DOCUMENT_ERROR:
      return {
        ...state,
        isUploading: false,
        error,
      };
    default:
      return state;
  }
};
