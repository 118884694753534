import React, { useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import JobBulkOperationTable from "./JobBulkOperationTable";
import JobBulkOperationActionBar from "./JobBulkOperationActionBar";
import { Form } from "react-final-form";
import arrayMutators from "final-form-arrays";
import { connect } from "react-redux";
import get from "lodash/get";
import { translate, usePermissions } from "react-admin";

import { jobBulkOperation } from "../../redux/job/bulkOperation/action";
import LoadingOverlay from "react-loading-overlay";

const styles = theme => ({
  root: {
    display: "flex",
    flex: 1,
    justifyContent: "center",
  },
});

const JobBulkOperationPage = ({ classes, dispatchBulkOperation, isLoading, translate }) => {
  const [formData, setFormData] = useState([]);

  const saveFormData = data => {
    setFormData(get(data, "jobs", []));
  };

  const { permissions } = usePermissions();

  const submitAction = bulkAction => {
    const tempIds = formData.filter(job => !!job.active).map(job => job.id);
    const jobIds = [...new Set(tempIds)];
    dispatchBulkOperation(jobIds, bulkAction);
  };

  return (
    <LoadingOverlay
      active={isLoading}
      text={translate("filterMisc.pleaseWait")}
      spinner
      className={classes.root}
      styles={{
        overlay: base => ({
          ...base,
          background: "rgba(40, 44, 52, 0.5)", //light blueish-grey, transparent
        }),
      }}
    >
      <Form
        className={classes.root}
        onSubmit={saveFormData}
        mutators={arrayMutators}
        render={({
          handleSubmit,
          form: {
            mutators: { push },
            change,
            reset,
            submit,
          },
          values,
        }) => (
          <form className={classes.root} onSubmit={handleSubmit}>
            <JobBulkOperationTable
              addRow={push}
              updateField={(name, field) => {
                change(name, field);
                submit();
              }}
              permissions={permissions}
              values={values}
            />
            <JobBulkOperationActionBar
              onBulkOperationButtonClick={submitAction}
              onResetButtonClick={reset}
              permissions={permissions}
            />
          </form>
        )}
      />
    </LoadingOverlay>
  );
};

const mapStateToProps = state => ({
  isLoading: state.jobBulkOperation.isLoading,
});

export default connect(
  mapStateToProps,
  {
    dispatchBulkOperation: jobBulkOperation,
  }
)(withStyles(styles)(translate(JobBulkOperationPage)));
