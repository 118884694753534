import React from "react";
import { connect } from "react-redux";
import { Layout, WithPermissions } from "react-admin";
import omit from "lodash/omit";
import { destroyNotificationTimer, initNotificationTimer } from "../../redux/notifications/action";
import { destroyWebsocket, initWebsocket } from "../../redux/websocket/action";
import Sidebar from "./CustomSidebar";
import CustomAppBar from "./CustomAppBar";
import CustomMenu from "./CustomMenu";
import { initPushNotificationsRequest, stopPushNotificationsRequest } from "../../redux/pushNotifications/actions";

class CustomLayoutWithPermissions extends React.Component {
  state = {
    websocketUsername: null,
  };

  componentDidMount() {
    const { initNotificationTimer, initPushNotificationsRequest } = this.props;
    initNotificationTimer();
    initPushNotificationsRequest();
  }

  componentWillUnmount() {
    const { destroyNotificationTimer, destroyWebsocket, stopPushNotificationsRequest } = this.props;
    destroyNotificationTimer();
    destroyWebsocket();
    stopPushNotificationsRequest();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { permissions, initWebsocket, destroyWebsocket } = this.props;
    const { websocketUsername } = this.state;

    if (permissions && permissions.username) {
      const username = permissions.username;
      if (username !== websocketUsername) {
        if (websocketUsername) {
          destroyWebsocket();
        }
        initWebsocket(username);
        this.setState({ websocketUsername: username });
      }
    } else if (permissions && websocketUsername) {
      destroyWebsocket();
    }
  }

  render() {
    const {
      initNotificationTimer,
      destroyNotificationTimer,
      initWebsocket,
      destroyWebsocket,
      permissions,
      initPushNotificationsRequest,
      stopPushNotificationsRequest,
      ...props
    } = this.props;

    return <Layout {...omit(props, ["restartingAt"])} sidebar={Sidebar} appBar={CustomAppBar} menu={CustomMenu} />;
  }
}

const CustomLayout = props => (
  <WithPermissions render={({ permissions }) => <CustomLayoutWithPermissions {...props} permissions={permissions} />} />
);

export default connect(
  undefined,
  {
    initNotificationTimer,
    destroyNotificationTimer,
    initWebsocket,
    destroyWebsocket,
    initPushNotificationsRequest,
    stopPushNotificationsRequest,
  }
)(CustomLayout);
