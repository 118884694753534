import React, { useCallback, useEffect } from "react";
import moment from "moment";
import { useInput, FieldTitle, useTranslate } from "react-admin";
import { KeyboardDatePicker, KeyboardDateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { makeStyles } from "@material-ui/styles";
import { noop } from "../lib/util";

moment.locale("de");

const useStyles = makeStyles(theme => ({
  picker: {
    marginTop: theme.spacing(),
    marginBottom: theme.spacing(),
  },
}));

const CustomDateInput = ({
  dateTime,
  label,
  source,
  className,
  providerOptions,
  resource,
  isRequired,
  initialValue,
  onChange = noop,
  ...props
}) => {
  const translate = useTranslate();
  const classes = useStyles();
  const { input, meta } = useInput({ source });

  const { touched, error } = meta;

  const handleChange = useCallback(
    value => {
      Date.parse(value) ? input.onChange(value.toISOString()) : input.onChange(null);
      onChange(value);
    },
    [onChange]
  );

  const PickerComponent = dateTime ? KeyboardDateTimePicker : KeyboardDatePicker;

  useEffect(() => {
    if (initialValue && !input.value) {
      input.onChange(initialValue.toISOString());
    }
  }, []);

  return (
    <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} {...providerOptions}>
      <PickerComponent
        label={<FieldTitle label={label} source={source} resource={resource} isRequired={isRequired} />}
        inputVariant="filled"
        error={!!(touched && error)}
        helperText={touched && error}
        format={dateTime ? translate("format.date.dateTimeFormat") : translate("format.date.dateFormat")}
        className={`${classes.picker} ${className}`}
        value={input.value ? new Date(input.value) : null}
        onChange={handleChange}
        ampm={false}
        {...props}
        variant="inline"
      />
    </MuiPickersUtilsProvider>
  );
};

export default CustomDateInput;
