import React, { Component } from "react";
import { connect } from "react-redux";
import { Button } from "react-admin";
import { syncModels } from "../../redux/model/syncModels/action";
import get from "lodash/get";
import CircularProgress from "@material-ui/core/CircularProgress";
import sanitizeButton from "../sanitizeButton";
import Icon from "@material-ui/icons/ImportExport";

class SyncModelsButton extends Component {
  render() {
    const { syncModels, children, isFetching, disabled, progressStyle, ...rest } = this.props;
    return (
      <Button {...sanitizeButton(rest)} onClick={() => syncModels()} disabled={disabled || isFetching}>
        {isFetching ? <CircularProgress size={20} thickness={2} color="primary" style={progressStyle} /> : children}
      </Button>
    );
  }
}

SyncModelsButton.defaultProps = {
  children: <Icon />,
};

export default connect(
  state => ({
    isFetching: get(state, "syncModels.isFetching"),
  }),
  {
    syncModels,
  }
)(SyncModelsButton);
