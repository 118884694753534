import React from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  List,
  Datagrid,
  TextField,
  ReferenceField,
  translate,
  TopToolbar,
  CreateButton,
  DeleteButton,
  CloneButton,
  BooleanField,
  EditButton,
  Pagination,
  EmailField,
} from "react-admin";
import resources from "../../config/resources";
import Chip from "@material-ui/core/Chip";
import { getKey } from "../../lib/i18nUtils";
import Roles from "../../config/Roles";
import get from "lodash/get";
import CircularProgress from "@material-ui/core/CircularProgress";
import paginationOptions from "../../config/PaginationOptions";
import UserFilters from "./UserFilters";
import switchRecordIds from "../../lib/switchRecordIds";

const styles = () => ({
  actionButton: {
    display: "flex",
    justifyContent: "flex-end",
    float: "right",
  },
  emailField: {
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    maxWidth: "8vw",
    "&:hover": {
      overflow: "visible",
      maxWidth: "20vw",
    },
  },
});

const AuthoritiesField = translate(({ record, translate }) => (
  <span>
    {(record.authorities || []).map((at, i) => (
      <Chip label={translate(Roles[at].translation)} key={`authority--${i}`} />
    ))}
  </span>
));

const Actions = ({ basePath, displayedFilters, filters, filterValues, resource, showFilter }) => (
  <TopToolbar>
    {filters &&
      React.cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: "button",
      })}
    <CreateButton basePath={basePath} />
  </TopToolbar>
);

const DeleteUser = ({ permissions, ...props }) => {
  const disabled = !permissions || get(props, "record.username") === permissions.username;
  return (
    <DeleteButton
      {...props}
      record={switchRecordIds(get(props, "record"))}
      disabled={disabled}
      icon={!permissions ? <CircularProgress size={20} thickness={2} color="primary" /> : undefined}
    />
  );
};

const UsersList = ({ translate, permissions, classes, ...props }) => (
  <List
    {...props}
    filters={<UserFilters />}
    bulkActionButtons={false}
    actions={<Actions />}
    sort={{ field: "id", order: "ASC" }}
    pagination={<Pagination {...paginationOptions} />}
  >
    <Datagrid>
      <TextField source="__id" label={translate(getKey("id", resources.USERS))} />
      <TextField source="displayName" />
      <TextField source="username" />
      <EmailField source="email" cellClassName={classes.emailField} />
      <BooleanField source="enabled" />
      <BooleanField source="locked" />
      <BooleanField source="credentialsExpired" />
      <AuthoritiesField label={translate(getKey("authorities", resources.USERS))} />
      <ReferenceField
        reference={resources.CONTRACTORS}
        source="contractor"
        allowEmpty={true}
        link={false}
        sortBy="contractor.contractorName"
      >
        <TextField source="contractorName" />
      </ReferenceField>
      <EditButton className={classes.actionButton} />
      <CloneButton className={classes.actionButton} />
      <DeleteUser permissions={permissions} className={classes.actionButton} undoable={false} />
    </Datagrid>
  </List>
);

export default withStyles(styles)(translate(UsersList));
