import React from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  translate,
  List,
  Datagrid,
  TextField,
  TopToolbar,
  CreateButton,
  ReferenceField,
  EditButton,
  FunctionField,
  Pagination,
} from "react-admin";
import resources from "../../config/resources";
import paginationOptions from "../../config/PaginationOptions";
import SupportedMaterialFilters from "./SupportedMaterialFilters";
import { getKey } from "../../lib/i18nUtils";
import CustomDeleteButton from "../customFields/buttons/CustomDeleteButton";

const styles = () => ({
  actionButton: {
    display: "flex",
    justifyContent: "flex-end",
    float: "right",
  },
});

const Actions = ({ basePath }) => (
  <TopToolbar>
    <CreateButton basePath={basePath} />
  </TopToolbar>
);

const SupportedMaterialsList = ({ translate, classes, ...props }) => (
  <List
    {...props}
    actions={<Actions />}
    bulkActionButtons={false}
    filters={<SupportedMaterialFilters />}
    pagination={<Pagination {...paginationOptions} />}
  >
    <Datagrid>
      <TextField source="__id" sortBy="id" label={translate(getKey("id", resources.SUPPORTED_MATERIALS))} />
      <ReferenceField {...sanitizeRestProps(props)} reference={resources.CONTRACTORS} source="contractor" link={false}>
        <TextField source="contractorName" />
      </ReferenceField>
      <ReferenceField {...sanitizeRestProps(props)} reference={resources.MATERIALS} source="material" link={false}>
        <FunctionField
          render={({ name, methodKey, methodName, method }) => `${methodKey || methodName || method || "?"} (${name})`}
        />
      </ReferenceField>
      <ReferenceField {...sanitizeRestProps(props)} reference={resources.FORMULAS} source="priceFormula" link={false}>
        <FunctionField render={({ __id, name }) => `${name || "Unbenannt!"} (${__id})`} />
      </ReferenceField>
      <TextField source="productionTime" />
      <EditButton className={classes.actionButton} />
      <CustomDeleteButton className={classes.actionButton} undoable={false} />
    </Datagrid>
  </List>
);

const sanitizeRestProps = ({ hasList, hasEdit, hasShow, hasCreate, ...rest }) => rest;

export default withStyles(styles)(translate(SupportedMaterialsList));
