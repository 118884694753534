import { call, put, takeLatest } from "redux-saga/effects";
import config from "../../../config/index";
import fetchService from "../../../lib/fetchService";
import { FETCH_STORAGESERVICE_PING, fetchStsPing, fetchStsPingSuccess, fetchStsPingError } from "./action";
import { NOTIFICATION_TIMER_TICK } from "../../notifications/action";

const requestSaga = function*() {
  const url = `${config.urls.STORAGE_SERVICE}/ping`;

  try {
    const response = yield call(fetchService.get, url, undefined, {
      Authorization: "",
    });

    if (response.ok) {
      const responseData = yield call(() => response.json());
      yield put(fetchStsPingSuccess(responseData));
    } else {
      const statusCode = response.status;
      yield put(fetchStsPingError(new Error(`Request failed with error ${statusCode}!`)));
    }
  } catch (e) {
    yield put(fetchStsPingError(e));
  }
};

const subscribeNotification = function*() {
  yield put(fetchStsPing());
};

export default function*() {
  yield takeLatest(FETCH_STORAGESERVICE_PING, requestSaga);
  yield takeLatest(NOTIFICATION_TIMER_TICK, subscribeNotification);
}
