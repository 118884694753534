import get from "lodash/get";

import {
  CUSTOM_FETCH_MODEL_SUBTASKS_REQUEST,
  CUSTOM_FETCH_MODEL_SUBTASKS_SUCCESS,
  CUSTOM_FETCH_MODEL_SUBTASKS_FAILURE,
  CUSTOM_FETCH_SUBTASK_REQUEST,
  CUSTOM_FETCH_SUBTASK_SUCCESS,
  CUSTOM_FETCH_SUBTASK_FAILURE,
  CUSTOM_UPDATE_SUBTASK_REQUEST,
  CUSTOM_UPDATE_SUBTASK_SUCCESS,
  CUSTOM_UPDATE_SUBTASK_FAILURE,
  CUSTOM_CREATE_SUBTASK_REQUEST,
  CUSTOM_CREATE_SUBTASK_SUCCESS,
  CUSTOM_CREATE_SUBTASK_FAILURE,
} from "./action";

const initialTemplate = {
  isFetching: false,
  timeFetched: null,
  timeFetchedSuccess: null,
  error: null,
  data: null,
};

const initialState = {
  models: {},
  subtasks: initialTemplate,
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;

  const { subtaskId, modelId, modelSubtaskIds } = payload || {};

  if (type === CUSTOM_FETCH_MODEL_SUBTASKS_REQUEST) {
    return {
      ...state,
      subtasks: {
        ...state.subtasks,
        isFetching: true,
      },
    };
  } else if (type === CUSTOM_FETCH_MODEL_SUBTASKS_SUCCESS) {
    return {
      ...state,
      models: {
        ...state.models,
        [modelId]: modelSubtaskIds,
      },
      subtasks: {
        ...state.subtasks,
        isFetching: false,
        timeFetched: new Date(),
        timeFetchedSuccess: new Date(),
        data: {
          ...state.subtasks.data,
          ...get(payload, "response", {}),
        },
      },
    };
  } else if (type === CUSTOM_FETCH_MODEL_SUBTASKS_FAILURE) {
    return {
      ...state,
      subtasks: {
        ...state.subtasks,
        isFetching: false,
        timeFetched: new Date(),
        error: get(payload, "error"),
      },
    };
  } else if (type === CUSTOM_UPDATE_SUBTASK_REQUEST) {
    return {
      ...state,
      subtasks: {
        ...state.subtasks,
        data: {
          ...state.subtasks.data,
          [subtaskId]: {
            ...state.subtasks.data[subtaskId],
            isUpdating: true,
          },
        },
      },
    };
  } else if (type === CUSTOM_UPDATE_SUBTASK_SUCCESS) {
    return {
      ...state,
      subtasks: {
        ...state.subtasks,
        data: {
          ...state.subtasks.data,
          [subtaskId]: {
            ...state.subtasks.data[subtaskId],
            isUpdating: false,
            updateSuccessful: true,
          },
        },
      },
    };
  } else if (type === CUSTOM_UPDATE_SUBTASK_FAILURE) {
    return {
      ...state,
      subtasks: {
        ...state.subtasks,
        data: {
          ...state.subtasks.data,
          [subtaskId]: {
            ...state.subtasks.data[subtaskId],
            isUpdating: false,
            updateSuccessful: false,
          },
        },
      },
    };
  } else if (type === CUSTOM_FETCH_SUBTASK_REQUEST) {
    return {
      ...state,
      subtasks: {
        ...state.subtasks,
        isFetching: true,
      },
    };
  } else if (type === CUSTOM_FETCH_SUBTASK_SUCCESS) {
    return {
      ...state,
      subtasks: {
        ...state.subtasks,
        isFetching: false,
        timeFetched: new Date(),
        timeFetchedSuccess: new Date(),
        data: {
          ...state.subtasks.data,
          [subtaskId]: get(payload, "response"),
        },
      },
    };
  } else if (type === CUSTOM_FETCH_SUBTASK_FAILURE) {
    return {
      ...state,
      subtasks: {
        ...state.subtasks,
        isFetching: false,
        timeFetched: new Date(),
        error: get(payload, "error"),
      },
    };
  } else if (type === CUSTOM_CREATE_SUBTASK_REQUEST) {
    return {
      ...state,
      subtasks: {
        ...state.subtasks,
        isCreating: true,
      },
    };
  } else if (type === CUSTOM_CREATE_SUBTASK_SUCCESS) {
    const modelSubtasksArray = get(state, `models.${modelId}`, []);
    modelSubtasksArray.push(subtaskId);
    return {
      ...state,
      models: {
        ...state.models,
        [modelId]: modelSubtasksArray,
      },
      subtasks: {
        ...state.subtasks,
        isCreating: false,
        creationSuccess: true,
        timeFetched: new Date(),
        timeFetchedSuccess: new Date(),
        data: {
          ...state.subtasks.data,
          [subtaskId]: get(payload, "response"),
        },
      },
    };
  } else if (type === CUSTOM_CREATE_SUBTASK_FAILURE) {
    return {
      ...state,
      subtasks: {
        ...state.subtasks,
        isFetching: false,
        timeFetched: new Date(),
        isCreating: false,
        creationSuccess: false,
        error: get(payload, "error"),
      },
    };
  }
  return state;
};

export default reducer;
