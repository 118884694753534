import createAction, { createActionWithMeta } from "../../../lib/createAction";

export const INTRANSPORT_JOB_REQUEST = "INTRANSPORT_JOB_REQUEST";
export const intransportJob = createAction(INTRANSPORT_JOB_REQUEST, "job");

export const INTRANSPORT_JOB_SUCCESS = "INTRANSPORT_JOB_SUCCESS";
export const intransportJobSuccess = createActionWithMeta(
  INTRANSPORT_JOB_SUCCESS,
  { uncache: true, refresh: true },
  "response"
);

export const INTRANSPORT_JOB_ERROR = "INTRANSPORT_JOB_ERROR";
export const intransportJobError = createActionWithMeta(
  INTRANSPORT_JOB_ERROR,
  { uncache: true, refresh: true },
  "error"
);
