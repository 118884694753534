import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { connect } from "react-redux";
import MuiTextField from "@material-ui/core/TextField";

import { showNotification, translate } from "react-admin";
import { Field } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import { withStyles } from "@material-ui/core/styles";

import resources from "../../config/resources";
import { getKey } from "../../lib/i18nUtils";
import JobBulkDisplayRow from "./JobBulkDisplayRow";
import { hasAnyRole } from "../../lib/permissionChecks";
import Roles from "../../config/Roles";

const styles = theme => ({
  root: {
    marginRight: theme.spacing(2),
  },
  history: {
    display: "flex",
    flex: 1,
    width: "100%",
    flexDirection: "column-reverse",
    maxHeight: "calc(100vh - 96px)",
    overflowY: "auto",
  },
});

const JobBulkOperationTable = ({
  addRow,
  updateField,
  classes,
  values,
  showNotification,
  translate,
  bulkOperationResult,
  isLoading,
  permissions,
}) => {
  const handleEnter = ({ charCode, target }) => {
    // this is the charCode for the "Enter" key
    if (charCode !== 13 || (!target.value && !target.value.length)) {
      return;
    }
    const jobId = parseInt(target.value.split("-")[0]);

    if (Array.isArray(values.jobs) && values.jobs.filter(it => it.id === jobId).length > 0) {
      showNotification("bulkOps.alreadyExists", "warning", {
        autoHideDuration: 3000,
      });
      return;
    }

    if (isFinite(jobId)) {
      addRow("jobs", { id: jobId, active: true });
      updateField("jobInput", undefined);
    }
  };

  return (
    <Card className={classes.root}>
      <CardContent style={{ padding: 0 }}>
        <div className={classes.history}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>{translate("bulkOps.execute")}</TableCell>
                <TableCell>{translate(getKey("externalId", resources.JOBS))}</TableCell>
                {hasAnyRole(permissions, [Roles.ROLE_ADMIN.key, Roles.ROLE_SALES.key]) && (
                  <TableCell>{translate(getKey("contractor", resources.JOBS))}</TableCell>
                )}
                <TableCell>{translate(getKey("status", resources.JOBS))}</TableCell>
                <TableCell>{translate("bulkOps.response")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <FieldArray name="jobs">
                {({ fields }) =>
                  fields.map((name, idx) => (
                    <JobBulkDisplayRow
                      key={"jobRow" + idx}
                      name={name}
                      updateField={updateField}
                      translate={translate}
                      response={bulkOperationResult}
                      isLoading={isLoading}
                    />
                  ))
                }
              </FieldArray>
              <TableRow>
                <TableCell />
                <TableCell>
                  <Field name="jobInput">
                    {({ input }) => (
                      <MuiTextField
                        onKeyPress={handleEnter}
                        name={input.name}
                        value={input.value}
                        onChange={input.onChange}
                        variant="outlined"
                        autoFocus
                      />
                    )}
                  </Field>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </div>
      </CardContent>
    </Card>
  );
};

const mapStateToProps = state => ({
  bulkOperationResult: state.jobBulkOperation.payload,
  isLoading: state.jobBulkOperation.isLoading,
});

export default connect(
  mapStateToProps,
  { showNotification }
)(withStyles(styles)(translate(JobBulkOperationTable)));
