import React, { useEffect } from "react";
import { useNotify } from "react-admin";
import { connect } from "react-redux";
import { useTranslate } from "react-admin";
import BugReportIcon from "@material-ui/icons/BugReport";
import { CircularProgress, MenuItem, ListItemIcon, Typography, makeStyles } from "@material-ui/core";
import get from "lodash/get";

import { exportReport } from "../../../redux/errorReport/action";
import usePrevious from "../../../lib/customHooks/usePrevious";

const useStyles = makeStyles(theme => ({
  button: { marginTop: theme.spacing(15) },
  icon: {
    minWidth: 40,
  },
  text: {
    whiteSpace: "normal",
  },
}));

const ExportErrorButton = ({ exportReport, success, inProgress, disabled }) => {
  const translate = useTranslate();
  const classes = useStyles();
  const notify = useNotify();
  const wasSuccess = usePrevious(success);

  useEffect(() => {
    if (!wasSuccess && success) {
      notify("exportErrorSuccess", "info", null, null, 8000);
    }
  }, [wasSuccess, success]);

  return (
    <MenuItem disabled={disabled || inProgress} onClick={() => exportReport()} className={classes.button}>
      <ListItemIcon className={classes.icon}>
        {inProgress ? <CircularProgress size={20} thickness={2} color="primary" /> : <BugReportIcon />}
      </ListItemIcon>
      <Typography variant="button" className={classes.text}>
        {translate("exportError")}
      </Typography>
    </MenuItem>
  );
};

const mapStateToProps = state => ({
  inProgress: get(state, "exportReport.inProgress"),
  success: get(state, "exportReport.success", false),
});

const mapDispatchToProps = {
  exportReport,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ExportErrorButton);
