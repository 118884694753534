import React from "react";
import PropTypes from "prop-types";
import { Datagrid, ListContextProvider } from "react-admin";
import GroupingDatagridBody from "../GroupingDataGrid/GroupingDataGridBody";
import {
  MaterialColumn,
  ActionColumn,
  PreviewColumn,
  GroupedColumn,
  PriceColumn,
  IndexColumn,
} from "../TableColumns/TableColumns";
import DetailsColumn from "../TableColumns/DetailsColumn";
import SubtaskPanel from "../subtasks/panel/SubtaskPanel";
import SubtaskTypes from "../../config/SubtaskTypes";

const ModelDatagrid = ({ base, permissions, paymentComplete, ...props }) => (
  <ListContextProvider value={props}>
    <Datagrid {...props} body={<GroupingDatagridBody groupBy={["materialId"]} />} hover={false}>
      <MaterialColumn
        label="Material"
        sortBy="materialId"
        paymentComplete={paymentComplete}
        permissions={permissions}
      />
      <IndexColumn label="Modell-Nr." />
      <PreviewColumn label="Vorschau" permissions={permissions} />
      <GroupedColumn label="Details">
        <DetailsColumn row={1} />
        <PriceColumn row={1} />
        <SubtaskPanel row={2} type={SubtaskTypes.MODEL.key} base={base} />
      </GroupedColumn>
      <ActionColumn label="Aktion" paymentComplete={paymentComplete} permissions={permissions} />
    </Datagrid>
  </ListContextProvider>
);

ModelDatagrid.propTypes = {
  base: PropTypes.string,
  permissions: PropTypes.object,
  paymentComplete: PropTypes.bool.isRequired,
};

ModelDatagrid.defaultProps = {
  paymentComplete: false,
};

export default ModelDatagrid;
