import React from "react";
import PropTypes from "prop-types";
import { FunctionField, ReferenceField, ReferenceManyField, useGetList } from "react-admin";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import resources from "../../config/resources";
import DocumentsUploadButton from "./DocumentsUploadButton";
import PreviewCard from "../PreviewCard";
import get from "lodash/get";

const styles = theme => ({
  actionContainer: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  documentGrid: {
    display: "flex",
    flexWrap: "wrap",
  },
});

const _DocumentsGrid = ({ ids, data, basePath, permissions = {}, parent, classes, archivedView = false }) => {
  const { data: documentData } = useGetList(resources.DOCUMENT_TYPES, { page: 1, perPage: 1000 }, {}, {});

  return (
    <div className={classes.documentGrid}>
      {ids &&
        ids.map(id => (
          <FunctionField
            key={id}
            basePath={basePath}
            record={data[id]}
            render={({ id, name, downloadUrl, ...document }) => (
              <ReferenceField
                record={document}
                basePath={basePath}
                reference={resources.DOCUMENT_TYPES}
                source="documentType"
                link={false}
                allowEmpty={true}
              >
                <FunctionField
                  render={({ labelExternal, labelInternal }) => (
                    <PreviewCard
                      title={
                        get(permissions, "authorities", []).includes("ROLE_CONTRACTOR") ? labelExternal : labelInternal
                      }
                      subtitle={name}
                      downloadUrl={downloadUrl}
                    />
                  )}
                />
              </ReferenceField>
            )}
          />
        ))}
      <DocumentsUploadButton
        permissions={permissions}
        parentBasePath={parent.basePath}
        parentResource={parent.resource}
        parentId={parent.record.__id}
        data={documentData}
        archivedView={archivedView}
      />
    </div>
  );
};

const DocumentsGrid = withStyles(styles)(_DocumentsGrid);

const DocumentsBox = ({ classes, permissions, style = {}, target, archivedView = false, ...props }) => {
  const documentReference = archivedView ? resources.ARCHIVED_DOCUMENTS : resources.DOCUMENTS;

  return (
    <Grid container direction="column" style={style}>
      <ReferenceManyField {...props} reference={documentReference} source="__id" target={target} allowEmpty={true}>
        <DocumentsGrid parent={props} basePath={props.basePath} permissions={permissions} archivedView={archivedView} />
      </ReferenceManyField>
    </Grid>
  );
};

DocumentsBox.propTypes = {
  style: PropTypes.object,
  classes: PropTypes.object.isRequired,
  permissions: PropTypes.object.isRequired,
};

export default withStyles(styles)(DocumentsBox);
