import React from "react";
import { Button, useTranslate } from "react-admin";
import WarehouseLocationIcon from "@material-ui/icons/BorderOuter";
import resources from "../../config/resources";
import { getKey } from "../../lib/i18nUtils";
import { connect } from "react-redux";
import { assignWarehouseCompartmentRequest } from "../../redux/assignWarehouseCompartment/action";

const WarehouseCompartmentSetButton = ({ record, model, shipment, onClick, assignCompartment }) => {
  const translate = useTranslate();

  const click = () => {
    assignCompartment(record.__id, model?.__id, shipment?.__id);
    onClick();
  };

  return (
    <Button color="primary" label={translate(getKey("assign", resources.WAREHOUSE_COMPARTMENTS))} onClick={click}>
      <WarehouseLocationIcon />
    </Button>
  );
};

export default connect(
  null,
  {
    assignCompartment: assignWarehouseCompartmentRequest,
  }
)(WarehouseCompartmentSetButton);
