export default {
  SUCCESS: {
    key: "SUCCESS",
    translationKey: "autoAssignLogStatus.SUCCESS",
  },
  FAILED_FORMULA_EVAL: {
    key: "FAILED_FORMULA_EVAL",
    translationKey: "autoAssignLogStatus.FAILED_FORMULA_EVAL",
  },
  FAILED_NO_ESTIMATE: {
    key: "FAILED_NO_ESTIMATE",
    translationKey: "autoAssignLogStatus.FAILED_NO_ESTIMATE",
  },
  FAILED_NO_MODEL_ESTIMATES: {
    key: "FAILED_NO_MODEL_ESTIMATES",
    translationKey: "autoAssignLogStatus.FAILED_NO_MODEL_ESTIMATES",
  },
  FAILED_MAX_PRICE: {
    key: "FAILED_MAX_PRICE",
    translationKey: "autoAssignLogStatus.FAILED_MAX_PRICE",
  },
  FAILED_MAX_PRICE_DIFF: {
    key: "FAILED_MAX_PRICE_DIFF",
    translationKey: "autoAssignLogStatus.FAILED_MAX_PRICE_DIFF",
  },
  FAILED_WRONG_STATUS: {
    key: "FAILED_WRONG_STATUS",
    translationKey: "autoAssignLogStatus.FAILED_WRONG_STATUS",
  },
  FAILED_TRANSITION_BLOCKED: {
    key: "FAILED_TRANSITION_BLOCKED",
    translationKey: "autoAssignLogStatus.FAILED_TRANSITION_BLOCKED",
  },
  FAILED_GENERIC: {
    key: "FAILED_GENERIC",
    translationKey: "autoAssignLogStatus.FAILED_GENERIC",
  },
};
