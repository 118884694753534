import RohStatus from "./RohStatus";
import compact from "lodash/compact";
import uniq from "lodash/uniq";

export const statusChoices = Object.values(RohStatus)
  .filter(el => el.filterable && el.subtaskStatus)
  .map(el => ({
    id: el.key,
    name: el.translationKey,
  }));

export const getDefaultStatus = (choices, status) => {
  const choiceKeys = Object.values(choices).map(el => el.id);
  if (status && choiceKeys.includes(status)) return choices[choiceKeys.indexOf(status)];
  else return choices[0];
};

export const getStatusesFromModels = models => {
  const choices =
    models && typeof models === "object" && uniq(compact(Object.values(models).map(model => model.rohStatus)));

  const formattedChoices =
    choices && choices.length > 0 && statusChoices.filter(statusChoice => choices.includes(statusChoice.id));

  return formattedChoices && formattedChoices.length > 0 ? formattedChoices : statusChoices;
};
