import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { Button, ReferenceManyField } from "react-admin";
import resources from "../../config/resources";
import sanitizeButton from "../sanitizeButton";
import AssignIcon from "@material-ui/icons/AddShoppingCart";
import green from "@material-ui/core/colors/green";
import { withStyles } from "@material-ui/core/styles";
import classnames from "classnames";
import PaymentWarningDialog from "./PaymentWarningDialog";
import AssignModelDialog from "./AssignModelDialog";
import RohStatus from "../../config/RohStatus";

const styles = theme => ({
  button: {
    backgroundColor: `${green[500]} !important`,
    color: theme.palette.getContrastText(green[500]),
  },
});

export const ReferenceArrayChildProvider = ({ children, ...props }) =>
  React.Children.map(children, child => React.cloneElement(child, { ...props }));

class AssignModelButton extends Component {
  state = {
    isOpen: false,
    displayPaymentWarning: !this.props.paymentComplete,
  };

  handleClick = () => {
    this.setState({ isOpen: true });
  };

  handleDialogClose = () => {
    this.setState({ isOpen: false });
  };

  handleWarningClose = showAssignDialog => {
    this.setState({
      isOpen: showAssignDialog,
      displayPaymentWarning: !showAssignDialog,
    });
  };

  render() {
    const { classes = {}, color, className, paymentComplete, permissions, ...props } = this.props;

    return (
      <Fragment>
        <ReferenceManyField
          {...props}
          reference={resources.PRICE_ESTIMATES}
          source="__id"
          target="model#id"
          addLabel={false}
          allowEmpty={true}
          filter={{ rohStatus: RohStatus.UNASSIGNED.apiValue, active: true }}
        >
          <ReferenceArrayChildProvider>
            {!this.state.displayPaymentWarning ? (
              <AssignModelDialog
                {...props}
                isOpen={this.state.isOpen}
                onClose={this.handleDialogClose}
                model={props.record}
                permissions={permissions}
              />
            ) : (
              <PaymentWarningDialog
                isOpen={this.state.isOpen && this.state.displayPaymentWarning}
                onClose={this.handleWarningClose}
              />
            )}
          </ReferenceArrayChildProvider>
        </ReferenceManyField>
        <Button
          {...sanitizeButton(props)}
          color={color}
          className={color === "default" ? classnames(classes.button, className) : className}
          onClick={this.handleClick}
        />
      </Fragment>
    );
  }
}

AssignModelButton.propTypes = {
  addLabel: PropTypes.bool,
  basePath: PropTypes.string.isRequired,
  record: PropTypes.object.isRequired,
  resource: PropTypes.string.isRequired,
  label: PropTypes.string,
  paymentComplete: PropTypes.bool.isRequired,
  permissions: PropTypes.object.isRequired,
};

AssignModelButton.defaultProps = {
  addLabel: false,
  children: <AssignIcon />,
  paymentComplete: false,
};

export default withStyles(styles)(AssignModelButton);
