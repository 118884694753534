import React from "react";
import {
  CreateButton,
  Datagrid,
  FunctionField,
  List,
  NumberField,
  Pagination,
  ReferenceField,
  TextField,
  TextInput,
  TopToolbar,
  useTranslate,
} from "react-admin";
import { makeStyles } from "@material-ui/core/styles";

import paginationOptions from "../../config/PaginationOptions";
import { getKey } from "../../lib/i18nUtils";
import resources from "../../config/resources";
import DateField from "../customFields/DateField";
import CustomFilter from "../CustomFilter";
import { PrintJobStatus } from "../../config/PrintJobStatus";
import SelectLongArrayInput from "../../SelectLongArrayInput";
import DownloadButton from "../customFields/buttons/DownloadButton";

const useStyles = makeStyles(theme => ({
  customFilter: {
    display: "flex",
    gap: `${theme.spacing(1)}px`,
  },
  horizontalInputsContainer: {
    display: "flex",
    gap: `${theme.spacing()}px`,
  },
  progress: {
    margin: theme.spacing(),
  },
}));

const Actions = ({ basePath, hasCreate }) => (
  <TopToolbar>{hasCreate && <CreateButton basePath={basePath} />}</TopToolbar>
);

const ArchivePrintJobsFilter = ({ ...props }) => {
  const translate = useTranslate();
  const classes = useStyles();

  return (
    <CustomFilter {...props} saveFilter={false} className={classes.customFilter}>
      <TextInput source="id" label={translate(getKey("id", resources.ARCHIVED_PRINT_JOBS))} />
      <SelectLongArrayInput
        source="status"
        label={translate(getKey("status", resources.ARCHIVED_PRINT_JOBS))}
        optionText="translation"
        optionValue="key"
        choices={Object.values(PrintJobStatus)}
        defaultValue={Object.keys(PrintJobStatus).filter(it => it !== "DONE")}
      />
    </CustomFilter>
  );
};

const DownloadDocument = ({ record: { __id } = {} }) => {
  const translate = useTranslate();

  return (
    <DownloadButton
      id={__id}
      label={translate(getKey("downloadButton", resources.ARCHIVED_PRINT_JOBS))}
      downloadPath="/pdf/archivePrintjob"
      fileName={translate(getKey("downloadedFileName", resources.ARCHIVED_PRINT_JOBS), { id: __id })}
    />
  );
};

const ArchivedPrintJobsList = ({ ...props }) => {
  const translate = useTranslate();

  return (
    <List
      {...props}
      actions={<Actions />}
      bulkActionButtons={false}
      pagination={<Pagination {...paginationOptions} />}
      sort={{ field: "completedAt", order: "ASC" }}
      filters={<ArchivePrintJobsFilter />}
    >
      <Datagrid>
        <NumberField source="__id" label={translate(getKey("id", resources.ARCHIVED_PRINT_JOBS))} sortBy="id" />
        <DateField source="printedAt" idField="id" format={translate("format.date.dateTimeFormat")} dateTime />
        <NumberField source="height" />
        <NumberField source="printDuration" label={translate(getKey("printDuration", resources.ARCHIVED_PRINT_JOBS))} />
        <DateField source="completedAt" displayFormat="DD. MMMM YYYY HH:mm" />
        <DateField source="packedAt" />
        <ReferenceField link={false} reference={resources.USERS} source="assignedTo" sortBy="assignedTo.username">
          <TextField source="username" />
        </ReferenceField>
        <ReferenceField link={false} reference={resources.PRINTERS} source="printer" sortBy="printer.name">
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField link={false} reference={resources.BUILD_UNITS} source="buildUnit" sortBy="buildUnit.name">
          <TextField source="name" />
        </ReferenceField>
        <TextField source="notes" />
        <FunctionField
          label={translate(getKey("status", resources.ARCHIVED_PRINT_JOBS))}
          render={({ status }) => translate(PrintJobStatus[status].translation)}
        />
        <DownloadDocument />
      </Datagrid>
    </List>
  );
};

export default ArchivedPrintJobsList;
