import { put, takeEvery } from "redux-saga/effects";
import { fetchRestartNotificationSuccess } from "./action";
import WebSocketTopics from "../../../config/WebSocketTopics";
import { WEB_SOCKET_ACTION, WEB_SOCKET_MESSAGE_RECEIVED } from "../../websocket/action";
import { EVENTS } from "../../../lib/WebSocket";

const websocketMessageSaga = function*(action = {}) {
  const { topic, body } = action.payload || {};

  if (topic === WebSocketTopics.RESTARTING) {
    yield put(fetchRestartNotificationSuccess(body));
  }
};

const websocketSaga = function*(action = {}) {
  const { event } = action.payload || {};

  if (event === EVENTS.CONNECTED) {
    yield put(
      fetchRestartNotificationSuccess({
        restartScheduled: false,
        restartingAt: null,
      })
    );
  }
};

export default function*() {
  yield takeEvery(WEB_SOCKET_MESSAGE_RECEIVED, websocketMessageSaga);
  yield takeEvery(WEB_SOCKET_ACTION, websocketSaga);
}
