import createReducer, { mergeReducers } from "../../lib/createReducer";
import { ANALYSE_WALLTHICKNESS, ANALYSE_WALLTHICKNESS_SUCCESS, ANALYSE_WALLTHICKNESS_ERROR } from "./action";

const init = {
  reference: null,
  fileUuid: null,
  criticalArea: null,
  thickness: null,

  isFetching: false,
  fetchSuccess: false,
  hasError: false,
  payload: null,
  error: null,
};

const requestReducer = createReducer(
  ANALYSE_WALLTHICKNESS,
  ({ reference, fileUuid, criticalArea, thickness }) => ({
    ...init,
    reference,
    fileUuid,
    criticalArea,
    thickness,
    isFetching: true,
  }),
  true
);

const successReducer = createReducer(ANALYSE_WALLTHICKNESS_SUCCESS, ({ response }) => ({
  isFetching: false,
  fetchSuccess: true,
  payload: response,
}));

const errorReducer = createReducer(ANALYSE_WALLTHICKNESS_ERROR, ({ error }) => ({
  isFetching: false,
  hasError: true,
  error,
}));

export default mergeReducers([requestReducer, successReducer, errorReducer], init);
