import createAction, { createActionWithMeta } from "../../../lib/createAction";

export const ACCEPT_JOB_REQUEST = "ACCEPT_JOB_REQUEST";
export const acceptJob = createAction(ACCEPT_JOB_REQUEST, "job");

export const ACCEPT_JOB_SUCCESS = "ACCEPT_JOB_SUCCESS";
export const acceptJobSuccess = createActionWithMeta(ACCEPT_JOB_SUCCESS, { uncache: true, refresh: true }, "response");

export const ACCEPT_JOB_ERROR = "ACCEPT_JOB_ERROR";
export const acceptJobError = createActionWithMeta(ACCEPT_JOB_ERROR, { uncache: true, refresh: true }, "error");
