import { call, put, takeEvery } from "redux-saga/effects";
import resources from "../../../config/resources";
import config from "../../../config/index";
import fetchService from "../../../lib/fetchService";
import { ACTION_ON_MODEL, actionOnModelError, actionOnModelSuccess } from "./action";
import { handleSagaError } from "../../../dataproviders/errorResponseHandler";
import { crudGetOne, refreshView } from "react-admin";

const saga = function*({ payload }) {
  const { modelId, action, body, qs, subpath } = payload;

  const uriSubpath = subpath ? encodeURI(subpath) : "";

  const url = `${config.urls.BACKEND}/v2/${resources.MODELS}/${encodeURIComponent(modelId)}/${encodeURIComponent(
    action
  )}${uriSubpath}`;

  try {
    const response = yield call(fetchService.post, url, qs, body);

    for (const action of yield call(handleSagaError, response, actionOnModelError)) {
      yield put(action);
      return;
    }

    if (response.ok) {
      const responseData = yield call(() => response.json());
      yield put(crudGetOne(resources.MODELS, modelId));
      yield put(refreshView());
      yield put(actionOnModelSuccess(responseData));
    } else {
      yield put(crudGetOne(resources.MODELS, modelId));
      const statusCode = response.status;
      yield put(actionOnModelError(new Error(`Request failed with error ${statusCode}!`)));
    }
  } catch (e) {
    yield put(crudGetOne(resources.MODELS, modelId));
    yield put(actionOnModelError(e));
  }
};

export default function*() {
  yield takeEvery(ACTION_ON_MODEL, saga);
}
