import createAction, { createActionWithMeta } from "../../../lib/createAction";

export const UPLOAD_FILE_VERSION_REQUEST = "ROH/UPLOAD_FILE_VERSION_REQUEST";
export const uploadFileVersionRequest = createAction(
  UPLOAD_FILE_VERSION_REQUEST,
  "file",
  "fileUuid",
  "token",
  "recordId",
  "handleSuccess"
);

export const UPLOAD_FILE_VERSION_PROGRESS = "ROH/UPLOAD_FILE_VERSION_PROGRESS";
export const uploadFileVersionProgress = createAction(UPLOAD_FILE_VERSION_PROGRESS, "file", "progress");

export const UPLOAD_FILE_VERSION_SUCCESS = "ROH/UPLOAD_FILE_VERSION_SUCCESS";
export const uploadFileVersionSuccess = createAction(UPLOAD_FILE_VERSION_SUCCESS, "file", "response");

export const UPLOAD_FILE_VERSION_ERROR = "ROH/UPLOAD_FILE_VERSION_ERROR";
export const uploadFileVersionError = createActionWithMeta(
  UPLOAD_FILE_VERSION_ERROR,
  (file, error = {}) => ({
    notification: {
      body: error.message || "ra.message.error",
      level: "warning",
    },
  }),
  "file",
  "error"
);
