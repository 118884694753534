import React from "react";
import { Edit } from "react-admin";
import { Container } from "@material-ui/core";

import PrintJobForm from "./PrintJobForm";

const PrintJobEdit = ({ ...props }) => {
  return (
    <Container maxWidth="md">
      <Edit {...props} undoable={false}>
        <PrintJobForm />
      </Edit>
    </Container>
  );
};
export default PrintJobEdit;
