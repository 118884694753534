import { call, put, takeEvery } from "redux-saga/effects";
import config from "../../config/index";
import fetchService from "../../lib/fetchService";
import { FETCH_PING, fetchPingError, fetchPingSuccess } from "./action";
import { WEB_SOCKET_ACTION } from "../websocket/action";
import { EVENTS } from "../../lib/WebSocket";

const requestSaga = function*() {
  const url = `${config.urls.BACKEND}/ping`;

  try {
    const response = yield call(fetchService.get, url, undefined, {
      Authorization: "",
    });

    if (response.ok) {
      const responseData = yield call(() => response.json());
      yield put(fetchPingSuccess(responseData));
    } else {
      const statusCode = response.status;
      yield put(fetchPingError(new Error(`Request failed with error ${statusCode}!`)));
    }
  } catch (e) {
    yield put(fetchPingError(e));
  }
};

const websocketSaga = function*(action = {}) {
  const { event } = action.payload || {};

  if (event === EVENTS.CONNECTED) {
    yield put(fetchPingSuccess("ok"));
  } else if (event === EVENTS.DISCONNECTED) {
    yield put(fetchPingError("error"));
  }
};

export default function*() {
  yield takeEvery(FETCH_PING, requestSaga);
  yield takeEvery(WEB_SOCKET_ACTION, websocketSaga);
}
