import React from "react";
import { crudUpdate, DeleteButton, Edit, SaveButton, SimpleForm, TextInput, translate } from "react-admin";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import CardActions from "@material-ui/core/CardActions";
import PropTypes from "prop-types";
import Title from "../customFields/Title";
import resources from "../../config/resources";

const styles = () => ({
  root: {
    display: "flex",
    justifyContent: "center",
  },
});

/**
 * filterParts from the response have to be removed from edit payload
 */
const transformDataToSubmit = data => ({
  ...data,
  filterParts: null,
});

const Actions = props => (
  <CardActions>
    <SaveButton {...props} transform={transformDataToSubmit} />
    <DeleteButton {...props} undoable={false} />
  </CardActions>
);

const FilterGraphEdit = ({ translate, classes, dispatchCrudUpdate, ...props }) => (
  <div className={classes.root}>
    <Edit title={<Title resource={resources.FILTER_GRAPHS} />} {...props}>
      <SimpleForm toolbar={<Actions />}>
        <TextInput source="name" />
      </SimpleForm>
    </Edit>
  </div>
);

FilterGraphEdit.propTypes = {
  dispatchCrudUpdate: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

export default connect(
  undefined,
  { dispatchCrudUpdate: crudUpdate }
)(withStyles(styles)(translate(FilterGraphEdit)));
