import createAction, { createActionWithMeta } from "../../../lib/createAction";

export const FETCH_FILE_VERSIONS_REQUEST = "FETCH_FILE_VERSIONS_REQUEST";
export const fetchFileVersion = createAction(FETCH_FILE_VERSIONS_REQUEST, "fileUuid", "fileToken");

export const FETCH_FILE_VERSIONS_SUCCESS = "FETCH_FILE_VERSIONS_SUCCESS";
export const fetchFileVersionSuccess = createAction(FETCH_FILE_VERSIONS_SUCCESS, "fileUuid", "response");

export const FETCH_FILE_VERSIONS_ERROR = "FETCH_FILE_VERSIONS_ERROR";
export const fetchFileVersionError = createActionWithMeta(
  FETCH_FILE_VERSIONS_ERROR,
  {
    notification: {
      body: "ra.message.error",
      level: "warning",
    },
  },
  "fileUuid",
  "error"
);
