import React from "react";
import { connect } from "react-redux";
import { Button, showNotification, useGetOne, useTranslate } from "react-admin";
import green from "@material-ui/core/colors/green";
import { withStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import get from "lodash/get";
import compact from "lodash/compact";
import sanitizeButton from "../sanitizeButton";
import { assignPriceEstimates } from "../../redux/model/assignModels/action";
import resources from "../../config/resources";
import { getKey } from "../../lib/i18nUtils";
import { getLocalNoteToContractor } from "../../redux/model/noteToContractor/selector";

const styles = theme => ({
  button: {
    backgroundColor: `${green[500]} !important`,
    color: theme.palette.getContrastText(green[500]),
  },
});

const AssignModelButton = ({
  assignMany,
  showNotification,
  record,
  classes,
  children,
  disabled,
  isFetching,
  isLoading,
  onClick,
  model,
  estimates,
  noteToContractor,
  ...rest
}) => {
  const translate = useTranslate();
  const handleClick = () => {
    if (!record) {
      showNotification("ra.input.references.single_missing", "warning");
      return;
    }

    if (typeof onClick === "function") {
      onClick(record);
    }

    const group = record["_GROUP"];
    if (model && model.__id && (!group || (group && Object.values(group).length < 2))) {
      assignMany([record.__id], noteToContractor !== "" ? noteToContractor : null);
    } else {
      const priceEstimates = compact(Object.values(group).map(estimate => estimate.__id));

      if (priceEstimates && priceEstimates.length > 0) {
        assignMany(priceEstimates, noteToContractor !== "" ? noteToContractor : null);
      }
    }
  };

  const { data: contractor, loading } = useGetOne(resources.CONTRACTORS, record.contractor);
  const materialiseReady =
    contractor && contractor.isMaterialise && model
      ? model.latestFileOnMaterialise
      : contractor && contractor.isMaterialise && estimates
      ? Object.values(estimates).filter(it => !it.latestModelFileOnMaterialise).length === 0
      : true;

  const label = materialiseReady
    ? translate(getKey("assign", resources.PRICE_ESTIMATES))
    : translate("materialiseModelUnsynched");

  return (
    <Button
      {...sanitizeButton({ ...rest, label: label })}
      className={classes.button}
      onClick={() => handleClick()}
      disabled={disabled || isFetching || isLoading || loading || !materialiseReady}
    >
      {isFetching || isLoading || loading ? <CircularProgress size={20} thickness={2} color="primary" /> : children}
    </Button>
  );
};

AssignModelButton.defaultProps = {
  isFetching: false,
  isLoading: false,

  disabled: false,
  classes: {},
};

const mapStateToProps = state => ({
  isLoading: get(state, "admin.loading") > 0,
  isFetching: get(state, "assignModel.isFetching") || get(state, "assignModels.isFetching"),
  noteToContractor: getLocalNoteToContractor(state),
});

export default withStyles(styles)(
  connect(
    mapStateToProps,
    {
      assignMany: assignPriceEstimates,
      showNotification,
    }
  )(AssignModelButton)
);
