import config from "../config/index";
import fetchService from "../lib/fetchService";
import qs from "qs";

export default {
  login: ({ username, password, token }) => {
    let request;

    if (typeof token === "string") {
      const params = {
        client: config.oauth.CLIENT_ID,
        secret: config.oauth.CLIENT_SECRET,
        token,
      };

      request = new Request(`${config.urls.BACKEND}/token?${qs.stringify(params)}`, {
        method: "POST",
        credentials: "include",
        mode: "cors",
        cache: "no-cache",
        headers: new Headers({
          Accept: "application/json",
          "Accept-Charset": "utf-8",
          "Access-Control-Allow-Origin": "*",
        }),
      });
    } else {
      const basicAuthBase = `${config.oauth.CLIENT_ID}:${config.oauth.CLIENT_SECRET}`;
      const basicAuthToken = new Buffer(basicAuthBase).toString("base64");
      const body = `grant_type=password&username=${encodeURIComponent(username)}&password=${encodeURIComponent(
        password
      )}`;

      request = new Request(`${config.urls.BACKEND}/oauth/token`, {
        method: "POST",
        credentials: "include",
        body,
        mode: "cors",
        cache: "no-cache",
        headers: new Headers({
          "Content-Type": "application/x-www-form-urlencoded",
          Accept: "application/json",
          "Accept-Charset": "utf-8",
          Authorization: `Basic ${basicAuthToken}`,
          "Access-Control-Allow-Origin": "*",
        }),
      });
    }

    return fetch(request)
      .then(response => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.status);
        }
        return response.json();
      })
      .then(response => {
        localStorage.setItem("token", response["access_token"]);
        localStorage.setItem("refresh", response["refresh_token"]);
        const expiresAt = new Date().getTime() + parseInt(response["expires_in"]) * 1000;
        localStorage.setItem("expiresAt", expiresAt);
        return response;
      })
      .then(response => {
        return fetchService.get(
          `${config.urls.BACKEND}/me`,
          {},
          {
            Authorization: `Bearer ${response["access_token"]}`,
          }
        );
      })
      .then(res => {
        if (res.status < 200 || res.status >= 300) {
          throw new Error(res.statusText);
        }
        return res.json();
      })
      .then(res => {
        localStorage.setItem("me", JSON.stringify(res));
      })
      .catch(error => console.log("###error", error));
  },

  logout: ignoredParams => {
    localStorage.removeItem("token");
    localStorage.removeItem("refresh");
    localStorage.removeItem("expiresAt");
    localStorage.removeItem("me");
    return Promise.resolve();
  },

  checkAuth: ignoredParams => {
    const token = localStorage.getItem("token");
    if (!token) return Promise.reject();

    const expiresAt = localStorage.getItem("expiresAt");
    if (!expiresAt || new Date().getTime() > parseInt(expiresAt)) {
      return Promise.reject();
    }

    return Promise.resolve();
  },

  checkError: error => {
    const status = error.status;
    if (status === 401 || status === 403) {
      localStorage.removeItem("token");
      localStorage.removeItem("refresh");
      localStorage.removeItem("expiresAt");
      localStorage.removeItem("me");
      return Promise.reject();
    }
    return Promise.resolve();
  },

  getPermissions: () => {
    const me = localStorage.getItem("me");
    return me ? Promise.resolve(JSON.parse(me)) : Promise.reject();
  },
};
