/**
 * An action creator creator. It will return a standard redux action creator, which will put it's arguments inside the returned payload.
 * When you call the action creator creator, you pass it a list of argument names. These names will later end up in the action payload as object keys.
 *
 * Example:
 *   const actionCreator = createAction(AN_ACTION_TYPE, "foo", "cake");
 *       ==> actionCreator equals (arg1, arg2) => ({type: AN_ACTION_TYPE, payload: {foo: arg1, cake: arg2}})
 *
 *   const action = actionCreator("bar", "a lie");
 *       ==> action equals {type: AN_ACTION_TYPE, payload: {foo: "bar", cake: "a lie"}}
 *
 * @param type {string} - The type of the action
 * @param argNames {string} - The name of the arguments the actionCreator will be called with. The values passed to the actionCreator will end up in the action payload with their name as their key.
 * @returns {Function} Returns a Redux actionCreator
 */
export const createAction = (type, ...argNames) => {
  if (argNames.length === 0) {
    return (...args) => ({ type, payload: args[0] });
  } else {
    const reducer = (previousValue, currentValue, currentIndex) => {
      return (...args) => ({
        type,
        payload: {
          ...previousValue(...args).payload,
          [currentValue]: args[currentIndex],
        },
      });
    };
    const emptyAction = () => ({ type, payload: {} });
    return argNames.reduce(reducer, emptyAction);
  }
};

export const createActionWithMeta = (type, meta, ...argNames) => (...args) => ({
  ...createAction(type, ...argNames)(...args),
  meta: typeof meta === "function" ? meta(...args) : meta,
});

export default createAction;
