import createAction from "../../lib/createAction";

export const DOWNLOAD_FILE_REQUEST = "DOWNLOAD_FILE_REQUEST";
export const downloadFile = createAction(DOWNLOAD_FILE_REQUEST);

export const DOWNLOAD_FILE_SUCCESS = "DOWNLOAD_FILE_SUCCESS";
export const downloadSuccess = createAction(DOWNLOAD_FILE_SUCCESS);

export const DOWNLOAD_FILE_ERROR = "DOWNLOAD_FILE_ERROR";
export const downloadError = createAction(DOWNLOAD_FILE_ERROR, "error");
