import React from "react";
import {
  List,
  Datagrid,
  ReferenceField,
  TextField,
  NumberField,
  FunctionField,
  translate,
  Pagination,
  ShowButton,
} from "react-admin";
import PropTypes from "prop-types";
import paginationOptions from "../../config/PaginationOptions";
import resources from "../../config/resources";
import DateField from "../customFields/DateField";
import Chip from "@material-ui/core/Chip";
import PriceField from "../customFields/PriceField";
import AutoAssignLogStatusField from "./AutoAssignLogStatusField";
import { getKey } from "../../lib/i18nUtils";
import AutoAssignLogsFilters from "./AutoAssignLogsFilters";

const AutoAssignLogsList = ({ translate, ...props }) => (
  <List
    {...props}
    bulkActionButtons={false}
    pagination={<Pagination {...paginationOptions} />}
    actions={null}
    sort={{ field: "created", order: "DESC" }}
    filters={<AutoAssignLogsFilters />}
  >
    <Datagrid>
      <NumberField source="__id" label={translate(getKey("id", resources.AUTO_ASSIGN_LOGS))} sortBy="id" />
      <DateField source="created" displayFormat="DD. MMMM YYYY HH:mm:ss" />
      <ReferenceField reference={resources.PRICE_ESTIMATES} source="formula">
        <NumberField source="__id" label={translate(getKey("id", resources.FORMULAS))} />
      </ReferenceField>
      <NumberField source="formulaVersion" />
      <AutoAssignLogStatusField source="status" />
      <ReferenceField source="priceEstimate" reference={resources.PRICE_ESTIMATES} link={false} allowEmpty>
        <FunctionField render={record => (record ? <PriceField record={record} source="price" /> : null)} />
      </ReferenceField>
      <ReferenceField source="model" reference={resources.MODELS} link={false}>
        <TextField source="rapidshopId" />
      </ReferenceField>
      <ReferenceField source="job" reference={resources.JOBS} allowEmpty={true} link={false}>
        <FunctionField render={({ externalId }) => (externalId ? <Chip label={externalId} /> : null)} />
      </ReferenceField>
      <ReferenceField
        label={translate(getKey("order", resources.MODELS))}
        source="model"
        reference={resources.MODELS}
        link={false}
      >
        <ReferenceField source="order" reference={resources.ORDERS} link={false}>
          <TextField source="rapidshopId" />
        </ReferenceField>
      </ReferenceField>
      <ReferenceField source="contractor" reference={resources.CONTRACTORS} link={false} allowEmpty={true}>
        <TextField source="contractorName" />
      </ReferenceField>
      <ShowButton />
    </Datagrid>
  </List>
);

AutoAssignLogsList.propTypes = {
  translate: PropTypes.func.isRequired,
};

export default translate(AutoAssignLogsList);
