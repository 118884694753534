export default {
  COMMERCIAL: {
    key: "COMMERCIAL",
    translation: "customerType.COMMERCIAL",
  },
  PRIVATE: {
    key: "PRIVATE",
    translation: "customerType.PRIVATE",
  },
};
