import React from "react";
import PropTypes from "prop-types";
import { BooleanField, Labeled, Show, TextField, translate } from "react-admin";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Chip from "@material-ui/core/Chip";
import CardContent from "@material-ui/core/CardContent";
import CustomerTypes from "../../config/CustomerTypes";
import Typography from "@material-ui/core/Typography";
import PersonIcon from "@material-ui/icons/Person";
import CallIcon from "@material-ui/icons/Call";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import Divider from "@material-ui/core/Divider";
import DateField from "../customFields/DateField";

import { getKey } from "../../lib/i18nUtils";
import resources from "../../config/resources";
import Title from "../customFields/Title";

const styles = theme => ({
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: "2rem",
  },
  iconWrapper: {
    marginBottom: "2rem",
  },
  icon: {
    width: "2rem",
    height: "2rem",
    marginBottom: "2rem",
  },
});

const enhance = cmp => withStyles(styles)(translate(cmp));

const TypeField = translate(({ record, translate }) => (
  <span>{record.customerType ? <Chip label={translate(CustomerTypes[record.customerType].translation)} /> : null}</span>
));

const _CustomerShowBody = translate(({ translate, classes, ...props }) => (
  <CardContent>
    <Grid container direction="column">
      <Grid container direction="row">
        <Grid item>
          <Labeled {...props} source="debitorNumber">
            <TextField {...props} source="debitorNumber" />
          </Labeled>
        </Grid>
        <Grid item>
          <Labeled {...props} source="allowNewsletter">
            <BooleanField {...props} source="allowNewsletter" />
          </Labeled>
        </Grid>
        <Grid item>
          <Labeled {...props} source="allowNotifications">
            <BooleanField {...props} source="allowNotifications" />
          </Labeled>
        </Grid>
        <Grid item>
          <Labeled {...props} source="customerType">
            <TypeField {...props} />
          </Labeled>
        </Grid>
      </Grid>
      <Grid item>
        <Divider className={classes.divider} />
      </Grid>
      <Grid container direction="row" justify="space-between" spacing={32}>
        <Grid item xs={4} container direction="column">
          <Grid item container direction="column" alignItems="center" className={classes.iconWrapper}>
            <PersonIcon className={classes.icon} />
            <Typography variant="subtitle1">{translate("customerShowCategory.person")}</Typography>
          </Grid>
          <Grid item>
            <Labeled {...props} source="title">
              <TextField {...props} source="title" />
            </Labeled>
          </Grid>
          <Grid item container direction="row">
            <Grid item xs={6}>
              <Labeled {...props} source="prename">
                <TextField {...props} source="prename" />
              </Labeled>
            </Grid>
            <Grid item xs={6}>
              <Labeled {...props} source="lastname">
                <TextField {...props} source="lastname" />
              </Labeled>
            </Grid>
          </Grid>
          <Grid item container direction="row">
            <Grid item xs={6}>
              <Labeled {...props} source="dayOfBirth">
                <DateField {...props} source="dayOfBirth" />
              </Labeled>
            </Grid>
            <Grid item xs={6}>
              <Labeled {...props} source="gender">
                <TextField {...props} source="gender" />
              </Labeled>
            </Grid>
          </Grid>
          <Grid item>
            <Labeled {...props} source="companyName">
              <TextField {...props} source="companyName" />
            </Labeled>
          </Grid>
        </Grid>
        <Grid item xs={4} container direction="column">
          <Grid item container direction="column" alignItems="center" className={classes.iconWrapper}>
            <CallIcon className={classes.icon} />
            <Typography variant="subtitle1">{translate("customerShowCategory.contact")}</Typography>
          </Grid>
          <Grid item container direction="row">
            <Grid item xs={6}>
              <Labeled {...props} source="phone">
                <TextField {...props} source="phone" />
              </Labeled>
            </Grid>
            <Grid item xs={6}>
              <Labeled {...props} source="phoneMobile">
                <TextField {...props} source="phoneMobile" />
              </Labeled>
            </Grid>
          </Grid>
          <Grid item container direction="row">
            <Grid item xs={6}>
              <Labeled {...props} source="email">
                <TextField {...props} source="email" />
              </Labeled>
            </Grid>
            <Grid item xs={6}>
              <Labeled {...props} source="emailInvoice">
                <TextField {...props} source="emailInvoice" />
              </Labeled>
            </Grid>
          </Grid>
          <Grid item>
            <Labeled {...props} source="fax">
              <TextField {...props} source="fax" />
            </Labeled>
          </Grid>
        </Grid>
        <Grid item xs={4} container direction="column">
          <Grid item container direction="column" alignItems="center" className={classes.iconWrapper}>
            <LocationOnIcon className={classes.icon} />
            <Typography variant="subtitle1">{translate("customerShowCategory.address")}</Typography>
          </Grid>
          <Grid item container direction="row">
            <Grid item xs={6}>
              <Labeled {...props} source="defaultDeliveryAddress">
                <TextField
                  {...props}
                  source="defaultDeliveryAddressStr"
                  label={translate(getKey("defaultDeliveryAddress", resources.CUSTOMERS))}
                />
              </Labeled>
            </Grid>
            <Grid item xs={6}>
              <Labeled {...props} source="defaultInvoiceAddress">
                <TextField
                  {...props}
                  source="defaultInvoiceAddressStr"
                  label={translate(getKey("defaultInvoiceAddress", resources.CUSTOMERS))}
                />
              </Labeled>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  </CardContent>
));
const CustomerShowBody = enhance(_CustomerShowBody);

const CustomerShow = props => (
  <Show title={<Title resource={resources.CUSTOMERS} />} {...props}>
    <CustomerShowBody />
  </Show>
);

_CustomerShowBody.propTypes = {
  translate: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

export default CustomerShow;
