import React, { useState } from "react";
import PropTypes from "prop-types";
import { ReferenceManyField, translate } from "react-admin";
import classnames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";

import { resources } from "../../config/resources";
import CommentCreate from "./CommentCreate";
import CommentsGrid from "./CommentsGrid";
import CommentsTabWrapper from "./CommentsTabWrapper";

const styles = theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
  },
  title: {
    marginBottom: theme.spacing(3),
  },
});

const CommentBox = ({
  permissions,
  translate,
  jobId,
  shipmentId,
  classes,
  className,
  archivedView = false,
  ...props
}) => {
  const [selectedJobIdFromTab, setSelectedJobIdFromTab] = useState();

  const reference = archivedView ? resources.ARCHIVED_COMMENTS : resources.COMMENTS;

  return (
    <Card className={classnames(classes.root, className)}>
      <CardContent>
        <Typography variant="subtitle1" className={classes.title}>
          {translate("resources.comments.name")}
        </Typography>
        {jobId && (
          <ReferenceManyField reference={reference} target="job#id" {...props}>
            <CommentsGrid translate={translate} permissions={permissions} />
          </ReferenceManyField>
        )}
        {shipmentId && (
          <CommentsTabWrapper
            {...props}
            translate={translate}
            onChangeTab={id => setSelectedJobIdFromTab(id)}
            permissions={permissions}
            archivedView={archivedView}
          />
        )}
      </CardContent>
      {permissions && permissions.authorities.includes("ROLE_SALES") && (
        <CommentCreate
          {...props}
          permissions={permissions}
          jobId={isFinite(selectedJobIdFromTab) ? selectedJobIdFromTab : jobId}
          shipmentId={!isFinite(selectedJobIdFromTab) ? shipmentId : null}
        />
      )}
    </Card>
  );
};

CommentBox.propTypes = {
  permissions: PropTypes.object.isRequired,
  jobId: PropTypes.number,
  shipmentId: PropTypes.number,
  className: PropTypes.string,

  translate: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(translate(CommentBox));
