import { HttpError } from "react-admin";
import get from "lodash/get";
import { Logger } from "../lib/logger";
import ResponseCodeError from "../lib/ResponseCodeError";
import translationProvider from "./translationProvider";
import isEmpty from "lodash/isEmpty";

const LOG = Logger("errorResponseHandler.js");

export const handleRequestError = async ({ status, url, headers }, responseJson) => {
  try {
    LOG.error("Got error response from server!", status, responseJson, url, headers);

    const { code = 0, fieldErrors = {} } = responseJson;

    const message = !isEmpty(fieldErrors)
      ? `errorCodes.${Object.values(fieldErrors)[0]}`
      : code
      ? `errorCodes.${code}`
      : "invalidValue";

    const fieldErrorMap = parseFieldErrors(fieldErrors, message);

    const fieldError = { fieldErrors: fieldErrorMap };
    if (code === 0) throw fieldError;

    throw new ResponseCodeError(code, message, status, fieldErrorMap);
  } catch (e) {
    if (e instanceof ResponseCodeError) {
      throw e;
    } else if (status === 400) {
      throw new HttpError("ra.message.invalid_form", status, e.fieldErrors);
    } else if (status === 401 || status === 403) {
      throw new HttpError("ra.auth.sign_in_error", status);
    } else if (status === 500 || status === 501) {
      throw new HttpError("ra.message.error", status, e.fieldErrors);
    } else throw new HttpError("ra.notification.http_error", status);
  }
};

/**
 * @param response {Response}
 * @returns {PromiseLike<Response>}
 */
export const handleError = async response => {
  if (!response || response.ok) return response;

  const json = (await response.json().catch(() => ({}))) || {};

  await handleRequestError(response, json);
};

export const handleSagaError = async (response, errorAction = {}) => {
  try {
    await handleError(response);
    return [];
  } catch (e) {
    if (typeof errorAction === "function") {
      errorAction = errorAction(e);
    }
    const { meta = {} } = errorAction;
    if (e instanceof HttpError || e.hasOwnProperty("body")) {
      meta.notification = {
        body: e.message,
        level: "warning",
      };
    }
    return [{ ...errorAction, meta }];
  }
};

// FIXME: Add proper translations for other languages
const translations = translationProvider;
const translate = (key, fallback) => get(translations, key, fallback || key);

/**
 *
 * @param [fieldErrors] {Object.<string, string>|null}
 * @param errorContent {string}
 * @returns {Object.<string, string>}
 */
const parseFieldErrors = (fieldErrors, errorContent) => {
  if (!fieldErrors) return {};
  errorContent = translate(errorContent, " ");
  const errors = {};
  for (const field in fieldErrors) {
    if (fieldErrors.hasOwnProperty(field)) errors[field] = errorContent;
  }
  return errors;
};

export default handleError;
