import { useGetList } from "react-admin";

const ReferenceGroupArrayFieldController = ({
  reference,
  children,
  basePath,
  record,
  target,
  filter = {},
  sort = {
    field: "id",
    order: "ASC",
  },
  pagination = { page: 1, perPage: 1000 },
  targetIds,
}) => {
  const finalFilter = target
    ? targetIds
      ? {
          [target]: targetIds,
          ...filter,
        }
      : record && record._GROUP
      ? {
          [target]: Object.values(record._GROUP).map(it => it.__id),
          ...filter,
        }
      : filter
    : filter;

  const { data, ids, loaded } = useGetList(reference, pagination, sort, finalFilter);

  return children({
    // tslint:disable-next-line:triple-equals
    loaded,
    ids,
    data,
    basePath,
    currentSort: sort,
  });
};

export default ReferenceGroupArrayFieldController;
