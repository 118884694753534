import { put, takeEvery } from "redux-saga/effects";
import { userCheck } from "react-admin";
import { NOTIFICATION_TIMER_TICK, WINDOW_FOCUSED } from "../../notifications/action";

const checkSaga = function*() {
  yield put(userCheck());
};

export default function*() {
  yield takeEvery([NOTIFICATION_TIMER_TICK, WINDOW_FOCUSED], checkSaga);
}
