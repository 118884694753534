import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Edit, SimpleForm, translate } from "react-admin";
import FormulaForm from "./FormulaForm";
import Title from "../customFields/Title";
import resources from "../../config/resources";

const styles = () => ({
  root: {
    display: "flex",
    justifyContent: "center",
  },
});

const FormulaEdit = ({ translate, classes, ...props }) => (
  <div className={classes.root}>
    <Edit title={<Title resource={resources.FORMULAS} />} actions={false} undoable={false} {...props}>
      <SimpleForm initialValues={{ enabled: true }} redirect="list">
        <FormulaForm />
      </SimpleForm>
    </Edit>
  </div>
);

FormulaEdit.propTypes = {
  classes: PropTypes.object.isRequired,
  translate: PropTypes.func.isRequired,
};

export default withStyles(styles)(translate(FormulaEdit));
