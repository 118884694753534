import createAction, { createActionWithMeta } from "../../lib/createAction";

export const ANALYSE_WALLTHICKNESS = "ANALYSE_WALLTHICKNESS";
export const analyseWallthickness = createAction(
  ANALYSE_WALLTHICKNESS,
  "reference",
  "fileUuid",
  "criticalArea",
  "thickness"
);

export const ANALYSE_WALLTHICKNESS_SUCCESS = "ANALYSE_WALLTHICKNESS_SUCCESS";
export const analyseWallthicknessSuccess = createActionWithMeta(
  ANALYSE_WALLTHICKNESS_SUCCESS,
  {
    notification: {
      body: "ra.notification.updated",
      level: "info",
    },
  },
  "response"
);

export const ANALYSE_WALLTHICKNESS_ERROR = "ANALYSE_WALLTHICKNESS_ERROR";
export const analyseWallthicknessError = createActionWithMeta(
  ANALYSE_WALLTHICKNESS_ERROR,
  {
    notification: {
      body: "ra.notification.bad_item",
      level: "warning",
    },
  },
  "error"
);
