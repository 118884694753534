import React from "react";
import { Button } from "react-admin";
import { useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import isEmpty from "lodash/isEmpty";

import { acceptJob } from "../../redux/job/acceptJob/action";
import resources from "../../config/resources";
import sanitizeButton from "../sanitizeButton";
import green from "@material-ui/core/colors/green";

const useStyles = makeStyles(theme => ({
  button: {
    backgroundColor: `${green[600]} !important`,
    color: theme.palette.getContrastText(green[600]),
  },
}));

const ConfirmJobButton = ({ record, resource, color, selectedIds, ...props }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleClick = async () => {
    if (resource === resources.JOBS || record) {
      dispatch(acceptJob(record.__id));
    }

    if (!isEmpty(selectedIds)) {
      Promise.all(selectedIds.map(async id => await dispatch(acceptJob(id))));
    }
  };

  return (
    <Button
      {...sanitizeButton(props)}
      color={color}
      className={color === "default" ? classes.button : ""}
      onClick={handleClick}
    >
      <CheckIcon />
    </Button>
  );
};

export default ConfirmJobButton;
