import React from "react";
import PropTypes from "prop-types";
import { Datagrid, List, NumberField, Pagination, TextField, translate } from "react-admin";
import { getKey } from "../../lib/i18nUtils";
import resources from "../../config/resources";
import IconChipField from "../customFields/IconChipField";
import paginationOptions from "../../config/PaginationOptions";

const ColorField = ({ record }) => (
  <span>
    {record.colorName && record.colorCode && <IconChipField color={record.colorCode} label={record.colorName} />}
  </span>
);

const AdditionsList = ({ translate, ...props }) => (
  <List {...props} bulkActionButtons={false} actions={false} pagination={<Pagination {...paginationOptions} />}>
    <Datagrid>
      <NumberField sortBy="id" source="__id" textAlign="left" />
      <TextField source="name" />
      <ColorField label={translate(getKey("colorName", resources.ADDITIONS))} />
      <TextField source="colorCode" />
      <NumberField
        source="modelAmount"
        label={translate(getKey("models", resources.ADDITIONS))}
        sortable={false}
        textAlign="left"
      />
    </Datagrid>
  </List>
);

ColorField.propTypes = {
  record: PropTypes.object,
};

AdditionsList.propTypes = {
  translate: PropTypes.func.isRequired,
};

export default translate(AdditionsList);
