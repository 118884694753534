import React, { memo } from "react";
import IconButton from "@material-ui/core/IconButton";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import classNames from "classnames";
import { translate } from "react-admin";

const ExpandRowButton = ({ classes, expanded, expandContentId, translate, ...props }) => (
  <IconButton
    className={classNames(classes.expandIcon, {
      [classes.expanded]: expanded,
    })}
    component="div"
    tabIndex={-1}
    aria-hidden="true"
    {...props}
  >
    <ExpandMoreIcon />
  </IconButton>
);

export default memo(translate(ExpandRowButton));
