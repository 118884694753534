import React from "react";
import { translate, Button } from "react-admin";
import AssignmentReturn from "@material-ui/icons/AssignmentReturn";
import { connect } from "react-redux";
import { applyAllNotices } from "../../redux/model/applyAllNotices/action";

const ApplyAllNoticesButton = ({ translate, data, applyNotices, ...props }) => {
  const onClick = () => {
    const modelIds = data ? Object.values(data).map(x => x.__id) : [];
    if (modelIds.length === 0) {
      return;
    }

    applyNotices(modelIds);
  };

  return (
    <Button variant="contained" color="default" label={translate("applyAllNotices")} onClick={onClick}>
      <AssignmentReturn />
    </Button>
  );
};

export default connect(
  undefined,
  { applyNotices: applyAllNotices }
)(translate(ApplyAllNoticesButton));
