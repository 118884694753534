import React from "react";
import PropTypes from "prop-types";
import { Labeled, Show, SimpleShowLayout, TextField, translate } from "react-admin";
import Chip from "@material-ui/core/Chip";
import TemplateTypes from "../../config/TemplateTypes";
import get from "lodash/get";
import Title from "../customFields/Title";
import resources from "../../config/resources";

const TypeField = translate(({ record, translate }) => (
  <span>{record.type ? <Chip label={translate(TemplateTypes[record.type].translation)} /> : null}</span>
));

const HtmlField = ({ record, source }) => <div dangerouslySetInnerHTML={{ __html: get(record, source) }} />;

const TemplateShow = ({ translate, ...props }) => (
  <Show title={<Title resource={resources.TEMPLATES} />} {...props}>
    <SimpleShowLayout>
      <TextField source="name" />
      <Labeled {...props} source="type">
        <TypeField />
      </Labeled>
      <TextField source="subject" />
      <HtmlField source="body" />
    </SimpleShowLayout>
  </Show>
);

TypeField.propTypes = {
  translate: PropTypes.func,
  record: PropTypes.object,
};

HtmlField.propTypes = {
  record: PropTypes.object,
  source: PropTypes.string,
};

TemplateShow.propTypes = {
  translate: PropTypes.func.isRequired,
};

export default translate(TemplateShow);
