import React from "react";
import { connect } from "react-redux";
import { Button } from "react-admin";
import resources from "../../config/resources";
import sanitizeButton from "../sanitizeButton";
import { confirmOrderConfirmation } from "../../redux/job/confirmOrderConfirmation/action";
import Icon from "@material-ui/icons/DoneOutline";

const OrderConfirmationConfirmedButton = ({
  record,
  resource,
  confirmOrderConfirmation,
  classes,
  color,
  className,
  onClick,
  ...props
}) => {
  const handleClick = e => {
    if (resource === resources.JOBS && record) {
      confirmOrderConfirmation(record.__id);
    }
    onClick(e);
  };

  return (
    <Button {...sanitizeButton(props)} startIcon={<Icon />} color={color} className={className} onClick={handleClick} />
  );
};

export default connect(
  undefined,
  {
    confirmOrderConfirmation,
  }
)(OrderConfirmationConfirmedButton);
