import createReducer, { mergeReducers } from "../../../lib/createReducer";
import {
  FETCH_RESTART_NOTIFICATION,
  FETCH_RESTART_NOTIFICATION_SUCCESS,
  FETCH_RESTART_NOTIFICATION_ERROR,
} from "./action";

const init = {
  isFetching: false,
  fetchSuccess: false,
  hasError: false,
  payload: null,
  error: null,
};

const requestReducer = createReducer(FETCH_RESTART_NOTIFICATION, () => ({
  isFetching: true,
}));

const successReducer = createReducer(FETCH_RESTART_NOTIFICATION_SUCCESS, ({ response }) => ({
  isFetching: false,
  fetchSuccess: true,
  hasError: false,
  payload: response,
  error: null,
}));

const errorReducer = createReducer(FETCH_RESTART_NOTIFICATION_ERROR, ({ error }) => ({
  isFetching: false,
  hasError: true,
  fetchSuccess: false,
  error,
  payload: null,
}));

export default mergeReducers([requestReducer, successReducer, errorReducer], init);
