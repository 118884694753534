import React from "react";
import { Create } from "react-admin";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import DocumentTypeForm from "./DocumentTypeForm";

const styles = () => ({
  root: {
    display: "flex",
    justifyContent: "center",
  },
});

const DocumentTypeCreate = ({ classes, ...props }) => (
  <div className={classes.root}>
    <Create {...props}>
      <DocumentTypeForm />
    </Create>
  </div>
);

DocumentTypeCreate.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DocumentTypeCreate);
