import React from "react";
import IconChipField from "../customFields/IconChipField";
import { PrintJobStatus } from "../../config/PrintJobStatus";
import { LinearProgress, useQuery, useTranslate } from "react-admin";
import { Print } from "@material-ui/icons";
import resources from "../../config/resources";

const PrintJobChip = ({ record: { printJob, count }, style }) => {
  const translate = useTranslate();
  const { data, loading } = useQuery({
    type: "getOne",
    resource: resources.PRINT_JOBS,
    payload: { id: printJob },
  });

  if (loading) {
    return <LinearProgress />;
  }

  if (!data) {
    return null;
  }

  return (
    <IconChipField
      style={style}
      color={PrintJobStatus[data.status].color}
      label={`${data.__id} | ${translate(PrintJobStatus[data.status].translation)} | ${count || "?"} Modell${
        count > 1 ? "e" : ""
      }`}
      icon={Print}
    />
  );
};

export default PrintJobChip;
