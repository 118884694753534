import React from "react";
import { Dialog, DialogTitle, Typography, IconButton, Divider } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import {
  Create,
  NumberInput,
  RadioButtonGroupInput,
  SelectInput,
  TextInput,
  required,
  useNotify,
  useTranslate,
} from "react-admin";

import resources from "../../config/resources";
import { MODEL_DEFECT_LOCATION, MODEL_DEFECT_TYPE } from "../../config/ModelFailureReasons";
import { getKey } from "../../lib/i18nUtils";
import CustomForm from "../contractors/CustomFormComponents";
import ModelPartStateIcon from "./ModelPartStateIcon";

const useStyles = makeStyles(theme => ({
  title: {
    fontSize: "1.5em",
    fontWeight: "bold",
  },
  stageOfProcess: {
    display: "grid",
    gridAutoFlow: "column",
    alignContent: "center",
    justifyContent: "center",
    padding: "1em",
  },
  buttons: {
    display: "flex",
    justifyContent: "space-between",
  },
  hidden: {
    display: "none",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(0.5),
    top: theme.spacing(0.5),
    color: theme.palette.grey[500],
  },
}));

const CreateBrokenPartsDialog = ({ id, isOpen, onClose, originChoices, ...props }) => {
  const classes = useStyles();
  const translate = useTranslate();
  const notify = useNotify();

  const handleClose = () => {
    onClose();
  };

  const onSuccess = () => {
    notify("ra.notification.created", "info", { smart_count: 1 });
    onClose();
  };

  return (
    <Dialog open={isOpen} onClose={handleClose} fullWidth maxWidth="xs">
      <DialogTitle>
        <Typography variant="h6" align="center">
          {translate(getKey("dialogTitle", resources.MODEL_PARTS_DEFECTS))}
        </Typography>
        <IconButton className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
        <Divider />
      </DialogTitle>
      <Create
        {...props}
        resource={resources.MODEL_PARTS_DEFECTS}
        basePath={"/" + resources.MODEL_PARTS_DEFECTS}
        onSuccess={onSuccess}
      >
        <CustomForm classes={classes}>
          <TextInput source="model" defaultValue={`/${resources.MODELS}/${id}`} className={classes.hidden} />
          <RadioButtonGroupInput
            label={false}
            source="originState"
            choices={originChoices}
            fullWidth
            optionText={<ModelPartStateIcon withTooltip />}
          />
          <NumberInput required source="count" fullWidth />
          <SelectInput
            source="defectPlace"
            label={translate(getKey("location", resources.MODEL_PARTS_DEFECTS))}
            choices={Object.values(MODEL_DEFECT_LOCATION)}
            optionText={({ translation }) => translate(translation)}
            optionValue="key"
            translateChoice={false}
            validate={[required()]}
            fullWidth
            alwaysOn
          />
          <SelectInput
            source="defectType"
            label={translate(getKey("type", resources.MODEL_PARTS_DEFECTS))}
            choices={Object.values(MODEL_DEFECT_TYPE)}
            optionText={({ translation }) => translate(translation)}
            optionValue="key"
            translateChoice={false}
            fullWidth
            required
            alwaysOn
          />
        </CustomForm>
      </Create>
    </Dialog>
  );
};

export default CreateBrokenPartsDialog;
