import { forIn, invert, pickBy } from "lodash";
import { FilterOperations } from "../config/Filter";

export const createFilterGraphPayload = (filterValues, resource) => {
  const payload = {
    filteredEntity: resource,
    filterParts: [],
  };

  /**
   * format filter paths
   */
  const qs = {};
  forIn(filterValues, (k, v) => {
    while (typeof k === "object" && !Array.isArray(k)) {
      const keys = Object.keys(k);
      if (keys.length === 1) {
        const key = keys[0];
        v += "#" + key;
        k = k[key];
        if (key === "like" || key === "equals" || key === "contains" || key === "neq" || key === "null") {
          break;
        }
      } else {
        break;
      }
    }
    if (
      v.endsWith("#like") ||
      v.endsWith("#equals") ||
      v.endsWith("#contains") ||
      v.endsWith("#neq") ||
      v.endsWith("#null")
    ) {
      qs[v] = k;
    } else if (v === "prefetch" && Array.isArray(k)) {
      qs[v] = k;
    } else {
      qs[v + "#like"] = k;
    }
  });

  /**
   * create filter parts from formatted paths
   */
  forIn(qs, (k, v) => {
    const extractedOperation = /[^#]*$/.exec(v)[0];
    let operation = invert(FilterOperations)[extractedOperation];
    if (operation) {
      v = v.replace(`#${extractedOperation}`, "");
    } else {
      operation = invert(FilterOperations)["like"];
    }

    let formattedValues = Array.isArray(k) ? k : [k];
    if (operation === "LIKE") {
      formattedValues = formattedValues.map(val => `%${val}%`);
    }

    payload.filterParts.push({
      operation,
      values: formattedValues,
      path: v.split("#"),
    });
  });

  return payload;
};

export const createFilterFromPaths = filterPaths =>
  filterPaths.reduce((obj, { paths, values }) => {
    const joinedKey = `${paths.join("#")}`;

    obj[joinedKey] = Array.isArray(values)
      ? values.map(val => (typeof val === "string" ? val.replace(/%/g, "") : val))
      : values.replace(/%/g, "");

    return obj;
  }, {});

/**
 * @unused
 */
export const removeDuplicateFilterValues = filterValues =>
  pickBy(filterValues, (_val, key) => {
    const operation = key.includes("#") && /[^#]*$/.exec(key)[0];
    const operationIsValid = Object.values(FilterOperations).includes(operation);
    const cleanPath =
      operation && operationIsValid ? key.replace(operation, "").replace(/#$/, "") : key.replace(/#$/, "");

    /**
     * if there are duplicate filter paths only keep the path with a valid operation
     */

    if (operation && operationIsValid) {
      return !Object.keys(filterValues).includes(`${cleanPath}`);
    } else {
      return true;
    }
  });
