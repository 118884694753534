import createAction from "../../lib/createAction";

// custom fetch for all references
export const CUSTOM_REF_FETCH_REQUEST = "CUSTOM_REF_FETCH_REQUEST";
export const customRefFetch = createAction(CUSTOM_REF_FETCH_REQUEST, "occurrence", "reference", "sort", "filter");

export const CUSTOM_REF_FETCH_SUCCESS = "CUSTOM_REF_FETCH_SUCCESS";
export const customRefFetchSuccess = createAction(CUSTOM_REF_FETCH_SUCCESS, "occurrence", "response");

export const CUSTOM_REF_FETCH_FAILURE = "CUSTOM_REF_FETCH_FAILURE";
export const customRefFetchFailure = createAction(CUSTOM_REF_FETCH_FAILURE, "occurrence", "error");

// custom fetch for selected references
export const CUSTOM_SELECTED_REF_FETCH_REQUEST = "CUSTOM_SELECTED_REF_FETCH_REQUEST";
export const customSelectedRefFetch = createAction(
  CUSTOM_SELECTED_REF_FETCH_REQUEST,
  "occurrence",
  "reference",
  "sort",
  "filter"
);

export const CUSTOM_SELECTED_REF_FETCH_SUCCESS = "CUSTOM_SELECTED_REF_FETCH_SUCCESS";
export const customSelectedRefFetchSuccess = createAction(CUSTOM_SELECTED_REF_FETCH_SUCCESS, "occurrence", "response");

export const CUSTOM_SELECTED_REF_FETCH_FAILURE = "CUSTOM_SELECTED_REF_FETCH_FAILURE";
export const customSelectedRefFetchFailure = createAction(CUSTOM_SELECTED_REF_FETCH_FAILURE, "occurrence", "error");
