import { call, put, takeEvery } from "redux-saga/effects";
import fetchService from "../../../lib/fetchService";
import config from "../../../config";
import {
  FETCH_FILE_VERSIONS_OF_FILE_REQUEST,
  fetchFileVersionsOfFileError,
  fetchFileVersionsOfFileSuccess,
} from "./action";

const requestSaga = function*({ payload, meta }) {
  const { fileUuid, fileToken } = payload;

  const url = `${config.urls.STORAGE_SERVICE}/files/${fileUuid}/versions`;
  const data = { token: fileToken };

  try {
    const response = yield call(fetchService.get, url, data);
    if (response.ok) {
      const responseData = yield call(() => response.json());
      const defaultAction = fetchFileVersionsOfFileSuccess(fileUuid, responseData);
      if (!meta || !meta.onSuccess) {
        yield put(defaultAction);
      } else {
        yield put({
          ...defaultAction,
          meta: meta.onSuccess,
        });
      }
    } else {
      const statusCode = response.status;
      const defaultAction = fetchFileVersionsOfFileError(
        fileUuid,
        new Error(`Request failed with error ${statusCode}!`)
      );
      if (!meta || !meta.onFailure) {
        yield put(defaultAction);
      } else {
        yield put({
          ...defaultAction,
          meta: meta.onFailure,
        });
      }
    }
  } catch (e) {
    const defaultAction = fetchFileVersionsOfFileError(fileUuid, e);
    if (!meta || !meta.onFailure) {
      yield put(defaultAction);
    } else {
      yield put({
        ...defaultAction,
        meta: meta.onFailure,
      });
    }
  }
};

export default function*() {
  yield takeEvery(FETCH_FILE_VERSIONS_OF_FILE_REQUEST, requestSaga);
}
