import React, { useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Typography,
} from "@material-ui/core";
import { AddCircleOutline, Close, Warning } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import { Field, Form } from "react-final-form";
import { useMutation, useRefresh, useTranslate } from "react-admin";
import CloseIcon from "@material-ui/icons/ErrorOutline";
import SaveIcon from "@material-ui/icons/Save";
import isEmpty from "lodash/isEmpty";

import ModelPartStateIcon from "./ModelPartStateIcon";
import resources from "../../config/resources";
import CreateBrokenPartsDialog from "./CreateBrokenPartsDialog";
import { MODEL_DEFECT_ORIGIN_STATES } from "../../config/ModelFailureReasons";

const useStyles = makeStyles(theme => ({
  modelPart: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
  },
  cellWrapper: {
    justifySelf: "center",
    display: "flex",
    alignItems: "center",
    height: 50,
    width: 125,
  },
  input: {
    width: "40%",
    height: "60%",
    marginLeft: 5,
    textAlign: "center",
  },
  checkSum: {
    fontWeight: 700,
  },
  warning: {
    color: theme.palette.error.main,
    fontSize: "1.5em",
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
  },
  iconPaddingStyle: {
    paddingRight: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(0.5),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(0.5),
    top: theme.spacing(0.5),
    color: theme.palette.grey[500],
  },
}));

const EditModelPartsStatesDialog = ({ id, isOpen, onClose, values, modelAmount }) => {
  const translate = useTranslate();
  const refresh = useRefresh();
  const classes = useStyles();

  const [update, { loading }] = useMutation();
  const [isDialogOpen, setDialogOpen] = useState(false);

  const submit = async values => {
    await update({
      type: "update",
      resource: resources.MODELS,
      payload: { id: id, data: values },
    });
    onClose();
  };

  const handleOpenDefect = () => setDialogOpen(true);
  const handleCloseDefect = () => {
    setDialogOpen(false);
    refresh();
  };

  const modelPartsStatesObject = Object.fromEntries(values);

  const defectOriginChoices = Object.entries(MODEL_DEFECT_ORIGIN_STATES).reduce(
    (resultArray, [key, value]) =>
      modelPartsStatesObject[key] > 0 ? (resultArray.push({ id: value, name: key }), resultArray) : resultArray,
    []
  );

  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="xs">
      <Form onSubmit={submit} initialValues={modelPartsStatesObject}>
        {({ handleSubmit, values: formValues }) => {
          const sum = Object.entries(formValues)
            .filter(entry => entry[0] !== "defectCount")
            .map(entry => entry[1])
            .reduce((a, b) => a + b);
          return (
            <form onSubmit={handleSubmit}>
              <DialogTitle>
                <Typography variant="h5" align="center">
                  {translate("modelPartState.dialogTitle")}
                </Typography>
                <IconButton className={classes.closeButton} onClick={onClose}>
                  <Close />
                </IconButton>
                <Divider />
              </DialogTitle>
              <DialogContent>
                <div className={classes.modelPart}>
                  {values.map(part => (
                    <div className={classes.cellWrapper} key={part[0]}>
                      <ModelPartStateIcon name={part[0]} withTooltip />
                      <Field
                        className={classes.input}
                        id={part[0]}
                        name={part[0]}
                        component="input"
                        type="number"
                        min={0}
                        defaultValue={part[1]}
                        parse={value => parseInt(value)}
                        disabled={part[0] === "defectCount"}
                      />
                    </div>
                  ))}
                </div>
                <Box mt={3}>
                  <Grid container spacing={2}>
                    <Grid item xs={6} container alignItems="center" justify="center">
                      <Typography variant="h6" className={classes.checkSum}>{`Σ ${sum}/${modelAmount}`}</Typography>
                      {modelAmount > sum && <Warning className={classes.warning} />}
                    </Grid>
                    <Grid item xs={6}>
                      <Button
                        variant="outlined"
                        color="primary"
                        disabled={isEmpty(defectOriginChoices)}
                        onClick={handleOpenDefect}
                        fullWidth
                      >
                        <AddCircleOutline className={classes.iconPaddingStyle} />
                        {translate("ra.action.add_brocken")}
                      </Button>
                    </Grid>
                    <Grid item xs={6}>
                      <Button onClick={onClose} fullWidth>
                        <CloseIcon className={classes.iconPaddingStyle} />
                        {translate("ra.action.cancel")}
                      </Button>
                    </Grid>
                    <Grid item xs={6}>
                      <Button variant="contained" type="submit" color="primary" disabled={loading} fullWidth>
                        <SaveIcon className={classes.iconPaddingStyle} />
                        {translate("ra.action.save")}
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </DialogContent>
            </form>
          );
        }}
      </Form>
      <CreateBrokenPartsDialog
        id={id}
        isOpen={isDialogOpen}
        onClose={handleCloseDefect}
        originChoices={defectOriginChoices}
      />
    </Dialog>
  );
};

export default EditModelPartsStatesDialog;
