import createReducer, { mergeReducers } from "../../../lib/createReducer";
import {
  DOWNLOAD_PRODUCTION_PORTFOLIO,
  DOWNLOAD_PRODUCTION_PORTFOLIO_ERROR,
  DOWNLOAD_PRODUCTION_PORTFOLIO_SUCCESS,
} from "./action";

const init = {
  jobId: null,

  isFetching: false,
  fetchSuccess: false,
  hasError: false,
  payload: null,
  error: null,
};

const requestReducer = createReducer(
  DOWNLOAD_PRODUCTION_PORTFOLIO,
  ({ jobId }) => ({
    ...init,
    jobId,
    isFetching: true,
  }),
  true
);

const successReducer = createReducer(DOWNLOAD_PRODUCTION_PORTFOLIO_ERROR, ({ response }) => ({
  isFetching: false,
  fetchSuccess: true,
  payload: response,
}));

const errorReducer = createReducer(DOWNLOAD_PRODUCTION_PORTFOLIO_SUCCESS, ({ error }) => ({
  isFetching: false,
  hasError: true,
  error,
}));

export default mergeReducers([requestReducer, successReducer, errorReducer], init);
