import React from "react";
import {
  translate,
  TextField,
  Datagrid,
  List,
  EditButton,
  ReferenceField,
  FunctionField,
  Pagination,
  Filter,
  ReferenceInput,
  AutocompleteInput,
} from "react-admin";
import resources from "../../config/resources";
import paginationOptions from "../../config/PaginationOptions";
import { getKey } from "../../lib/i18nUtils";

const MaterialiseMaterialMappingsFilters = ({ translate, ...props }) => (
  <Filter {...props}>
    <ReferenceInput
      source="internalMaterial#id"
      label={translate(getKey("internalMaterial", resources.MATERIALISE_MATERIAL_MAPPING))}
      reference={resources.MATERIALS}
      perPage={1000}
      filterToQuery={searchText => ({ autocomplete: searchText })}
      alwaysOn
    >
      <AutocompleteInput
        optionText={({ name, methodKey, methodName, method }) =>
          `${methodKey || methodName || method || "?"} (${name})`
        }
        optionValue="__id"
      />
    </ReferenceInput>
  </Filter>
);

const MaterialiseMaterialMappingsList = ({ translate, ...props }) => (
  <List
    {...props}
    bulkActionButtons={false}
    filters={<MaterialiseMaterialMappingsFilters translate={translate} />}
    pagination={<Pagination {...paginationOptions} />}
  >
    <Datagrid>
      <TextField source="__id" label={translate(getKey("id", resources.MATERIALISE_MATERIAL_MAPPING))} />
      <ReferenceField source="internalMaterial" reference={resources.MATERIALS} link={false} sortable={false}>
        <FunctionField render={({ name, methodKey }) => `${methodKey || "?"}: ${name}`} />
      </ReferenceField>
      <TextField source="materialiseTechnology" />
      <TextField source="materialiseMaterial" />
      <TextField source="materialiseFinishing" />
      <TextField source="materialiseColor" />
      <EditButton />
    </Datagrid>
  </List>
);

export default translate(MaterialiseMaterialMappingsList);
