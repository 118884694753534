import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Button, translate, useGetMany } from "react-admin";
import { sendJobOffer } from "../../redux/job/sendJobOffer/action";
import resources from "../../config/resources";
import sanitizeButton from "../sanitizeButton";
import Icon from "@material-ui/icons/ShoppingCart";
import Confirm from "../Confirm";
import { getKey } from "../../lib/i18nUtils";

const SendOfferButton = ({
  record,
  resource,
  translate,
  sendJobOffer,
  isFetchingOffer,
  isLoading,
  models = {},
  onConfirm,
  ...props
}) => {
  const [isOpen, setOpen] = useState(false);

  const handleClick = e => {
    setOpen(true);
  };

  const onClose = () => setOpen(false);

  const handleConfirm = () => {
    if (!record || resource !== resources.JOBS) {
      return;
    }
    sendJobOffer(record.__id);
    onConfirm();
  };

  useEffect(() => {
    if (!isFetchingOffer) {
      onClose();
    }
  }, [isFetchingOffer]);

  const contractorIds = Object.values(models).map(it => it.contractor);
  const { loading, data } = useGetMany(resources.CONTRACTORS, contractorIds);

  const disabled =
    !loading && data
      ? !!data.find(it => !!it && it.isMaterialise) && !!Object.values(models).find(it => !it.latestFileOnMaterialise)
      : true;

  const label = disabled ? translate("materialiseModelUnsynched") : translate(getKey("sendOffer", resources.JOBS));

  return (
    <Fragment>
      <Button {...sanitizeButton(props)} onClick={handleClick} label={label} disabled={disabled}>
        <Icon />
      </Button>
      <Confirm
        isOpen={isOpen}
        title={translate("sendOfferDialog.title")}
        content={translate("sendOfferDialog.body1")}
        onConfirm={handleConfirm}
        onClose={onClose}
        loading={isFetchingOffer || isLoading}
      />
    </Fragment>
  );
};

const mapStateToProps = state => ({
  isFetchingOffer: state.sendJobOffer.isFetching,
  isLoading: state.admin.loading > 0,
});

export default connect(
  mapStateToProps,
  {
    sendJobOffer,
  }
)(translate(SendOfferButton));
