import { call, put, takeEvery } from "redux-saga/effects";
import { DOWNLOAD_PRODUCTION_PORTFOLIO, downloadError, downloadSuccess } from "./action";
import config from "../../../config";
import fetchService from "../../../lib/fetchService";
import { handleSagaError } from "../../../dataproviders/errorResponseHandler";

const saga = function*({ payload }) {
  const { jobId } = payload;

  const url = `${config.urls.BACKEND}/pdf/production_portfolio/${encodeURIComponent(jobId)}`;

  try {
    const response = yield call(fetchService.get, url);

    for (const action of yield call(handleSagaError, response, downloadError)) {
      yield put(action);
      return;
    }

    const blob = yield call({ context: response, fn: response.blob });
    const windowUrl = window.URL || window.webkitURL;
    const blobUrl = windowUrl.createObjectURL(blob);
    const anchor = window.document.createElement("a");
    window.document.body.appendChild(anchor);
    anchor.setAttribute("href", blobUrl);
    anchor.setAttribute("download", `Produktionsmappe-${jobId}.pdf`);
    anchor.setAttribute("style", "display: none;");
    anchor.click();

    //the download actually starts asynchronously, so we delay this a bit to not break it
    setTimeout(function() {
      windowUrl.revokeObjectURL(blobUrl);
      window.document.body.removeChild(anchor);
    }, 100);
    yield put(downloadSuccess());
  } catch (e) {
    yield put(downloadError(e));
  }
};

export default function*() {
  yield takeEvery(DOWNLOAD_PRODUCTION_PORTFOLIO, saga);
}
