import React, { useState } from "react";

import { connect } from "react-redux";
import { translate } from "react-admin";
import { withStyles } from "@material-ui/core/styles";
import withTheme from "@material-ui/core/styles/withTheme";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

import { hideNotification } from "../../redux/notifications/action";
import { getNotifications } from "../../redux/notifications/selectors";
import NotificationStyles from "../../config/NotificationStyles";
import NotificationBody from "./NotificationBody";
import NotificationReadButton from "./NotificationReadButton";
import resources from "../../config/resources";

const styles = {
  content: {},
};

const _NotificationContent = ({ classes, notification, onClose, translate, theme, open, ...rest }) => {
  if (!notification) return null;

  const { color = "#000000" } = NotificationStyles[notification.notificationStyle] || {};
  const fontColor = theme.palette.getContrastText(color);

  return (
    <SnackbarContent
      {...rest}
      style={{
        backgroundColor: color,
        color: fontColor,
        borderRadius: 4,
        boxShadow: `0 2px 18px -4px ${color}`,
      }}
      message={<NotificationBody notification={notification} shouldPushToSystem={open} />}
      action={[
        <NotificationReadButton record={notification} resource={resources.NOTIFICATIONS} buttonLabel="Gelesen" />,
        <IconButton key="close" aria-label="Close" color="inherit" onClick={onClose}>
          <CloseIcon />
        </IconButton>,
      ]}
    />
  );
};
const NotificationContent = withStyles(styles)(withTheme(translate(_NotificationContent)));

const Notification = ({ hideNotification, notification, listPos }) => {
  const [hidden, setHidden] = useState(false);

  const onClose = () => {
    setHidden(true);
    setTimeout(() => hideNotification(notification.id), 500);
  };

  const open = listPos === 0 && !hidden;

  return (
    <Snackbar
      open={open}
      onClose={onClose}
      autoHideDuration={7000}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
    >
      <NotificationContent notification={notification} onClose={onClose} open={open} />
    </Snackbar>
  );
};

const NotificationRoot = ({ hideNotification, notifications = [], ...props }) =>
  notifications.map((it, key) => (
    <Notification {...props} key={it.id} listPos={key} notification={it} hideNotification={hideNotification} />
  ));

export default connect(
  state => ({
    notifications: getNotifications(state),
  }),
  { hideNotification }
)(withStyles(styles)(NotificationRoot));
