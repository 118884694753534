import get from "lodash/get";
import { TextField, useGetOne } from "react-admin";
import resources from "../../config/resources";
import React, { useState } from "react";
import StatusField from "../customFields/StatusField";
import Checkbox from "@material-ui/core/Checkbox";
import CheckIcon from "@material-ui/icons/Check";
import Chip from "@material-ui/core/Chip";

import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { Field, useField } from "react-final-form";

const JobBulkDisplayRow = ({ name, updateField, translate, response, isLoading }) => {
  const field = useField(name);
  const jobId = parseInt(get(field, "input.value.id"));

  let { data, error } = useGetOne(resources.JOBS, "/jobs/" + jobId);

  if (response && response[jobId]) {
    data = response[jobId];
  }
  const success =
    response && response[jobId] && typeof response[jobId].success !== "undefined" ? response[jobId].success : null;

  const [isChecked, setIsChecked] = useState(true);
  const [isForceUnchecked, setIsForceUnchecked] = useState(false);

  if (isLoading && isForceUnchecked) {
    setIsForceUnchecked(false);
  }

  if (((success !== null && !success) || error) && isChecked && !isForceUnchecked) {
    setIsChecked(false);
    updateField(`${name}.active`, false);
    setIsForceUnchecked(true);
  }

  return (
    <TableRow>
      <TableCell>
        <Field
          name="active"
          type="checkbox"
          render={props => (
            <Checkbox
              name={props.input.name}
              value={props.input.value}
              checked={isChecked}
              onChange={() => {
                updateField(`${name}.active`, !isChecked);
                setIsChecked(!isChecked);
              }}
            />
          )}
        />
      </TableCell>
      <TableCell>{data ? get(data, "externalId") : `${translate("bulkOps.notFound")}: ${jobId} `}</TableCell>
      <TableCell>
        <TextField source="contractorName" />
      </TableCell>
      <TableCell>{data && <StatusField record={data} source="status" useChip={true} />}</TableCell>
      <TableCell>
        {success !== null ? (
          success ? (
            <CheckIcon />
          ) : (
            <Chip
              label={translate(`errorCodes.${data.errorCode}`)}
              style={{
                paddingRight: 0,
                backgroundColor: "#f44336",
                color: "#FFFFFF",
                border: "solid 2px #80241d",
                fontWeight: 600,
              }}
            />
          )
        ) : null}
      </TableCell>
    </TableRow>
  );
};

export default JobBulkDisplayRow;
