import React, { useState, useEffect, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Button, translate } from "react-admin";
import get from "lodash/get";
import ReloadIcon from "@material-ui/icons/Autorenew";
import CircularProgress from "@material-ui/core/CircularProgress";
import Snackbar from "@material-ui/core/Snackbar";
import sanitizeButton from "../sanitizeButton";
import Confirm from "../Confirm";
import { reestimatePrices } from "../../redux/model/reestimatePrices/action";
import { reestimatePricesStatus } from "../../redux/model/reestimatePricesStatus/action";
import usePrevious from "../../lib/customHooks/usePrevious";

const ReestimatePricesButton = ({
  translate,
  disabled,
  isFetching,
  reestimationRunning,
  reestimatePrices,
  reestimatePricesStatus,
  children,
  modelIds,
  progressStyle = {},
  ...rest
}) => {
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [disableConfirmation, setDisableConfirmation] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const prevState = usePrevious({ isFetching, reestimatePricesStatus });

  useEffect(() => {
    if (prevState && !reestimatePricesStatus && prevState.reestimatePricesStatus) {
      reestimatePricesStatus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reestimatePricesStatus]);

  useEffect(() => {
    if (prevState && !isFetching && prevState.isFetching) {
      setShowNotification(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetching]);

  const reestimateAllModelPrices = () => {
    reestimatePrices();
    setTimeout(() => {
      reestimatePricesStatus();
      setDisableConfirmation(false);
      setConfirmDialogOpen(false);
    }, 1000);
  };

  const reestimateSingleModelPrices = ids => {
    ids.forEach(id => {
      reestimatePrices(id);
      setShowNotification(true);
    });
  };

  const handleConfirm = () => {
    if (Array.isArray(modelIds) && modelIds.length > 0) {
      reestimateSingleModelPrices(modelIds);
    } else {
      reestimateAllModelPrices();
    }
  };

  const handleDialogClose = () => {
    reestimatePricesStatus();
    setConfirmDialogOpen(false);
  };

  const handleClick = () => {
    if (Array.isArray(modelIds) && modelIds.length > 0) {
      reestimateSingleModelPrices(modelIds);
    } else {
      setConfirmDialogOpen(true);
    }
  };

  return (
    <Fragment>
      <Button {...sanitizeButton(rest)} onClick={handleClick} disabled={disabled || isFetching || reestimationRunning}>
        {isFetching || reestimationRunning ? (
          <CircularProgress size={20} thickness={2} color="primary" style={progressStyle} />
        ) : (
          children
        )}
      </Button>
      <Confirm
        isOpen={confirmDialogOpen}
        title={translate("reestimateDialog.title")}
        content={translate("reestimateDialog.body1")}
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
        loading={isFetching || reestimationRunning}
        disabled={disableConfirmation}
      />
      {Array.isArray(modelIds) && modelIds.length > 0 && (
        <Snackbar
          open={showNotification}
          message={translate("reestimationRunning")}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          style={{ zIndex: 3 }}
        />
      )}
    </Fragment>
  );
};

ReestimatePricesButton.propTypes = {
  translate: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool.isRequired,
  reestimatePrices: PropTypes.func.isRequired,
  children: PropTypes.node,
  label: PropTypes.string.isRequired,
  variant: PropTypes.string,
  color: PropTypes.string,
  alwaysOn: PropTypes.bool,
  fullWith: PropTypes.bool,
  modelIds: PropTypes.arrayOf(PropTypes.number),
};

ReestimatePricesButton.defaultProps = {
  disabled: false,
  isFetching: false,
  children: <ReloadIcon />,
  label: "",
};

const mapStateToProps = state => ({
  isFetching: get(state, "reestimatePrices.isFetching") || get(state, "reestimatePricesStatus.isFetching"),
  reestimationRunning: get(state, "reestimatePricesStatus.payload.running"),
});

export default connect(
  mapStateToProps,
  { reestimatePrices, reestimatePricesStatus }
)(translate(ReestimatePricesButton));
