import React from "react";
import { Edit } from "react-admin";
import { Container } from "@material-ui/core";

import PostProcessingForm from "./PostProcessingForm";

const PostProcessingEdit = ({ ...props }) => {
  return (
    <Container maxWidth="md">
      <Edit {...props} undoable={false}>
        <PostProcessingForm />
      </Edit>
    </Container>
  );
};
export default PostProcessingEdit;
