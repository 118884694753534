import createAction, { createActionWithMeta } from "../../../lib/createAction";

export const CANCEL_JOB_REQUEST = "CANCEL_JOB_REQUEST";
export const cancelJob = createAction(CANCEL_JOB_REQUEST, "job");

export const CANCEL_JOB_SUCCESS = "CANCEL_JOB_SUCCESS";
export const cancelJobSuccess = createActionWithMeta(CANCEL_JOB_SUCCESS, { uncache: true, refresh: true }, "response");

export const CANCEL_JOB_ERROR = "CANCEL_JOB_ERROR";
export const cancelJobError = createActionWithMeta(CANCEL_JOB_ERROR, { uncache: true, refresh: true }, "error");
