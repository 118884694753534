import React from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
import Chip from "@material-ui/core/Chip";
import { withStyles } from "@material-ui/core/styles";
import classnames from "classnames";
import RohStatus from "../../config/RohStatus";
import { translate } from "react-admin";
import LinearProgress from "@material-ui/core/LinearProgress/LinearProgress";
import MultiChip from "../MultiChip";

const styles = theme => ({
  chip: {
    margin: theme.spacing(1),
    marginLeft: 0,
  },
});

const sanitizeRestProps = ({
  addLabel,
  allowEmpty,
  basePath,
  cellClassName,
  className,
  formClassName,
  headerClassName,
  label,
  link,
  locale,
  record,
  resource,
  sortable,
  sortBy,
  source,
  textAlign,
  translateChoice,
  ...rest
}) => rest;

const StatusField = ({
  translate,
  className,
  classes = {},
  source,
  record = {},
  resource,
  useChip = false,
  isContractorView = false,
  ...rest
}) => {
  const key = get(record, source);
  const rohStatus = RohStatus[key];

  if (!rohStatus) return <LinearProgress style={{ maxWidth: 100 }} />;

  if (useChip) {
    let backgroundColor = rohStatus.primaryColor || "#aaaaaa";
    let color = rohStatus.textColor || "#ffffff";
    let border = !!rohStatus.borderColor ? `solid 2px ${rohStatus.borderColor}` : "none";

    return (
      <Chip
        className={classnames(classes.chip, className)}
        label={isContractorView ? translate(rohStatus.contractorTranslationKey) : translate(rohStatus.translationKey)}
        style={{
          paddingRight: 0,
          backgroundColor,
          color,
          border,
          fontWeight: 600,
        }}
        {...sanitizeRestProps(rest)}
      />
    );
  } else {
    return (
      <MultiChip
        className={className}
        values={[
          {
            label: isContractorView
              ? translate(rohStatus.contractorTranslationKey)
              : translate(rohStatus.translationKey),
            color: rohStatus.textColor,
            backgroundColor: rohStatus.primaryColor,
            borderColor: rohStatus.borderColor,
          },
        ]}
        singleValue={true}
      />
    );
  }
};

StatusField.propTypes = {
  translate: PropTypes.func,
  className: PropTypes.string,
  classes: PropTypes.object,
  elStyle: PropTypes.object,
  sortBy: PropTypes.string,
  source: PropTypes.string.isRequired,
  record: PropTypes.object,
  useChip: PropTypes.bool,
  isContractorView: PropTypes.bool,
};

export default withStyles(styles)(translate(StatusField));
