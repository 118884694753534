import { FormDataConsumer, maxLength, minLength, required, SelectInput, TextInput } from "react-admin";
import FormulaTypes from "../../config/FormulaTypes";
import get from "lodash/get";
import { AssignScriptFormula, PriceCalculationFormula } from "./FormulaInputTypes";
import React from "react";
import CheckFormulaDialog from "./CheckFormulaDialog";
import { translate } from "react-admin";
import { withStyles } from "@material-ui/core/styles";

const validateName = [required(), maxLength(250), minLength(1)];
const validateType = [required()];

const styles = () => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
});

const FormulaForm = ({ translate, classes, ...props }) => (
  <div className={classes.root}>
    <TextInput source="name" validate={validateName} translate={translate} {...props} />
    <SelectInput
      source="type"
      choices={Object.values(FormulaTypes)}
      optionText={({ translation }) => translate(translation)}
      optionValue="key"
      validate={validateType}
      translate={translate}
      fullWidth={true}
      {...props}
    />
    <FormDataConsumer {...props}>
      {({ formData, ...rest }) =>
        get(formData, "type") === FormulaTypes.AUTO_ASSIGN_FORMULA.key ? (
          <div>
            <AssignScriptFormula {...rest} translate={translate} />
            <CheckFormulaDialog {...rest} {...props} translate={translate} />
          </div>
        ) : (
          <div>
            <PriceCalculationFormula {...rest} translate={translate} />
          </div>
        )
      }
    </FormDataConsumer>
  </div>
);

export default withStyles(styles)(translate(FormulaForm));
