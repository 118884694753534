import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { ReferenceField, translate, usePermissions } from "react-admin";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import EditIcon from "@material-ui/icons/Edit";
import get from "lodash/get";
import LinearProgress from "@material-ui/core/LinearProgress";
import CircularProgress from "@material-ui/core/CircularProgress";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import Dialog from "@material-ui/core/Dialog";
import isEmpty from "lodash/isEmpty";

import SubtaskPopup from "../SubtaskPopup";
import SubtaskStatus from "../../../config/SubtaskStatus";
import SubtaskShow from "../SubtaskShow";
import resources from "../../../config/resources";
import SubtaskTypes from "../../../config/SubtaskTypes";
import FormActions from "../../../config/FormActions";
import LabeledField from "../../customFields/LabeledField";
import AdditionField from "../../additions/AdditionField";
import { getKey } from "../../../lib/i18nUtils";
import { customFetchModelSubtasks, customUpdateSubtask } from "../../../redux/subtasks/action";
import { getModelSubtasksArray, isAllSubtasksFetching, isSubtaskUpdating } from "../../../redux/subtasks/selector";

const styles = () => ({
  root: {
    width: "100%",
    padding: 0,
    margin: 0,
  },
  action: {
    "&:hover": {
      cursor: "pointer",
    },
  },
  iconButton: {
    padding: 6,
  },
  spaceBetween: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: 0,
    marginBot: 0,
    height: 30,
  },
  textField: {
    color: "#000000",
  },
});

const SubtaskLabel = ({ record, type, model, translate, basePath, classes, showDetails, setShowDetails, ...props }) => {
  if (type === SubtaskTypes.NOTICE.key) {
    const notice = get(model, "notice");
    return notice ? (
      <LabeledField
        resource={resources.MODELS}
        source="notice"
        type={<span style={{ maxWidth: "7vw" }}>{notice}</span>}
      />
    ) : null;
  } else if (type === SubtaskTypes.ADDITION.key) {
    return (
      <ReferenceField
        {...props}
        record={record}
        source="addition"
        reference={resources.ADDITIONS}
        link={false}
        allowEmpty={true}
        basePath={basePath}
      >
        <AdditionField label={translate(getKey("addition", resources.SUBTASKS))} />
      </ReferenceField>
    );
  } else {
    return (
      <Fragment>
        <div onClick={() => setShowDetails(true)}>{get(record, "title") ?? null}</div>
        <Dialog fullWidth open={showDetails} onClose={() => setShowDetails(false)}>
          <SubtaskShow {...props} record={record} onSubmit={() => setShowDetails(false)} />
        </Dialog>
      </Fragment>
    );
  }
};

const _CustomListItem = ({ subtask, translate, record, classes, base, updateSubtask, isUpdating, ...props }) => {
  const { permissions } = usePermissions();
  const [showDetails, setShowDetails] = useState(false);
  const handleCheckBoxClick = subtask => {
    updateSubtask(subtask.id, {
      status: get(subtask, "status") === SubtaskStatus.DONE.key ? SubtaskStatus.TODO.key : SubtaskStatus.DONE.key,
    });
  };

  const subtaskType = get(subtask, "type");
  return (
    <ListItem role={undefined} dense button>
      <ListItemIcon>
        {isUpdating ? (
          <CircularProgress edge="start" size={20} />
        ) : (
          <Checkbox
            edge="start"
            checked={get(subtask, "status") === SubtaskStatus.DONE.key}
            onChange={() => handleCheckBoxClick(subtask)}
          />
        )}
      </ListItemIcon>
      <ListItemText>
        <SubtaskLabel
          {...props}
          record={subtask}
          model={record}
          translate={translate}
          basePath="/subtasks"
          classes={classes}
          type={subtaskType}
          showDetails={showDetails}
          setShowDetails={setShowDetails}
        />
      </ListItemText>
      {permissions &&
        Array.isArray(permissions.authorities) &&
        permissions.authorities.includes("ROLE_ADMIN") &&
        subtask &&
        subtaskType !== SubtaskTypes.ADDITION.key &&
        subtaskType !== SubtaskTypes.NOTICE.key && (
          <ListItemSecondaryAction>
            <IconButton edge="end" className={classes.iconButton}>
              <SubtaskPopup
                resource={resources.SUBTASKS}
                source="subtasks"
                basePath={base}
                record={subtask}
                action={FormActions.EDIT}
              >
                <EditIcon className={classes.action} />
              </SubtaskPopup>
            </IconButton>
          </ListItemSecondaryAction>
        )}
    </ListItem>
  );
};

const mapStateToPropsCustomListItem = (state, props) => ({
  isUpdating: isSubtaskUpdating(get(props, "subtask.id"))(state),
});

const mapDispatchToPropsCustomListItem = {
  updateSubtask: customUpdateSubtask,
};

const CustomListItem = compose(
  connect(
    mapStateToPropsCustomListItem,
    mapDispatchToPropsCustomListItem
  ),
  withStyles(styles)
)(_CustomListItem);

const ModelSubtaskList = ({
  record,
  base,
  classes,
  data,
  ids,
  translate,
  fetchSubtasks,
  subtasks,
  isFetching,
  ...props
}) => {
  const modelId = get(record, "id");

  useEffect(() => {
    if (modelId) {
      fetchSubtasks(modelId);
    }
  }, [modelId]);

  if (isFetching && isEmpty(subtasks)) {
    return <LinearProgress />;
  }

  return (
    <List className={classes.root} dense>
      {Array.isArray(subtasks) &&
        subtasks.map((sub, i) => {
          return (
            <CustomListItem
              {...props}
              subtask={sub}
              key={`${get(sub, "id")}-key-${i}`}
              translate={translate}
              record={record}
              base={base}
            />
          );
        })}
    </List>
  );
};

ModelSubtaskList.propTypes = {
  ids: PropTypes.array,
  data: PropTypes.object,
  base: PropTypes.string,
  statusList: PropTypes.array,
  type: PropTypes.string,
  classes: PropTypes.object,
};

const mapStateToProps = (state, props) => ({
  subtasks: getModelSubtasksArray(get(props, "record.id"))(state),
  isFetching: isAllSubtasksFetching(state),
});

const mapDispatchToProps = dispatch => ({
  fetchSubtasks: pathToSubtasks => dispatch(customFetchModelSubtasks(pathToSubtasks)),
});

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withStyles(styles),
  translate
)(ModelSubtaskList);
