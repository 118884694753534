import React from "react";
import get from "lodash/get";
import { Paper } from "@material-ui/core";

const SizeCategoryField = ({ record, source }) => {
  const size = get(record, source);

  return size ? (
    <Paper style={{ textAlign: "center", verticalAlign: "middle", fontSize: "150%" }} variant="elevation">
      {size}
    </Paper>
  ) : null;
};

export default SizeCategoryField;
