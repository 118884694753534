import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import { ReferenceManyField, ReferenceField, FunctionField, translate } from "react-admin";
import Typography from "@material-ui/core/Typography";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import groupBy from "lodash/groupBy";
import orderBy from "lodash/orderBy";
import ShipmentIcon from "@material-ui/icons/LocalMall";
import JobIcon from "@material-ui/icons/Assignment";

import { resources } from "../../config/resources";
import CommentsGrid from "./CommentsGrid";
import ReferenceManyMapper from "../ReferenceManyMapper";

const a11yProps = index => {
  return {
    id: `tab-${index}`,
    "aria-controls": `tabpanel-${index}`,
  };
};

const TabPanel = ({ children, value, index, ...rest }) => (
  <Typography
    component="div"
    role="tabpanel"
    hidden={value !== index}
    id={`tabpanel-${index}`}
    aria-labelledby={`tab-${index}`}
    {...rest}
  >
    <div style={value !== index ? { display: "none" } : {}}>{children}</div>
  </Typography>
);

const CommentsTabWrapper = ({ translate, onChangeTab, permissions, archivedView = false, ...props }) => {
  const [tabIndex, setTabIndex] = useState(0);
  const handleChange = (e, newValue, jobId) => {
    setTabIndex(newValue);
    onChangeTab(jobId);
  };

  const commentReference = archivedView ? resources.ARCHIVED_COMMENTS : resources.COMMENTS;
  const jobReference = archivedView ? resources.ARCHIVED_JOBS : resources.JOBS;

  return (
    <Fragment>
      <Tabs
        value={tabIndex}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons="on"
        indicatorColor="secondary"
        textColor="secondary"
      >
        <Tab label={translate("resources.comments.shipmentTitle")} icon={<ShipmentIcon />} {...a11yProps(0)} />
        {/* Group related job comments by job and create tabs with externalID as label*/}
        <ReferenceManyField reference={commentReference} target="job#models#shipment#id" {...props}>
          <ReferenceManyMapper>
            {({ ids, data, basePath }) => {
              if (data) {
                const groupedComments = groupBy(Object.values(data), "job");
                if (Object.values(groupedComments).length > 0) {
                  return Object.values(groupedComments).map((group, i) => {
                    if (group.length > 0) {
                      return (
                        <ReferenceField
                          basePath={basePath}
                          record={group[0]}
                          reference={jobReference}
                          source="job"
                          link={false}
                          key={i}
                        >
                          <FunctionField
                            render={({ externalId, id }) => (
                              <Tab
                                label={externalId}
                                {...a11yProps(i + 1)}
                                icon={<JobIcon />}
                                onClick={e => handleChange(e, i + 1, id)}
                              />
                            )}
                          />
                        </ReferenceField>
                      );
                    } else {
                      return null;
                    }
                  });
                }
              }
              return null;
            }}
          </ReferenceManyMapper>
        </ReferenceManyField>
      </Tabs>
      {/* Display shipment comments */}
      <TabPanel value={tabIndex} index={0}>
        <ReferenceManyField reference={commentReference} target="shipment#id" {...props}>
          <CommentsGrid translate={translate} permissions={permissions} />
        </ReferenceManyField>
      </TabPanel>

      <ReferenceManyField reference={commentReference} target="job#models#shipment#id" {...props}>
        {/* Group related job comments by job and display comment list*/}
        <ReferenceManyMapper>
          {({ ids, data, basePath }) => {
            if (data) {
              const groupedComments = groupBy(Object.values(data), "job");
              if (Object.values(groupedComments).length > 0) {
                return Object.values(groupedComments).map((group, i) => {
                  if (group.length > 0) {
                    return (
                      <TabPanel value={tabIndex} index={i + 1} key={i}>
                        <CommentsGrid
                          basePath={basePath}
                          translate={translate}
                          data={group.reduce((obj, curr) => ({ ...obj, [curr.id]: curr }), {})}
                          ids={orderBy(group, "createdAt", "desc").map(comment => comment.id)}
                        />
                      </TabPanel>
                    );
                  } else {
                    return null;
                  }
                });
              }
            }
            return null;
          }}
        </ReferenceManyMapper>
      </ReferenceManyField>
    </Fragment>
  );
};

CommentsTabWrapper.propTypes = {
  onChangeTab: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
};

export default translate(CommentsTabWrapper);
