import resources from "../../config/resources";
import get from "lodash/get";
import { FunctionField, ReferenceField, ReferenceInput, SelectInput, translate, usePermissions } from "react-admin";
import React from "react";
import find from "lodash/find";
import PropTypes from "prop-types";
import { getFilterGraphs } from "../../redux/filterGraph/selector";
import { connect } from "react-redux";

const FilterGraphSelectInput = ({
  resource,
  onChangeSelection,
  isEditForm = false,
  translate,
  filterGraphs,
  basePath,
  ...props
}) => {
  const { permissions } = usePermissions();
  /**
   * filter selectable graphs
   */
  const { id: userId, authorities } = permissions || {};
  let userFilter = {};
  if (!Array.isArray(authorities) || !authorities.includes("ROLE_ADMIN")) {
    userFilter["owner#id"] = userId;
    userFilter["ownerMaySee"] = true;

    if (isEditForm) {
      userFilter["ownerMayEdit"] = true;
    }
  }
  /**
   * return selected graph to parent component
   */
  const handleSelect = id => {
    if (id) {
      const selectedGraph = find(filterGraphs, { id });
      onChangeSelection(selectedGraph || {});
    } else {
      onChangeSelection({});
    }
  };

  return (
    <ReferenceInput
      {...props}
      source="selectedFilterGraph"
      label={translate("resources.filterGraphs.actions.select")}
      reference={resources.FILTER_GRAPHS}
      sort={{ field: "name", order: "ASC" }}
      perPage={1000}
      fullWidth
      filter={{
        filteredEntity: resource,
        ...userFilter,
      }}
      filterToQuery={searchText => ({ name: searchText ? searchText : "" })}
      onChange={props => handleSelect(get(props, "target.value"))}
      allowEmpty
      resettable
    >
      <SelectInput
        allowEmpty={true}
        resettable
        optionValue="id"
        optionText={({ name, ...graph }) => (
          <ReferenceField
            record={graph}
            reference={resources.USER_DETAILS}
            source="owner"
            link={false}
            basePath={basePath}
          >
            <FunctionField render={({ displayName }) => <span>{`${name} (${displayName})`}</span>} />
          </ReferenceField>
        )}
      />
    </ReferenceInput>
  );
};

FilterGraphSelectInput.propTypes = {
  resource: PropTypes.string.isRequired,
  onChangeSelection: PropTypes.func.isRequired,
  permissions: PropTypes.object.isRequired,
  isEditForm: PropTypes.bool,
  filterGraphs: PropTypes.arrayOf(PropTypes.object).isRequired,
  basePath: PropTypes.string.isRequired,

  translate: PropTypes.func.isRequired,
};

export default connect(state => ({
  filterGraphs: getFilterGraphs(state),
}))(translate(FilterGraphSelectInput));
