import createAction, { createActionWithMeta } from "../../../lib/createAction";

export const SEND_JOB_OFFER_REQUEST = "SEND_JOB_OFFER_REQUEST";
export const sendJobOffer = createAction(SEND_JOB_OFFER_REQUEST, "job");

export const SEND_JOB_OFFER_SUCCESS = "SEND_JOB_OFFER_SUCCESS";
export const sendJobOfferSuccess = createActionWithMeta(
  SEND_JOB_OFFER_SUCCESS,
  {
    uncache: true,
    notification: {
      body: "ra.notification.updated",
      level: "info",
    },
    refresh: true,
  },
  "response"
);

export const SEND_JOB_OFFER_ERROR = "SEND_JOB_OFFER_ERROR";
export const sendJobOfferError = createActionWithMeta(
  SEND_JOB_OFFER_ERROR,
  {
    uncache: true,
    notification: {
      body: "ra.notification.bad_item",
      level: "warning",
    },
    refresh: true,
  },
  "error"
);
