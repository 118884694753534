import React from "react";
import {
  BooleanField,
  ChipField,
  Datagrid,
  EditButton,
  FunctionField,
  List,
  ReferenceField,
  TextField,
  translate,
} from "react-admin";
import Chip from "@material-ui/core/Chip";
import PropTypes from "prop-types";
import get from "lodash/get";

import { FilterGraphTypes } from "../../config/Filter";
import { getKey } from "../../lib/i18nUtils";
import resources from "../../config/resources";
import ShareFilterGraphButton from "./ShareFilterGraphButton";
import FilterGraphListFilters from "./FilterGraphListFilters";
import CustomDeleteButton from "../customFields/buttons/CustomDeleteButton";

const FilterGraphsList = ({ permissions, translate, ...props }) => {
  const userIsAdmin = get(permissions, "authorities", []).includes("ROLE_ADMIN");
  const { id: userId } = permissions || {};

  return (
    <List
      {...props}
      bulkActionButtons={false}
      actions={null}
      filters={<FilterGraphListFilters />}
      filter={{
        prefetch: ["filterParts"],
      }}
    >
      <Datagrid>
        <TextField source="__id" label={translate(getKey("id", resources.FILTER_GRAPHS))} />
        <TextField source="name" />
        <FunctionField
          label={translate(getKey("filteredEntity", resources.FILTER_GRAPHS))}
          sortBy="filteredEntity"
          render={({ filteredEntity }) => (
            <Chip
              label={translate(`resources.${filteredEntity}.name`, {
                smart_count: 1,
              })}
            />
          )}
        />
        <FunctionField
          label={translate(getKey("type", resources.FILTER_GRAPHS))}
          sortBy="type"
          render={({ type }) => <Chip label={translate(FilterGraphTypes[type].translationKey)} />}
        />
        <ReferenceField source="owner" reference={resources.USERS} link={false}>
          <ChipField source="username" />
        </ReferenceField>

        {userIsAdmin && <BooleanField source="ownerMaySee" />}
        {userIsAdmin && <BooleanField source="ownerMayEdit" />}
        {userIsAdmin && <BooleanField source="ownerMayShare" />}

        <FunctionField
          render={record =>
            record.ownerMayShare && record.ownerId === userId ? (
              <ShareFilterGraphButton record={record} basePath={props.basePath} />
            ) : null
          }
        />
        <FunctionField
          render={record =>
            userIsAdmin || (record.ownerMayEdit && record.ownerId === userId) ? (
              <EditButton record={record} basePath={props.basePath} resource={resources.FILTER_GRAPHS} />
            ) : null
          }
        />
        {userIsAdmin && <CustomDeleteButton undoable={false} />}
      </Datagrid>
    </List>
  );
};

FilterGraphsList.propTypes = {
  permissions: PropTypes.object.isRequired,
  translate: PropTypes.func.isRequired,
};

export default translate(FilterGraphsList);
