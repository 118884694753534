import ShipmentCommentedNotification from "../components/notifications/ShipmentCommentedNotification";
import MentionedInCommentNotification from "../components/notifications/MentionedInCommentNotification";
import PrepaymentPayedNotification from "../components/notifications/PrepaymentPayedNotification";
import JobCommentedNotification from "../components/notifications/JobCommentedNotification";
import JobDocumentAddedNotification from "../components/notifications/JobDocumentAddedNotification";
import JobInDeliveryNotification from "../components/notifications/JobInDeliveryNotification";
import JobAcceptedNotification from "../components/notifications/JobAcceptedNotification";
import JobDeclinedNotification from "../components/notifications/JobDeclinedNotification";

const translationPrefix = "notificationTypes.";
const bodyPrefix = "notifications.";

export const NotificationTypes = {
  JOB_ACCEPTED: {
    key: "JOB_ACCEPTED",
    translation: `${translationPrefix}JOB_ACCEPTED`,
    bodyTranslation: `${bodyPrefix}JOB_ACCEPTED`,
    templateType: "JOB_ACCEPTED_NOTIFICATION",
    body: JobAcceptedNotification,
  },
  JOB_DECLINED: {
    key: "JOB_DECLINED",
    translation: `${translationPrefix}JOB_DECLINED`,
    bodyTranslation: `${bodyPrefix}JOB_DECLINED`,
    templateType: "JOB_DECLINED_NOTIFICATION",
    body: JobDeclinedNotification,
  },
  JOB_IN_DELIVERY: {
    key: "JOB_IN_DELIVERY",
    translation: `${translationPrefix}JOB_IN_DELIVERY`,
    bodyTranslation: `${bodyPrefix}JOB_IN_DELIVERY`,
    templateType: "JOB_IN_DELIVERY_NOTIFICATION",
    body: JobInDeliveryNotification,
  },
  JOB_DOCUMENTS_ADDED: {
    key: "JOB_DOCUMENTS_ADDED",
    translation: `${translationPrefix}JOB_DOCUMENTS_ADDED`,
    bodyTranslation: `${bodyPrefix}JOB_DOCUMENTS_ADDED`,
    templateType: "JOB_DOCUMENTS_ADDED_NOTIFICATION",
    body: JobDocumentAddedNotification,
  },
  JOB_COMMENTED: {
    key: "JOB_COMMENTED",
    translation: `${translationPrefix}JOB_COMMENTED`,
    bodyTranslation: `${bodyPrefix}JOB_COMMENTED`,
    templateType: "JOB_COMMENTED_NOTIFICATION",
    body: JobCommentedNotification,
  },
  SHIPMENT_COMMENTED: {
    key: "SHIPMENT_COMMENTED",
    translation: `${translationPrefix}SHIPMENT_COMMENTED`,
    bodyTranslation: `${bodyPrefix}SHIPMENT_COMMENTED`,
    templateType: "SHIPMENT_COMMENTED_NOTIFICATION",
    body: ShipmentCommentedNotification,
  },
  MENTIONED_IN_COMMENT: {
    key: "MENTIONED_IN_COMMENT",
    translation: `${translationPrefix}MENTIONED_IN_COMMENT`,
    bodyTranslation: `${bodyPrefix}MENTIONED_IN_COMMENT`,
    templateType: "MENTIONED_IN_COMMENT_NOTIFICATION",
    body: MentionedInCommentNotification,
  },
  PREPAYMENT_PAYED: {
    key: "PREPAYMENT_PAYED",
    translation: `${translationPrefix}PREPAYMENT_PAYED`,
    bodyTranslation: `${bodyPrefix}PREPAYMENT_PAYED`,
    templateType: "PREPAYMENT_PAYED_NOTIFICATION",
    body: PrepaymentPayedNotification,
  },
};

export default NotificationTypes;
