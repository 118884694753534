import React from "react";
import { makeStyles } from "@material-ui/styles";
import CustomFilter from "../CustomFilter";
import { NumberInput, TextInput, usePermissionsOptimized } from "react-admin";
import { getKey } from "../../lib/i18nUtils";
import resources from "../../config/resources";

const useStyles = makeStyles(theme => ({
  container: {
    display: "grid !important",
    width: "100%",

    gridTemplateColumns: "repeat(18, 1fr)",
    [theme.breakpoints.down("md")]: {
      gridTemplateColumns: "repeat(9, 1fr)",
    },
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "repeat(6, 1fr)",
    },
    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "repeat(3, 1fr)",
    },
    gridColumnGap: `${theme.spacing(1)}px`,

    "& > *": {
      gridColumnEnd: "span 3",
    },
  },
  autocompleteContainer: {
    width: "100%",
    marginBottom: theme.spacing(1),
    "& > *": {
      width: "100%",
    },
  },
  singleAutocompleteContainer: {
    width: "100%",
    "& *": {
      width: "100%",
    },
  },
  //we override the Ra input styles, so the arrayinputs have the same height as the other inputs
  inputRoot: {
    flexWrap: "nowrap",
  },
  inputRootFilled: {
    flexWrap: "nowrap",
  },
}));

const AutoAssignLogsFilters = ({ ...props }) => {
  const classes = useStyles(props);
  const permissions = usePermissionsOptimized();

  return (
    <CustomFilter {...props} className={classes.container} permissions={permissions}>
      <NumberInput
        source="model#rapidshopId"
        resettable
        fullWidth
        alwaysOn
        label={getKey("model", resources.AUTO_ASSIGN_LOGS)}
      />
      <TextInput
        source="job#externalId"
        resettable
        fullWidth
        alwaysOn
        label={getKey("job", resources.AUTO_ASSIGN_LOGS)}
      />
      <NumberInput
        source="model#order#rapidshopId"
        resettable
        fullWidth
        alwaysOn
        label={getKey("order", resources.MODELS)}
      />
    </CustomFilter>
  );
};

export default AutoAssignLogsFilters;
