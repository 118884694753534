import React from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { translate } from "react-admin";
import PropTypes from "prop-types";
import { Form, Field } from "react-final-form";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";
import SaveIcon from "@material-ui/icons/Save";
import get from "lodash/get";

import { customCreateSubtask } from "../../redux/subtasks/action";
import SubtaskTypes from "../../config/SubtaskTypes";
import SubtaskStatus from "../../config/SubtaskStatus";
import { getDefaultStatus, statusChoices } from "../../config/SubtaskSelect";

const styles = () => ({
  form: {
    marginTop: "2vw",
    marginLeft: "2vw",
    marginRight: "2vw",
  },
  dialogActions: {
    width: "100%",
    display: "flex",
    justifyContent: "space-around",
    marginTop: "1vw",
    marginBottom: "1vw",
  },
  icon: {
    marginRight: 5,
  },
});

const TextFieldAdapter = ({ input, meta, ...rest }) => (
  <TextField
    {...input}
    {...rest}
    onChange={event => input.onChange(event.target.value)}
    helperText={meta.touched ? meta.error : ""}
    error={meta.touched ? meta.invalid : false}
    margin="normal"
  />
);

/**
 * TODO: Adjust this component to work for shipment subtasks aswell!
 */
const SubtaskCreate = ({ record, translate, classes, closeDialog, parentRecord, createSubtask, ...props }) => {
  const handleSubmit = newSubtask => {
    createSubtask(newSubtask);
    closeDialog();
  };

  const validateTitle = value => {
    if (!value) return translate("ra.validation.required");
    if (get(value, "length") < 3) return translate("ra.validation.minLength", { min: 3 });
    if (get(value, "length") > 255) return translate("ra.validation.maxLength", { max: 255 });
    return undefined;
  };

  const subtaskTemplate = {
    title: "",
    description: "",
    status: SubtaskStatus.TODO.key,
    type: SubtaskTypes.MODEL.key,
    rohStatus: getDefaultStatus(statusChoices, (parentRecord || {}).rohStatus).id,
    model: record.model,
  };

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={subtaskTemplate}
      render={({ handleSubmit, form, submitting, pristine, values }) => (
        <form onSubmit={handleSubmit} className={classes.form}>
          <div>
            <Field
              name="title"
              component={TextFieldAdapter}
              label={translate("resources.subtasks.fields.title")}
              validate={validateTitle}
              fullWidth
              multiline
              required
              autoFocus
            />
          </div>
          <div>
            <Field
              name="description"
              component={TextFieldAdapter}
              label={translate("resources.subtasks.fields.description")}
              multiline
              fullWidth
            />
          </div>
          <div>
            <Field
              name="rohStatus"
              component={TextFieldAdapter}
              label={translate("resources.subtasks.fields.rohStatus")}
              fullWidth
              disabled
            />
          </div>
          <div className={classes.dialogActions}>
            <Button type="submit" variant="contained" color="primary" disabled={pristine}>
              <SaveIcon className={classes.icon} />
              {translate("ra.action.save")}
            </Button>
            <Button onClick={closeDialog} variant="outlined">
              <CloseIcon className={classes.icon} />
              {translate("ra.action.cancel")}
            </Button>
          </div>
        </form>
      )}
    />
  );
};

SubtaskCreate.propTypes = {
  classes: PropTypes.object,
  record: PropTypes.object.isRequired,
  parentRecord: PropTypes.object.isRequired,
  translate: PropTypes.func,
  closeDialog: PropTypes.func,
  createSubtask: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  createSubtask: customCreateSubtask,
};

export default compose(
  connect(
    null,
    mapDispatchToProps
  ),
  withStyles(styles),
  translate
)(SubtaskCreate);
