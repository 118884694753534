import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { translate, FunctionField } from "react-admin";

const styles = () => ({
  text: {
    fontWeight: 700,
  },
});

const GroupedIndexColumn = ({ classes, translate, groupIndex, ...props }) => (
  <FunctionField
    {...props}
    render={record => {
      const group = record["_GROUP"];
      if (!group || typeof group !== "object") return null;
      return <span className={classes.text}>{groupIndex + 1}</span>;
    }}
  />
);

export default withStyles(styles)(translate(GroupedIndexColumn));
