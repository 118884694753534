import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import { Confirm, translate } from "react-admin";
import DropdownButton from "../customFields/buttons/DropdownButton";
import DeclineModelReasons from "../../config/DeclineModelReasons";
import { declineJobModel } from "../../redux/job/declineJobModel/action";
import Icon from "@material-ui/icons/Warning";
import red from "@material-ui/core/colors/red";

const styles = theme => ({
  button: {
    backgroundColor: `${red[500]} !important`,
    color: theme.palette.getContrastText(red[500]),
  },
});

class DeclineModelButton extends Component {
  state = {
    showDeclineDialog: false,
    declineDialogData: {
      reason: null,
      message: null,
      model: null,
    },
  };

  handleDeclineSelect = (selected, message) => {
    const { record } = this.props;
    if (!record) return;
    this.setState({
      showDeclineDialog: true,
      declineDialogData: {
        reason: selected,
        message,
        model: record.__id,
      },
    });
  };

  closeDeclineDialog = () => {
    this.setState({
      showDeclineDialog: false,
      declineDialogData: {
        reason: null,
        message: null,
        model: null,
      },
    });
  };

  handleDeclineConfirm = () => {
    const { declineJobModel, record, jobId: job } = this.props;
    const {
      declineDialogData: { reason, message, model },
    } = this.state;

    if (!record) return;
    const { __id } = record;

    if (model !== __id) return;

    declineJobModel(job, model, reason, message);

    this.closeDeclineDialog();
  };

  render() {
    const { translate, label, color, variant, classes } = this.props;

    const { showDeclineDialog } = this.state;

    const declineReasons = Object.values(DeclineModelReasons).map(reason => ({
      key: reason.key,
      label: translate(reason.translation),
      isInput: reason.needsMessage,
    }));

    const buttonContent = (
      <Fragment>
        <Icon />
        &nbsp;
        {label || translate("declineModel.markNeedsUpdate")}
      </Fragment>
    );

    return (
      <Fragment>
        <DropdownButton
          label={buttonContent}
          buttonProps={{
            color,
            variant,
            className: classes.button,
          }}
          items={declineReasons}
          onSelect={this.handleDeclineSelect}
        />
        <Confirm
          title={translate("declineModel.markNeedsUpdate")}
          content={translate("declineModel.declineDialogText")}
          isOpen={showDeclineDialog}
          onClose={this.closeDeclineDialog}
          onConfirm={this.handleDeclineConfirm}
        />
      </Fragment>
    );
  }
}

DeclineModelButton.propTypes = {
  record: PropTypes.object,
  resource: PropTypes.string,
  label: PropTypes.string,
};

DeclineModelButton.defaultProps = {
  variant: "contained",
  addLabel: false,
};

export default connect(
  undefined,
  {
    declineJobModel,
  }
)(withStyles(styles)(translate(DeclineModelButton)));
