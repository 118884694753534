import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import { Button, translate } from "react-admin";
import get from "lodash/get";

import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import OkIcon from "@material-ui/icons/Done";
import AbortIcon from "@material-ui/icons/Close";
import { getKey } from "../../lib/i18nUtils";
import resources from "../../config/resources";
import JsCodeInput from "../JsCodeInput";

const FormulaInput = ({ translate, showParameters, ...props }) => {
  const [codeParameters, setCodeParameters] = useState(get(props, "codeParameters", {}));
  const [inputValues, setInputValues] = useState(get(props, "codeParameters", {}));
  const [isOpen, setIsOpen] = useState(false);

  const onOpen = () => {
    setInputValues(codeParameters);
    setIsOpen(true);
  };

  const onAbort = () => {
    setInputValues(codeParameters);
    setIsOpen(false);
  };

  const onSubmit = () => {
    setCodeParameters(inputValues);
    setIsOpen(false);
  };

  const onInput = key => evt => {
    const newValues = {
      ...inputValues,
      [key]: evt.target.value,
    };
    setInputValues(newValues);
  };

  return (
    <Fragment>
      <Dialog
        fullWidth={true}
        open={isOpen}
        maxWidth="md"
        onClose={onAbort}
        aria-labelledby="formula-params-dialog-title"
      >
        <DialogTitle id="formula-params-dialog-title">
          {translate(getKey("editParameters", resources.FORMULAS))}
        </DialogTitle>
        <DialogContent>
          <form autoComplete="off">
            <Grid container>
              {Object.keys(inputValues).map(
                key =>
                  ["string", "number"].includes(typeof inputValues[key]) && (
                    <Grid item xs={12} md={6} lg={3} style={{ paddingTop: 16 }} key={key}>
                      <TextField label={key} value={inputValues[key]} onChange={onInput(key)} />
                    </Grid>
                  )
              )}
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <Button label="ra.action.cancel" onClick={onAbort} color="default">
            <AbortIcon />
          </Button>
          <Button
            label={translate(getKey("saveParameters", resources.FORMULAS))}
            onClick={onSubmit}
            variant="contained"
          >
            <OkIcon />
          </Button>
        </DialogActions>
      </Dialog>
      <JsCodeInput {...props} codeParameters={codeParameters} showParameters={showParameters} />
      <Button label={translate(getKey("editParameters", resources.FORMULAS))} onClick={onOpen} color="default" />
    </Fragment>
  );
};

FormulaInput.propTypes = {
  codeParameters: PropTypes.object,
  showParameters: PropTypes.bool,
  translate: PropTypes.func.isRequired,
};

export default translate(FormulaInput);
