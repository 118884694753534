import { call, put, takeEvery } from "redux-saga/effects";
import resources from "../../../config/resources";
import config from "../../../config/index";
import fetchService from "../../../lib/fetchService";
import { DECLINE_JOB_MODEL_REQUEST, declineJobModelError, declineJobModelSuccess } from "./action";
import { handleSagaError } from "../../../dataproviders/errorResponseHandler";

const requestSaga = function*({ payload }) {
  const { job, model, reason, message } = payload;

  const url = `${config.urls.BACKEND}/v2/${resources.JOBS}/${encodeURIComponent(job)}/decline/${encodeURIComponent(
    model
  )}`;
  const body = { reason, message };

  try {
    const response = yield call(fetchService.post, url, {}, body);

    for (const action of yield call(handleSagaError, response, declineJobModelError)) {
      yield put(action);
      return;
    }

    if (response.ok) {
      const responseData = yield call(() => response.json());
      yield put(declineJobModelSuccess(responseData));
    } else {
      const statusCode = response.status;
      yield put(declineJobModelError(new Error(`Request failed with error ${statusCode}!`)));
    }
  } catch (e) {
    yield put(declineJobModelError(e));
  }
};

export default function*() {
  yield takeEvery(DECLINE_JOB_MODEL_REQUEST, requestSaga);
}
