export default {
  50: "#e1e5ea",
  100: "#b3bfcc",
  200: "#8194aa",
  300: "#4f6987",
  400: "#29486e",
  500: "#032854",
  600: "#03244d",
  700: "#021e43",
  800: "#02183a",
  900: "#010f29",
  A100: "#6386ff",
  A200: "#305eff",
  A400: "#0038fc",
  A700: "#0032e3",
  contrastDefaultColor: "light",
};
