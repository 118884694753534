import { mergeReducers } from "../../../lib/createReducer";
import cloneDeep from "lodash/cloneDeep";
import {
  FETCH_FILE_VERSIONS_OF_FILE_REQUEST,
  FETCH_FILE_VERSIONS_OF_FILE_SUCCESS,
  FETCH_FILE_VERSIONS_OF_FILES_ERROR,
} from "./action";
import keyBy from "lodash/keyBy";

const init = {
  data: {},
  fetching: [],

  hasError: false,
  error: null,
};

const requestReducer = (state = init, { type, payload }) => {
  if (type !== FETCH_FILE_VERSIONS_OF_FILE_REQUEST) return state;

  const { fileUuid } = payload;

  const newState = cloneDeep(state);
  newState.hasError = false;
  newState.error = null;
  newState.fetching.push(fileUuid);

  return newState;
};

const successReducer = (state = init, { type, payload }) => {
  if (type !== FETCH_FILE_VERSIONS_OF_FILE_SUCCESS) return state;

  const { fileUuid, response } = payload;

  const newState = cloneDeep(state);
  newState.data[fileUuid] = keyBy(response, it => it.uuid);
  newState.fetching.splice(state.fetching.indexOf(fileUuid), 1);

  return newState;
};

const errorReducer = (state = init, { type, payload }) => {
  if (type !== FETCH_FILE_VERSIONS_OF_FILES_ERROR) return state;

  const { fileUuid, error } = payload;

  const newState = cloneDeep(state);
  newState.fetching.splice(state.fetching.indexOf(fileUuid), 1);
  newState.hasError = true;
  newState.error = error;

  return newState;
};

export default mergeReducers([requestReducer, successReducer, errorReducer], init);
