import React from "react";
import { connect } from "react-redux";
import { Button } from "react-admin";
import resources from "../../config/resources";
import sanitizeButton from "../sanitizeButton";
import { receivedJob } from "../../redux/job/receivedJob/action";
import green from "@material-ui/core/colors/green";
import { withStyles } from "@material-ui/core/styles";
import classnames from "classnames";
import Icon from "@material-ui/icons/MoveToInbox";

const styles = theme => ({
  button: {
    backgroundColor: `${green[600]} !important`,
    color: theme.palette.getContrastText(green[600]),
  },
});

const ReceivedJobButton = ({ record, resource, receivedJob, onClick, classes = {}, color, className, ...props }) => {
  const handleClick = e => {
    if (resource === resources.JOBS && record) {
      receivedJob(record.__id);
    }
    onClick(e);
  };

  return (
    <Button
      {...sanitizeButton(props)}
      startIcon={<Icon />}
      color={color}
      className={color === "default" ? classnames(classes.button, className) : className}
      onClick={handleClick}
    />
  );
};

export default connect(
  undefined,
  {
    receivedJob,
  }
)(withStyles(styles)(ReceivedJobButton));
