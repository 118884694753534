import React from "react";
import {
  BooleanInput,
  Create,
  email,
  FormDataConsumer,
  maxLength,
  minLength,
  ReferenceInput,
  required,
  SelectArrayInput,
  SelectInput,
  SimpleForm,
  TextInput,
  translate,
} from "react-admin";
import resources from "../../config/resources";
import Roles from "../../config/Roles";
import yellow from "@material-ui/core/colors/yellow";

const validateUsername = [required(), minLength(3), maxLength(80)];
const validatePassword = [required(), minLength(5), maxLength(80)];
const validateDisplayName = [required(), minLength(3), maxLength(80)];
const validateRoles = [required()];
const validateEmail = [required(), email()];

const AuthorityWarningField = ({ translate, ...props }) => (
  <FormDataConsumer {...props}>
    {({ formData }) =>
      typeof formData.contractor === "number" &&
      (!Array.isArray(formData.authorities) ||
        formData.authorities.length !== 1 ||
        formData.authorities[0] !== Roles.ROLE_CONTRACTOR.key) && (
        <div
          style={{
            padding: 16,
            borderRadius: 4,
            backgroundColor: yellow[100],
            border: `solid 3px ${yellow[500]}`,
            minWidth: "100%",
            width: 200,
            boxSizing: "border-box",
            color: "#332e00",
          }}
        >
          {translate("contractorUserWithWrongRolesWarning")}
        </div>
      )
    }
  </FormDataConsumer>
);

const UserCreate = ({ translate, ...props }) => (
  <div style={{ display: "flex", justifyContent: "center" }}>
    <Create {...props}>
      <SimpleForm initialValues={{ enabled: true }} redirect="list" variant="standard" margin="normal">
        <TextInput source="username" validate={validateUsername} />
        <TextInput source="password" type="password" validate={validatePassword} />
        <TextInput source="displayName" validate={validateDisplayName} />
        <TextInput source="email" type="email" validate={validateEmail} />
        <BooleanInput source="enabled" />
        <BooleanInput source="locked" />
        <BooleanInput source="credentialsExpired" />
        <BooleanInput source="accountExpired" />
        <ReferenceInput source="contractor" reference={resources.CONTRACTORS} allowEmpty>
          <SelectInput optionText="contractorName" optionValue="id" />
        </ReferenceInput>
        <SelectArrayInput
          source="authorities"
          choices={Object.values(Roles)}
          optionValue="key"
          optionText={({ translation }) => translate(translation)}
          validate={validateRoles}
        />
        <AuthorityWarningField translate={translate} />
      </SimpleForm>
    </Create>
  </div>
);

export default translate(UserCreate);
