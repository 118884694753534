import React, { useState } from "react";
import { FallbackImageField } from "./FallbackImageField";
import Dialog from "@material-ui/core/Dialog";
import { withStyles } from "@material-ui/core/styles";

const styles = {
  container: {
    cursor: "pointer",
    display: "inline-block",
    borderRadius: 3,
    transition: "all 0.2s ease-in-out",
    "&:hover": {
      boxShadow: "0 2px 4px #aaa",
    },
  },
  dialogImage: {
    height: "80vmin",
  },
};

const ClickableImageField = ({ classes, children, stlSource, ...rest }) => {
  const [open, setOpen] = useState(false);

  return (
    <div className={classes.container}>
      <FallbackImageField {...rest} classes={classes} onClick={() => setOpen(true)} />
      <Dialog open={open} onClose={() => setOpen(false)} fullWidth={false} maxWidth={false}>
        {children || <FallbackImageField {...rest} classes={{ ...classes, image: classes.dialogImage }} />}
      </Dialog>
    </div>
  );
};

ClickableImageField.propTypes = FallbackImageField.propTypes;

export default withStyles(styles)(ClickableImageField);
