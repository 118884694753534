import resources from "../../config/resources";
import fetchService from "../../lib/fetchService";
import { call, put, takeEvery } from "redux-saga/effects";
import { CHECK_PRINTJOB_TIMES, checkPrintjobTimesError, checkPrintjobTimesSuccess } from "./action";
import { handleSagaError } from "../../dataproviders/errorResponseHandler";
import config from "../../config";

const requestSaga = function*({ payload }) {
  const { id } = payload;

  const url = `${config.urls.BACKEND}/${resources.PRINT_JOBS}/${id}/timing`;

  try {
    const response = yield call(fetchService.post, url);

    for (const action of yield call(handleSagaError, response, checkPrintjobTimesError)) {
      yield put(action);
      return;
    }

    if (response.ok) {
      const responseData = yield call(() => response.json());
      yield put(checkPrintjobTimesSuccess(responseData));
    } else {
      const statusCode = response.status;
      yield put(checkPrintjobTimesError(new Error(`Request failed with error ${statusCode}!`)));
    }
  } catch (e) {
    yield put(checkPrintjobTimesError(e));
  }
};

export default function*() {
  yield takeEvery(CHECK_PRINTJOB_TIMES, requestSaga);
}
