import { call, put, take, takeEvery } from "redux-saga/effects";
import { UPLOAD_DOCUMENT_REQUEST, uploadDocumentError, uploadDocumentProgress, uploadDocumentSuccess } from "./action";
import { createUploadFileChannel } from "../../lib/FileUploader";
import config from "../../config";

const uploadDocumentSaga = function*(action = {}) {
  const { file: plFile, meta, handleSuccess, documentMeta } = action.payload || {};
  const { documentTypeId, resource, recordId } = documentMeta || {};

  let file;
  if (plFile === null || plFile === undefined) {
    yield put(uploadDocumentError(plFile, new Error("File is null")));
    return;
  } else if (Array.isArray(plFile)) {
    if (plFile.length > 1) console.warn("[uploadDocumentSaga] Cannot upload multiple files. Only uploading first...");

    file = plFile[0];
  } else if (plFile instanceof FileList) {
    if (plFile.length > 1) console.warn("[uploadDocumentSaga] Cannot upload multiple files. Only uploading first...");

    file = plFile.item(0);
  } else {
    file = plFile;
  }

  const url = `${config.urls.BACKEND}/v2/${resource}/${recordId}/uploadDocument/${documentTypeId}`;

  const fileMultipart = new FormData();
  fileMultipart.append("file", file);

  const channel = yield call(createUploadFileChannel, url, fileMultipart);

  while (true) {
    const { progress = 0, error, success, payload } = yield take(channel);

    if (error) {
      const defaultAction = uploadDocumentError(file, error);
      if (!meta || !meta.onFailure) {
        yield put(defaultAction);
      } else {
        yield put({
          ...defaultAction,
          meta: meta.onFailure,
        });
      }
      return;
    }

    if (success) {
      const responseData = !payload
        ? null
        : yield call(() => {
            try {
              return JSON.parse(payload);
            } catch (e) {
              return payload;
            }
          });

      const defaultAction = uploadDocumentSuccess(file, responseData);
      if (!meta || !meta.onSuccess) {
        yield put(defaultAction);
      } else {
        yield put({
          ...defaultAction,
          meta: meta.onSuccess,
        });
      }

      if (handleSuccess !== null && typeof handleSuccess === "object") {
        yield put(handleSuccess);
      }

      return;
    }

    yield put(uploadDocumentProgress(file, progress));
  }
};

export default function* uploadDocumentRequestWatcherSaga() {
  yield takeEvery(UPLOAD_DOCUMENT_REQUEST, uploadDocumentSaga);
}
