import { getKey } from "../../../lib/i18nUtils";
import resources from "../../../config/resources";
import { AutocompleteArrayInput, ReferenceArrayInput, useTranslate } from "react-admin";
import AdditionField from "../../additions/AdditionField";
import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";

const styles = theme => ({
  autocomplete: {
    width: "100%",
    "& > *": {
      width: "100%",
    },
  },
});

const AdditionFilter = ({ classes }) => {
  const translate = useTranslate();
  return (
    <ReferenceArrayInput
      source="models#additions#id"
      label={translate(getKey("addition", resources.MODELS))}
      reference={resources.ADDITIONS}
      sort={{ field: "name", order: "ASC" }}
      perPage={40}
      alwaysOn
      fullWidth
      filterToQuery={searchText => ({ autocomplete: searchText })}
      suggestionLimit={40}
    >
      <AutocompleteArrayInput
        optionText={<AdditionField />}
        matchSuggestion={(filter, { name = "", colorName = "" }) =>
          (name && name.includes(filter)) || (colorName && colorName.includes(filter))
        }
        inputText={({ name, colorName }) => `${name} ${colorName ? `(${colorName})` : ""}`}
        optionValue="__id"
        fullWidth
        options={{ fullWidth: true }}
        translateChoice={false}
        resettable
        classes={{ ...classes, container: classes.autocomplete }}
      />
    </ReferenceArrayInput>
  );
};

export default withStyles(styles)(AdditionFilter);
