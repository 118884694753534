export default {
  ROLE_SALES: {
    key: "ROLE_SALES",
    translation: "role.ROLE_SALES",
  },
  ROLE_CONTRACTOR: {
    key: "ROLE_CONTRACTOR",
    translation: "role.ROLE_CONTRACTOR",
  },
  ROLE_ADMIN: {
    key: "ROLE_ADMIN",
    translation: "role.ROLE_ADMIN",
  },
};
