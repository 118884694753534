import { call, take, takeEvery, fork, put, cancel } from "redux-saga/effects";
import {
  INIT_PUSH_NOTIFICATIONS_REQUEST,
  SHOW_SYSTEM_PUSH_NOTIFICATION,
  initPushNotificationsFailed,
  initPushNotificationsSuccess,
  STOP_PUSH_NOTIFICATIONS_REQUEST,
  stopPushNotificationsSuccess,
  showSystemPushNotificationSuccess,
  showSystemPushNotificationError,
} from "./actions";
import * as PushService from "../../lib/PushService";

const handlePushNotificationSaga = function*({ showNotification }, { payload }) {
  const { subject, options } = payload;
  try {
    yield call(showNotification, subject, options);
    yield put(showSystemPushNotificationSuccess(subject, options));
  } catch (e) {
    yield put(showSystemPushNotificationError(subject, options, e));
  }
};

const handlePushNotificationTask = function*(args) {
  yield takeEvery(SHOW_SYSTEM_PUSH_NOTIFICATION, handlePushNotificationSaga, args);
};

const initPushNotificationsSaga = function*() {
  try {
    const showNotification = yield call(PushService.initPush);

    const task = yield fork(handlePushNotificationTask, { showNotification });

    yield put(initPushNotificationsSuccess());

    yield take(STOP_PUSH_NOTIFICATIONS_REQUEST);

    yield cancel(task);

    yield put(stopPushNotificationsSuccess());
  } catch (e) {
    yield put(initPushNotificationsFailed(e));
  }
};

export default function*() {
  yield takeEvery(INIT_PUSH_NOTIFICATIONS_REQUEST, initPushNotificationsSaga);
}
