import createReducer, { mergeReducers } from "../../../lib/createReducer";
import {
  NOTE_TO_CONTRACTOR_ERROR,
  NOTE_TO_CONTRACTOR_LOCAL_STATE,
  NOTE_TO_CONTRACTOR_REQUEST,
  NOTE_TO_CONTRACTOR_SUCCESS,
} from "./action";

const init = {
  models: [],
  note: null,
  append: true,
  localNote: "",

  isFetching: false,
  fetchSuccess: false,
  hasError: false,
  payload: null,
  error: null,
};

const localStateReducer = createReducer(NOTE_TO_CONTRACTOR_LOCAL_STATE, ({ note }) => ({
  ...init,
  localNote: note,
}));

const requestReducer = createReducer(
  NOTE_TO_CONTRACTOR_REQUEST,
  ({ models, note, append }) => ({
    ...init,
    localNote: note, //the init spread resets the localNoteStorage, but we don't want that here and I don't know how to do it otherwise
    models,
    note,
    append,
    isFetching: true,
  }),
  true
);

const successReducer = createReducer(NOTE_TO_CONTRACTOR_SUCCESS, ({ response }) => ({
  isFetching: false,
  fetchSuccess: true,
  payload: response,
}));

const errorReducer = createReducer(NOTE_TO_CONTRACTOR_ERROR, ({ error }) => ({
  isFetching: false,
  hasError: true,
  error,
}));

export default mergeReducers([requestReducer, successReducer, errorReducer, localStateReducer], init);
