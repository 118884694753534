import React, { useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import LinearProgress from "@material-ui/core/LinearProgress";
import get from "lodash/get";

import {
  customRefFetch,
  customSelectedRefFetch,
  customSelectedRefFetchFailure,
} from "../../redux/customReferenceFetch/action";
import {
  getCustomReferenceFetchedItemData,
  isCustomReferenceFetchedItemFetching,
  isCustomSelectedReferenceFetchedItemFetching,
} from "../../redux/customReferenceFetch/selectors";

/**
 * Our custom Input component to replace the ReferenceArrayInput by react-admin
 *
 * It accepts only one child!
 * Props explained:
 * Occurrence is needed for dynamic store handling (see /redux/customReferenceFetch action and reducer)
 * reference, sort, filter (like before) for building the request for all available choices
 * record and source for building the already-selected-items request
 *
 * CAUTION:
 * If you use this component make sure it is used correctly as in ShareFilterGraphButton
 * (i.e. you have to pass the already selected references to the initialValues prop of the final form Field/Form component!)
 */
const CustomRefArrayInput = ({
  children,
  occurrence,
  reference,
  sort,
  filter,
  record,
  source,
  fetchReferences,
  fetchSelectedReferences,
  deselectReferences,
  data,
  isFetching,
}) => {
  const refId = get(record, "id");

  useEffect(() => {
    fetchReferences(occurrence, reference, sort, filter);
    if (refId && source) {
      fetchSelectedReferences(occurrence, `${refId.substr(1)}/${source}`);
    } else {
      deselectReferences(occurrence);
    }
  }, [refId]);

  if (isFetching) {
    return <LinearProgress />;
  }

  const allOptions = get(data, reference, []);

  return (
    <>
      {React.cloneElement(children, {
        options: allOptions,
      })}
    </>
  );
};

CustomRefArrayInput.propTypes = {
  children: PropTypes.element.isRequired,
  occurrence: PropTypes.string.isRequired,
  reference: PropTypes.string.isRequired,
  sort: PropTypes.object,
  filter: PropTypes.object,
  record: PropTypes.object.isRequired,
  source: PropTypes.string.isRequired,
  fetchReferences: PropTypes.func.isRequired,
  fetchSelectedReferences: PropTypes.func.isRequired,
  data: PropTypes.object,
  isFetching: PropTypes.bool,
};

CustomRefArrayInput.defaultProps = {
  sort: null,
  filter: null,
};

const mapStateToProps = (state, { occurrence }) => ({
  data: getCustomReferenceFetchedItemData(occurrence)(state),
  isFetching:
    isCustomReferenceFetchedItemFetching(occurrence)(state) ||
    isCustomSelectedReferenceFetchedItemFetching(occurrence)(state),
});

const mapDispatchToProps = {
  fetchReferences: customRefFetch,
  fetchSelectedReferences: customSelectedRefFetch,
  deselectReferences: customSelectedRefFetchFailure,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomRefArrayInput);
