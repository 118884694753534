import { call, put, takeLatest } from "redux-saga/effects";
import { crudGetMany } from "react-admin";
import resources from "../../../config/resources";
import config from "../../../config/index";
import fetchService from "../../../lib/fetchService";
import { NOTE_TO_CONTRACTOR_REQUEST, noteToContractorSuccess, noteToContractorError } from "./action";

const requestSaga = function*({ payload }) {
  const { models, note, append = true } = payload;

  const url = `${config.urls.BACKEND}/v2/${resources.MODELS}/noteToContractor`;
  const data = {};
  const body = { modelIds: models, noteToContractor: note, append };

  try {
    const response = yield call(fetchService.post, url, data, body);

    if (response.ok) {
      const responseData = yield call(() => response.json());
      yield put(noteToContractorSuccess(responseData));
      yield put(crudGetMany(resources.MODELS, models));
    } else {
      const statusCode = response.status;
      yield put(noteToContractorError(new Error(`Request failed with error ${statusCode}!`)));
      yield put(crudGetMany(resources.MODELS, models));
    }
  } catch (e) {
    yield put(noteToContractorError(e));
  }
};

export default function*() {
  yield takeLatest(NOTE_TO_CONTRACTOR_REQUEST, requestSaga);
}
