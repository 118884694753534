import React from "react";
import PropTypes from "prop-types";
import { ReferenceField, FunctionField, translate } from "react-admin";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { QuillDeltaToHtmlConverter } from "quill-delta-to-html";
import blue from "@material-ui/core/colors/blue";

import DateField from "../customFields/DateField";
import resources from "../../config/resources";
import UserAvatar from "../userDetails/UserAvatar";

const styles = theme => ({
  contentWrapper: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  infoWrapper: {
    display: "flex",
    flexDirection: "row",
    marginBottom: theme.spacing(0.5),
    alignItems: "center",
  },
  date: {
    ...theme.typography.caption,
    marginLeft: theme.spacing(1),
  },
});

const getHtmlFromDeltaString = (deltaString, errorMessage, permissions) => {
  try {
    const delta = JSON.parse(deltaString);
    if (!Array.isArray(delta)) {
      throw new Error("invalid delta format!");
    } else {
      const converter = new QuillDeltaToHtmlConverter(delta, {});
      converter.renderCustomWith((op, context) => {
        if (op.insert.type === "mention") {
          const { value, denotationChar, id } = op.insert.value;
          const isMe = permissions && permissions.id === id;
          return `<span style="background-color: ${blue[isMe ? 700 : 50]}; border-radius: 3px; padding: 4px;${
            isMe ? "color: #FFFFFF" : ""
          }"><span style="opacity: 0.3">${denotationChar}</span>${value}</span>`;
        }
      });
      return converter.convert();
    }
  } catch (error) {
    return `<p>${errorMessage}</p>`;
  }
};

const CommentItem = ({ record, basePath, classes, translate, permissions }) => (
  <ReferenceField
    basePath={basePath}
    record={record}
    reference={resources.USER_DETAILS}
    source="createdBy"
    link={false}
  >
    <FunctionField
      render={userDetails => (
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "horizontal",
          }}
        >
          <div style={{ marginRight: 8 }}>
            <UserAvatar {...userDetails} size={28} />
          </div>
          <div className={classes.contentWrapper}>
            <div className={classes.infoWrapper}>
              <Typography variant="subtitle1" style={{ fontWeight: 700 }}>
                {userDetails.displayName}
              </Typography>
              <DateField
                source="createdAt"
                record={record}
                displayFormat="DD. MMMM YYYY HH:mm"
                className={classes.date}
              />
            </div>
            <div
              dangerouslySetInnerHTML={{
                __html: getHtmlFromDeltaString(
                  record.content,
                  translate("resources.comments.validationError"),
                  permissions
                ),
              }}
              style={{
                border: "solid 1px #bbb",
                width: "calc(100% - 32px)",
                padding: "0 16px",
                borderRadius: 8,
                borderTopLeftRadius: 0,
              }}
            />
          </div>
        </div>
      )}
    />
  </ReferenceField>
);

CommentItem.propTypes = {
  record: PropTypes.object.isRequired,
  basePath: PropTypes.string.isRequired,

  translate: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(translate(CommentItem));
