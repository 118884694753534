import React, { useEffect } from "react";
import { Box } from "@material-ui/core";
import { TextInput, useTranslate } from "react-admin";
import { useForm, useFormState } from "react-final-form";

import resources from "../../config/resources";
import { getKey } from "../../lib/i18nUtils";

const CustomDurationInputs = props => {
  const { classes } = props;
  const translate = useTranslate();
  const { printer, height } = useFormState()?.values || {};
  const form = useForm();

  const calculatePrintDuration = (printer, height) => {
    if (!printer || !height || printer === "/printers/6") {
      return;
    }

    if (
      ["/printers/4", "/printers/5", "/printers/7", "/printers/9", "/printers/10", "/printers/11"].includes(printer)
    ) {
      return (0.0255 * height + 1.613).toFixed(1);
    } else {
      return (0.0385 * height + 1.69).toFixed(1);
    }
  };

  const calculateCoolingDuration = (printer, height) => {
    if (!printer || !height || printer === "/printers/6") {
      return;
    }

    if (["/printers/1", "/printers/2", "/printers/3"].includes(printer)) {
      return (height * 0.1321 + 9.2236).toFixed(1);
    } else {
      return (height * 0.1189 + 8.3012).toFixed(1);
    }
  };

  useEffect(() => {
    form.change("printDuration", calculatePrintDuration(printer, height));
    form.change("coolingDuration", calculateCoolingDuration(printer, height));
  }, [printer, height]);

  return (
    <Box className={classes.horizontalInputsContainer}>
      <TextInput
        {...props}
        className={classes.printJobInput}
        label={translate(getKey("printDuration", resources.PRINT_JOBS))}
        source="printDuration"
        helperText={false}
      />
      <TextInput
        {...props}
        className={classes.printJobInput}
        label={translate(getKey("coolingDuration", resources.PRINT_JOBS))}
        source="coolingDuration"
        helperText={false}
      />
    </Box>
  );
};

export default CustomDurationInputs;
