import createReducer, { mergeReducers } from "../../../lib/createReducer";
import { UNASSIGN_MODEL_ERROR, UNASSIGN_MODEL_REQUEST, UNASSIGN_MODEL_SUCCESS } from "./action";

const init = {
  model: null,

  isFetching: false,
  fetchSuccess: false,
  hasError: false,
  payload: null,
  error: null,
};

const unassignModelReducer = createReducer(
  UNASSIGN_MODEL_REQUEST,
  ({ id }) => ({
    ...init,
    model: id,
    isFetching: true,
  }),
  true
);

const unassignModelSuccessReducer = createReducer(UNASSIGN_MODEL_SUCCESS, ({ response }) => ({
  isFetching: false,
  fetchSuccess: true,
  payload: response,
}));

const unassignModelErrorReducer = createReducer(UNASSIGN_MODEL_ERROR, ({ error }) => ({
  isFetching: false,
  hasError: true,
  error,
}));

export default mergeReducers([unassignModelReducer, unassignModelSuccessReducer, unassignModelErrorReducer], init);
