import createReducer, { mergeReducers } from "../../lib/createReducer";
import { EXPORT_REPORT, EXPORT_REPORT_SUCCESS, EXPORT_REPORT_ERROR } from "./action";

const init = {
  inProgress: false,
  success: false,
  hasError: false,
  error: null,
};

const requestReducer = createReducer(
  EXPORT_REPORT,
  () => ({
    ...init,
    inProgress: true,
  }),
  true
);

const successReducer = createReducer(EXPORT_REPORT_SUCCESS, () => ({
  inProgress: false,
  success: true,
}));

const errorReducer = createReducer(EXPORT_REPORT_ERROR, ({ error }) => ({
  inProgress: false,
  hasError: true,
  error,
}));

export default mergeReducers([requestReducer, successReducer, errorReducer], init);
