import React from "react";
import {
  BooleanInput,
  Create,
  FormDataConsumer,
  Labeled,
  maxLength,
  minLength,
  number,
  NumberInput,
  regex,
  required,
  SelectArrayInput,
  SimpleForm,
  TextInput,
  translate,
} from "react-admin";
import withTheme from "@material-ui/core/styles/withTheme";
import WarehouseLocationIcon from "@material-ui/icons/BorderOuter";
import { ColorInput } from "../ColorInput";
import IconChipField from "../customFields/IconChipField";
import RohStatus from "../../config/RohStatus";
import { getKey } from "../../lib/i18nUtils";
import resources from "../../config/resources";

const validateName = [required(), minLength(1), maxLength(255)];
const validateLabel = [required(), minLength(1), maxLength(255)];
const validateColor = [required(), regex(/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/)];
const validatePriority = [required(), number()];
const validateForStatus = [required(), minLength(1)];

const WarehouseLocationCreate = ({ translate, theme, ...props }) => (
  <div style={{ display: "flex", justifyContent: "center" }}>
    <Create {...props}>
      <SimpleForm initialValues={{ enabled: true }} redirect="list" variant="filled" margin="normal">
        <TextInput source="name" validate={validateName} />
        <TextInput source="description" multiline />
        <FormDataConsumer>
          {({ formData }) => (
            <TextInput
              source="label"
              validate={validateLabel}
              defaultValue={formData.name}
              style={{ width: "256px" }}
            />
          )}
        </FormDataConsumer>
        <ColorInput
          source="color"
          inputProps={{ autoComplete: "off", variant: "filled" }}
          validate={validateColor}
          defaultValue="#FFFFFF"
        />
        <Labeled label={translate(getKey("label", resources.WAREHOUSE_LOCATIONS))}>
          <FormDataConsumer>
            {({ formData }) => (
              <IconChipField color={formData.color} label={formData.label} icon={WarehouseLocationIcon} />
            )}
          </FormDataConsumer>
        </Labeled>
        <NumberInput source="priority" step={1} validate={validatePriority} defaultValue={0} />
        <BooleanInput source="enabled" defaultValue={true} />
        <SelectArrayInput
          source="forStatus"
          choices={Object.values(RohStatus).filter(it => it.filterable && it.modelStatus)}
          optionValue="apiValue"
          optionText={it => translate(it.translationKey || "")}
          translateChoice={false}
          fullWidth={true}
          validate={validateForStatus}
        />
      </SimpleForm>
    </Create>
  </div>
);

export default withTheme(translate(WarehouseLocationCreate));
