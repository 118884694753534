import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslate, FunctionField } from "react-admin";

import resources from "../../config/resources";
import { getKey } from "../../lib/i18nUtils";
import LabeledField from "../customFields/LabeledField";
import MuiTextField from "@material-ui/core/TextField";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(1),
    width: "auto",
    flex: 1,
  },
  label: {
    fontWeight: 700,
  },
}));

const PriceColumn = ({ groupIndex, ...props }) => {
  const translate = useTranslate();
  const classes = useStyles();
  return (
    <Grid container direction="column" className={classes.root}>
      <Grid container direction="row" alignItems="center">
        <Typography variant="body1" className={classes.label}>
          {translate(getKey("dimensions", resources.MODELS))}: &nbsp;
        </Typography>
        <FunctionField {...props} render={({ width }) => parseFloat(width).toFixed(2)} />
        <span>&nbsp;x&nbsp;</span>
        <FunctionField {...props} render={({ height }) => parseFloat(height).toFixed(2)} />
        <span>&nbsp;x&nbsp;</span>
        <FunctionField {...props} render={({ length }) => parseFloat(length).toFixed(2)} />
        <span>&nbsp;mm</span>
      </Grid>
      <Grid container direction="row" alignItems="center">
        <Typography variant="body1" className={classes.label}>
          {translate(getKey("volume", resources.MODELS))}: &nbsp;
        </Typography>
        <FunctionField {...props} render={({ volume }) => parseFloat(volume).toFixed(5)} />
        <span>
          &nbsp;mm<sup>3</sup>
        </span>
      </Grid>
      <Grid container direction="row" alignItems="center">
        <Typography variant="body1" className={classes.label}>
          {translate(getKey("singlePrice", resources.MODELS))}: &nbsp;
        </Typography>
        <FunctionField {...props} render={({ netPrice }) => parseFloat(netPrice).toFixed(2)} />
        <span>&nbsp;EUR</span>
      </Grid>
      <Grid container direction="row" alignItems="center">
        <Typography variant="body1" className={classes.label}>
          {translate(getKey("totalPrice", resources.MODELS))}: &nbsp;
        </Typography>
        <FunctionField
          {...props}
          render={({ netPrice, amount }) =>
            isFinite(netPrice) && isFinite(amount)
              ? (parseFloat(netPrice).toFixed(2) * Math.floor(amount)).toFixed(2)
              : parseFloat(netPrice).toFixed(2)
          }
        />
        <span>&nbsp; EUR</span>
      </Grid>
      <Grid container direction="row" alignItems="center">
        <FunctionField
          {...props}
          render={({ notice }) =>
            notice && (
              <LabeledField
                resource={resources.MODELS}
                source="notice"
                type={<span style={{ maxWidth: "7vw" }}>{notice}</span>}
              />
            )
          }
        />
      </Grid>
      <Grid container direction="row" alignItems="center">
        <FunctionField
          {...props}
          render={({ noteToContractor }) => {
            return (
              <>
                <Typography variant="body1" style={{ display: "inline-flex", fontWeight: 700 }}>
                  {translate(getKey("noteToContractor", resources.MODELS))}:
                </Typography>
                {noteToContractor && (
                  <MuiTextField
                    multiline
                    InputProps={{
                      readOnly: true,
                      disableUnderline: true,
                    }}
                    rowsMax="3" //TODO: deprecated but only working prop for this. care when upgrading mui
                    value={noteToContractor}
                    size="small"
                  />
                )}
              </>
            );
          }}
        />
      </Grid>
    </Grid>
  );
};
export default PriceColumn;
