import { put, takeEvery } from "redux-saga/effects";
import { WEB_SOCKET_MESSAGE_RECEIVED } from "../websocket/action";
import WebSocketTopics from "../../config/WebSocketTopics";
import { activeUsersChanged } from "./actions";

const handleWebsocket = function*(action = {}) {
  const { topic, body } = action.payload || {};

  if (topic === WebSocketTopics.ACTIVE_USER) {
    const { activeUserIds } = body;

    yield put(activeUsersChanged(activeUserIds));
  }
};

export default function*() {
  yield takeEvery(WEB_SOCKET_MESSAGE_RECEIVED, handleWebsocket);
}
