import React from "react";
import {
  Show,
  List,
  Datagrid,
  Pagination,
  TextField,
  NumberField,
  useTranslate,
  BooleanField,
  useQuery,
  TopToolbar,
  EditButton,
  ShowButton,
  ReferenceField,
  ReferenceManyField,
  SingleFieldList,
} from "react-admin";
import moment from "moment";
import {
  TableContainer,
  Table,
  Toolbar,
  Typography,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Box,
  makeStyles,
  LinearProgress,
  CircularProgress,
} from "@material-ui/core";
import isEmpty from "lodash/isEmpty";

import resources from "../../config/resources";
import Title from "../customFields/Title";
import { getKey } from "../../lib/i18nUtils";
import paginationOptions from "../../config/PaginationOptions";
import ExpansionDatagridBody from "../ExpansionDatagrid/ExpansionDatagridBody";
import ReferenceManyMapper from "../ReferenceManyMapper";

const useStyles = makeStyles(theme => ({
  toolbar: {
    justifyContent: "flex-start",
    padding: theme.spacing(),
  },
}));

const CustomActions = ({ hasEdit, basePath, data }) => {
  const translate = useTranslate();
  const classes = useStyles();
  return (
    <TopToolbar className={classes.toolbar}>
      <Box display="flex" flexGrow={1}>
        <Typography variant="h5">
          {translate(getKey("availableCompartments", resources.WAREHOUSE_LOCATIONS))}
        </Typography>
      </Box>
      {hasEdit && <EditButton basePath={basePath} record={data} />}
    </TopToolbar>
  );
};

const CustomShowShipmentButton = ({ model }) => {
  const { data, loading } = useQuery({
    type: "HAL_REFERENCE",
    resource: resources.SHIPMENTS,
    payload: { link: model?.shipment_S },
  });

  return loading ? (
    <CircularProgress thickness={3} size={24} />
  ) : (
    <ShowButton basePath={"/" + resources.SHIPMENTS} record={data?.[0]} />
  );
};

const warehouseCompartmentModelsCells = [
  "rapidshopId",
  "materialName",
  "amount",
  "contractorName",
  "dateOrdered",
  "methodName",
];

const WarehouseCompartmentPanel = ({ record }) => {
  const translate = useTranslate();

  const { data, loading } = useQuery({
    type: "HAL_REFERENCE",
    resource: resources.MODELS,
    payload: { link: record?.models_S },
  });

  return (
    <TableContainer>
      <Toolbar>
        <Typography variant="h6">{translate(getKey("assignedModels", resources.WAREHOUSE_COMPARTMENTS))}</Typography>
      </Toolbar>
      {loading ? (
        <LinearProgress />
      ) : isEmpty(data) ? (
        <Typography align="center" variant="h6" paragraph>
          {translate(getKey("noAssignedModels", resources.WAREHOUSE_COMPARTMENTS))}
        </Typography>
      ) : (
        <Table size="small">
          <TableHead>
            <TableRow>
              {warehouseCompartmentModelsCells.map((col, i) => (
                <TableCell align={i === 0 ? "left" : "right"} key={`head-cell-${i}`}>
                  {translate(getKey(col, resources.WAREHOUSE_COMPARTMENTS))}
                </TableCell>
              ))}
              <TableCell align="right">{translate(getKey("job", resources.WAREHOUSE_COMPARTMENTS))}</TableCell>
              <TableCell align="right">{translate(getKey("shipment", resources.WAREHOUSE_COMPARTMENTS))}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Array.isArray(data) &&
              data.map(model => (
                <TableRow key={model.id}>
                  {warehouseCompartmentModelsCells.map((col, i) => (
                    <TableCell align={i === 0 ? "left" : "right"} key={`body-cell-${model.id}-${i}`}>
                      {typeof model[col] === "number" || !moment(model[col]).isValid()
                        ? model[col]
                        : moment(model[col]).format(translate("format.date.dateFormat"))}
                    </TableCell>
                  ))}
                  <TableCell align="right">
                    <ReferenceField
                      resource={resources.MODELS}
                      basePath={"/" + resources.WAREHOUSE_LOCATIONS}
                      record={model}
                      reference={resources.JOBS}
                      source="job"
                      link={false}
                    >
                      <TextField source="externalId" />
                    </ReferenceField>
                  </TableCell>
                  <TableCell align="right">
                    <CustomShowShipmentButton model={model} />
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      )}
    </TableContainer>
  );
};

const CustomWarehouseLocationShow = ({ record }) => {
  const translate = useTranslate();

  return (
    <List
      bulkActionButtons={false}
      actions={false}
      resource={resources.WAREHOUSE_COMPARTMENTS}
      basePath={resources.WAREHOUSE_LOCATIONS}
      pagination={<Pagination {...paginationOptions} />}
      title=" "
      filter={{ "warehouseLocation.id": record?.__id }}
      hasCreate={false}
      hasShow={false}
      hasList={false}
      hasEdit={false}
      sort={{ field: "id", order: "asc" }}
    >
      <Datagrid rowClick="expand" body={<ExpansionDatagridBody />} expand={<WarehouseCompartmentPanel />}>
        <TextField source="name" label={translate(getKey("name", resources.WAREHOUSE_COMPARTMENTS))} />
        <NumberField source="number" label={translate(getKey("number", resources.WAREHOUSE_COMPARTMENTS))} />
        <ReferenceManyField
          label={translate(getKey("shipment", resources.WAREHOUSE_COMPARTMENTS))}
          reference={resources.SHIPMENTS}
          target="models#warehouseCompartment#id"
          sortable={false}
        >
          <SingleFieldList linkType={false}>
            <TextField source="orderRapidshopId" />
          </SingleFieldList>
        </ReferenceManyField>
        <ReferenceManyField
          label={translate(getKey("modelAmount", resources.WAREHOUSE_COMPARTMENTS))}
          reference={resources.MODELS}
          target="warehouseCompartment#id"
          sortable={false}
        >
          <ReferenceManyMapper>{({ ids }) => ids?.length ?? null}</ReferenceManyMapper>
        </ReferenceManyField>
        <BooleanField source="new" label={translate(getKey("new", resources.WAREHOUSE_COMPARTMENTS))} />
      </Datagrid>
    </List>
  );
};

const WarehouseLocationShow = props => {
  return (
    <Show {...props} actions={<CustomActions />} title={<Title resource={resources.WAREHOUSE_LOCATIONS} />}>
      <CustomWarehouseLocationShow />
    </Show>
  );
};

export default WarehouseLocationShow;
