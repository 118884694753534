import createAction from "../../lib/createAction";

export const RESTART_REQUEST = "RESTART_REQUEST";
export const restart = createAction(RESTART_REQUEST);

export const RESTART_SUCCESS = "RESTART_SUCCESS";
export const restartSuccess = createAction(RESTART_SUCCESS, "response");

export const RESTART_ERROR = "RESTART_ERROR";
export const restartError = createAction(RESTART_ERROR, "error");
