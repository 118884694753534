import createReducer, { mergeReducers } from "../../../lib/createReducer";
import { ACTION_ON_MODEL, ACTION_ON_MODEL_SUCCESS, ACTION_ON_MODEL_ERROR } from "./action";

const init = {
  modelId: null,
  action: null,
  body: null,
  qs: null,
  subpath: null,

  isFetching: false,
  fetchSuccess: false,
  hasError: false,
  payload: null,
  error: null,
};

const requestReducer = createReducer(
  ACTION_ON_MODEL,
  ({ modelId, action, body, qs, subpath }) => ({
    ...init,
    modelId,
    action,
    body,
    qs,
    subpath,
    isFetching: true,
  }),
  true
);

const successReducer = createReducer(ACTION_ON_MODEL_SUCCESS, ({ response }) => ({
  isFetching: false,
  fetchSuccess: true,
  payload: response,
}));

const errorReducer = createReducer(ACTION_ON_MODEL_ERROR, ({ error }) => ({
  isFetching: false,
  hasError: true,
  error,
}));

export default mergeReducers([requestReducer, successReducer, errorReducer], init);
