import React from "react";
import { Edit } from "react-admin";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import DocumentTypeForm from "./DocumentTypeForm";
import Title from "../customFields/Title";
import resources from "../../config/resources";

const styles = () => ({
  root: {
    display: "flex",
    justifyContent: "center",
  },
});

const DocumentTypeEdit = ({ classes, ...props }) => (
  <div className={classes.root}>
    <Edit title={<Title resource={resources.DOCUMENT_TYPES} />} undoable={false} {...props}>
      <DocumentTypeForm />
    </Edit>
  </div>
);

DocumentTypeEdit.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DocumentTypeEdit);
