import React, { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import Card from "@material-ui/core/Card";
import Avatar from "@material-ui/core/Avatar";
import { createMuiTheme, createStyles, MuiThemeProvider, withStyles } from "@material-ui/core/styles";
import LockIcon from "@material-ui/icons/Lock";
import theme from "../../theme/theme";
import backgroundImage from "../../assets/login.jpg";
import LoginForm from "./LoginForm";
import { Notification } from "react-admin";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import { isBrowserSupported, isBrowserRecommended } from "../../lib/browserSupport";
import { translate } from "react-admin";
import yellow from "@material-ui/core/colors/yellow";
import grey from "@material-ui/core/colors/grey";
import red from "@material-ui/core/colors/red";
import { connect } from "react-redux";
import { hasPingError } from "../../redux/ping/selector";
import { fetchPing } from "../../redux/ping/action";

const styles = theme =>
  createStyles({
    main: {
      display: "flex",
      flexDirection: "column",
      minHeight: "100vh",
      height: "1px",
      alignItems: "center",
      justifyContent: "flex-start",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
    },
    card: {
      minWidth: 300,
      marginTop: "6em",
    },
    avatar: {
      margin: "1em",
      display: "flex",
      justifyContent: "center",
    },
    icon: {
      backgroundColor: theme.palette.secondary[500],
    },
  });

const sanitizeRestProps = ({
  array,
  backgroundImage,
  classes,
  className,
  location,
  staticContext,
  theme,
  title,
  pingHasError,
  fetchPing,
  ...rest
}) => rest;

class Login extends Component {
  constructor(props) {
    super(props);
    this.theme = createMuiTheme(props.theme);
    this.containerRef = React.createRef();
    this.backgroundImageLoaded = false;
  }

  // Even though the React doc ensure the ref creation is done before the
  // componentDidMount, it can happen that the ref is set to null until the
  // next render.
  // So, to handle this case the component will now try to load the image on
  // the componentDidMount, but if the ref doesn't exist, it will try again
  // on the following componentDidUpdate. The try will be done only once.
  // @see https://reactjs.org/docs/refs-and-the-dom.html#adding-a-ref-to-a-dom-element
  updateBackgroundImage = (lastTry = false) => {
    if (!this.backgroundImageLoaded && this.containerRef.current) {
      const { backgroundImage } = this.props;
      this.containerRef.current.style.backgroundImage = `url(${backgroundImage})`;
      this.backgroundImageLoaded = true;
    }

    if (lastTry) {
      this.backgroundImageLoaded = true;
    }
  };

  // Load background image asynchronously to speed up time to interactive
  lazyLoadBackgroundImage() {
    const { backgroundImage } = this.props;

    if (backgroundImage) {
      const img = new Image();
      img.onload = this.updateBackgroundImage;
      img.src = backgroundImage;
    }
  }

  componentDidMount() {
    const { fetchPing } = this.props;
    fetchPing();
    this.lazyLoadBackgroundImage();
  }

  componentDidUpdate() {
    if (!this.backgroundImageLoaded) {
      this.lazyLoadBackgroundImage(true);
    }
  }

  render() {
    const { classes, className, loginForm, location, translate, pingHasError, ...rest } = this.props;

    return (
      <MuiThemeProvider theme={this.theme}>
        <div className={classnames(classes.main, className)} {...sanitizeRestProps(rest)} ref={this.containerRef}>
          <Card className={classes.card}>
            <div className={classes.avatar}>
              <Avatar className={classes.icon}>
                <LockIcon />
              </Avatar>
            </div>
            <LoginForm location={location} />
            {pingHasError && (
              <SnackbarContent
                message={translate("pingErrorLogin")}
                style={{
                  backgroundColor: red[800],
                  color: "#FFF",
                  maxWidth: 300,
                  textAlign: "center",
                  fontWeight: 700,
                }}
              />
            )}
            {(!isBrowserSupported || !isBrowserRecommended) && (
              <SnackbarContent
                message={
                  !isBrowserSupported ? translate("browserNotSupportedBox") : translate("browserNotRecommendedBox")
                }
                style={{
                  backgroundColor: !isBrowserSupported ? yellow[900] : grey[400],
                  maxWidth: 300,
                  textAlign: "center",
                }}
              />
            )}
          </Card>

          <Notification />
        </div>
      </MuiThemeProvider>
    );
  }
}

Login.propTypes = {
  authProvider: PropTypes.object,
  backgroundImage: PropTypes.string,
  classes: PropTypes.object,
  className: PropTypes.string,
  input: PropTypes.object,
  loginForm: PropTypes.element,
  meta: PropTypes.object,
  previousRoute: PropTypes.string,
};

Login.defaultProps = {
  backgroundImage,
  theme,
  loginForm: <LoginForm />,
};

export default connect(
  state => ({
    pingHasError: hasPingError(state),
  }),
  {
    fetchPing,
  }
)(withStyles(styles)(translate(Login)));
