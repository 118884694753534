import React from "react";
import PropTypes from "prop-types";
import {
  FunctionField,
  Labeled,
  NumberField,
  ReferenceField,
  Show,
  SimpleShowLayout,
  TextField,
  translate,
} from "react-admin";
import { withStyles } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";
import DateField from "../customFields/DateField";
import resources from "../../config/resources";
import { highlight } from "../../lib/codeFormatting";
import FormulaParameters from "../../config/FormulaParameters";
import AutoAssignLogStatusField from "./AutoAssignLogStatusField";
import PriceField from "../customFields/PriceField";
import Title from "../customFields/Title";

const styles = () => ({
  root: {
    display: "flex",
    justifyContent: "center",
  },
});

const AutoAssignLogsShow = ({ translate, classes, ...props }) => (
  <div className={classes.root}>
    <Show title={<Title resource={resources.AUTO_ASSIGN_LOGS} />} {...props}>
      <SimpleShowLayout>
        <NumberField source="__id" />
        <DateField source="created" displayFormat="DD. MMMM YYYY HH:mm:ss" />
        <Labeled {...props} source="status">
          <AutoAssignLogStatusField source="status" />
        </Labeled>
        <ReferenceField source="formula" reference={resources.FORMULAS} link={false}>
          <FunctionField
            source="formulaStr"
            render={({ formulaStr = "" }) => {
              const highlighted = highlight({ FormulaParameters })(formulaStr);
              const html = highlighted.replace(/\n/g, "<br/>");
              return <span dangerouslySetInnerHTML={{ __html: html }} />;
            }}
            style={{
              fontFamily: '"Fira code", "Fira Mono", monospace',
              backgroundColor: "#202428",
              color: "#FFFFFF",
              lineHeight: 1,
              width: "100%",
            }}
          />
        </ReferenceField>
        <NumberField source="formulaVersion" />
        <ReferenceField source="priceEstimate" reference={resources.PRICE_ESTIMATES} link={false} allowEmpty>
          <FunctionField
            render={record =>
              record ? <PriceField record={record} currencySource="priceCurrency" valueSource="priceValue" /> : null
            }
          />
        </ReferenceField>
        <ReferenceField source="model" reference={resources.MODELS} link={false}>
          <TextField source="rapidshopId" />
        </ReferenceField>
        <ReferenceField source="model" reference={resources.MODELS} link={false}>
          <ReferenceField source="shipment" reference={resources.SHIPMENTS} link={false}>
            <TextField source="rapidshopId" />
          </ReferenceField>
        </ReferenceField>
        <ReferenceField source="job" reference={resources.JOBS} allowEmpty={true}>
          <FunctionField render={({ externalId }) => (externalId ? <Chip label={externalId} /> : null)} />
        </ReferenceField>
        <ReferenceField source="contractor" reference={resources.CONTRACTORS} link={false} allowEmpty={true}>
          <TextField source="contractorName" />
        </ReferenceField>
        <TextField source="message" component="pre" />
      </SimpleShowLayout>
    </Show>
  </div>
);

AutoAssignLogsShow.propTypes = {
  translate: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(translate(AutoAssignLogsShow));
