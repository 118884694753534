import React, { useEffect } from "react";
import { Box } from "@material-ui/core";
import { useTranslate } from "react-admin";
import { useForm, useFormState } from "react-final-form";
import moment from "moment";

import resources from "../../config/resources";
import { getKey } from "../../lib/i18nUtils";
import CustomDateInput from "../CustomDateInput";

const CustomDateInputs = ({ onBuildUnitCompleteChange, classes, ...props }) => {
  const translate = useTranslate();
  const { printedAt, printDuration, coolingDuration } = useFormState()?.values || {};
  const form = useForm();

  useEffect(() => {
    if (printedAt && printDuration) {
      form.change("completedAt", moment(printedAt).add(printDuration, "hours"));
    }
  }, [printedAt, printDuration]);

  useEffect(() => {
    if (printedAt && printDuration && coolingDuration) {
      const total = moment(printedAt).add(parseFloat(printDuration) + parseFloat(coolingDuration), "hours");
      form.change("buildUnitCompletedAt", total);
    }
  }, [printedAt, printDuration, coolingDuration]);

  return (
    <>
      <Box className={classes.horizontalInputsContainer}>
        <CustomDateInput
          {...props}
          source="packedAt"
          className={classes.printJobInput}
          label={translate(getKey("packedAt", resources.PRINT_JOBS))}
          initialValue={new Date()}
        />
        <CustomDateInput
          {...props}
          dateTime
          source="printedAt"
          className={classes.printJobInput}
          label={translate(getKey("printedAt", resources.PRINT_JOBS))}
          initialValue={new Date()}
        />
      </Box>
      <Box className={classes.horizontalInputsContainer}>
        <CustomDateInput
          {...props}
          dateTime
          source="completedAt"
          label={translate(getKey("completedAt", resources.PRINT_JOBS))}
          className={classes.printJobInput}
        />
        <CustomDateInput
          {...props}
          dateTime
          source="buildUnitCompletedAt"
          label={translate(getKey("buildUnitCompletedAt", resources.PRINT_JOBS))}
          className={classes.printJobInput}
          onChange={onBuildUnitCompleteChange}
        />
      </Box>
    </>
  );
};

export default CustomDateInputs;
