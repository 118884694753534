import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import {
  ArrayInput,
  BooleanInput,
  email,
  FormDataConsumer,
  maxLength,
  minLength,
  minValue,
  NullableBooleanInput,
  number,
  NumberInput,
  ReferenceInput,
  required,
  SelectInput,
  SimpleFormIterator,
  TextInput,
  translate,
} from "react-admin";
import blue from "@material-ui/core/colors/lightBlue";

import { getKey } from "../../lib/i18nUtils";
import resources from "../../config/resources";
import ContractorTypes from "../../config/ContractorTypes";
import CustomForm, { EmailTemplatesInput } from "./CustomFormComponents";

const styles = () => ({
  root: {
    width: "40vw",
  },
});

/**
 * Caution!
 * We use our own <SimpleForm/> implementation here to support our custom TemplatesInput, see ./CustomFormComponents
 */

/**
 * All necessary input validations
 */
const nameValidation = [required(), minLength(3), maxLength(20)];
const emailListValidation = (values, all, props) => {
  if (values === null || values === undefined || !Array.isArray(values)) return "ra.validation.required";

  if (values.length < 1) return "ra.validation.required";

  for (const it of values) {
    const mailValid = email()(it, all, props);
    if (mailValid) return mailValid;
  }
};
const emailValidation = email();
const typeValidation = [required()];
const autoOrderTimeValidation = [number(), minValue(-1)];
const needsQualityControlValidation = [required()];

/**
 * --------------------------------
 * The Main Form for the Contractors Create and Edit
 * --------------------------------
 *
 * Renders our Customized Form (see above)
 * With all necessary react admin inputs + our custom EmailTemplatesInput
 *
 */
const ContractorForm = ({ translate, classes, ...props }) => (
  <CustomForm {...props} classes={classes}>
    <TextInput source="contractorName" fullWidth={true} validate={nameValidation} />
    <ArrayInput source="emailAddress" fullWidth={true} validate={emailListValidation}>
      <SimpleFormIterator>
        <TextInput
          fullWidth={true}
          validate={emailValidation}
          label={translate(getKey("emailAddress", resources.CONTRACTORS))}
          defaultValue={() => ""}
          format={v => (typeof v === "string" ? v : "")}
        />
      </SimpleFormIterator>
    </ArrayInput>
    <SelectInput
      source="contractorType"
      choices={Object.values(ContractorTypes)}
      optionValue="key"
      optionText={({ translation }) => translate(translation)}
      fullWidth={true}
      validate={typeValidation}
    />
    <EmailTemplatesInput />
    <NullableBooleanInput source="needsQualityControl" fullWidth={true} validate={needsQualityControlValidation} />
    <BooleanInput source="forceOrderConfirmation" />
    <BooleanInput source="checkOrderConfirmation" />
    <NumberInput
      source="autoOrderTime"
      fullWidth={true}
      validate={autoOrderTimeValidation}
      helperText={translate("autoOrderTime.helperText")}
    />
    <FormDataConsumer>
      {({ formData }) => (
        <div
          style={{
            padding: 16,
            boxSizing: "border-box",
            minWidth: "100%",
            width: 300,
            backgroundColor: blue[100],
            color: "#012332",
            borderRadius: 4,
            border: `solid 3px ${blue[500]}`,
            marginTop: 16,
          }}
        >
          {formData.autoOrderTime === null || formData.autoOrderTime === ""
            ? translate("autoOrderTime.valueGlobal")
            : formData.autoOrderTime < 0
            ? translate("autoOrderTime.valueOff")
            : translate("autoOrderTime.valueFixed", {
                smart_count: formData.autoOrderTime,
              })}
        </div>
      )}
    </FormDataConsumer>
    <ReferenceInput source="autoAssignUser" reference={resources.USERS} allowEmpty>
      <SelectInput optionText="username" optionValue="id" />
    </ReferenceInput>
    <BooleanInput source="materialPartition" />
  </CustomForm>
);

ContractorForm.propTypes = {
  translate: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(translate(ContractorForm));
