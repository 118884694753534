import React from "react";
import Button from "@material-ui/core/Button";
import GetAppIcon from "@material-ui/icons/GetApp";
import { useTranslate } from "react-admin";
import { getKey } from "../../lib/i18nUtils";
import resources from "../../config/resources";
import moment from "moment";

const AuditLogButton = ({ record }) => {
  const translate = useTranslate();

  if (!record.auditLogUrl) {
    return null;
  }

  const label =
    translate(getKey("auditLog", resources.ARCHIVED_ORDERS)) +
    (record.inactiveSince ? ` (${moment(record.inactiveSince).format("DD.MM.YYYY HH:mm")})` : "");

  const dlProps = {
    href: record.auditLogUrl,
    target: record.auditLogUrl,
    download: true,
  };

  return (
    <a style={{ textDecoration: "none" }} {...dlProps}>
      <Button variant="contained" color="default" startIcon={<GetAppIcon />}>
        {label}
      </Button>
    </a>
  );
};

export default AuditLogButton;
