import React from "react";
import { Edit, NumberInput, SaveButton, SelectInput, SimpleForm, TextInput, Toolbar } from "react-admin";
import get from "lodash/get";

const GenericInput = props => {
  const type = get(props, "record.targetType");
  switch (type) {
    case "java.lang.Boolean":
      return (
        <SelectInput
          {...props}
          choices={[{ value: "true" }, { value: "false" }]}
          optionValue="value"
          optionText="value"
        />
      );
    case "java.lang.Integer":
    case "java.lang.Long":
      return <NumberInput {...props} />;
    default:
      return <TextInput {...props} />;
  }
};

const CustomToolbar = props => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);

const PropertiesEdit = ({ translate, ...props }) => (
  <div style={{ display: "flex", justifyContent: "center" }}>
    <Edit undoable={false} {...props} style={{ width: "700px", maxWidth: "calc(100% - 80px)" }}>
      <SimpleForm
        initialValues={{ enabled: true }}
        toolbar={<CustomToolbar />}
        redirect="list"
        variant="standard"
        margin="normal"
      >
        <TextInput source="key" fullWidth={true} disabled />
        <TextInput source="targetType" fullWidth={true} disabled />
        <GenericInput source="value" fullWidth={true} />
        <TextInput source="currentEnvValue" fullWidth={true} disabled />
      </SimpleForm>
    </Edit>
  </div>
);

export default PropertiesEdit;
