import { END, eventChannel } from "redux-saga";
import { call, put, take, takeEvery } from "redux-saga/effects";
import config from "../../config";
import {
  DESTROY_NOTIFICATION_TIMER,
  destroyNotificationTimerSuccess,
  INIT_NOTIFICATION_TIMER,
  initNotificationTimerSuccess,
  notificationTimerTick,
  pushNotification,
  windowFocused,
  windowFocusLost,
} from "./action";
import { WEB_SOCKET_MESSAGE_RECEIVED } from "../websocket/action";
import TOPICS from "../../config/WebSocketTopics";

function TimerSocket(timeout) {
  this.tickSubscribers = [];
  this.endSubscribers = [];
  this.interval = setInterval(() => this.tickSubscribers.forEach(it => it()), timeout);
  this.onTick = subscriber => {
    if (typeof subscriber === "function") this.tickSubscribers.push(subscriber);
  };
  this.onEnd = subscriber => {
    if (typeof subscriber === "function") this.endSubscribers.push(subscriber);
  };
  this.end = () => {
    if (!this.isRunning()) return;
    clearInterval(this.interval);
    this.interval = null;
    this.endSubscribers.forEach(it => it());
  };
  this.isRunning = () => this.interval !== null;
  return this;
}

const subscribeTimer = timer =>
  new eventChannel(emit => {
    timer.onTick(() => emit(notificationTimerTick()));
    timer.onEnd(() => emit(END));
    return timer.end;
  });

const subscribeWindow = () =>
  new eventChannel(emit => {
    let visibilityState, visibilityChange;
    if (typeof document.hidden !== "undefined") {
      visibilityChange = "visibilitychange";
      visibilityState = "visibilityState";
    } else if (typeof document.mozHidden !== "undefined") {
      visibilityChange = "mozvisibilitychange";
      visibilityState = "mozVisibilityState";
    } else if (typeof document.msHidden !== "undefined") {
      visibilityChange = "msvisibilitychange";
      visibilityState = "msVisibilityState";
    } else if (typeof document.webkitHidden !== "undefined") {
      visibilityChange = "webkitvisibilitychange";
      visibilityState = "webkitVisibilityState";
    }

    const listener = () => {
      switch (document[visibilityState]) {
        case "visible":
          emit(windowFocused());
          break;
        case "hidden":
          emit(windowFocusLost());
          break;
        default:
          return;
      }
    };

    document.addEventListener(visibilityChange, listener);
    document.addEventListener("focus", listener);

    return () => {
      document.removeEventListener(visibilityChange, listener);
      document.removeEventListener("focus", listener);
    };
  });

const handleWebsocketSaga = function*(action = {}) {
  const { topic = "", body } = action.payload || {};
  if (topic.endsWith(TOPICS.NOTIFICATIONS)) {
    yield put(pushNotification(body));
  }
};

const initSaga = function*() {
  yield takeEvery(WEB_SOCKET_MESSAGE_RECEIVED, handleWebsocketSaga);

  const timer = new TimerSocket(config.notificationInterval);
  yield put(initNotificationTimerSuccess(timer.interval));

  const timerChannel = yield call(subscribeTimer, timer);
  yield put(notificationTimerTick());
  yield takeEvery(timerChannel, function*(action) {
    yield put(action);
  });

  const windowChannel = yield call(subscribeWindow);
  yield takeEvery(windowChannel, function*(action) {
    yield put(action);
  });

  yield take(DESTROY_NOTIFICATION_TIMER);
  yield call(timer.end);
  yield put(destroyNotificationTimerSuccess());
};

export const timerSetupSaga = function*() {
  yield takeEvery(INIT_NOTIFICATION_TIMER, initSaga);
};
