import React, { useEffect, useState } from "react";
import { Datagrid, List, Pagination, TextField, useTranslate } from "react-admin";
import { Chat } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core";

import JobPanel from "../jobs/JobPanel";
import JobFilters from "../jobs/JobFilters";
import { getKey } from "../../lib/i18nUtils";
import resources from "../../config/resources";
import paginationOptions from "../../config/PaginationOptions";
import DateField from "../customFields/DateField";
import MultiStatusField from "../customFields/MultiStatusField";
import ExpansionDatagridBody from "../ExpansionDatagrid/ExpansionDatagridBody";
import AssignUserButton from "../customFields/buttons/AssignUserButton";
import CommentCountField from "../customFields/CommentCountField";
import PriceField from "../customFields/PriceField";
import CustomButtonWithModals from "./CustomButtonWithModals";
import DownloadButton from "../customFields/buttons/DownloadButton";

const useStyles = makeStyles(theme => ({
  listMain: {
    marginTop: theme.spacing(6),
  },
  listDialog: {
    padding: theme.spacing(5),
  },
}));

const PostBulkActionButtons = ({ selectedIds }) => {
  const translate = useTranslate();
  const [ids, setIds] = useState([]);

  useEffect(() => {
    const ids = selectedIds.map(selectedId => {
      return parseInt(selectedId.split("/")[2]);
    });
    setIds(ids);
  }, [selectedIds]);

  return (
    <>
      <CustomButtonWithModals
        label={translate(getKey("handoverEdit", resources.POSTPROCESSING_JOB))}
        selectedIds={ids}
        propToUpdate="handover"
      />
      <CustomButtonWithModals
        label={translate(getKey("endEdit", resources.POSTPROCESSING_JOB))}
        selectedIds={ids}
        propToUpdate="end"
      />
    </>
  );
};

const ModelDescriptionDownload = ({ record: { __id } }) => {
  const translate = useTranslate();

  return (
    <DownloadButton
      id={__id}
      label={translate(getKey("modelDescription", resources.POSTPROCESSING_JOB))}
      downloadPath="/pdf/modelDescription"
      fileName={translate(getKey("modelDescriptionFileName", resources.POSTPROCESSING_JOB), { id: __id })}
    />
  );
};

const ExternalAdditionOrderDownload = ({ record: { __id } }) => {
  const translate = useTranslate();

  return (
    <DownloadButton
      id={__id}
      label={translate(getKey("externalAdditionOrder", resources.POSTPROCESSING_JOB))}
      downloadPath="/pdf/externalAdditionOrder"
      fileName={translate(getKey("externalAdditionOrderFileName", resources.POSTPROCESSING_JOB), { id: __id })}
    />
  );
};

const PostProcessingJobsList = ({ permissions, ...props }) => {
  const translate = useTranslate();
  const classes = useStyles();

  return (
    <List
      {...props}
      resource={resources.JOBS}
      bulkActionButtons={<PostBulkActionButtons />}
      actions={null}
      filters={<JobFilters permissions={permissions} />}
      filter={{
        active: "true",
        hasActiveModels: "true",
        status: ["NEW", "OFFER_SENT", "NEEDS_UPDATE", "IN_PRODUCTION", "IN_TRANSPORT"],
        postProcessingJobExists: "true",
        additionAmount: [">=", "1"],
      }}
      pagination={<Pagination {...paginationOptions} />}
      sort={{ field: "id", order: "DESC" }}
      classes={{ main: classes.listMain }}
    >
      <Datagrid expand={<JobPanel permissions={permissions} />} body={<ExpansionDatagridBody />} {...props}>
        <TextField source="externalId" sortBy="id" />
        <MultiStatusField source="status" useChip={true} />
        <TextField label="Anz. Teile" textAlign="right" />
        <CommentCountField source="commentsCount" label={<Chat fontSize="small" />} />
        <TextField source="modelAmount" label={translate(getKey("models", resources.JOBS))} textAlign="right" />

        <PriceField label={translate(getKey("priceValue", resources.JOBS))} textAlign="right" source="price" />
        <DateField source="dateEstimate" textAlign="right" />
        <TextField label={translate(getKey("sizeCategory", resources.JOBS))} textAlign="right" />

        <AssignUserButton size={30} permissions={permissions} source="assignee" textAlign="right" />
        <ModelDescriptionDownload />
        <ExternalAdditionOrderDownload />
      </Datagrid>
    </List>
  );
};

export default PostProcessingJobsList;
