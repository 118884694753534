import {
  UPLOAD_FILE_VERSION_ERROR,
  UPLOAD_FILE_VERSION_PROGRESS,
  UPLOAD_FILE_VERSION_REQUEST,
  UPLOAD_FILE_VERSION_SUCCESS,
} from "./action";

const init = {
  file: null,
  isUploading: false,
  progress: 0.0,
  uploaded: false,
  error: null,
  uploadResponse: null,
};

export default (state = init, { type, payload = {} } = {}) => {
  if (!payload) return state;

  const { file, progress, response, error } = payload;

  if (type === UPLOAD_FILE_VERSION_REQUEST) {
    if (state.isUploading) {
      console.error(
        `Action "${UPLOAD_FILE_VERSION_REQUEST} doesn't support parallel operation, but was dispatched again while still running!`
      );
      return state;
    }

    return {
      ...init,
      file: file,
      isUploading: true,
      progress: 0.0,
    };
  }
  if (type === UPLOAD_FILE_VERSION_PROGRESS) {
    return {
      ...state,
      progress,
    };
  }
  if (type === UPLOAD_FILE_VERSION_SUCCESS) {
    return {
      ...state,
      isUploading: false,
      progress: 1.0,
      uploaded: true,
      uploadResponse: response,
    };
  }
  if (type === UPLOAD_FILE_VERSION_ERROR) {
    return {
      ...state,
      isUploading: false,
      error,
    };
  }

  return state;
};
