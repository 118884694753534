import React from "react";
import PropTypes from "prop-types";
import { Filter, ReferenceArrayInput, TextInput, translate } from "react-admin";
import { withStyles } from "@material-ui/core/styles";
import resources from "../../config/resources";
import SelectLongArrayInput from "../../SelectLongArrayInput";
import { getKey } from "../../lib/i18nUtils";

const styles = theme => ({
  container: {
    alignItems: "center",
    display: "grid !important",
    width: "100%",

    gridTemplateColumns: "repeat(18, 1fr)",
    [theme.breakpoints.down("md")]: {
      gridTemplateColumns: "repeat(9, 1fr)",
    },
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "repeat(6, 1fr)",
    },
    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "repeat(3, 1fr)",
    },
    gridColumnGap: `${theme.spacing(1)}px`,

    "& > *": {
      gridColumnEnd: "span 3",
    },
  },
  autocompleteContainer: {
    width: "100%",
    marginBottom: theme.spacing(1),
    "& > *": {
      width: "100%",
    },
  },
});

const FilterGraphListFilters = ({ translate, classes, ...props }) => (
  <Filter {...props} classes={{ form: classes.container }}>
    <TextInput source="id" fullWidth resettable label={getKey("id", resources.FILTER_GRAPHS)} disabled />
    <TextInput source="name" alwaysOn fullWidth resettable label={getKey("name", resources.FILTER_GRAPHS)} />
    <SelectLongArrayInput
      source="filteredEntity"
      label={translate(getKey("filteredEntity", resources.FILTER_GRAPHS))}
      choices={Object.values(resources).map(it => ({ resource: it }))}
      optionValue="resource"
      optionText={({ resource } = {}) => translate(`resources.${resource}.name`, { smart_count: 1 })}
      translateChoice={false}
      fullWidth
      alwaysOn
      resettable
    />
    <ReferenceArrayInput
      source="owner"
      label={translate(getKey("owner", resources.FILTER_GRAPHS))}
      reference={resources.USER_DETAILS}
      sort={{ field: "displayName", order: "ASC" }}
      perPage={100}
      alwaysOn
      fullWidth
    >
      <SelectLongArrayInput
        optionValue="id"
        optionText={({ displayName } = {}) => displayName || ""}
        fullWidth
        options={{ fullWidth: true }}
        classes={{ container: classes.autocompleteContainer }}
        translateChoice={false}
        resettable
      />
    </ReferenceArrayInput>
  </Filter>
);

FilterGraphListFilters.propTypes = {
  classes: PropTypes.object.isRequired,
  translate: PropTypes.func.isRequired,
};

export default withStyles(styles)(translate(FilterGraphListFilters));
