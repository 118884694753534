import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Edit } from "react-admin";
import ContractorForm from "./ContractorForm";
import Title from "../customFields/Title";
import resources from "../../config/resources";

const styles = () => ({
  root: {
    display: "flex",
    justifyContent: "center",
  },
});

const ContractorEdit = ({ classes, ...props }) => (
  <div className={classes.root}>
    <Edit undoable={false} title={<Title resource={resources.CONTRACTORS} />} {...props}>
      <ContractorForm />
    </Edit>
  </div>
);

ContractorEdit.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ContractorEdit);
