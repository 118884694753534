import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { translate, TextInput, SelectArrayInput } from "react-admin";
import ContractorTypes from "../../config/ContractorTypes";
import resources from "../../config/resources";
import { getKey } from "../../lib/i18nUtils";
import CustomFilter from "../CustomFilter";

const styles = theme => ({
  container: {
    display: "grid !important",
    width: "100%",

    gridTemplateColumns: "repeat(18, 1fr)",
    [theme.breakpoints.down("md")]: {
      gridTemplateColumns: "repeat(9, 1fr)",
    },
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "repeat(6, 1fr)",
    },
    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "repeat(3, 1fr)",
    },
    gridColumnGap: `${theme.spacing(1)}px`,

    "& > *": {
      gridColumnEnd: "span 3",
    },
  },
});

const ContractorFilters = ({ translate, classes, ...props }) => (
  <CustomFilter {...props} className={classes.container}>
    <TextInput source="contractorName" alwaysOn fullWidth resettable />
    <TextInput source="emailAddressStr" alwaysOn fullWidth resettable />
    <SelectArrayInput
      source="contractorType"
      label={translate(getKey("contractorType", resources.CONTRACTORS))}
      choices={Object.values(ContractorTypes)}
      optionText={({ translation }) => translate(translation)}
      optionValue="key"
      translateChoice={false}
      fullWidth
      alwaysOn
    />
  </CustomFilter>
);

export default withStyles(styles)(translate(ContractorFilters));
