import React from "react";
import WarningIcon from "@material-ui/icons/Warning";
import Tooltip from "@material-ui/core/Tooltip";

const OrderWarnings = ({ record, translate }) => {
  const style = {
    "padding-right": "1rem",
    "line-height": "1rem",
    margin: "1rem",
  };

  let warnings = [];

  if (record.fileFormatFailure) {
    warnings.push(<li style={style}>{translate("modelFailure.fileFormatFailure")}</li>);
  }
  if (record.shellCountFailure) {
    warnings.push(<li style={style}>{translate("modelFailure.shellCountFailure")}</li>);
  }
  if (record.modelSizeTooLargeFailure) {
    warnings.push(<li style={style}>{translate("modelFailure.modelSizeTooLargeFailure")}</li>);
  }
  if (record.modelSizeTooSmallFailure) {
    warnings.push(<li style={style}>{translate("modelFailure.modelSizeTooSmallFailure")}</li>);
  }
  if (record.wallThicknessFailure) {
    warnings.push(<li style={style}>{translate("modelFailure.wallThicknessFailure")}</li>);
  }
  if (record.wallThicknessCheckTimeoutFailure) {
    warnings.push(<li style={style}>{translate("modelFailure.wallThicknessCheckTimeoutFailure")}</li>);
  }

  if (warnings.length > 0) {
    return (
      <div>
        <Tooltip title={<ul style={{ fontSize: 16 }}>{warnings}</ul>}>
          <WarningIcon style={{ color: "#ffb133" }} />
        </Tooltip>
      </div>
    );
  } else {
    return null;
  }
};

export default OrderWarnings;
