import { call, put, take, takeEvery } from "redux-saga/effects";
import {
  UPLOAD_FILE_VERSION_REQUEST,
  uploadFileVersionError,
  uploadFileVersionProgress,
  uploadFileVersionSuccess,
} from "./action";
import { createUploadFileChannel } from "../../../lib/FileUploader";
import config from "../../../config";
import { fetchFileVersionsOfFile } from "../fetchFileVersionsOfFile/action";

const uploadFileSaga = function*(action = {}) {
  const { fileUuid, token, file: plFile, meta, recordId, handleSuccess } = action.payload || {};

  let file;
  if (plFile === null || plFile === undefined) {
    yield put(uploadFileVersionError(plFile, new Error("File is null")));
    return;
  } else if (Array.isArray(plFile)) {
    if (plFile.length > 1)
      console.warn("[StorageService::uploadFileSaga] Cannot upload multiple files. Only uploading first...");

    file = plFile[0];
  } else if (plFile instanceof FileList) {
    if (plFile.length > 1)
      console.warn("[StorageService::uploadFileSaga] Cannot upload multiple files. Only uploading first...");

    file = plFile.item(0);
  } else {
    file = plFile;
  }

  const url = `${config.urls.BACKEND}/v2/models/${recordId}/upload`;

  const fileMultipart = new FormData();
  fileMultipart.append("file", file);

  const channel = yield call(createUploadFileChannel, url, fileMultipart);

  while (true) {
    const { progress = 0, error, success, payload } = yield take(channel);

    if (error) {
      const defaultAction = uploadFileVersionError(file, error);
      if (!meta || !meta.onFailure) {
        yield put(defaultAction);
      } else {
        yield put({
          ...defaultAction,
          meta: meta.onFailure,
        });
      }
      return;
    }

    if (success) {
      const responseData = !payload
        ? null
        : yield call(() => {
            try {
              return JSON.parse(payload);
            } catch (e) {
              return payload;
            }
          });

      const defaultAction = uploadFileVersionSuccess(file, responseData);
      if (!meta || !meta.onSuccess) {
        yield put(defaultAction);
      } else {
        yield put({
          ...defaultAction,
          meta: meta.onSuccess,
        });
      }
      yield put(fetchFileVersionsOfFile(fileUuid, token));

      if (handleSuccess !== null && typeof handleSuccess === "object") {
        yield put(handleSuccess);
      }

      return;
    }

    yield put(uploadFileVersionProgress(file, progress));
  }
};

export default function* uploadRequestWatcherSaga() {
  yield takeEvery(UPLOAD_FILE_VERSION_REQUEST, uploadFileSaga);
}
