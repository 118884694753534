import createAction from "../../lib/createAction";

// custom fetch for model subtasks
export const CUSTOM_FETCH_MODEL_SUBTASKS_REQUEST = "CUSTOM_FETCH_MODEL_SUBTASKS_REQUEST";
export const customFetchModelSubtasks = createAction(CUSTOM_FETCH_MODEL_SUBTASKS_REQUEST, "modelId");

export const CUSTOM_FETCH_MODEL_SUBTASKS_SUCCESS = "CUSTOM_FETCH_MODEL_SUBTASKS_SUCCESS";
export const customFetchModelSubtasksSuccess = createAction(
  CUSTOM_FETCH_MODEL_SUBTASKS_SUCCESS,
  "response",
  "modelId",
  "modelSubtaskIds"
);

export const CUSTOM_FETCH_MODEL_SUBTASKS_FAILURE = "CUSTOM_FETCH_MODEL_SUBTASKS_FAILURE";
export const customFetchModelSubtasksFailure = createAction(CUSTOM_FETCH_MODEL_SUBTASKS_FAILURE, "error");

// custom update specific subtask
export const CUSTOM_UPDATE_SUBTASK_REQUEST = "CUSTOM_UPDATE_SUBTASK_REQUEST";
export const customUpdateSubtask = createAction(CUSTOM_UPDATE_SUBTASK_REQUEST, "subtaskId", "data");

export const CUSTOM_UPDATE_SUBTASK_SUCCESS = "CUSTOM_UPDATE_SUBTASK_SUCCESS";
export const customUpdateSubtaskSuccess = createAction(CUSTOM_UPDATE_SUBTASK_SUCCESS, "subtaskId");

export const CUSTOM_UPDATE_SUBTASK_FAILURE = "CUSTOM_UPDATE_SUBTASK_FAILURE";
export const customUpdateSubtaskFailure = createAction(CUSTOM_UPDATE_SUBTASK_FAILURE, "subtaskId");

// custom fetch single subtask
export const CUSTOM_FETCH_SUBTASK_REQUEST = "CUSTOM_FETCH_SUBTASK_REQUEST";
export const customFetchSubtask = createAction(CUSTOM_FETCH_SUBTASK_REQUEST, "subtaskId");

export const CUSTOM_FETCH_SUBTASK_SUCCESS = "CUSTOM_FETCH_SUBTASK_SUCCESS";
export const customFetchSubtaskSuccess = createAction(CUSTOM_FETCH_SUBTASK_SUCCESS, "response", "subtaskId");

export const CUSTOM_FETCH_SUBTASK_FAILURE = "CUSTOM_FETCH_SUBTASK_FAILURE";
export const customFetchSubtaskFailure = createAction(CUSTOM_FETCH_SUBTASK_FAILURE, "error");

// custom create a subtask
export const CUSTOM_CREATE_SUBTASK_REQUEST = "CUSTOM_CREATE_SUBTASK_REQUEST";
export const customCreateSubtask = createAction(CUSTOM_CREATE_SUBTASK_REQUEST, "data");

export const CUSTOM_CREATE_SUBTASK_SUCCESS = "CUSTOM_CREATE_SUBTASK_SUCCESS";
export const customCreateSubtaskSuccess = createAction(
  CUSTOM_CREATE_SUBTASK_SUCCESS,
  "response",
  "subtaskId",
  "modelId"
);

export const CUSTOM_CREATE_SUBTASK_FAILURE = "CUSTOM_CREATE_SUBTASK_FAILURE";
export const customCreateSubtaskFailure = createAction(CUSTOM_CREATE_SUBTASK_FAILURE, "error");
