import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import { Button } from "react-admin";
import AssignMaterialDialog from "./AssignMaterialDialog";
import { withStyles } from "@material-ui/core/styles";
import resources from "../../config/resources";
import ReferenceGroupArrayFieldController from "../ReferenceGroupArrayFieldController";
import { ReferenceArrayFieldView } from "../ReferenceArrayFieldView";
import RohStatus from "../../config/RohStatus";
import sanitizeButton from "../sanitizeButton";
import AssignIcon from "@material-ui/icons/AddShoppingCart";
import green from "@material-ui/core/colors/green";
import classnames from "classnames";
import { ReferenceArrayChildProvider } from "./AssignModelButton";
import PaymentWarningDialog from "./PaymentWarningDialog";

const styles = theme => ({
  progress: { marginTop: "1em" },
  button: {
    backgroundColor: `${green[500]} !important`,
    color: theme.palette.getContrastText(green[500]),
  },
});

const AssignMaterialButton = ({
  addLabel,
  color,
  className,
  classes,
  permissions,
  paymentWarning,
  record,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [displayPaymentWarning, setDisplayPaymentWarning] = useState(paymentWarning);

  const handleClick = () => {
    setIsOpen(true);
  };

  const handleDialogClose = () => {
    setIsOpen(false);
  };

  const handleWarningClose = showAssignDialog => {
    setIsOpen(showAssignDialog);
    setDisplayPaymentWarning(!showAssignDialog);
  };

  const modelIds = record._GROUP
    ? Object.values(record._GROUP)
        .filter(({ rohStatus }) => rohStatus === RohStatus.UNASSIGNED.apiValue)
        .map(it => it.__id)
    : [];

  return (
    <Fragment>
      <ReferenceGroupArrayFieldController
        {...props}
        record={record}
        reference={resources.PRICE_ESTIMATES}
        target="model#id"
        addLabel={false}
        filter={{ rohStatus: RohStatus.UNASSIGNED.apiValue, active: true }}
        sort={{ field: "priceValue", order: "ASC" }}
        targetIds={modelIds}
      >
        {controllerProps => (
          <Fragment>
            <ReferenceArrayFieldView
              {...props}
              {...controllerProps}
              reference={resources.PRICE_ESTIMATES}
              basePath={"/" + resources.PRICE_ESTIMATES}
            >
              <ReferenceArrayChildProvider>
                {!displayPaymentWarning ? (
                  <AssignMaterialDialog
                    isOpen={isOpen}
                    onClose={handleDialogClose}
                    permissions={permissions}
                    modelIds={modelIds}
                  />
                ) : (
                  <PaymentWarningDialog isOpen={isOpen && displayPaymentWarning} onClose={handleWarningClose} />
                )}
              </ReferenceArrayChildProvider>
            </ReferenceArrayFieldView>
            <Button
              disabled={controllerProps.loaded === false}
              {...sanitizeButton(props)}
              color={color}
              className={color === "default" ? classnames(classes.button, className) : className}
              onClick={handleClick}
            />
          </Fragment>
        )}
      </ReferenceGroupArrayFieldController>
    </Fragment>
  );
};

AssignMaterialButton.propTypes = {
  addLabel: PropTypes.bool,
  basePath: PropTypes.string.isRequired,
  record: PropTypes.object.isRequired,
  resource: PropTypes.string.isRequired,
  label: PropTypes.string,
  model: PropTypes.number,
  paymentWarning: PropTypes.bool.isRequired,
  permissions: PropTypes.object.isRequired,
};

AssignMaterialButton.defaultProps = {
  addLabel: false,
  children: <AssignIcon />,
  paymentWarning: false,
};

export default withStyles(styles)(AssignMaterialButton);
