import React from "react";
import PropTypes from "prop-types";
import LinearProgress from "@material-ui/core/LinearProgress";

export const ReferenceArrayFieldView = ({
  children,
  className,
  classes = {},
  data,
  ids,
  loaded,
  reference,
  basePath,
}) => {
  if (loaded === false) {
    return <LinearProgress className={classes.progress} />;
  }

  return React.cloneElement(children, {
    className,
    resource: reference,
    ids,
    data,
    loaded,
    basePath,
    currentSort: {},
  });
};

ReferenceArrayFieldView.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  data: PropTypes.object,
  ids: PropTypes.array,
  loaded: PropTypes.bool,
  children: PropTypes.element.isRequired,
  reference: PropTypes.string.isRequired,
  basePath: PropTypes.string,
};
