import createAction, { createActionWithMeta } from "../../../lib/createAction";

export const REESTIMATE_PRICES_STATUS_REQUEST = "REESTIMATE_PRICES_STATUS_REQUEST";
export const reestimatePricesStatus = createAction(REESTIMATE_PRICES_STATUS_REQUEST);

export const REESTIMATE_PRICES_STATUS_SUCCES = "REESTIMATE_PRICES_STATUS_SUCCES";
export const reestimatePricesStatusSuccess = createActionWithMeta(
  REESTIMATE_PRICES_STATUS_SUCCES,
  {
    uncache: true,
    refresh: true,
  },
  "response"
);

export const REESTIMATE_PRICES_STATUS_ERROR = "REESTIMATE_PRICES_STATUS_ERROR";
export const reestimatePricesStatusError = createActionWithMeta(
  REESTIMATE_PRICES_STATUS_ERROR,
  {
    uncache: true,
    refresh: true,
  },
  "error"
);
