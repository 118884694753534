import { put, takeLatest } from "redux-saga/effects";
import { DOWNLOAD_FILE_REQUEST, downloadError, downloadSuccess } from "./action";
import config from "../../config";
import fetchService from "../../lib/fetchService";

const downloadSaga = function*({ payload = {} }) {
  const { id, downloadPath, fileName, body = {}, fetch = fetchService.get, headers = {} } = payload;

  const url = new URL(
    `${config.urls.BACKEND}/${downloadPath.replace(/^\/|\/$/g, "")}${id ? "/" + encodeURIComponent(id) : ""}`
  );

  try {
    const response = yield fetch(url, null, body, headers);

    if (response.ok) {
      const blob = yield response.blob();
      const fileUrl = window.URL.createObjectURL(blob);

      const tempLink = document.createElement("a");
      tempLink.href = fileUrl;
      tempLink.download = fileName || "File.pdf";
      document.body.appendChild(tempLink);
      tempLink.click();
      tempLink.remove();
      yield put(downloadSuccess());
    } else {
      yield put(downloadError(response));
    }
  } catch (e) {
    yield put(downloadError(e));
  }
};

export default function*() {
  yield takeLatest(DOWNLOAD_FILE_REQUEST, downloadSaga);
}
