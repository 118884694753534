import React, { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { translate, crudGetAll } from "react-admin";
import { withStyles } from "@material-ui/core/styles";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Avatar from "@material-ui/core/Avatar";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Radio from "@material-ui/core/Radio";
import ListItemText from "@material-ui/core/ListItemText";
import FormControl from "@material-ui/core/FormControl";
import get from "lodash/get";
import DocumentIcon from "@material-ui/icons/InsertDriveFile";
import UploadButton from "../customFields/buttons/UploadButton";
import { uploadDocumentRequest } from "../../redux/uploadDocument/action";
import {
  getDocumentUploadUploadFinished,
  getDocumentUploadIsUploading,
  getDocumentUploadProgress,
} from "../../redux/uploadDocument/selector";
import usePrevious from "../../lib/customHooks/usePrevious";
import PreviewCard from "../PreviewCard";
import resources from "../../config/resources";
import { getKey } from "../../lib/i18nUtils";

const styles = theme => ({
  icon: {
    marginRight: theme.spacing(1),
  },
  select: {
    marginTop: theme.spacing(2),
  },
});

const filterDocumentTypes = (authorities = []) => ({ uploadableBySales, uploadableByContractor }) => {
  if (uploadableBySales && authorities.includes("ROLE_SALES")) {
    return true;
  } else if (uploadableByContractor && authorities.includes("ROLE_CONTRACTOR")) {
    return true;
  } else {
    return false;
  }
};

const DocumentsUploadButton = ({
  data,
  parentBasePath,
  parentResource,
  parentId,
  permissions,
  isUploading,
  uploadProgress,
  uploadFinished,
  upload,
  dispatchCrudGetAll,
  translate,
  classes,
  archivedView = false,
  ...props
}) => {
  const [showDialog, setShowDialog] = useState(false);
  const [selectedTypeId, setSelectedTypeId] = useState(undefined);
  const prevState = usePrevious({ isUploading });

  const documentReference = archivedView ? resources.ARCHIVED_DOCUMENTS : resources.DOCUMENTS;

  useEffect(() => {
    if (uploadFinished && prevState.isUploading) {
      dispatchCrudGetAll(documentReference, { field: "id", order: "default" }, {}, 1000, () =>
        dispatchCrudGetAll(
          parentResource,
          { field: "id", order: "default" },
          {
            id: parentId,
            prefetch: ["documents"],
          },
          1
        )
      );
    }
  }, [uploadFinished, dispatchCrudGetAll, parentId, parentResource, prevState]);

  const authorities = get(permissions, "authorities", []);
  const isContractor = authorities.includes("ROLE_CONTRACTOR");

  const documentTypes = Object.values(data).filter(filterDocumentTypes(authorities));

  return (
    <Fragment>
      <PreviewCard
        title={translate("uploadDocument.uploadDocument")}
        previewIcon={<CloudUploadIcon className={classes.icon} />}
        onClick={() => setShowDialog(true)}
        style={{ maxWidth: 120 }}
        disabled={!documentTypes.length > 0}
      />
      <Dialog open={showDialog} onClose={() => setShowDialog(false)}>
        <DialogTitle>{translate("uploadDocument.uploadDocument")}</DialogTitle>
        <DialogContent>
          <Typography>{translate("uploadDocument.selectDocumentType")}</Typography>
          <FormControl fullWidth className={classes.select}>
            <List style={{ maxHeight: "calc(100vh - 300px)", overflowY: "auto" }} dense={true}>
              {documentTypes.map(({ __id, name, labelExternal, labelInternal, uploadableByContractor }) => (
                <ListItem key={__id} button onClick={() => setSelectedTypeId(__id)} color="primary" dense={true}>
                  <Avatar>
                    <DocumentIcon />
                  </Avatar>
                  <ListItemText
                    primary={isContractor ? labelExternal : labelInternal}
                    secondary={
                      isContractor
                        ? ""
                        : uploadableByContractor
                        ? translate(getKey("visibleToSub", resources.DOCUMENT_TYPES))
                        : translate(getKey("visibleInternally", resources.DOCUMENT_TYPES))
                    }
                  />
                  <ListItemSecondaryAction>
                    <Radio checked={__id === selectedTypeId} onClick={() => setSelectedTypeId(__id)} />
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <UploadButton
            variant="contained"
            color="primary"
            acceptsMultiple={false}
            isUploading={isUploading && !uploadFinished}
            uploadProgress={uploadProgress < 99 && isUploading ? uploadProgress : null}
            onStartUpload={file =>
              upload(file, {
                documentTypeId: selectedTypeId,
                resource: parentResource,
                recordId: parentId,
              })
            }
            buttonProps={{
              disabled: !selectedTypeId,
            }}
            onFinishUpload={() => setShowDialog(false)}
          >
            {translate("uploadDocument.selectFile")}
          </UploadButton>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

DocumentsUploadButton.propTypes = {
  data: PropTypes.array.isRequired,
  parentBasePath: PropTypes.string.isRequired,
  parentResource: PropTypes.string.isRequired,
  parentId: PropTypes.string.isRequired,
  permissions: PropTypes.object.isRequired,
  isUploading: PropTypes.bool,
  uploadProgress: PropTypes.number,
  uploadFinished: PropTypes.bool,
  upload: PropTypes.func.isRequired,
  dispatchCrudGetAll: PropTypes.func.isRequired,

  classes: PropTypes.object.isRequired,
  translate: PropTypes.func.isRequired,
};

DocumentsUploadButton.defaultProps = {
  data: {},
  permissions: {},
};

const mapStateToProps = state => ({
  isUploading: getDocumentUploadIsUploading(state),
  uploadProgress: getDocumentUploadProgress(state) * 100,
  uploadFinished: getDocumentUploadUploadFinished(state),
});

const mapDispatchToProps = dispatch => ({
  dispatchCrudGetAll: (resource, sort, filter, limit, cb) => dispatch(crudGetAll(resource, sort, filter, limit, cb)),
  upload: (file, documentMeta) => {
    dispatch(uploadDocumentRequest(file, documentMeta));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(translate(DocumentsUploadButton)));
