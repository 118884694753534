import createAction from "../../lib/createAction";

export const INIT_PUSH_NOTIFICATIONS_REQUEST = "INIT_PUSH_NOTIFICATIONS_REQUEST";
export const initPushNotificationsRequest = createAction(INIT_PUSH_NOTIFICATIONS_REQUEST);

export const INIT_PUSH_NOTIFICATIONS_SUCCESS = "INIT_PUSH_NOTIFICATIONS_SUCCESS";
export const initPushNotificationsSuccess = createAction(INIT_PUSH_NOTIFICATIONS_SUCCESS);

export const INIT_PUSH_NOTIFICATIONS_FAILED = "INIT_PUSH_NOTIFICATIONS_FAILED";
export const initPushNotificationsFailed = createAction(INIT_PUSH_NOTIFICATIONS_FAILED, "error");

export const STOP_PUSH_NOTIFICATIONS_REQUEST = "STOP_PUSH_NOTIFICATIONS_REQUEST";
export const stopPushNotificationsRequest = createAction(STOP_PUSH_NOTIFICATIONS_REQUEST);

export const STOP_PUSH_NOTIFICATIONS_SUCCESS = "STOP_PUSH_NOTIFICATIONS_SUCCESS";
export const stopPushNotificationsSuccess = createAction(STOP_PUSH_NOTIFICATIONS_SUCCESS);

export const SHOW_SYSTEM_PUSH_NOTIFICATION = "SHOW_SYSTEM_PUSH_NOTIFICATION";
export const showSystemPushNotification = createAction(SHOW_SYSTEM_PUSH_NOTIFICATION, "subject", "options");

export const SHOW_SYSTEM_PUSH_NOTIFICATION_SUCCESS = "SHOW_SYSTEM_PUSH_NOTIFICATION_SUCCESS";
export const showSystemPushNotificationSuccess = createAction(
  SHOW_SYSTEM_PUSH_NOTIFICATION_SUCCESS,
  "subject",
  "options"
);

export const SHOW_SYSTEM_PUSH_NOTIFICATION_ERROR = "SHOW_SYSTEM_PUSH_NOTIFICATION_ERROR";
export const showSystemPushNotificationError = createAction(
  SHOW_SYSTEM_PUSH_NOTIFICATION_ERROR,
  "subject",
  "options",
  "error"
);
