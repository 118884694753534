export default ({ alignIcon, children, classes, className, color, disabled, label, size, variant, style, mini }) => ({
  alignIcon,
  children,
  classes,
  className,
  color,
  disabled,
  label,
  size,
  variant,
  style,
  mini,
});
