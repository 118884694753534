import React, { Component } from "react";
import PropTypes from "prop-types";
import { Button } from "react-admin";
import { connect } from "react-redux";
import resources from "../../config/resources";
import sanitizeButton from "../sanitizeButton";
import ActionIcon from "@material-ui/icons/Link";
import get from "lodash/get";
import CircularProgress from "@material-ui/core/CircularProgress";

class UnconnectedModelActionButton extends Component {
  state = {
    isLoading: false,
  };

  componentWillReceiveProps({ isFetching, isRefreshing }, nextContext) {
    const { isLoading } = this.state;
    if (isLoading && !isFetching && !isRefreshing) {
      this.setState({ isLoading: false });
    }
  }

  handleClick = e => {
    const { action, record, onClick } = this.props;

    this.setState({ isLoading: true }, () => action(record.__id, record, this.props));

    if (typeof onClick === "function") onClick(e);
  };

  render() {
    const { action, onClick, children, isFetching, disabled, ...props } = this.props;
    const { isLoading } = this.state;

    if (props.resource && props.resource !== resources.MODELS) {
      throw new Error("ModelActionButton can only work in a model resource context.");
    }

    return (
      <Button {...sanitizeButton(props)} onClick={this.handleClick} disabled={isFetching || isLoading || disabled}>
        {isLoading ? <CircularProgress size={20} thickness={2} color="primary" /> : children}
      </Button>
    );
  }
}

UnconnectedModelActionButton.defaultProps = {
  children: <ActionIcon />,
};

UnconnectedModelActionButton.propTypes = {
  addLabel: PropTypes.bool,
  basePath: PropTypes.string.isRequired,
  record: PropTypes.object.isRequired,
  resource: PropTypes.string.isRequired,
  label: PropTypes.string,
  action: PropTypes.func.isRequired,
  onClick: PropTypes.func,
};

const ModelActionButton = connect(
  state => ({
    isFetching: get(state, "modelActions.isFetching", false),
    isRefreshing: get(state, "admin.loading", 0) > 0,
  }),
  (dispatch, { reduxActionCreator }) => ({ action: (...args) => dispatch(reduxActionCreator(...args)) })
)(UnconnectedModelActionButton);

export default ModelActionButton;
