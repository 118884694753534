import createAction, { createActionWithMeta } from "../../../lib/createAction";

export const DECLINE_JOB_MODEL_REQUEST = "DECLINE_JOB_MODEL_REQUEST";
export const declineJobModel = createAction(DECLINE_JOB_MODEL_REQUEST, "job", "model", "reason", "message");

export const DECLINE_JOB_MODEL_SUCCESS = "DECLINE_JOB_MODEL_SUCCESS";
export const declineJobModelSuccess = createActionWithMeta(
  DECLINE_JOB_MODEL_SUCCESS,
  { uncache: true, refresh: true },
  "response"
);

export const DECLINE_JOB_MODEL_ERROR = "DECLINE_JOB_MODEL_ERROR";
export const declineJobModelError = createActionWithMeta(
  DECLINE_JOB_MODEL_ERROR,
  { uncache: true, refresh: true },
  "error"
);
