import createAction, { createActionWithMeta } from "../../lib/createAction";

export const CHECK_AUTO_ASSIGN_REQUEST = "CHECK_AUTO_ASSIGN_REQUEST";
export const checkAutoAssign = createAction(CHECK_AUTO_ASSIGN_REQUEST, "formula", "model");

export const CHECK_AUTO_ASSIGN_SUCCESS = "CHECK_AUTO_ASSIGN_SUCCESS";
export const checkAutoAssignSuccess = createActionWithMeta(
  CHECK_AUTO_ASSIGN_SUCCESS,
  {
    uncache: true,
    notification: {
      body: "ra.notification.updated",
      level: "info",
    },
    refresh: false,
  },
  "response"
);

export const CHECK_AUTO_ASSIGN_ERROR = "CHECK_AUTO_ASSIGN_ERROR";
export const checkAutoAssignError = createActionWithMeta(
  CHECK_AUTO_ASSIGN_ERROR,
  {
    uncache: true,
    notification: {
      body: "ra.notification.bad_item",
      level: "warning",
    },
    refresh: false,
  },
  "error"
);

export const CHECK_AUTO_ASSIGN_RESULT_CLEAR = "CHECK_AUTO_ASSIGN_RESULT_CLEAR";
export const checkAutoAssignResultClear = createActionWithMeta(CHECK_AUTO_ASSIGN_RESULT_CLEAR, {
  uncache: true,
});
