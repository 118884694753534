import React from "react";
import { Show } from "react-admin";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import get from "lodash/get";

import ShipmentPanel from "./ShipmentPanel";
import CommentBox from "../comments/CommentBox";
import Title from "../customFields/Title";
import resources from "../../config/resources";

const styles = theme => ({
  root: {
    "& > *": {
      background: "none",
      boxShadow: "none",
      padding: "0 1px",
    },
  },
});

const sanitizeRestProps = ({ hasList, hasEdit, hasShow, hasCreate, ...rest }) => rest;

const CustomLayout = ({ title, location, parentProps, ...rest }) => (
  <Grid container direction="column">
    <Grid item>
      <ShipmentPanel {...rest} {...sanitizeRestProps(parentProps)} override={{ title, location }} />
    </Grid>
    <Grid item>
      <CommentBox {...rest} {...sanitizeRestProps(parentProps)} shipmentId={get(rest, "record.id")} />
    </Grid>
  </Grid>
);

const ShipmentShow = ({ classes, title, location, ...props }) => (
  <Show {...props} className={classes.root} title={<Title resource={resources.SHIPMENTS} />}>
    <CustomLayout title={title} location={location} parentProps={props} />
  </Show>
);

export default withStyles(styles)(ShipmentShow);
