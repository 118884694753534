import React from "react";
import Chip from "@material-ui/core/Chip";
import { withStyles } from "@material-ui/core/styles";
import { translate } from "react-admin";
import AutoAssignLogStatus from "../../config/AutoAssignLogStatus";
import PropTypes from "prop-types";
import red from "@material-ui/core/colors/red";
import green from "@material-ui/core/colors/green";

const styles = () => ({
  error: {
    backgroundColor: red[400],
    color: "white",
  },
  success: {
    backgroundColor: green[400],
    color: "white",
  },
});

const AutoAussignLogStatusField = ({ record, source, translate, classes }) =>
  record.status ? (
    <Chip
      label={translate(AutoAssignLogStatus[record[source]].translationKey)}
      className={AutoAssignLogStatus[record[source]] === AutoAssignLogStatus.SUCCESS ? classes.success : classes.error}
    />
  ) : null;

AutoAssignLogStatus.propTypes = {
  record: PropTypes.object.isRequired,
  source: PropTypes.string.isRequired,
  translate: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(translate(AutoAussignLogStatusField));
