import { put, takeEvery, select } from "redux-saga/effects";
import { crudGetManyReference } from "react-admin";
import { WEB_SOCKET_MESSAGE_RECEIVED } from "../websocket/action";
import WebSocketTopics from "../../config/WebSocketTopics";
import { getOneToManyReferencesForResource } from "./selectors";

const handleWebsocketSaga = function*(action = {}) {
  const { topic, body } = action.payload || {};

  if (topic === WebSocketTopics.RESOURCE_UPDATES) {
    const refs = yield select(getOneToManyReferencesForResource(body.resource));

    for (const { resource, reference, id, target, name, sort } of refs) {
      yield put(crudGetManyReference(reference, target, id, name, { page: 1, perPage: 10000 }, sort, {}, resource));
    }
  }
};

export default function*() {
  yield takeEvery(WEB_SOCKET_MESSAGE_RECEIVED, handleWebsocketSaga);
}
