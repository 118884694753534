import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import resources from "../../config/resources";
import SystemPushNotification from "./SystemPushNotification";

const MentionedInCommentNotification = ({ notification, shouldPushToSystem }) => (
  <span>
    Sie wurden in einem Kommentar erwähnt.&nbsp;
    {notification.shipmentId && (
      <Link to={`/${resources.SHIPMENTS}/%2F${resources.SHIPMENTS}%2F${notification.shipmentId}/show`}>
        Zur Bestellung
      </Link>
    )}
    {notification.jobId && (
      <Link to={`/${resources.JOBS}?filter=%7B"id"%3A"${notification.jobId}"%7D&order=DESC&page=1&perPage=10&sort=id`}>
        Zum Sub. Auftrag
      </Link>
    )}
    {shouldPushToSystem && (
      <SystemPushNotification
        open={true}
        subject="In Kommentar erwähnt"
        body="Sie wurden in einem Kommentar erwähnt."
        timestamp={moment(notification.createdAt).unix()}
        tag={`notify-${notification.id}`}
        actions={[
          {
            action: "show",
            title: notification.shipmentId ? "Zur Bestellung" : "Zum Sub. Auftrag",
            redirectUrl: notification.shipmentId
              ? `/${resources.SHIPMENTS}/%2F${resources.SHIPMENTS}%2F${notification.shipmentId}/show`
              : `/${resources.JOBS}?filter=%7B"id"%3A"${notification.jobId}"%7D&order=DESC&page=1&perPage=10&sort=id`,
          },
        ]}
      />
    )}
  </span>
);

export default MentionedInCommentNotification;
