import forIn from "lodash/forIn";

/**
 *
 * @param {Array.<string>} [resources] - All cachable resources
 * @param {function} [shouldUseCache] - Tells from cache entry, if it should be persisted
 * @return {{getResource: *, getOneEntry: getOneEntry, deleteOne: deleteOne, deleteMany: deleteMany, getOne: getOne, getMany: (function(*=): function(*=): Map<any, any>), put: (function(*=): Function), putMany: (function(*=): Function), getManyIf: getManyIf, deleteIf: deleteIf}}
 * @constructor
 */
export function Cache(resources = [], shouldUseCache) {
  /**
   * Private cache map
   * @type {Map<string, Map<string, {data, meta}>>}
   */
  const cache = new Map();

  resources.forEach(res => {
    cache.set(res, new Map());
  });

  const func = {
    /**
     * Gets all cache entries for one resource
     * @param resource
     * @return {Map.<string, {data, meta}>}
     */
    getResource: getResource(cache),

    getOneEntry: resource => {
      const resourceData = func.getResource(resource);
      if (resourceData === undefined) return undefined;
      return getOneEntry(resourceData);
    },

    deleteOne: resource => {
      const resourceData = func.getResource(resource);
      if (resourceData === undefined) {
        return () => false;
      } else {
        return id => resourceData.delete(parseInt(id));
      }
    },

    deleteMany: resource => {
      const resourceData = func.getResource(resource);
      if (resourceData === undefined) {
        return () => false;
      }

      return (ids = []) => ids.forEach(id => resourceData.delete(parseInt(id)));
    },

    getOne: resource => {
      const getter = func.getOneEntry(resource);
      if (getter === undefined) return () => undefined;
      return id => {
        const cacheEntity = getter(parseInt(id));
        if (cacheEntity === undefined) return undefined;

        const { data, meta } = cacheEntity;

        if (typeof shouldUseCache === "function") {
          if (shouldUseCache(meta, data)) {
            return data;
          } else {
            func.deleteOne(resource)(parseInt(id));
            return undefined;
          }
        }

        return data;
      };
    },

    getMany: resource => {
      const getter = func.getOne(resource);
      return (ids = []) => {
        const resultMap = new Map();
        if (Array.isArray(ids)) {
          ids.forEach(id => {
            const val = getter(id);
            if (val !== undefined) resultMap.set(id, val);
          });
        }
        return resultMap;
      };
    },

    putOne: resource => {
      const resourceData = func.getResource(resource);
      if (resourceData === undefined) {
        throw new Error(`Resource ${resource} is not a cached.`);
      }

      return (id, data, meta) => {
        if (id === undefined || data === undefined) {
          throw new Error("Both id and data are required!");
        }

        resourceData.set(parseInt(id), { data, meta });
      };
    },

    putMany: resource => {
      const putter = func.putOne(resource);

      return (values, meta) => {
        forIn(values, (data, id) => {
          putter(id, data, meta);
        });
      };
    },

    getManyIf: resource => {
      const resourceData = func.getResource(resource);
      if (resourceData === undefined) return false;

      return (filter = (meta, id, data) => false) => {
        const resultMap = new Map();
        resourceData.forEach(({ data, meta }, id) => {
          if (filter(meta, id, data)) {
            resultMap.set(id, data);
          }
        });
        return resultMap;
      };
    },

    deleteIf: resource => {
      const resourceData = func.getResource(resource);
      if (resourceData === undefined) return false;

      return (filter = (meta, id, data) => false) => {
        resourceData.forEach(({ data, meta }, id) => {
          if (filter(meta, id, data)) {
            resourceData.delete(parseInt(id));
          }
        });
      };
    },

    deleteAll: resource => {
      cache.set(resource, new Map());
    },
  };
  return func;
}

const getResource = cache => resource => {
  const resourceData = cache.get(resource);

  if (!Map.prototype.isPrototypeOf(resourceData)) {
    return undefined;
  } else {
    return resourceData;
  }
};

const getOneEntry = resourceData => {
  const getter = id => {
    const cacheEntity = resourceData.get(parseInt(id));
    if (cacheEntity === undefined || typeof cacheEntity !== "object" || !cacheEntity.hasOwnProperty("data"))
      return undefined;

    return cacheEntity;
  };
  getter.resource = resourceData;
  return getter;
};
