import { FunctionField, ReferenceManyField, SingleFieldList } from "react-admin";
import resources from "../../config/resources";
import get from "lodash/get";
import React from "react";
import isNumber from "lodash/isNumber";
import moment from "moment";
import green from "@material-ui/core/colors/green";
import yellow from "@material-ui/core/colors/yellow";
import red from "@material-ui/core/colors/red";
import { getKey } from "../../lib/i18nUtils";
import Tooltip from "@material-ui/core/Tooltip";
import CircularProgress from "@material-ui/core/CircularProgress";

const TimeLeftField = ({ translate, target = "models#priceEstimates#id", ...props }) => (
  <ReferenceManyField {...props} reference={resources.SHIPMENTS} target={target} perPage={1}>
    <SingleFieldList linkType={false}>
      <FunctionField
        render={({ dateShipping }) => (
          <TimeLeftProgress
            productionDays={get(props, "record.productionDays")}
            dateShipping={dateShipping}
            translate={translate}
          />
        )}
      />
    </SingleFieldList>
  </ReferenceManyField>
);

const TimeLeftProgress = ({ productionDays, dateShipping, translate }) => {
  if (!dateShipping || !isNumber(productionDays)) return null;

  const diff = moment().diff(dateShipping, "days");
  const value = Math.max(0, 1 + Math.min(0, diff + productionDays) / Math.max(1, productionDays * 2)) * 95 + 5;
  const color = value < 50 ? green[500] : value < 75 ? yellow[800] : red[600];
  const daysRemaining = -diff - productionDays;
  const tooltip =
    daysRemaining >= 0
      ? translate(getKey("timeRemainingTooltip", resources.PRICE_ESTIMATES), daysRemaining)
      : translate(getKey("timeOverdueTooltip", resources.PRICE_ESTIMATES), -daysRemaining);

  return (
    <Tooltip title={<span style={{ fontSize: 16 }}>{tooltip}</span>}>
      <CircularProgress
        variant="static"
        size={24}
        thickness={12}
        value={value}
        style={{
          color,
          backgroundColor: "#EEEEEE",
          borderRadius: "50%",
          border: `solid 1px ${color}`,
        }}
      />
    </Tooltip>
  );
};

export default TimeLeftField;
