import { call, put, takeEvery } from "redux-saga/effects";
import resources from "../../../config/resources";
import config from "../../../config/index";
import fetchService from "../../../lib/fetchService";
import { INTRANSPORT_JOB_REQUEST, intransportJobError, intransportJobSuccess } from "./action";
import { handleSagaError } from "../../../dataproviders/errorResponseHandler";

const requestSaga = function*({ payload }) {
  const { job } = payload;

  const url = `${config.urls.BACKEND}/v2/${resources.JOBS}/${encodeURIComponent(job)}/intransport`;

  try {
    const response = yield call(fetchService.post, url);

    for (const action of yield call(handleSagaError, response, intransportJobError)) {
      yield put(action);
      return;
    }

    if (response.ok) {
      const responseData = yield call(() => response.json());
      yield put(intransportJobSuccess(responseData));
    } else {
      const statusCode = response.status;
      yield put(intransportJobError(new Error(`Request failed with error ${statusCode}!`)));
    }
  } catch (e) {
    yield put(intransportJobError(e));
  }
};

export default function*() {
  yield takeEvery(INTRANSPORT_JOB_REQUEST, requestSaga);
}
