import React, { Fragment, useState } from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { SelectArrayInput, translate } from "react-admin";
import { Field, Form } from "react-final-form";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import Chip from "@material-ui/core/Chip";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import ShareIcon from "@material-ui/icons/Share";
import CloseIcon from "@material-ui/icons/Close";
import Button from "@material-ui/core/Button";
import get from "lodash/get";
import reduce from "lodash/reduce";
import SaveIcon from "@material-ui/icons/Save";
import classnames from "classnames";

import resources from "../../config/resources";
import { shareFilterGraphRequest } from "../../redux/filterGraph/shareFilterGraph/action";
import CustomRefArrayInput from "../customReferenceFetch/CustomRefArrayInput";
import refFetchOccurrences from "../../config/CustomRefFetchOccurrences";
import { getCustomSelectedReferenceFetchedItemData } from "../../redux/customReferenceFetch/selectors";
import Roles from "../../config/Roles";
import { getKey } from "../../lib/i18nUtils";

const styles = theme => ({
  form: {
    [theme.breakpoints.up("md")]: {
      width: 500,
    },
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  saveIcon: {
    fontSize: 18,
  },
  autocomplete: {
    width: "100%",
    "& > *": {
      width: "100%",
    },
  },
});

const _ShareAutoCompleteInput = ({ translate, record, initialShareInputValues }) => (
  <Field name="sharedWithUsers" initialValue={initialShareInputValues}>
    {({ input, ...rest }) => (
      <CustomRefArrayInput
        occurrence={refFetchOccurrences.shareFilterGraphButton}
        reference={resources.USERS}
        record={record}
        source="sharedWithUsers"
        sort={{ field: "displayName", order: "ASC" }}
        filter={{ authority: ["ROLE_ADMIN", "ROLE_SALES"] }}
      >
        <Autocomplete
          {...input}
          {...rest}
          onChange={(event, value) => input.onChange(value)}
          multiple
          autoHighlight
          getOptionLabel={option => get(option, "displayName", "")}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip
                variant="outlined"
                size="small"
                label={get(option, "displayName", "")}
                {...getTagProps({ index })}
              />
            ))
          }
          renderInput={params => <TextField {...params} variant="filled" label={translate("resources.users.name")} />}
        />
      </CustomRefArrayInput>
    )}
  </Field>
);

const mapStateToPropsShareAutocomplete = state => ({
  initialShareInputValues: get(
    getCustomSelectedReferenceFetchedItemData(refFetchOccurrences.shareFilterGraphButton)(state),
    resources.USERS,
    []
  ),
});

const ShareAutoCompleteInput = compose(
  connect(mapStateToPropsShareAutocomplete),
  translate
)(_ShareAutoCompleteInput);

const ShareFilterGraphButton = ({ translate, classes, record, basePath, dispatchShare }) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const onSubmit = ({ sharedWithUsers, authorities = [] }) => {
    const recordId = get(record, "__id");
    const shareIds = reduce(
      sharedWithUsers,
      (result, user) => {
        result.push(get(user, "id"));
        return result;
      },
      []
    );
    dispatchShare(recordId, shareIds, authorities);
    handleClose();
  };
  const handleClose = () => {
    setDialogOpen(false);
  };

  return (
    <Fragment>
      <Button onClick={() => setDialogOpen(true)}>
        <ShareIcon className={classes.icon} />
        {translate("ra.action.share")}
      </Button>
      <Dialog open={dialogOpen} onClose={handleClose} disablePortal={true} fullWidth>
        <DialogTitle>{translate("resources.filterGraphs.actions.share")}</DialogTitle>
        <DialogContent>
          <Form
            onSubmit={onSubmit}
            render={({ handleSubmit, form }) => (
              <form onSubmit={handleSubmit}>
                <ShareAutoCompleteInput record={record} />
                <SelectArrayInput
                  source="authorities"
                  choices={Object.values(Roles)}
                  optionValue="key"
                  optionText={({ translation }) => translate(translation)}
                  label={translate(getKey("authorities", resources.USERS))}
                  fullWidth
                />
                <DialogActions>
                  <Button onClick={handleClose}>
                    <CloseIcon className={classes.icon} />
                    {translate("ra.action.cancel")}
                  </Button>
                  <Button type="submit" variant="contained" color="primary">
                    <SaveIcon className={classnames(classes.icon, classes.saveIcon)} />
                    {translate("ra.action.save")}
                  </Button>
                </DialogActions>
              </form>
            )}
          />
        </DialogContent>
      </Dialog>
    </Fragment>
  );
};

ShareFilterGraphButton.propTypes = {
  record: PropTypes.object.isRequired,
  basePath: PropTypes.string.isRequired,
  dispatchShare: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  initialShareInputValues: PropTypes.array.isRequired,
};

export default connect(
  null,
  {
    dispatchShare: shareFilterGraphRequest,
  }
)(withStyles(styles)(translate(ShareFilterGraphButton)));
