import React, { useState } from "react";
import { connect } from "react-redux";
import {
  crudGetOne,
  GET_LIST,
  showNotification,
  translate,
  useDataProvider,
  usePermissions,
  useQuery,
  useQueryWithStore,
} from "react-admin";
import get from "lodash/get";
import Icon from "@material-ui/icons/AccountCircle";
import resources from "../../../config/resources";
import { assignToJob, assignToShipment } from "../../../redux/assignedUser/action";
import DropdownButton from "./DropdownButton";
import UserAvatar from "../../userDetails/UserAvatar";
import { getKey } from "../../../lib/i18nUtils";
import { HAL_REFERENCE } from "../../../dataproviders/v2BackendDataProvider";
import CircularProgress from "@material-ui/core/CircularProgress";
import ErrorIcon from "@material-ui/icons/Error";

const mapSortUserDetails = ({ data = [] }, currentUser = {}) => {
  if (!currentUser) return [];
  let mapped = [];
  for (const userObject of data) {
    if (userObject.user === currentUser.id && mapped.length > 0) {
      mapped = [userObject, ...mapped];
    } else {
      mapped.push(userObject);
    }
  }
  return mapped;
};

const UserDetails = ({ userDetailsId, children, size = 10 }) => {
  const { data: userDetails, loading, error } = useQuery({
    type: HAL_REFERENCE,
    resource: resources.ASSIGNED_USERS,
    payload: { link: userDetailsId },
  });
  if (error) {
    return <ErrorIcon aria-errormessage={error.message || error} color="error" fontSize="small" role="presentation" />;
  } else if (loading || !userDetails) {
    return <CircularProgress size={size} />;
  } else {
    return children(userDetails[0]);
  }
};

const AssignUserButton = ({
  record,
  source = "assignee",
  assignedUserDetailsSource = "assignedUserDetails",
  assignType,
  buttonSize,
  tooltipPlacement,
  assignToShipment,
  assignToJob,
  crudGetOne,
  size,
  translate,
  label,
  showNotification,
  textAlign,
  sortBy,
  ...props
}) => {
  const [assigneeOverride, setAssigneeOverride] = useState(null);
  const [oldAssignee, setOldAssignee] = useState(null);
  const { permissions } = usePermissions();
  const dataProvider = useDataProvider();

  /**
   * Fetch the userDetails manually
   */
  const { data } = useQueryWithStore({
    type: GET_LIST,
    resource: resources.USER_DETAILS,
    payload: {
      pagination: { page: 1, perPage: 100 },
      sort: { field: "displayName", order: "ASC" },
      filter: { "user.authorities.like": "%ROLE_SALES%" },
    },
  });

  const assignee = get(record, source);
  const assignedUserDetails = get(record, assignedUserDetailsSource);

  label = label || translate(getKey(source, props.resource));

  const callback = ({ payload, requestPayload, error }) => {
    if (payload && requestPayload && !error) {
      setTimeout(crudGetOne, 2000, props.resource, record.id, props.basePath, false);
    } else if (error) {
      if (typeof error === "string") {
        showNotification(error, "warning");
      } else {
        showNotification("ra.message.error", "warning");
      }
      setAssigneeOverride(null);
    }
  };

  let onClickAction = () => false;
  if (props.resource === resources.SHIPMENTS) {
    onClickAction = (userDetailsId, _, { refData }) => {
      setAssigneeOverride(refData);
      const requestPayload = {
        data: {
          shipment: record.id,
          userDetails: userDetailsId,
        },
      };
      dataProvider
        .create(resources.ASSIGNED_USERS, requestPayload)
        .then(({ data }) => callback({ payload: data, requestPayload }))
        .catch(error => callback({ requestPayload, error }));
    };
  } else if (props.resource === resources.JOBS) {
    onClickAction = (userDetailsId, _, { refData }) => {
      setAssigneeOverride(refData);
      const requestPayload = {
        data: {
          job: record.id,
          userDetails: userDetailsId,
        },
      };
      dataProvider
        .create(resources.ASSIGNED_USERS, requestPayload)
        .then(({ data }) => callback({ payload: data, requestPayload }))
        .catch(error => callback({ requestPayload, error }));
    };
  }

  let child;
  if (assigneeOverride) {
    child = (
      <UserAvatar
        displayName={assigneeOverride.displayName}
        displayStyle={assigneeOverride.displayStyle}
        size={size}
        avatarUrl={assigneeOverride.avatarUrl}
      />
    );
  } else if (assignedUserDetails) {
    child = (
      <UserDetails userDetailsId={assignedUserDetails} size={size}>
        {it =>
          it ? (
            <UserAvatar
              size={size}
              displayName={it.displayName}
              avatarUrl={it.avatarUrl}
              displayStyle={it.displayStyle}
              tooltipPlacement={tooltipPlacement}
              user={it.user}
            />
          ) : (
            <Icon />
          )
        }
      </UserDetails>
    );
  } else {
    child = <Icon />;
  }

  if (assignee !== oldAssignee) {
    setAssigneeOverride(null);
    setOldAssignee(assignee);
  }

  return (
    <DropdownButton
      label={child}
      items={mapSortUserDetails({ data }, permissions).map(({ id, displayName, user, displayStyle, avatarUrl }) => ({
        key: id,
        label: displayName,
        icon: <UserAvatar displayName={displayName} displayStyle={displayStyle} size={30} avatarUrl={avatarUrl} />,
        refData: { displayName, user, displayStyle, avatarUrl },
      }))}
      onSelect={onClickAction}
      title={label}
      menuProps={{ disableAutoFocusItem: true }}
    />
  );
};

export default connect(
  undefined,
  {
    assignToShipment,
    assignToJob,
    crudGetOne,
    showNotification,
  }
)(translate(AssignUserButton));
