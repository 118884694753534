import React, { Fragment, useState } from "react";
import moment from "moment";
import { Button, Mutation, UPDATE } from "react-admin";
import DateField from "../customFields/DateField";
import sanitizeButton from "../sanitizeButton";
import resources from "../../config/resources";

const NotificationReadButton = ({
  record,
  source = "readAt",
  resource = resources.NOTIFICATIONS,
  displayFormat = "DD. MMMM YYYY HH:mm",
  children,
  basePath,
  buttonLabel,
  ...rest
}) => {
  let field = null;

  const [optimisticValue, setOptimisticValue] = useState(null);

  const value = optimisticValue || (record && record[source]);

  if (value) {
    field = <DateField record={record} source={source} displayFormat={displayFormat} staticValue={optimisticValue} />;
  }

  if (!record || !record.id) {
    return null;
  }

  const newValue = moment().toISOString();
  const payload = {
    id: record.id,
    data: {
      [source]: newValue,
    },
  };

  let options = {
    undoable: false,
    onSuccess: {
      callback: () => setOptimisticValue(null),
    },
    onFailure: {
      callback: () => setOptimisticValue(null),
    },
  };

  if (basePath && basePath.startsWith(`/${resources.NOTIFICATIONS}`)) {
    options.onSuccess = {
      basePath,
      refresh: true,
    };
  }

  return (
    <Fragment>
      {field}
      <Mutation type={UPDATE} resource={resource} payload={payload} options={options}>
        {approve =>
          source &&
          !field && (
            <Button
              {...sanitizeButton(rest)}
              source={source}
              onClick={() => {
                approve();
                setOptimisticValue(newValue);
              }}
              label={buttonLabel}
            >
              {children}
            </Button>
          )
        }
      </Mutation>
    </Fragment>
  );
};

export default NotificationReadButton;
