import React from "react";
import { withStyles } from "@material-ui/core/styles";
import WarehouseLocationIcon from "@material-ui/icons/BorderOuter";
import {
  CreateButton,
  Datagrid,
  EditButton,
  FunctionField,
  List,
  NumberField,
  Pagination,
  ShowButton,
  TextField,
  TopToolbar,
  translate,
} from "react-admin";
import IconChipField from "../customFields/IconChipField";
import { getKey } from "../../lib/i18nUtils";
import resources from "../../config/resources";
import MultiStatusField from "../customFields/MultiStatusField";
import paginationOptions from "../../config/PaginationOptions";
import CustomDeleteButton from "../customFields/buttons/CustomDeleteButton";

const styles = () => ({
  actionButton: {
    display: "flex",
    justifyContent: "flex-end",
    float: "right",
  },
});

const Actions = ({ basePath, displayedFilters, filters, filterValues, resource, showFilter }) => (
  <TopToolbar>
    {filters &&
      React.cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: "button",
      })}
    <CreateButton basePath={basePath} />
  </TopToolbar>
);

const WarehouseLocationList = ({ translate, classes, ...props }) => (
  <List {...props} bulkActionButtons={false} actions={<Actions />} pagination={<Pagination {...paginationOptions} />}>
    <Datagrid rowClick="show">
      <TextField source="name" />
      <FunctionField
        label={translate(getKey("label", resources.WAREHOUSE_LOCATIONS))}
        render={({ color, label }) => <IconChipField color={color} label={label} icon={WarehouseLocationIcon} />}
      />
      <NumberField source="priority" textAlign="left" />
      <MultiStatusField source="forStatus" />
      <ShowButton className={classes.actionButton} />
      <EditButton className={classes.actionButton} />
      <CustomDeleteButton className={classes.actionButton} undoable={false} />
    </Datagrid>
  </List>
);

export default withStyles(styles)(translate(WarehouseLocationList));
