import { call, put, takeEvery, select } from "redux-saga/effects";
import { EXPORT_REPORT, exportReportSuccess, exportReportError } from "./action";
import { logHandler, LoggingLevel, getLog } from "../../lib/logger";
import config from "../../config";
import { exportJson } from "../../lib/jsonExporter";

const exportReportSaga = function*() {
  try {
    const state = yield select(state => state);
    const log = yield call(getLog);

    const time = new Date().getTime();

    const token = localStorage.getItem("token");
    const expiresAt = localStorage.getItem("expiresAt");

    const data = {
      timeExported: time,
      exportSource: "NAVIGATION_BUTTON",
      location: window.location.href,
      client: {
        appCodeName: window.navigator.appCodeName,
        appName: window.navigator.appName,
        appVersion: window.navigator.appVersion,
        language: window.navigator.language,
        languages: window.navigator.languages,
        platform: window.navigator.platform,
        userAgent: window.navigator.userAgent,
        vendor: window.navigator.vendor,
        vendorSub: window.navigator.vendorSub,
      },
      login: { token, expiresAt },
      config,
      state,
      log,
    };

    exportJson(data);
  } catch (e) {
    yield put(exportReportError(e));
  }

  yield put(exportReportSuccess());
};

const logActionSaga = function*(action) {
  const type = action.type;

  if (type.match(/^@@/)) return;

  if (type.match(/^RA\/USER_LOGIN$/)) action = { type, HIDDEN: "HIDDEN" };

  yield call(logHandler, LoggingLevel.REDUX_ACTION, [action], `REDUX_ACTION/${action.type}`);
};

export default function*() {
  yield takeEvery(EXPORT_REPORT, exportReportSaga);
  yield takeEvery(() => true, logActionSaga);
}
