import React, { useState } from "react";
import { useRefresh } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import { Warning } from "@material-ui/icons";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import EditModelPartsStatesDialog from "./EditModelPartsStatesDialog";
import ModelPartStateIcon from "./ModelPartStateIcon";

const useStyles = makeStyles(theme => ({
  epsilon: {
    margin: theme.spacing(1),
  },
  partStates: {
    display: "flex",
    flexWrap: "wrap",
    padding: theme.spacing(2),
  },
  singlePart: {
    justifySelf: "center",
    display: "flex",
    justifyContent: "space-around",
    padding: theme.spacing(0),
  },
  warning: { color: "red", fontSize: "1.5em" },
  grid: {
    borderBottom: "gray solid 1px",
    cursor: "pointer",
  },
  checkSum: {
    justifySelf: "center",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    fontWeight: 700,
    fontSize: "18px",
    width: "100%",
    cursor: "pointer",
  },
}));

const shownStateCountPropNames = [
  "assignedCount",
  "packedCount",
  "printingCount",
  "printedCount",
  "receivedCount",
  "postProcessingCount",
  "qaCount",
  "readyForShippingCount",
  "shippedCount",
  "defectCount",
];

const ModelPartsDisplay = ({ withDialog = false, record, ...props }) => {
  const classes = useStyles();
  const refresh = useRefresh();

  const [isDialogOpen, setDialogOpen] = useState(false);

  if (!record) {
    return null;
  }

  const modelAmount = record.amount;

  const countList = Object.entries(record).filter(
    entry => shownStateCountPropNames.includes(entry[0]) && entry[0] !== "unassignedCount"
  );
  const nonEmptyCounts = countList.filter(count => count[1] > 0);

  const sum = nonEmptyCounts
    .filter(entry => entry[0] !== "defectCount")
    .map(item => item[1])
    .reduce((a, b) => a + b, 0);
  const displayWarning = modelAmount > sum;

  const handleOpenEdit = () => setDialogOpen(true);
  const handleCloseEdit = () => {
    setDialogOpen(false);
    refresh();
  };

  return (
    <>
      <Grid container spacing={1} className={classes.grid} onClick={handleOpenEdit}>
        {nonEmptyCounts.map(part => (
          <Grid item>
            <div className={classes.singlePart}>
              <ModelPartStateIcon name={part[0]} withTooltip />
              <Typography> {part[1]}</Typography>
            </div>
          </Grid>
        ))}
      </Grid>
      <div className={classes.checkSum} onClick={handleOpenEdit}>
        <span className={classes.epsilon}>Σ {`${sum}/${modelAmount}`}</span>
        {displayWarning && <Warning className={classes.warning} />}
      </div>
      <EditModelPartsStatesDialog
        isOpen={isDialogOpen}
        onClose={handleCloseEdit}
        values={countList}
        id={record.__id}
        modelAmount={modelAmount}
      />
    </>
  );
};

export default ModelPartsDisplay;
