import { HttpError } from "react-admin";

/**
 * Error object for throwing an exception with an (own/internal) error code.
 *
 * @param {Number} code - Error code returned from backend
 * @param {String} message - Error message translation key
 * @param {Number} status - Response HTTP status
 * @param {Object.<String, String>} [fieldErrors] - Form field errors in the format [field name]: [translation key]
 * @param {String} [devMessage] - Debug message for the developer.
 * @returns {HttpError}
 * @constructor
 */
function ResponseCodeError(code, message, status, fieldErrors, devMessage) {
  const instance = new HttpError(message, status, fieldErrors);
  Object.setPrototypeOf(instance, Object.getPrototypeOf(this));

  this.code = code;
  this.message = message;
  this.status = status;
  this.fieldErrors = fieldErrors;
  this.devMessage = devMessage;

  return instance;
}

ResponseCodeError.prototype = Object.create(HttpError.prototype, {
  constructor: {
    value: HttpError,
    enumerable: false,
    writable: true,
    configurable: true,
  },
});

if (Object.setPrototypeOf) {
  Object.setPrototypeOf(ResponseCodeError, HttpError);
} else {
  ResponseCodeError.__proto__ = HttpError;
}

export default ResponseCodeError;
