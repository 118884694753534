export default {
  SCALING: {
    key: "SCALING",
    apiKey: "SCALING",
    translation: "declineReasons.scale",
    needsMessage: false,
  },
  TRIANGULATION: {
    key: "TRIANGULATION",
    apiKey: "TRIANGULATION",
    translation: "declineReasons.triangulation",
    needsMessage: false,
  },
  FILE_ERROR: {
    key: "FILE_ERROR",
    apiKey: "FILE_ERROR",
    translation: "declineReasons.fileError",
    needsMessage: false,
  },
  WALL_THICKNESS: {
    key: "WALL_THICKNESS",
    apiKey: "WALL_THICKNESS",
    translation: "declineReasons.wallthickness",
    needsMessage: false,
  },
  OTHER: {
    key: "OTHER",
    apiKey: "OTHER",
    translation: "declineReasons.other",
    needsMessage: true,
  },
};
