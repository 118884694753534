import React from "react";
import { translate } from "react-admin";
import { withStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
import RohStatus from "../../config/RohStatus";
import get from "lodash/get";
import classnames from "classnames";
import MultiChip from "../MultiChip";

const styles = {
  root: { display: "flex", flexWrap: "wrap" },
};

const GroupedModelMultiStatusField = ({ translate, ids, source, data, loaded, classes, className, ...rest }) => {
  if (loaded === false) {
    return <LinearProgress style={{ maxWidth: 100 }} />;
  }

  const chipData = [];
  const includedLabels = [];

  ids.forEach(id => {
    const key = get(data, `${id}.${source}`, undefined);
    if (key === undefined) return;

    const rohStatus = RohStatus[key];
    if (!rohStatus) return;

    const label = `${translate(rohStatus.translationKey)} | ${data[id].materialName || "N/A"} | ${data[id].methodName ||
      "N/A"} | ${data[id].contractorName || "-"}`;

    if (includedLabels.includes(label)) {
      return;
    } else {
      includedLabels.push(label);
    }

    chipData.push({
      label: label,
      color: rohStatus.textColor,
      backgroundColor: rohStatus.primaryColor,
      borderColor: rohStatus.borderColor,
      importance: rohStatus.importance,
    });
  });

  const sorted = chipData.sort(({ importance: a }, { importance: b }) => a - b);

  return (
    <div className={classnames(classes.root, className)} {...sanitizeRestProps(rest)}>
      <MultiChip values={sorted} />
    </div>
  );
};

const sanitizeRestProps = ({
  currentSort,
  setSort,
  loading,
  loaded,
  useChip,
  showFilter,
  setPerPage,
  setPage,
  setFilters,
  selectedIds,
  perPage,
  hideFilter,
  hasCreate,
  filterValues,
  displayedFilters,
  defaultTitle,
  ...props
}) => props;

export default translate(withStyles(styles)(GroupedModelMultiStatusField));
