import React from "react";
import { getKey } from "../../lib/i18nUtils";
import { translate, TextField } from "react-admin";
import Typography from "@material-ui/core/Typography";

const LabeledField = ({ label, translate, style, type = <TextField />, ...props }) => (
  <div style={{ display: "flex", alignItems: "center" }}>
    <Typography variant="body1" style={{ display: "inline-flex", fontWeight: 700 }}>
      {typeof label === "string" && label.length > 0 ? label : translate(getKey(props.source, props.resource))}
      :&nbsp;
    </Typography>
    {React.cloneElement(type, {
      ...props,
      style: { ...style, display: "inline-flex" },
    })}
  </div>
);

export default translate(LabeledField);
