import React from "react";
import { TextInput, translate } from "react-admin";
import { withStyles } from "@material-ui/core/styles";
import resources from "../../config/resources";
import { getKey } from "../../lib/i18nUtils";
import CustomFilter from "../CustomFilter";

const styles = theme => ({
  container: {
    display: "grid !important",
    width: "100%",

    gridTemplateColumns: "repeat(18, 1fr)",
    [theme.breakpoints.down("md")]: {
      gridTemplateColumns: "repeat(9, 1fr)",
    },
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "repeat(6, 1fr)",
    },
    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "repeat(3, 1fr)",
    },
    gridColumnGap: `${theme.spacing(1)}px`,

    "& > *": {
      gridColumnEnd: "span 3",
      overflow: "hidden",
    },
  },
});

const MaterialFilters = ({ translate, classes, ...props }) => (
  <CustomFilter {...props} className={classes.container}>
    <TextInput source="name" alwaysOn fullWidth resettable />
    <TextInput
      source="method#key"
      alwaysOn
      fullWidth
      resettable
      label={translate(getKey("method", resources.MATERIALS))}
    />
  </CustomFilter>
);

export default withStyles(styles)(translate(MaterialFilters));
