import { call, put, take, takeEvery } from "redux-saga/effects";
import { DESTROY_WEBSOCKET, INIT_WEBSOCKET_REQUEST, webSocketAction, webSocketMessageReceived } from "./action";
import { TOPICS, USER_PREFIX, USER_TOPICS } from "../../config/WebSocketTopics";
import config from "../../config";
import { createWebSocketChannel, EVENTS } from "../../lib/WebSocket";
import { Logger } from "../../lib/logger";

const LOG = Logger("websocket/saga.js");

let channel = null;

const closeChannel = function*() {
  yield call(channel.close);
  console.log("WebSocket closed.");
};

const createWebsocketChannelSaga = function*(action = {}) {
  const { username } = action.payload || {};

  if (!username) {
    LOG.error("Cannot connect to websocket: Not logged in!");
    return;
  }

  const subscribeTopics = [];
  for (const topic of Object.values(TOPICS)) {
    if (USER_TOPICS.includes(topic)) {
      subscribeTopics.push(USER_PREFIX + username + topic);
    } else {
      subscribeTopics.push(topic);
    }
  }

  LOG.debug("Subscribing to topics", subscribeTopics);

  const socketUrl = `${config.urls.BACKEND}/ws/socket-registry?access_token=${localStorage.getItem("token")}`;

  channel = yield call(createWebSocketChannel, socketUrl, {
    subscribeTopics,
    debug: true,
  });
  console.log("WebSocket opened.");

  try {
    while (channel) {
      const { type, exit, ...data } = yield take(channel);

      yield put(webSocketAction(type, data));

      if (type === EVENTS.RECEIVED_MESSAGE) {
        const { topic, body } = data;
        try {
          const json = yield call(JSON.parse, body);
          yield put(webSocketMessageReceived(topic, json));
        } catch (e) {}
      }

      if (exit) {
        break;
      }
    }
  } catch (e) {}
};

export default function*() {
  yield takeEvery(INIT_WEBSOCKET_REQUEST, createWebsocketChannelSaga);
  yield takeEvery(DESTROY_WEBSOCKET, closeChannel);
}
