import createAction, { createActionWithMeta } from "../../../lib/createAction";

export const JOB_BULK_OPERATION_REQUEST = "JOB_BULK_OPERATION_REQUEST";
export const jobBulkOperation = createAction(JOB_BULK_OPERATION_REQUEST, "jobIds", "bulkAction");

export const JOB_BULK_OPERATION_SUCCESS = "JOB_BULK_OPERATION_SUCCESS";
export const jobBulkOperationSuccess = createActionWithMeta(
  JOB_BULK_OPERATION_SUCCESS,
  {
    uncache: true,
    notification: {
      body: "ra.notification.updated",
      level: "info",
    },
    refresh: false,
  },
  "response"
);

export const JOB_BULK_OPERATION_ERROR = "JOB_BULK_OPERATION_ERROR";
export const jobBulkOperationError = createActionWithMeta(
  JOB_BULK_OPERATION_ERROR,
  {
    uncache: true,
    notification: {
      body: "ra.notification.bad_item",
      level: "warning",
    },
    refresh: false,
  },
  "error"
);

export const JOB_BULK_OPERATION_RESULT_CLEAR = "JOB_BULK_OPERATION_RESULT_CLEAR";
export const jobBulkOperationResultClear = createActionWithMeta(JOB_BULK_OPERATION_RESULT_CLEAR, {
  uncache: true,
});
