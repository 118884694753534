import React, { Fragment, useState, useEffect } from "react";
import inflection from "inflection";
import { withStyles } from "@material-ui/core/styles";
import { List, ListController, getElementsFromRecords, InferredElement } from "react-admin";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import resources from "./config/resources";
import listFieldTypes from "./components/listFieldTypes";

const styles = {
  list: {
    "& > div": {
      overflow: "auto",
    },
  },
};

const mapData = data => id => {
  const { _prefetched: ignored, ...it } = data[id];
  return it;
};

const ListViewGuesser = props => {
  const { ids, data, resource } = props;
  const [inferredChild, setInferredChild] = useState(null);
  useEffect(() => {
    if (ids.length > 0 && data && !inferredChild) {
      const inferredElements = getElementsFromRecords(ids.map(mapData(data)), listFieldTypes);
      const inferredChild = new InferredElement(listFieldTypes.table, null, inferredElements);

      process.env.NODE_ENV !== "production" &&
        // eslint-disable-next-line no-console
        console.log(
          `Guessed List:
export const ${inflection.capitalize(inflection.singularize(resource))}List = props => (
    <List {...props}>
${inferredChild.getRepresentation()}
    </List>
);`
        );
      setInferredChild(inferredChild.getElement());
    }
  }, [data, ids, inferredChild, resource]);

  return <List {...props}>{inferredChild}</List>;
};

const DebugView = ({ classes, ...props }) => {
  const [resource, setResource] = useState(undefined);

  return (
    <Fragment>
      {!resource && (
        <Select value={resource} onChange={event => setResource(event.target.value)}>
          {Object.values(resources).map((it, key) => (
            <MenuItem key={key} value={it}>
              {it}
            </MenuItem>
          ))}
        </Select>
      )}
      {resource && (
        <div
          style={{
            width: "calc(100vw - 290px)",
            overflowX: "scroll !important",
          }}
        >
          <div style={{ boxSizing: "content-box", width: "100%" }}>
            <ListController {...props} basePath={"/" + resource} resource={resource}>
              {controllerProps => (
                <ListViewGuesser
                  {...controllerProps}
                  {...props}
                  basePath={"/" + resource}
                  resource={resource}
                  bulkActionButtons={false}
                  className={classes.list}
                />
              )}
            </ListController>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default withStyles(styles)(DebugView);
