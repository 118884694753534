import createAction, { createActionWithMeta } from "../../../lib/createAction";

export const CONFIRM_JOB_ORDER_CONFIRMATION_REQUEST = "CONFIRM_JOB_ORDER_CONFIRMATION_REQUEST";
export const confirmOrderConfirmation = createAction(CONFIRM_JOB_ORDER_CONFIRMATION_REQUEST, "job");

export const CONFIRM_JOB_ORDER_CONFIRMATION_SUCCESS = "CONFIRM_JOB_ORDER_CONFIRMATION_SUCCESS";
export const confirmOrderConfirmationSuccess = createActionWithMeta(
  CONFIRM_JOB_ORDER_CONFIRMATION_SUCCESS,
  { uncache: true, refresh: true },
  "response"
);

export const CONFIRM_JOB_ORDER_CONFIRMATION_ERROR = "CONFIRM_JOB_ORDER_CONFIRMATION_ERROR";
export const confirmOrderConfirmationError = createActionWithMeta(
  CONFIRM_JOB_ORDER_CONFIRMATION_ERROR,
  { uncache: true, refresh: true },
  "error"
);
