import React from "react";
import PropTypes from "prop-types";
import { Field, Form } from "react-final-form";
import { connect } from "react-redux";
import compose from "recompose/compose";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import { createStyles, withStyles } from "@material-ui/core/styles";
import { translate, useLogin, useNotify } from "react-admin";
import get from "lodash/get";

const styles = () =>
  createStyles({
    form: {
      padding: "0 1em 1em 1em",
    },
    input: {
      marginTop: "1em",
    },
    button: {
      width: "100%",
    },
  });

// see http://redux-form.com/6.4.3/examples/material-ui/
const renderInput = ({
  meta: { touched, error } = { touched: false, error: "" }, // eslint-disable-line react/prop-types
  input: { ...inputProps }, // eslint-disable-line react/prop-types
  ...props
}) => <TextField error={!!(touched && error)} helperText={touched && error} {...inputProps} {...props} fullWidth />;

const LoginForm = ({ classes, isLoading, translate }, props) => {
  const login = useLogin();
  const notify = useNotify();

  const submit = ({ username, password }) => {
    login({ username, password }).catch(e => {
      const statusCode = get(e, "message");
      if (statusCode >= 400 && statusCode < 500) {
        notify("ra.auth.incorrectLogin", "warning");
      } else {
        notify("ra.auth.sign_in_error", "error");
      }
    });
  };

  return (
    <Form
      onSubmit={submit}
      validate={values => {
        const errors = {};
        if (!values.username) errors.username = translate("ra.validation.required");
        if (!values.password) errors.password = translate("ra.validation.required");
        return errors;
      }}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <div className={classes.form}>
            <div className={classes.input}>
              <Field
                autoFocus
                id="username"
                name="username"
                component={renderInput}
                label={translate("ra.auth.username")}
                disabled={isLoading}
              />
            </div>
            <div className={classes.input}>
              <Field
                id="password"
                name="password"
                component={renderInput}
                label={translate("ra.auth.password")}
                type="password"
                disabled={isLoading}
              />
            </div>
          </div>
          <CardActions>
            <Button variant="contained" type="submit" color="primary" disabled={isLoading} className={classes.button}>
              {isLoading && <CircularProgress size={25} thickness={2} />}
              {translate("ra.auth.sign_in")}
            </Button>
          </CardActions>
        </form>
      )}
    </Form>
  );
};

LoginForm.propTypes = {
  classes: PropTypes.object,
  redirectTo: PropTypes.string,
};

const mapStateToProps = state => ({ isLoading: state.admin.loading > 0 });

const enhance = compose(
  withStyles(styles),
  translate,
  connect(mapStateToProps)
);

export default enhance(LoginForm);
