import createAction, { createActionWithMeta } from "../../../lib/createAction";

export const REESTIMATE_PRICES_REQUEST = "REESTIMATE_PRICES_REQUEST";
export const reestimatePrices = createAction(REESTIMATE_PRICES_REQUEST, "modelId");

export const REESTIMATE_PRICES_SUCCES = "REESTIMATE_PRICES_SUCCES";
export const reestimatePricesSuccess = createActionWithMeta(
  REESTIMATE_PRICES_SUCCES,
  {
    uncache: true,
    notification: {
      body: "ra.notification.updated",
      level: "info",
    },
    refresh: true,
  },
  "response"
);

export const REESTIMATE_PRICES_ERROR = "REESTIMATE_PRICES_ERROR";
export const reestimatePricesError = createActionWithMeta(
  REESTIMATE_PRICES_ERROR,
  {
    uncache: true,
    notification: {
      body: "ra.notification.bad_item",
      level: "warning",
    },
    refresh: true,
  },
  "error"
);
