import React from "react";
import { Datagrid, List, Pagination, ShowButton, TextField, translate } from "react-admin";
import resources from "../../config/resources";
import { withStyles } from "@material-ui/core/styles";
import DateField from "../customFields/DateField";
import UnpaidPrepaymentIcon from "@material-ui/icons/MoneyOff";
import PaidIcon from "@material-ui/icons/AttachMoney";
import { getKey } from "../../lib/i18nUtils";
import Tooltip from "@material-ui/core/Tooltip";
import Avatar from "@material-ui/core/Avatar";
import ExpressIcon from "@material-ui/icons/ArrowUpward";
import NotExpressIcon from "@material-ui/icons/Close";
import red from "@material-ui/core/colors/red";
import green from "@material-ui/core/colors/green";
import yellow from "@material-ui/core/colors/yellow";
import paginationOptions from "../../config/PaginationOptions";
import ArchivedShipmentFilters from "./ArchivedShipmentFilters";
import CommentCountField from "../customFields/CommentCountField";
import CommentIcon from "@material-ui/icons/Chat";
import AuditLogButton from "../documents/AuditLogButton";

const styles = theme => ({
  field: {
    display: "block",
  },
  inlineField: {
    display: "inline-block",
  },
  fullWidthField: {
    width: "100%",
  },
  titleText: {
    padding: theme.spacing.unit / 2,
    marginRight: theme.spacing.unit * 2,
  },
  card: {
    paddingTop: theme.spacing.unit * 2,
  },
  progress: {
    maxWidth: 120,
    marginTop: 0,
  },
  badge: {
    top: 10,
    right: 12,
  },
});

const SymbolField = ({ translate, record }) => {
  if (!record) {
    return null;
  }
  const { expressPrint, paymentComplete, waitForPaymentComplete } = record;
  let paymentIcon, paymentTooltip;
  if (paymentComplete) {
    paymentIcon = <PaidIcon style={{ color: green[700] }} />;
    paymentTooltip = "paymentComplete";
  } else if (waitForPaymentComplete) {
    paymentIcon = (
      <Avatar style={{ backgroundColor: red[600] }}>
        <UnpaidPrepaymentIcon />
      </Avatar>
    );
    paymentTooltip = "prepaymentNotComplete";
  } else {
    paymentIcon = <PaidIcon style={{ color: "AAAAAA" }} />;
    paymentTooltip = "prepaymentNotComplete";
  }

  let expressIcon = expressPrint ? (
    <ExpressIcon style={{ color: yellow[900] }} />
  ) : (
    <NotExpressIcon style={{ color: "#AAAAAA" }} />
  );

  return (
    <div style={{ display: "flex", alignItems: "baseline", justifyContent: "space-evenly" }}>
      <Tooltip
        title={<span style={{ fontSize: 16 }}>{translate(getKey(paymentTooltip, resources.ARCHIVED_MODELS))}</span>}
      >
        {paymentIcon}
      </Tooltip>
      {expressIcon}
      <CommentCountField source="commentsCount" record={record} />
    </div>
  );
};

const SymbolFieldLabel = ({ translate }) => (
  <div style={{ display: "flex", alignItems: "baseline" }}>
    <Tooltip title={<span style={{ fontSize: 16 }}>{translate(getKey("payment", resources.ARCHIVED_SHIPMENTS))}</span>}>
      <PaidIcon />
    </Tooltip>
    <Tooltip title={<span style={{ fontSize: 16 }}>{translate(getKey("express", resources.ARCHIVED_SHIPMENTS))}</span>}>
      <ExpressIcon />
    </Tooltip>
    <Tooltip
      title={<span style={{ fontSize: 16 }}>{translate(getKey("comments", resources.ARCHIVED_SHIPMENTS))}</span>}
    >
      <CommentIcon />
    </Tooltip>
  </div>
);

const ArchivedShipmentsList = ({ classes, permissions, translate, hasList, hasEdit, hasShow, hasCreate, ...props }) => (
  <List
    {...props}
    bulkActionButtons={false}
    filters={<ArchivedShipmentFilters permissions={permissions} {...props} />}
    classes={{ card: classes.card }}
    actions={null}
    hasList={hasList}
    hasShow={hasShow}
    hasEdit={hasEdit}
    hasCreate={hasCreate}
    sort={{ field: "dateShipping", order: "DESC" }}
    pagination={<Pagination {...paginationOptions} />}
  >
    <Datagrid classes={{ row: classes.row }}>
      <ShowButton />
      <TextField
        source="orderRapidshopId"
        sortBy="order.rapidshopId"
        label={translate(getKey("rapidshopId", resources.ARCHIVED_ORDERS))}
      />
      <SymbolField
        {...props}
        sortBy="expressPrint"
        translate={translate}
        label={<SymbolFieldLabel translate={translate} />}
      />
      <TextField
        source="customerName"
        sortBy="order.customer"
        label={translate(getKey("customer", resources.ARCHIVED_ORDERS))}
      />
      <DateField source="dateShipping" displayFormat="DD.MM.YY" />
      <DateField source="dateOrdered" sortBy="order.dateOrdered" displayFormat="DD.MM.YY HH:mm" />
      <AuditLogButton />
    </Datagrid>
  </List>
);

export default withStyles(styles)(translate(ArchivedShipmentsList));
