import React, { useEffect, useState } from "react";
import { Button, Card, CardActions, CardContent, Grid, LinearProgress, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  useDataProvider,
  useGetList,
  useMutation,
  useRefresh,
  useTranslate,
  ReferenceManyField,
  FunctionField,
} from "react-admin";
import { connect, useDispatch } from "react-redux";
import moment from "moment";

import config from "../../config/index";
import { getKey } from "../../lib/i18nUtils";
import { resources } from "../../config/resources";
import { HAL_LINK } from "../../dataproviders/v2BackendDataProvider";
import DistinctSingleFieldList from "../customFields/relations/DistinctSingleFieldList";
import { checkPrintjobTimes } from "../../redux/printJob/action";

const useStyles = makeStyles(theme => ({
  grid: {
    paddingTop: theme.spacing(4),
  },
  button: {
    width: "100%",
  },
}));

const SendToPrintJob = ({ jobs, selectedIds, ...props }) => {
  const classes = useStyles();
  const [mutate] = useMutation();
  const translate = useTranslate();
  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  const dispatch = useDispatch();
  const [selectedJobs, setSelectedJobs] = useState([]);
  const [disabledButton, setDisabledButton] = useState(true);

  const { data: printJobs, loading } = useGetList(
    resources.PRINT_JOBS,
    { page: 1, perPage: 1000 },
    { field: "id", order: "ASC" },
    { status: ["NEW", "PACKED"] }
  );

  useEffect(() => {
    setSelectedJobs([]);
    if (selectedIds.length === 0) {
      setDisabledButton(true);
    }
    if (selectedIds.length > 0) {
      setDisabledButton(false);
      selectedIds.forEach(selectedId => {
        for (const [key, value] of Object.entries(jobs)) {
          if (key === selectedId) {
            setSelectedJobs(oldJobs => [...oldJobs, value]);
          }
        }
      });
    }
  }, [selectedIds, jobs, setSelectedJobs]);

  const addToPrintJob = printJobId => {
    let doneModels = 0;
    let totalModels = 0;
    const mutateCallback = () => {
      doneModels++;
      if (doneModels === totalModels) {
        refresh();
        dispatch(checkPrintjobTimes(printJobId));
      }
    };

    Promise.all(
      selectedJobs.map(
        async job =>
          await dataProvider(HAL_LINK, resources.MODELS, {
            link: job.models_S,
          })
      )
    ).then(models => {
      const flatModels = models.flat();
      totalModels = flatModels.length;
      flatModels.forEach(model =>
        mutate(
          {
            type: "create",
            resource: resources.PRINT_JOB_MODELS,
            payload: {
              data: {
                printJob: `${config.urls.BACKEND}/v2/printJobs/${printJobId}`,
                model: `${config.urls.BACKEND}/v2/models/${model.__id}`,
                count: model.assignedCount,
                note: "",
              },
            },
          },
          { onSuccess: mutateCallback }
        )
      );
    });
  };

  return (
    <Grid container className={classes.grid} spacing={4}>
      {loading ? (
        <LinearProgress />
      ) : (
        Object.values(printJobs).map(printJob => (
          <Grid item xs={3} key={printJob.__id}>
            <Card>
              <CardActions>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  onClick={() => {
                    addToPrintJob(printJob.__id);
                  }}
                  disabled={disabledButton}
                >
                  {translate("ra.action.sendToPrintJob", {
                    id: printJob.__id,
                  })}
                </Button>
              </CardActions>
              <CardContent>
                <Typography>
                  {translate(getKey("printHeight", resources.PRINT_JOBS))}: {printJob.height}
                </Typography>
                <Typography>
                  {translate(getKey("begin", resources.PRINT_JOBS))}:{" "}
                  {moment(printJob.printedAt).format(translate("format.date.dateTimeFormat"))}{" "}
                </Typography>
                <Typography>
                  {translate(getKey("completedAt", resources.PRINT_JOBS))}:{" "}
                  {moment(printJob.completedAt).format(translate("format.date.dateTimeFormat"))}{" "}
                </Typography>
                <ReferenceManyField
                  basePath={"/" + resources.PRINT_JOBS}
                  resource={resources.PRINT_JOBS}
                  record={printJob}
                  reference={resources.MODELS}
                  target="printJobModels#printJob#id"
                >
                  <DistinctSingleFieldList collapseOn="materialName">
                    <FunctionField
                      render={({ materialName }) => (
                        <Typography>
                          {translate(getKey("material", resources.MODELS))}: {materialName}
                        </Typography>
                      )}
                    />
                  </DistinctSingleFieldList>
                </ReferenceManyField>
              </CardContent>
            </Card>
          </Grid>
        ))
      )}
    </Grid>
  );
};

const mapStateToProps = state => ({
  jobs: state.admin?.resources?.jobs?.data,
  selectedIds: state.admin?.resources?.jobs?.list?.selectedIds,
});
export default connect(mapStateToProps)(SendToPrintJob);
