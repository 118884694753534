import { buffers, END, eventChannel } from "redux-saga";
import * as SockJs from "sockjs-client";
import * as StompJs from "@stomp/stompjs";

const WebSocketFactory = endpoint => () => new SockJs(endpoint);

/**
 *
 * @param {string} name
 * @param rest
 * @returns {{type: string}}
 */
const Event = (name, rest = {}) => ({ type: name, ...rest });

export const EVENTS = {};

EVENTS.CONNECTED = "CONNECTED";
EVENTS.connect = frame => Event(EVENTS.CONNECTED, { frame });

EVENTS.DISCONNECTED = "DISCONNECTED";
EVENTS.disconnect = frame => Event(EVENTS.DISCONNECTED, { frame, exit: true });

EVENTS.ACTIVATED = "ACTIVATED";
EVENTS.activate = () => Event(EVENTS.ACTIVATED);

EVENTS.SUBSCRIBED = "SUBSCRIBED";
EVENTS.subscribe = topic => Event(EVENTS.SUBSCRIBED, { topic });

EVENTS.RECEIVED_MESSAGE = "RECEIVED_MESSAGE";
EVENTS.onMessage = (topic, body, headers) => Event(EVENTS.RECEIVED_MESSAGE, { topic, body, headers });

EVENTS.ERROR = "ERROR";
EVENTS.onError = frame => Event(EVENTS.ERROR, { frame });

EVENTS.WEBSOCKET_CLOSED = "WEBSOCKET_CLOSED";
EVENTS.onWebsocketClose = closeEvent => Event(EVENTS.WEBSOCKET_CLOSED, { closeEvent });

export const createWebSocketChannel = (socketUrl, { subscribeTopics = [], debug = false, stompConfig = {} } = {}) =>
  eventChannel(emitter => {
    const stompClient = new StompJs.Client({
      ...stompConfig,
      webSocketFactory: WebSocketFactory(socketUrl),
    });

    if (debug) {
      stompClient.debug = body => console.debug("WEBSOCKET DEBUGGER", body);
    }

    stompClient.onConnect = frame => {
      subscribeTopics.forEach(topic => {
        stompClient.subscribe(topic, ({ headers, body }) => emitter(EVENTS.onMessage(topic, body, headers)));
        emitter(EVENTS.subscribe(topic));
      });

      emitter(EVENTS.connect(frame));
    };

    stompClient.onStompError = frame => emitter(EVENTS.ERROR(frame));

    stompClient.onDisconnect(frame => {
      emitter(EVENTS.disconnect(frame));
      emitter(END);
    });

    stompClient.onWebSocketClose(closeEvent => {
      emitter(EVENTS.onWebsocketClose(closeEvent));
    });

    stompClient.activate();
    emitter(EVENTS.activate());

    return () => {
      stompClient.deactivate();
    };
  }, buffers.sliding(2));
