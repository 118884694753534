import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { AppBar, GET_LIST, Query, showNotification, translate, usePermissions, UserMenu } from "react-admin";
import Chip from "@material-ui/core/Chip";
import Typography from "@material-ui/core/Typography";
import yellow from "@material-ui/core/colors/yellow";
import grey from "@material-ui/core/colors/grey";
import red from "@material-ui/core/colors/red";
import Badge from "@material-ui/core/Badge";
import { isBrowserRecommended, isBrowserSupported } from "../../lib/browserSupport";
import {
  getRestartingAt,
  getRestartNotificationFetchSuccess,
  getRestartScheduled,
} from "../../redux/notifications/restart/selector";
import moment from "moment";
import { hasPingError } from "../../redux/ping/selector";
import { hasStsPingError } from "../../redux/storageService/ping/selector";
import { Link } from "react-router-dom";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton";
import ChangePasswordIcon from "@material-ui/icons/VpnKey";
import NotificationsIcon from "@material-ui/icons/Notifications";
import resources from "../../config/resources";
import NotificationConfigIcon from "@material-ui/icons/AddAlert";
import UserAvatar from "../../components/userDetails/UserAvatar";
import NotificationRoot from "../../components/notifications/NotificationRoot";
import { getNotificationCount } from "../../redux/notifications/selectors";
import { hasAnyRole } from "../../lib/permissionChecks";
import Roles from "../../config/Roles";
import SyncModelsButton from "../../components/models/SyncModelsButton";
import ReestimatePricesButton from "../../components/models/ReestimatePricesButton";

const ChangePasswordLink = props => <Link {...props} to="/changepw" />;
const NotificationConfigLink = props => <Link {...props} to={`/${resources.NOTIFICATION_CONFIGS}`} />;

const UserIcon = ({ permissions }) =>
  permissions ? (
    <UserAvatar
      user={permissions.id}
      displayName={permissions.displayName}
      displayStyle={permissions.displayStyle}
      size={32}
      useTooltip={false}
    />
  ) : null;

const _CustomUserMenu = ({ translate, permissions, ...props }) => (
  <UserMenu {...props} icon={<UserIcon permissions={permissions} />}>
    {hasAnyRole(permissions, [Roles.ROLE_ADMIN.key, Roles.ROLE_SALES.key]) && (
      <MenuItem component={NotificationConfigLink}>
        <ListItemIcon>
          <NotificationConfigIcon style={{ margin: 0 }} />
        </ListItemIcon>
        <ListItemText primary={translate(`resources.${resources.NOTIFICATION_CONFIGS}.name`, { smart_count: 1 })} />
      </MenuItem>
    )}
    <MenuItem component={ChangePasswordLink}>
      <ListItemIcon>
        <ChangePasswordIcon style={{ margin: 0 }} />
      </ListItemIcon>
      <ListItemText primary={translate("changePassword.button")} />
    </MenuItem>
  </UserMenu>
);
const CustomUserMenu = translate(_CustomUserMenu);

const sanitizeRestProps = ({ restartingAt, ...props }) => props;

const CustomAppBar = ({
  restartScheduled,
  pingHasError,
  stsPingHasError,
  showNotification,
  restartingAt,
  translate,
  fetchSuccess,
  additionalNotificationCount,
  classes,
  location,
  ...props
}) => {
  const [restartingIn, setRestartingIn] = useState("");
  const [renderInterval, setRenderInterval] = useState(null);
  const { permissions } = usePermissions();

  const calculateRestartingIn = ({ restartingAt }) => {
    if (!moment().isBefore(restartingAt)) {
      restartingAt = moment();
    }
    setRestartingIn(moment(restartingAt).fromNow());
  };

  useEffect(() => {
    if (restartScheduled) {
      calculateRestartingIn({ restartingAt });
      if (renderInterval === null) {
        const interval = setInterval(() => this.calculateRestartingIn(), 1000);
        setRenderInterval(interval);
      }
    } else if (renderInterval !== null) {
      clearInterval(renderInterval);
      setRenderInterval(null);
    }

    if (restartScheduled) {
      showNotification("restartScheduledAt", "info", {
        autoHideDuration: 8000,
        messageArgs: { time: moment(restartingAt).format("HH:mm:ss") },
      });
    }
  }, [restartScheduled, showNotification, restartingAt, renderInterval]);
  useEffect(() => {
    if (pingHasError) {
      showNotification("pingErrorDetails", "warning", {
        autoHideDuration: 8000,
      });
    }
  }, [pingHasError, showNotification]);
  useEffect(() => {
    if (stsPingHasError) {
      showNotification("stsPingErrorDetails", "warning", {
        autoHideDuration: 8000,
      });
    }
  }, [stsPingHasError, showNotification]);

  return (
    <Fragment>
      <AppBar {...sanitizeRestProps(props)} userMenu={<CustomUserMenu permissions={permissions} />}>
        <Typography
          variant="h6"
          color="inherit"
          style={{
            flex: 1,
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
          }}
          id="react-admin-title"
        />
        {(!isBrowserSupported || !isBrowserRecommended) && (
          <Chip
            label={!isBrowserSupported ? translate("browserNotSupported") : translate("browserNotRecommended")}
            style={{
              backgroundColor: !isBrowserSupported ? yellow[600] : grey[400],
              marginRight: 4,
            }}
          />
        )}
        {!fetchSuccess || !restartScheduled ? null : (
          <Chip
            label={translate("restartScheduled", { restartingIn })}
            style={{ backgroundColor: yellow[600], marginRight: 4 }}
          />
        )}
        {!pingHasError ? null : (
          <Chip
            label={translate("pingError")}
            style={{
              backgroundColor: red[600],
              color: "#FFF",
              fontWeight: 700,
              border: `solid 3px ${red[900]}`,
              marginRight: 4,
            }}
          />
        )}
        {!stsPingHasError ? null : (
          <Chip
            label={translate("stsPingError")}
            style={{
              backgroundColor: red[600],
              color: "#FFF",
              fontWeight: 700,
              border: `solid 3px ${red[900]}`,
              marginRight: 4,
            }}
          />
        )}
        {hasAnyRole(permissions, [Roles.ROLE_ADMIN.key]) && (
          <div>
            <SyncModelsButton
              {...props}
              label="syncModels"
              variant="text"
              style={{ color: "#FFFFFF" }}
              progressStyle={{ color: "#FFFFFF" }}
              source="syncButton"
            />
            <ReestimatePricesButton
              {...props}
              label="reestimatePrices"
              variant="text"
              style={{ color: "#FFFFFF" }}
              progressStyle={{ color: "#FFFFFF" }}
              source="reestimatePricesButton"
            />
          </div>
        )}
        {hasAnyRole(permissions, [Roles.ROLE_ADMIN.key, Roles.ROLE_SALES.key]) && (
          <Query
            type={GET_LIST}
            resource={resources.NOTIFICATIONS}
            payload={{
              pagination: {
                page: 1,
                perPage: additionalNotificationCount + 1,
              },
              filter: {
                read: "false",
                "translationKey#neq": location,
              },
              sort: { field: "id", order: "ASC" },
            }}
          >
            {({ total }) => (
              <IconButton style={{ color: "#FFFFFF" }} component={Link} to={`/${resources.NOTIFICATIONS}`}>
                <Badge
                  badgeContent={<span style={{ fontWeight: 900 }}>{total || "0"}</span>}
                  color={total > 0 ? "error" : "default"}
                >
                  <NotificationsIcon />
                </Badge>
              </IconButton>
            )}
          </Query>
        )}
      </AppBar>
      <NotificationRoot />
    </Fragment>
  );
};

export default connect(
  state => ({
    restartScheduled: getRestartScheduled(state),
    restartingAt: getRestartingAt(state),
    fetchSuccess: getRestartNotificationFetchSuccess(state),
    pingHasError: hasPingError(state),
    stsPingHasError: hasStsPingError(state),
    additionalNotificationCount: getNotificationCount(state),
    location: window.location.pathname + window.location.hash,
  }),
  { showNotification }
)(translate(CustomAppBar));
