import React from "react";
import { useTranslate, SimpleForm, Toolbar, SaveButton, ReferenceInput, SelectInput } from "react-admin";
import { makeStyles } from "@material-ui/core/";
import { useHistory } from "react-router-dom";

import { getKey } from "../../lib/i18nUtils";
import resources from "../../config/resources";
import CustomDateInput from "../CustomDateInput";
import ExecutionTypes from "../../config/ExecutionTypes";

const useStyles = makeStyles(theme => ({
  input: {
    width: "100%",
  },
}));

const PostProcessingForm = ({ ...props }) => {
  const classes = useStyles();
  const translate = useTranslate();
  const history = useHistory();

  return (
    <SimpleForm
      initialValues={{ enabled: true }}
      {...props}
      toolbar={
        <Toolbar {...props}>
          <SaveButton
            onSuccess={() => {
              history.goBack();
            }}
          />
        </Toolbar>
      }
    >
      <ReferenceInput
        reference={resources.ADDITIONS}
        source="addition"
        label={translate(getKey("addition", resources.POSTPROCESSING_JOB))}
        className={classes.input}
      >
        <SelectInput optionText="name" optionValue="id" helperText={false} fullWidth />
      </ReferenceInput>

      <SelectInput source="execution" choices={ExecutionTypes} optionValue="name" helperText={false} fullWidth />

      <CustomDateInput
        source="start"
        className={classes.printJobInput}
        label={translate(getKey("start", resources.POSTPROCESSING_JOB))}
        initialValue={new Date()}
        fullWidth
      />
      <CustomDateInput
        source="handover"
        className={classes.printJobInput}
        label={translate(getKey("handover", resources.POSTPROCESSING_JOB))}
        initialValue={new Date()}
        fullWidth
      />
      <CustomDateInput
        source="end"
        className={classes.printJobInput}
        label={translate(getKey("end", resources.POSTPROCESSING_JOB))}
        initialValue={new Date()}
        fullWidth
      />
    </SimpleForm>
  );
};

export default PostProcessingForm;
