import { Cache } from "./ResourceCache";
import resources from "../config/resources";

export const cache = Cache(Object.values(resources), () => true);
export default cache;

export const prefetching = [];

export const createCacheMeta = fetchedAt => ({ fetchedAt });

window.customCache = cache;
