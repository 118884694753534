import config from "../../../config";
import resources from "../../../config/resources";
import { call, put, takeEvery } from "redux-saga/effects";
import fetchService from "../../../lib/fetchService";
import { handleSagaError } from "../../../dataproviders/errorResponseHandler";
import { APPLY_ALL_NOTICES_REQUEST, applyAllNoticesError, applyAllNoticesSuccess } from "./action";
import { crudGetMany } from "react-admin";

const requestSaga = function*({ payload }) {
  const { models } = payload;

  const url = `${config.urls.BACKEND}/v2/${resources.MODELS}/applyNotices`;
  const body = { ids: models };

  try {
    const response = yield call(fetchService.post, url, {}, body);

    for (const action of yield call(handleSagaError, response, applyAllNoticesError)) {
      yield put(action);
      return;
    }

    if (response.ok) {
      yield put(applyAllNoticesSuccess());
      yield put(crudGetMany(resources.MODELS, models));
    } else {
      const statusCode = response.status;
      yield put(applyAllNoticesError(new Error(`Request failed with error ${statusCode}!`)));
    }
  } catch (e) {
    yield put(applyAllNoticesError(e));
  }
};

export default function*() {
  yield takeEvery(APPLY_ALL_NOTICES_REQUEST, requestSaga);
}
