export const RohStatus = {
  UNASSIGNED: {
    key: "UNASSIGNED",
    apiValue: "UNASSIGNED",
    primaryColor: "#ffb133",
    textColor: "#000000",
    borderColor: "#804d00",
    /*primaryColor: "rgba(239, 108, 0, 0.05)",
    textColor: "#4d1b00",
    borderColor: "#EF6C00",*/
    importance: 0, //smaller means more important
    translationKey: "orderStatus.ORDER_STATUS_UNASSIGNED",
    contractorTranslationKey: "orderStatus.contractorView.ORDER_STATUS_UNASSIGNED",
    filterable: true,
    modelStatus: true,
    jobStatus: false,
    subtaskStatus: true,
  },
  ASSIGNED: {
    key: "ASSIGNED",
    apiValue: "ASSIGNED",
    primaryColor: "rgba(198, 98, 95, 0.05)",
    textColor: "#02364d",
    borderColor: "#03A9F4",
    importance: 1, //smaller means more important
    translationKey: "orderStatus.ORDER_STATUS_ASSIGNED",
    contractorTranslationKey: "orderStatus.contractorView.ORDER_STATUS_ASSIGNED",
    filterable: true,
    modelStatus: true,
    jobStatus: false,
    subtaskStatus: true,
  },
  NEW: {
    key: "NEW",
    apiValue: "NEW",
    primaryColor: "#66d6ff",
    textColor: "#000000",
    borderColor: "#077fb3",
    importance: 1, //smaller means more important
    translationKey: "orderStatus.ORDER_STATUS_NEW",
    contractorTranslationKey: "orderStatus.contractorView.ORDER_STATUS_NEW",
    filterable: true,
    modelStatus: false,
    jobStatus: true,
    subtaskStatus: false,
  },
  OFFER_SENT: {
    key: "OFFER_SENT",
    apiValue: "OFFER_SENT",
    primaryColor: "rgba(158, 158, 158, 0.158)",
    textColor: "#4d4d4d",
    borderColor: "#9e9e9e",
    translationKey: "orderStatus.ORDER_STATUS_OFFER_SENT",
    contractorTranslationKey: "orderStatus.contractorView.ORDER_STATUS_OFFER_SENT",
    filterable: true,
    importance: 2,
    modelStatus: true,
    jobStatus: true,
    subtaskStatus: true,
  },
  IN_PRODUCTION: {
    key: "IN_PRODUCTION",
    apiValue: "IN_PRODUCTION",
    primaryColor: "#9CCC65",
    textColor: "#000000",
    borderColor: "#638040",
    translationKey: "orderStatus.ORDER_STATUS_IN_PRODUCTION",
    contractorTranslationKey: "orderStatus.contractorView.ORDER_STATUS_IN_PRODUCTION",
    filterable: true,
    importance: 3,
    modelStatus: false,
    jobStatus: true,
    subtaskStatus: false,
  },
  ACCEPTED: {
    key: "ACCEPTED",
    apiValue: "ACCEPTED",
    primaryColor: "#9CCC65",
    textColor: "#000000",
    borderColor: "#638040",
    translationKey: "orderStatus.ORDER_STATUS_IN_PRODUCTION",
    contractorTranslationKey: "orderStatus.contractorView.ORDER_STATUS_IN_PRODUCTION",
    filterable: true,
    importance: 3,
    modelStatus: true,
    jobStatus: false,
    subtaskStatus: false,
  },
  IN_TRANSPORT: {
    key: "IN_TRANSPORT",
    apiValue: "IN_TRANSPORT",
    primaryColor: "#9CCC65",
    textColor: "#000000",
    borderColor: "#638040",
    translationKey: "orderStatus.ORDER_STATUS_IN_TRANSPORT",
    contractorTranslationKey: "orderStatus.contractorView.ORDER_STATUS_IN_TRANSPORT",
    filterable: true,
    importance: 4,
    modelStatus: true,
    jobStatus: true,
    subtaskStatus: true,
  },
  RECEIVED: {
    key: "RECEIVED",
    apiValue: "RECEIVED",
    primaryColor: "rgba(158, 158, 158, 0.158)",
    textColor: "#4d4d4d",
    borderColor: "#9e9e9e",
    translationKey: "orderStatus.ORDER_STATUS_RECEIVED",
    contractorTranslationKey: "orderStatus.contractorView.ORDER_STATUS_RECEIVED",
    filterable: true,
    importance: 5,
    modelStatus: true,
    jobStatus: true,
    subtaskStatus: true,
  },
  DECLINED: {
    key: "DECLINED",
    apiValue: "DECLINED",
    primaryColor: "rgba(244, 67, 54, 0.05)",
    textColor: "#4d1612",
    borderColor: "#f44336",
    translationKey: "orderStatus.ORDER_STATUS_DECLINED",
    contractorTranslationKey: "orderStatus.contractorView.ORDER_STATUS_DECLINED",
    filterable: true,
    importance: 1,
    modelStatus: false,
    jobStatus: false,
    subtaskStatus: false,
  },
  NEEDS_UPDATE: {
    key: "NEEDS_UPDATE",
    apiValue: "NEEDS_UPDATE",
    primaryColor: "#f44336",
    textColor: "#FFFFFF",
    borderColor: "#80241d",
    translationKey: "orderStatus.ORDER_STATUS_NEEDS_UPDATE",
    contractorTranslationKey: "orderStatus.contractorView.ORDER_STATUS_NEEDS_UPDATE",
    filterable: true,
    importance: 1,
    modelStatus: true,
    jobStatus: true,
    subtaskStatus: true,
  },
  ADDITIONAL_WORK: {
    key: "ADDITIONAL_WORK",
    apiValue: "ADDITIONAL_WORK",
    primaryColor: "#ad1457",
    textColor: "#FFFFFF",
    borderColor: "#f8bbd0",
    /*primaryColor: "rgba(239, 108, 0, 0.05)",
    textColor: "#4d1b00",
    borderColor: "#EF6C00",*/
    importance: 7, //smaller means more important
    translationKey: "orderStatus.ORDER_STATUS_ADDITIONAL_WORK",
    contractorTranslationKey: "orderStatus.contractorView.ORDER_STATUS_ADDITIONAL_WORK",
    filterable: true,
    modelStatus: true,
    jobStatus: false,
    subtaskStatus: true,
  },
  QUALITY_CONTROL: {
    key: "QUALITY_CONTROL",
    apiValue: "QUALITY_CONTROL",
    primaryColor: "#7b1fa2",
    textColor: "#fff",
    borderColor: "#e1bee7",
    /*primaryColor: "rgba(239, 108, 0, 0.05)",
    textColor: "#4d1b00",
    borderColor: "#EF6C00",*/
    importance: 7, //smaller means more important
    translationKey: "orderStatus.ORDER_STATUS_QUALITY_CONTROL",
    contractorTranslationKey: "orderStatus.contractorView.ORDER_STATUS_QUALITY_CONTROL",
    filterable: true,
    modelStatus: true,
    jobStatus: false,
    subtaskStatus: true,
  },
  QUALITY_CONTROL_FAILED: {
    key: "QUALITY_CONTROL_FAILED",
    apiValue: "QUALITY_CONTROL_FAILED",
    primaryColor: "#f44336",
    textColor: "#FFFFFF",
    borderColor: "#80241d",
    /*primaryColor: "rgba(239, 108, 0, 0.05)",
    textColor: "#4d1b00",
    borderColor: "#EF6C00",*/
    importance: 7, //smaller means more important
    translationKey: "orderStatus.ORDER_STATUS_QUALITY_CONTROL_FAILED",
    contractorTranslationKey: "orderStatus.contractorView.ORDER_STATUS_QUALITY_CONTROL_FAILED",
    filterable: true,
    modelStatus: true,
    jobStatus: false,
    subtaskStatus: true,
  },
  COMPLETED: {
    key: "COMPLETED",
    apiValue: "COMPLETED",
    primaryColor: "rgba(156, 204, 101, 0.05)",
    textColor: "#3b4d26",
    borderColor: "#9CCC65",
    translationKey: "orderStatus.ORDER_STATUS_COMPLETED",
    contractorTranslationKey: "orderStatus.contractorView.ORDER_STATUS_COMPLETED",
    filterable: true,
    importance: 6,
    modelStatus: true,
    jobStatus: false,
    subtaskStatus: true,
  },
  CANCELED: {
    key: "CANCELED",
    apiValue: "CANCELED",
    primaryColor: "rgba(158, 158, 158, 0.158)",
    textColor: "#4d4d4d",
    borderColor: "#9e9e9e",
    translationKey: "orderStatus.ORDER_STATUS_CANCELED",
    contractorTranslationKey: "orderStatus.contractorView.ORDER_STATUS_CANCELED",
    filterable: true,
    importance: 7,
    modelStatus: false,
    jobStatus: false,
    subtaskStatus: false,
  },
  [null]: {
    key: null,
    primaryColor: "#D32F2F",
    textColor: "#ffffff",
    importance: 10000,
    translationKey: "orderStatus.ORDER_STATUS_UNKNOWN",
    contractorTranslationKey: "orderStatus.contractorView.ORDER_STATUS_UNKNOWN",
    filterable: false,
    modelStatus: false,
    jobStatus: false,
  },
};

export default RohStatus;
