import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import PropTypes from "prop-types";
import get from "lodash/get";
import { useNotify } from "react-admin";

import SubtaskCreate from "./SubtaskCreate";
import SubtaskEdit from "./SubtaskEdit";
import FormActions from "../../config/FormActions";
import classnames from "classnames";
import {
  isCreatingSubtask,
  isSubtaskUpdating,
  wasSubtaskCreationSuccessful,
  wasSubtaskUpdateSuccessful,
} from "../../redux/subtasks/selector";
import usePrevious from "../../lib/customHooks/usePrevious";

const styles = () => ({
  hidden: {
    display: "none",
  },
  fullWidth: {
    width: "100%",
  },
  innerButton: {
    display: "flex",
    alignItems: "center",
  },
  link: {
    textDecoration: "none",
  },
});

const sanitizeRestProps = ({ currentSort, loaded, statusList, ...rest }) => rest;

const SubtaskPopup = ({
  record,
  classes,
  action,
  fullWidth,
  children,
  parentRecord,
  isUpdating,
  updateSuccess,
  isCreating,
  creationSuccess,
  ...props
}) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [hidePopup, setHidePopup] = useState(false);
  const [formContent, setFormContent] = useState(null);

  const notify = useNotify();
  const wasUpdating = usePrevious(isUpdating);

  useEffect(() => {
    if (wasUpdating && !isUpdating && updateSuccess) {
      notify("ra.notification.updated", "info");
    }
  }, [isUpdating, updateSuccess, wasUpdating]);

  // TODO: Add creation notification! Somehow this component renders too often and executes the creation notification, way too many times
  // const wasCreating = usePrevious(isCreating);
  // useEffect(() => {
  //   if (wasCreating && !isCreating) {
  //     creationSuccess
  //       ? notify("ra.notification.created", "info")
  //       : notify("ra.notification.http_error", "error");
  //   }
  // }, [isCreating]);

  useEffect(() => {
    if (action === FormActions.CREATE) {
      if ((get(record, "shipment", false) || get(record, "model", false)) && parentRecord) {
        setFormContent(
          <SubtaskCreate
            record={record}
            parentRecord={parentRecord}
            {...sanitizeRestProps(props)}
            closeDialog={() => setDialogOpen(false)}
          />
        );
      } else {
        setHidePopup(true);
      }
    } else if (action === FormActions.EDIT) {
      setFormContent(
        <SubtaskEdit record={record} {...sanitizeRestProps(props)} closeDialog={() => setDialogOpen(false)} />
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [record]);

  return (
    <Fragment>
      <a
        role="button"
        href="/#"
        onClick={e => {
          e.preventDefault();
          setDialogOpen(true);
        }}
        className={classnames(classes.link, fullWidth && classes.fullWidth, hidePopup && classes.hidden)}
      >
        {children ? React.cloneElement(children) : null}
      </a>
      <Dialog fullWidth open={dialogOpen && formContent && !hidePopup} onClose={() => setDialogOpen(false)}>
        {formContent ?? null}
      </Dialog>
    </Fragment>
  );
};

SubtaskPopup.propTypes = {
  classes: PropTypes.object,
  record: PropTypes.object,
  action: PropTypes.string,
  fullWidth: PropTypes.bool,
  children: PropTypes.object,
  parentRecord: PropTypes.object,
};

const mapStateToProps = (state, props) => ({
  isUpdating: isSubtaskUpdating(get(props, "record.id"))(state),
  updateSuccess: wasSubtaskUpdateSuccessful(get(props, "record.id"))(state),
  isCreating: isCreatingSubtask(state),
  creationSuccess: wasSubtaskCreationSuccessful(state),
});

export default compose(
  connect(mapStateToProps),
  withStyles(styles)
)(SubtaskPopup);
