import React from "react";
import PropTypes from "prop-types";
import {
  CloneButton,
  CreateButton,
  Datagrid,
  EditButton,
  List,
  Pagination,
  ShowButton,
  TextField,
  TopToolbar,
  translate,
} from "react-admin";
import FormulaFilters from "./FormulaFilters";
import Chip from "@material-ui/core/Chip";
import { withStyles } from "@material-ui/core/styles";
import FormulaTypes from "../../config/FormulaTypes";
import resources from "../../config/resources";
import { getKey } from "../../lib/i18nUtils";
import paginationOptions from "../../config/PaginationOptions";
import CustomDeleteButton from "../customFields/buttons/CustomDeleteButton";

const styles = () => ({
  actionButton: {
    display: "flex",
    justifyContent: "flex-end",
    float: "right",
  },
  formularField: {
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    maxWidth: "15vw",
  },
});

const TypeField = translate(({ record, translate }) => (
  <span>{record.type ? <Chip label={translate(FormulaTypes[record.type].translation)} /> : null}</span>
));

const Actions = ({ basePath, displayedFilters, filters, filterValues, resource, showFilter }) => (
  <TopToolbar>
    {filters &&
      React.cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: "button",
      })}
    <CreateButton basePath={basePath} />
  </TopToolbar>
);

const sanitizeCloneRecordProps = ({
  autoAssignJobLogs,
  autoAssignJobLogs_A,
  autoAssignJobLogs_S,
  autoAssignMaterials,
  autoAssignMaterials_A,
  autoAssignMaterials_S,
  formula_A,
  formula_S,
  currentVersion,
  formula,
  id,
  ...props
}) => props;

const CustomCloneButton = ({ record, ...props }) => (
  <CloneButton {...props} record={sanitizeCloneRecordProps(record)} />
);

const FormulasList = ({ translate, classes, ...props }) => (
  <List
    {...props}
    actions={<Actions />}
    bulkActionButtons={false}
    filters={<FormulaFilters />}
    pagination={<Pagination {...paginationOptions} />}
  >
    <Datagrid>
      <TextField label={translate(getKey("id", resources.FORMULAS))} source="__id" />
      <TextField source="currentVersion" label={translate(getKey("version", resources.FORMULAS))} />
      <TextField source="name" />
      <TextField
        source="formulaStr"
        label={translate(getKey("formula", resources.FORMULAS))}
        cellClassName={classes.formularField}
      />
      <TypeField label={translate(getKey("type", resources.FORMULAS))} />
      <TextField
        source="autoAssignMaterialAmount"
        label={translate(getKey("autoAssignMaterials", resources.FORMULAS))}
      />
      <ShowButton className={classes.actionButton} />
      <EditButton className={classes.actionButton} />
      <CustomCloneButton className={classes.actionButton} />
      <CustomDeleteButton className={classes.actionButton} undoable={false} />
    </Datagrid>
  </List>
);

Actions.propTypes = {
  basePath: PropTypes.string,
  displayedFilters: PropTypes.object,
  filters: PropTypes.object,
  filterValues: PropTypes.object,
  resource: PropTypes.string,
  showFilter: PropTypes.func,
};

TypeField.propTypes = {
  translate: PropTypes.func,
  record: PropTypes.object,
};

FormulasList.propTypes = {
  classes: PropTypes.object.isRequired,
  translate: PropTypes.func.isRequired,
};

export default withStyles(styles)(translate(FormulasList));
