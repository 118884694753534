import React from "react";
import PropTypes from "prop-types";
import { Create, maxLength, minLength, required, SelectInput, SimpleForm, TextInput, translate } from "react-admin";
import TemplateTypes from "../../config/TemplateTypes";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { templateBase } from "../../config/templateValues";

const styles = () => ({
  inputField: {
    width: "40rem",
  },
  nameInput: {
    width: "20rem",
  },
  longText: {
    border: "solid 1px #aaa",
    padding: 8,
  },
});

const nameValidation = [required(), minLength(3), maxLength(100)];
const typeValidation = required();
const sujectValidation = [required(), minLength(1), maxLength(150)];
const bodyValidation = required();

const TemplateCreate = ({ classes, translate, ...props }) => (
  <Create {...props}>
    <SimpleForm initialValues={{ enabled: true, body: templateBase }} variant="standard" margin="normal">
      <Grid container direction="column">
        <Grid item container direction="row" justify="space-between" spacing={32}>
          <Grid item {...props}>
            <TextInput {...props} source="name" resettable className={classes.nameInput} validate={nameValidation} />
          </Grid>
          <Grid item>
            <SelectInput
              {...props}
              source="type"
              choices={Object.values(TemplateTypes)}
              optionText={({ translation }) => translate(translation)}
              optionValue="key"
              resettable
              validate={typeValidation}
            />
          </Grid>
        </Grid>
        <Grid item>
          <TextInput
            {...props}
            source="subject"
            resettable
            className={classes.inputField}
            validate={sujectValidation}
          />
        </Grid>
        <Grid item>
          <TextInput
            {...props}
            validate={bodyValidation}
            source="body"
            fullWidth={true}
            addLabel={false}
            className={classes.longText}
            multiline
          />
        </Grid>
      </Grid>
    </SimpleForm>
  </Create>
);

TemplateCreate.propTypes = {
  classes: PropTypes.object.isRequired,
  translate: PropTypes.func.isRequired,
};

export default withStyles(styles)(translate(TemplateCreate));
