import TableRow from "@material-ui/core/TableRow";
import { Checkbox, TableCell } from "@material-ui/core";
import React, { cloneElement, isValidElement } from "react";
import classnames from "classnames";

const ActionDatagridRow = ({
  basePath,
  children,
  classes = {},
  className,
  hover = true,
  id,
  record = {},
  resource,
  rowClick,
  selected = false,
  style,
  selectable = true,
  history,
  hasBulkActions,
  onToggleItem,
  rowAction,
  applySelectFilterToAction,
  onAction,
  ...rest
}) => {
  const showAction = applySelectFilterToAction ? selectable : true;

  // handleClick stuff might be missing, but who cares, right?

  return (
    <TableRow className={className} key={id} style={style} hover={hover} {...rest}>
      {rowAction && (
        <TableCell padding="none" className={classes.row}>
          {showAction &&
            cloneElement(rowAction, {
              id: id,
              record: record,
              onClick: onAction,
              classes: classes,
              contentId: `${id}-action`,
            })}
        </TableCell>
      )}
      {hasBulkActions && (
        <TableCell padding="checkbox">
          {selectable && (
            <Checkbox
              color="primary"
              className={`select-item ${classes.checkbox}`}
              checked={selected}
              onClick={onToggleItem}
            />
          )}
        </TableCell>
      )}
      {React.Children.map(
        children,
        (field, index) =>
          isValidElement(field) && (
            <TableCell
              className={classnames(`column-${field.props.source}`, classes.rowCell)}
              key={`${id}-${field.props.source || index}`}
            >
              {React.cloneElement(field, { record, basePath, resource })}
            </TableCell>
          )
      )}
    </TableRow>
  );
};

export default ActionDatagridRow;
