import React, { cloneElement } from "react";
import TableBody from "@material-ui/core/TableBody";
import classnames from "classnames";
import ActionDatagridRow from "./ActionDatagridRow";
import { noop } from "../../lib/util";

const ActionDatagridBody = ({
  basePath,
  children,
  classes,
  className,
  data = {},
  hasBulkActions = false,
  hover,
  ids = [],
  onToggleItem,
  resource,
  row = <ActionDatagridRow />,
  rowClick,
  rowStyle,
  selectedIds,
  styles,
  version,
  isRowSelectable,
  isLoading,
  rowAction,
  applySelectFilterToAction = true,
  onAction = noop,
  ...rest
}) => (
  <TableBody className={classnames("datagrid-body", className)} {...rest}>
    {ids.map((id, rowIndex) =>
      cloneElement(
        row,
        {
          basePath,
          classes,
          className: classnames(classes.row, {
            [classes.rowEven]: rowIndex % 2 === 0,
            [classes.rowOdd]: rowIndex % 2 !== 0,
            [classes.clickableRow]: rowClick,
          }),
          hasBulkActions,
          hover,
          id,
          key: id,
          record: data[id],
          resource,
          rowClick,
          selectable: !isRowSelectable || isRowSelectable(data[id]),
          selected: selectedIds.includes(id),
          onToggleItem,
          style: rowStyle ? rowStyle(data[id], rowIndex) : null,
          rowAction,
          applySelectFilterToAction,
          onAction,
        },
        children
      )
    )}
  </TableBody>
);

// trick material-ui Table into thinking this is one of the child type it supports
ActionDatagridBody.muiName = "TableBody";

export default ActionDatagridBody;
