import React, { useState } from "react";
import { getKey } from "../../lib/i18nUtils";
import { translate } from "react-admin";
import Typography from "@material-ui/core/Typography";
import get from "lodash/get";

const LabeledCollapsableListField = ({ label, translate, style, source, record, collapsedCount = 2, ...props }) => {
  const value = get(record, source);
  const [open, setOpen] = useState(false);

  if (!Array.isArray(value) || value.isEmpty) {
    return null;
  }

  const showAll = value.length <= collapsedCount + 1;

  const handleClick = showAll ? () => {} : () => setOpen(!open);

  const toListItem = it => <li onClick={handleClick}>{it}</li>;

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <Typography variant="body1" style={{ display: "inline-flex", fontWeight: 700 }} onClick={handleClick}>
        {typeof label === "string" && label.length > 0 ? label : translate(getKey(source, props.resource))}
        :&nbsp;
      </Typography>
      <ul>
        {open || showAll ? value.map(toListItem) : value.slice(0, collapsedCount).map(toListItem)}
        {!showAll && !open && <li onClick={handleClick}>...</li>}
      </ul>
    </div>
  );
};

export default translate(LabeledCollapsableListField);
