import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import { translate } from "react-admin";

const styles = theme => ({
  container: {
    minHeight: `${theme.spacing(4)}px`,
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    justifyContent: "start",
    flexWrap: "wrap",
    flex: 1,
  },
  singleContainer: {
    height: `${theme.spacing(2)}px`,
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    justifyContent: "start",
    flexWrap: "wrap",
  },
  unlimitedContainer: {
    height: "auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    justifyContent: "start",
    flexWrap: "wrap",
  },
  singleStatus: {
    display: "inline-block",
    boxSizing: "content-box",
    padding: "2px",
    borderRadius: "2px",
  },
  singleText: {
    fontSize: `${theme.spacing(1.5)}px`,
    lineHeight: `${theme.spacing(2)}px`,
    fontWeight: 600,
    paddingRight: `${theme.spacing(0.5)}px`,
    paddingLeft: `${theme.spacing(0.5)}px`,
  },
  icon: {
    display: "inline-block",
    boxSizing: "border-box",
    height: `${theme.spacing(1)}px`,
    width: `${theme.spacing(1)}px`,
    margin: `${theme.spacing(0.5)}px`,
    marginLeft: 0,
    borderRadius: "50%",
    verticalAlign: "bottom",
  },
});

const MultiChip = ({ classes, values, singleValue = false, unlimitedHeight = false, icon = true }) => {
  const children = values.map((it, key) => {
    if (!it) return null;

    const { label, color = "#ffffff", backgroundColor = "#aaaaaa", borderColor, styles = {} } = it;

    return (
      <div key={key} className={classes.singleStatus} style={{ backgroundColor, ...styles }}>
        <Typography variant="body1" className={classes.singleText} style={{ color }}>
          {icon && <span className={classes.icon} style={{ backgroundColor: borderColor || "none" }} />}
          {label}
        </Typography>
      </div>
    );
  });

  let className = classes.container;
  if (singleValue) className = classes.singleContainer;
  if (unlimitedHeight) className = classes.unlimitedContainer;

  return <div className={className}>{children}</div>;
};

MultiChip.propTypes = {
  classes: PropTypes.object.isRequired,
  translate: PropTypes.func.isRequired,
  values: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      color: PropTypes.string,
      backgroundColor: PropTypes.string,
      borderColor: PropTypes.string,
      styles: PropTypes.object,
      className: PropTypes.string,
      props: PropTypes.object,
    }).isRequired
  ).isRequired,
  singleValue: PropTypes.bool,
  unlimitedHeight: PropTypes.bool,
};

export default withStyles(styles)(translate(MultiChip));
