import React, { useState, useEffect } from "react";
import { translate, useListContext } from "react-admin";
import { Form } from "react-final-form";
import PropTypes from "prop-types";
import SearchIcon from "@material-ui/icons/Search";
import Button from "@material-ui/core/Button";
import LoadingOverlay from "react-loading-overlay";
import isEmpty from "lodash/isEmpty";
import merge from "lodash/merge";

import SaveFilterGraphButton from "./filterGraphs/SaveFilterGraphButton";
import { createFilterFromPaths } from "../lib/filterGraph";
import FilterGraphSelectInput from "./filterGraphs/FilterGraphSelectInput";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles(theme => ({
  rootContainer: {
    marginTop: -theme.spacing(1),
    width: "100%",
    marginBottom: theme.spacing(1),
  },
  root: {
    display: "flex",
    flexWrap: "nowrap",
    justifyContent: "space-between",
    alignItems: "center",
    flex: 1,
  },
  width100: {
    width: "100%",
  },
  // override Ra topmargin to align filterselect with buttons
  filterSelect: {
    marginTop: 0,
  },
  filterControls: {
    display: "flex",
  },
}));

const CustomFilter = ({
  children: fakeChildren,
  translate,
  className,
  loading,
  saveFilter = true,
  resetOnLoad = false,
  ...props
}) => {
  const { setFilters, filterValues } = useListContext();
  const [filterParts, setFilterParts] = useState([]);

  useEffect(() => {
    if (resetOnLoad) {
      setFilters({}, []);
      setFilterParts([]);
    }
  }, [resetOnLoad]);

  const classes = useStyles(props);

  const handleSelectFilterGraph = ({ __id, filterPartList }) => {
    if (isFinite(__id) && Array.isArray(filterPartList)) {
      setFilterParts(filterPartList);
    }
  };

  const commitFilters = values => {
    setFilters(merge(createFilterFromPaths(filterParts), values));
  };

  const resetFilter = form => {
    setFilters({}, []);
    setFilterParts([]);
    form.reset();
  };

  const handleKeyDownEvent = event => values => {
    if (event.key === "Enter" && !loading) {
      commitFilters(values);
    }
  };

  // children can be arrays as well as just single components. force them to be arrays
  const children = Array.isArray(fakeChildren) ? fakeChildren : !!fakeChildren ? [fakeChildren] : [];

  return (
    <div className={classes.rootContainer}>
      <LoadingOverlay
        active={loading}
        text={translate("filterMisc.pleaseWait")}
        spinner
        style={{
          display: "flex",
          flexDirection: "column",
          flexWrap: "nowrap",
          justifyContent: "space-between",
          flex: 1,
          alignItems: "center",
        }}
        styles={{
          overlay: base => ({
            ...base,
            background: "rgba(40, 44, 52, 0.5)", //light blueish-grey, transparent
          }),
        }}
      >
        <div className={classes.root}>
          <Form onSubmit={commitFilters} className={classes.width100}>
            {({ handleSubmit, form, values }) => (
              <form onSubmit={handleSubmit} className={classes.width100}>
                <Box className={className}>
                  {children.map((child, i) => (
                    <Box component="span" key={i}>
                      {child !== null
                        ? React.cloneElement(child, {
                            onKeyDown: handleKeyDownEvent(values),
                          })
                        : child}
                    </Box>
                  ))}
                </Box>
                <div className={classes.filterControls}>
                  <Box component="span" mr={2}>
                    <Button
                      disabled={loading || isEmpty(values)}
                      variant="outlined"
                      color="primary"
                      size="large"
                      type="submit"
                    >
                      <SearchIcon />
                      {!loading ? translate("filterMisc.commitSearch") : translate("filterMisc.loading")}
                    </Button>
                  </Box>
                  <Box component="span" mr={2}>
                    <Button
                      disabled={loading || (isEmpty(values) && isEmpty(filterValues))}
                      variant="outlined"
                      color="primary"
                      size="large"
                      onClick={() => resetFilter(form)}
                    >
                      {translate("filterMisc.reset")}
                    </Button>
                  </Box>
                  {saveFilter && (
                    <>
                      <Box component="span" mr={2}>
                        <FilterGraphSelectInput
                          className={classes.filterSelect}
                          resource={props.resource}
                          onChangeSelection={handleSelectFilterGraph}
                          basePath={props.basePath}
                        />
                      </Box>
                      <Box component="span" mr={2}>
                        <SaveFilterGraphButton
                          variant="outlined"
                          color="primary"
                          size="large"
                          filterValues={filterValues}
                          resource={props.resource}
                          basePath={props.basePath}
                        />
                      </Box>
                    </>
                  )}
                </div>
              </form>
            )}
          </Form>
        </div>
      </LoadingOverlay>
    </div>
  );
};

CustomFilter.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node),
  setFilters: PropTypes.func,
  translate: PropTypes.func,
  classes: PropTypes.object,
};

export default translate(CustomFilter);
