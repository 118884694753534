import React, { Fragment } from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import OkIcon from "@material-ui/icons/SubdirectoryArrowRight";
import Typography from "@material-ui/core/Typography";

const styles = theme => ({
  menu: {
    minWidth: "100%",
  },
  menuItem: {
    minWidth: "100%",
  },
  title: {
    padding: "4px 16px",
    fontWeight: 700,
  },
});

const stopPropagation = evt => {
  if (evt && evt.stopPropagation) evt.stopPropagation();
  if (evt && evt.nativeEvent) evt.nativeEvent.stopImmediatePropagation();
};

class DropdownButton extends React.Component {
  state = {
    anchorEl: null,
    width: "100%",
    inputValues: {},
    selected: null,
  };

  handleClick = evt => {
    stopPropagation(evt);
    const { currentTarget } = evt;
    this.setState({
      anchorEl: currentTarget,
      width: currentTarget.offsetWidth,
    });
  };

  handleClose = evt => {
    stopPropagation(evt);
    this.setState({ anchorEl: null });
  };

  handleInput = key => evt => {
    const { inputValues } = this.state;
    this.setState({
      inputValues: {
        ...inputValues,
        [key]: evt.target.value,
      },
    });
  };

  handleSelect = (entry, message, evt) => {
    stopPropagation(evt);

    const { onSelect, items } = this.props;
    this.handleClose();

    const selected = entry.key;

    this.setState({ selected: selected });

    onSelect(selected, message, entry, items);
  };

  makeTextField = entry => {
    const { inputValues } = this.state;
    const message = inputValues[entry.key] || "";
    return (
      <Input
        id={entry.key}
        label={entry.label}
        placeholder={entry.label}
        value={message}
        margin="none"
        onChange={this.handleInput(entry.key)}
        endAdornment={
          <InputAdornment position="end">
            <IconButton onClick={evt => this.handleSelect(entry, message, evt)} color="primary">
              <OkIcon />
            </IconButton>
          </InputAdornment>
        }
      />
    );
  };

  renderMenuItem = ({ classes } = this.props, { width } = this.state) => (entry, key) => {
    const { label, action = () => {}, styles = {}, disabled = false, isInput = false, selected, icon } = entry;

    let content;
    if (isInput) {
      content = this.makeTextField(entry);
    } else {
      content = (
        <Fragment>
          {icon && <ListItemIcon className={classes.icon}>{icon}</ListItemIcon>}
          {typeof label === "string" ? <ListItemText className={classes.label}>{label}</ListItemText> : label}
        </Fragment>
      );
    }

    return (
      <MenuItem
        onClick={evt => {
          stopPropagation(evt);
          if (disabled) return;
          if (!isInput) this.handleSelect(entry);
          action();
        }}
        key={key}
        className={classes.menuItem}
        style={{ minWidth: width - 32, ...styles }}
        disabled={disabled}
        selected={selected}
      >
        {content}
      </MenuItem>
    );
  };

  render() {
    const { classes, label, items = [], buttonProps = {}, title, menuProps = {} } = this.props;
    const { anchorEl } = this.state;

    return (
      <div>
        <Button
          {...buttonProps}
          aria-owns={anchorEl ? "simple-menu" : null}
          aria-haspopup="true"
          onClick={
            typeof buttonProps.onClick === "function"
              ? evt => {
                  this.handleClick(evt);
                  buttonProps.onClick(evt);
                }
              : this.handleClick
          }
        >
          {label}
        </Button>
        <Menu
          {...menuProps}
          id="simple-menu"
          anchorEl={anchorEl}
          open={!!anchorEl}
          onClose={this.handleClose}
          className={classes.menu}
        >
          {title && (
            <Typography variant="subtitle1" className={classes.title}>
              {title}
            </Typography>
          )}
          {items.map(this.renderMenuItem())}
        </Menu>
      </div>
    );
  }
}

DropdownButton.propTypes = {
  classes: PropTypes.object.isRequired,
  label: PropTypes.node.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.any.isRequired,
      label: PropTypes.node.isRequired,
      action: PropTypes.func,
      styles: PropTypes.object,
      disabled: PropTypes.bool,
      selected: PropTypes.bool,
      closeOnClick: PropTypes.bool,
      isInput: PropTypes.bool,
      icon: PropTypes.node,
    })
  ).isRequired,
  buttonProps: PropTypes.shape(Button.propTypes),
  onSelect: PropTypes.func.isRequired,
  title: PropTypes.node,
};

export default withStyles(styles)(DropdownButton);
