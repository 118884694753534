import React, { Component, Fragment } from "react";
import { Admin, Resource } from "react-admin";
import { Route } from "react-router-dom";
import Button from "@material-ui/core/Button";
import {
  Assignment,
  Print,
  LocalMall,
  Business,
  Functions,
  VpnKey,
  Contacts,
  Email,
  Style,
  PlaylistAddCheck,
  GraphicEq,
  Settings,
  Error,
  BorderOuter,
  AddCircle,
  Warning,
  FolderShared,
  InsertDriveFile,
  FilterList,
  AddAlert,
  Notifications,
} from "@material-ui/icons";

import { clearLog, getLog } from "./lib/logger";
import "./App.css";
import DataProvider from "./dataproviders/dataProvider";
import resources from "./config/resources";
import authProvider from "./dataproviders/authProvider";
import JobsList from "./components/jobs/JobsList";
import reducers from "./redux/reducers";
import sagas from "./redux/sagas";
import translationProvider from "./dataproviders/translationProvider";
import theme from "./theme/theme";
import CustomLayout from "./theme/custom/CustomLayout";
import ShipmentsList from "./components/shipments/ShipmentsList";
import LoginPage from "./components/login/LoginPage";
import ContractorJobView from "./components/jobs/ContractorJobView";
import ContractorJobList from "./components/jobs/ContractorJobList";
import ContractorsList from "./components/contractors/ContractorsList";
import ContractorCreate from "./components/contractors/ContractorCreate";
import ContractorEdit from "./components/contractors/ContractorEdit";
import config from "./config";
import UsersList from "./components/users/UsersList";
import UserCreate from "./components/users/UserCreate";
import UserEdit from "./components/users/UserEdit";
import ChangePasswordPage from "./components/login/ChangePasswordPage";
import FormulasList from "./components/formulas/FormulasList";
import FormulaCreate from "./components/formulas/FormulaCreate";
import FormulaEdit from "./components/formulas/FormulaEdit";
import FormulaShow from "./components/formulas/FormulaShow";
import TemplatesList from "./components/templates/TemplatesList";
import TemplateCreate from "./components/templates/TemplateCreate";
import TemplateShow from "./components/templates/TemplateShow";
import TemplateEdit from "./components/templates/TemplateEdit";
import MaterialsList from "./components/materials/MaterialsList";
import CustomersList from "./components/customers/CustomersList";
import CustomerShow from "./components/customers/CustomerShow";
import SupportedMaterialsList from "./components/supportedMaterials/SupportedMaterialsList";
import SupportedMaterialCreate from "./components/supportedMaterials/SupportedMaterialCreate";
import SupportedMaterialEdit from "./components/supportedMaterials/SupportedMaterialEdit";
import MaterialiseMaterialMappingsList from "./components/materialiseMaterialMappings/MaterialiseMaterialMappingsList";
import MaterialiseMaterialMappingsEdit from "./components/materialiseMaterialMappings/MaterialiseMaterialMappingsEdit";
import MaterialiseMaterialMappingsCreate from "./components/materialiseMaterialMappings/MaterialiseMaterialMappingsCreate";
import ModelStatusUpdatesList from "./components/modelStatusUpdates/ModelStatusUpdatesList";
import PropertiesList from "./components/properties/PropertiesList";
import PropertiesEdit from "./components/properties/PropertiesEdit";
import { exportJson } from "./lib/jsonExporter";
import ShipmentShow from "./components/shipments/ShipmentShow";
import WarehouseLocationsList from "./components/warehouseLocations/WarehouseLocationList";
import WarehouseLocationCreate from "./components/warehouseLocations/WarehouseLocationCreate";
import WarehouseLocationEdit from "./components/warehouseLocations/WarehouseLocationEdit";
import WarehouseLocationShow from "./components/warehouseLocations/WarehouseLocationShow";
import AdditionsList from "./components/additions/AdditionsList";
import MaterialiseExceptionsList from "./components/materialiseExceptions/MaterialiseExceptionsList";
import DocumentTypesList from "./components/documentTypes/DocumentTypesList";
import DocumentTypeCreate from "./components/documentTypes/DocumentTypeCreate";
import DocumentTypeEdit from "./components/documentTypes/DocumentTypeEdit";
import DocumentsList from "./components/documents/DocumentsList";
import DebugView from "./DebugView";
import FilterGraphsLIst from "./components/filterGraphs/FilterGraphsList";
import FilterGraphEdit from "./components/filterGraphs/FilterGraphEdit";
import NotificationsConfigList from "./components/notificationConfigs/NotificationConfigList";
import NotificationConfigCreate from "./components/notificationConfigs/NotificationConfigCreate";
import NotificationConfigEdit from "./components/notificationConfigs/NotificationConfigEdit";
import NotificationsList from "./components/notifications/NotificationsList";
import Roles from "./config/Roles";
import AutoAssignLogsList from "./components/autoAssignLogs/AutoAssignLogsList";
import AutoAssignLogsShow from "./components/autoAssignLogs/AutoAssignLogsShow";
import ArchivedShipmentsList from "./components/archivedShipments/ArchivedShipmentsList";
import ArchivedShipmentShow from "./components/archivedShipments/ArchivedShipmentShow";
import ArchivedJobsList from "./components/archivedJobs/ArchivedJobsList";
import ArchivedPrintJobsList from "./components/archivedPrintJobs/ArchivedPrintJobsList";
import JobBulkOperationPage from "./components/jobBulkOperations/JobBulkOperationPage";
import PrintJobsList from "./components/printJobs/PrintJobsList";
import PrintJobEdit from "./components/printJobs/PrintJobEdit";
import PrintJobCreate from "./components/printJobs/PrintJobCreate";
import PrintPreparationsList from "./components/printPreparation/PrintPreparationsList";
import PostProcessingEdit from "./components/postProcessingJobs/PostProcessingEdit";
import PostProcessingJobsList from "./components/postProcessingJobs/PostProcessingJobsList";

const ifRole = (auth = {}, role, component) => {
  const { authorities = [] } = auth;
  if (typeof role === "string") return authorities.includes(role) ? component : Array.isArray(component) ? [] : null;

  if (Array.isArray(role))
    return role.reduce((acc, it) => acc || !!ifRole(auth, it, true), false)
      ? component
      : Array.isArray(component)
      ? []
      : null;

  return Array.isArray(component) ? [] : null;
};

const customRoutes = [
  <Route exact path="/changepw" component={ChangePasswordPage} />,
  <Route exact path="/jobBulkOperations" component={JobBulkOperationPage} />,
];

const MaterialiseIcon = () => (
  <div style={{ width: "1.5em", height: "1.5em" }}>
    <img style={{ width: "100%" }} src="https://onsite.materialise.com/favicon.ico" alt="" />
  </div>
);

const exportError = (errorMessage, errorInfo) => {
  const log = getLog();
  const token = localStorage.getItem("token");
  const expiresAt = localStorage.getItem("expiresAt");

  const data = {
    timeExported: new Date().getTime(),
    exportSource: "CRITICAL_ERROR",
    location: window.location.href,
    client: window.navigator,
    login: { token, expiresAt },
    config,
    error: {
      message: errorMessage,
      details: errorInfo,
    },
    log,
  };

  exportJson(data);
};

class App extends Component {
  state = { hasError: false, errorMessage: null, errorInfo: null };

  constructor(props) {
    super(props);

    clearLog();
  }

  componentDidCatch(errorMessage, errorInfo) {
    this.setState({ hasError: true, errorMessage, errorInfo });
  }

  render() {
    const { hasError, errorMessage, errorInfo } = this.state;

    if (hasError) {
      return (
        <div
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            textAlign: "center",
          }}
        >
          <h1 style={{ fontSize: 100, margin: 0 }}>
            <Error fontSize="inherit" />
          </h1>
          <h2>Kritischer Fehler</h2>
          <p>
            Es ist ein kritischer, unerwarteter Fehler aufgetreten. Bitte wenden Sie sich an Ihren Systemadministrator.
            <br />
            Hängen Sie Ihrer Support-Anfrage den Fehlerbericht an, welchen Sie im Folgenden erzeugen können:
          </p>
          <Button variant="contained" size="large" onClick={() => exportError(errorMessage, errorInfo)}>
            Fehlerbericht Erzeugen
          </Button>
          <p>Um fortzufahren, nutzen Sie den Zurück-Button Ihres Browsers und laden Sie die Webseite neu.</p>
        </div>
      );
    }

    return (
      <Fragment>
        <Admin
          title="Rapidobject Auftragssteuerung"
          i18nProvider={translationProvider}
          theme={theme}
          layout={CustomLayout}
          loginPage={LoginPage}
          authProvider={authProvider}
          dataProvider={DataProvider()}
          customSagas={sagas}
          customReducers={reducers}
          customRoutes={customRoutes}
        >
          {auth => [
            <Resource
              name={resources.SHIPMENTS}
              icon={LocalMall}
              list={ifRole(auth, ["ROLE_SALES", "ROLE_ADMIN"], ShipmentsList)}
              show={ifRole(auth, ["ROLE_SALES", "ROLE_ADMIN"], ShipmentShow)}
            />,
            <Resource
              name={resources.JOBS}
              icon={Assignment}
              list={
                ifRole(auth, ["ROLE_SALES", "ROLE_ADMIN"], JobsList) ||
                ifRole(auth, "ROLE_CONTRACTOR", ContractorJobList)
              }
              show={ifRole(auth, ["ROLE_SALES", "ROLE_ADMIN", "ROLE_CONTRACTOR"], ContractorJobView)}
            />,
            <Resource
              name={resources.MATERIALS}
              icon={Style}
              list={ifRole(auth, ["ROLE_SALES", "ROLE_ADMIN"], MaterialsList)}
            />,
            <Resource name={resources.ADDITIONS} list={ifRole(auth, "ROLE_ADMIN", AdditionsList)} icon={AddCircle} />,
            <Resource
              name={resources.CUSTOMERS}
              icon={Contacts}
              list={ifRole(auth, ["ROLE_ADMIN"], CustomersList)}
              show={ifRole(auth, ["ROLE_ADMIN", "ROLE_SALES"], CustomerShow)}
            />,
            <Resource
              name={resources.CONTRACTORS}
              icon={Business}
              list={ifRole(auth, ["ROLE_ADMIN", "ROLE_SALES"], ContractorsList)}
              edit={ifRole(auth, "ROLE_ADMIN", ContractorEdit)}
              create={ifRole(auth, "ROLE_ADMIN", ContractorCreate)}
            />,
            <Resource
              name={resources.FILTER_GRAPHS}
              list={ifRole(auth, ["ROLE_ADMIN", "ROLE_SALES"], FilterGraphsLIst)}
              edit={FilterGraphEdit}
              icon={FilterList}
            />,
            <Resource
              name={resources.NOTIFICATIONS}
              icon={Notifications}
              list={ifRole(auth, ["ROLE_ADMIN", "ROLE_SALES"], NotificationsList)}
              options={{ hidden: true }}
            />,
            <Resource
              name={resources.NOTIFICATION_CONFIGS}
              icon={AddAlert}
              list={ifRole(auth, ["ROLE_ADMIN", "ROLE_SALES"], NotificationsConfigList)}
              create={ifRole(auth, ["ROLE_ADMIN", "ROLE_SALES"], NotificationConfigCreate)}
              edit={ifRole(auth, ["ROLE_ADMIN", "ROLE_SALES"], NotificationConfigEdit)}
              options={{ hidden: true }}
            />,
            <Resource
              name={resources.FORMULAS}
              icon={Functions}
              list={ifRole(auth, "ROLE_ADMIN", FormulasList)}
              create={ifRole(auth, "ROLE_ADMIN", FormulaCreate)}
              edit={ifRole(auth, "ROLE_ADMIN", FormulaEdit)}
              show={ifRole(auth, "ROLE_ADMIN", FormulaShow)}
            />,
            <Resource
              name={resources.SUPPORTED_MATERIALS}
              icon={PlaylistAddCheck}
              list={ifRole(auth, "ROLE_ADMIN", SupportedMaterialsList)}
              create={ifRole(auth, "ROLE_ADMIN", SupportedMaterialCreate)}
              edit={ifRole(auth, "ROLE_ADMIN", SupportedMaterialEdit)}
            />,
            <Resource
              name={resources.DOCUMENT_TYPES}
              list={ifRole(auth, "ROLE_ADMIN", DocumentTypesList)}
              icon={FolderShared}
              create={ifRole(auth, "ROLE_ADMIN", DocumentTypeCreate)}
              edit={ifRole(auth, "ROLE_ADMIN", DocumentTypeEdit)}
            />,
            <Resource
              name={resources.DOCUMENTS}
              icon={InsertDriveFile}
              list={ifRole(auth, "ROLE_ADMIN", DocumentsList)}
            />,
            <Resource
              name={resources.WAREHOUSE_LOCATIONS}
              icon={BorderOuter}
              list={ifRole(auth, "ROLE_ADMIN", WarehouseLocationsList)}
              create={ifRole(auth, "ROLE_ADMIN", WarehouseLocationCreate)}
              edit={ifRole(auth, "ROLE_ADMIN", WarehouseLocationEdit)}
              show={ifRole(auth, "ROLE_ADMIN", WarehouseLocationShow)}
            />,
            <Resource
              name={resources.TEMPLATES}
              icon={Email}
              list={ifRole(auth, "ROLE_ADMIN", TemplatesList)}
              create={ifRole(auth, "ROLE_ADMIN", TemplateCreate)}
              show={ifRole(auth, ["ROLE_ADMIN", "ROLE_SALES"], TemplateShow)}
              edit={ifRole(auth, "ROLE_ADMIN", TemplateEdit)}
            />,
            <Resource
              name={resources.USERS}
              icon={VpnKey}
              list={ifRole(auth, "ROLE_ADMIN", UsersList)}
              create={ifRole(auth, "ROLE_ADMIN", UserCreate)}
              edit={ifRole(auth, "ROLE_ADMIN", UserEdit)}
            />,
            <Resource
              name={resources.MATERIALISE_MATERIAL_MAPPING}
              icon={MaterialiseIcon}
              list={ifRole(auth, "ROLE_ADMIN", MaterialiseMaterialMappingsList)}
              edit={ifRole(auth, "ROLE_ADMIN", MaterialiseMaterialMappingsEdit)}
              create={ifRole(auth, "ROLE_ADMIN", MaterialiseMaterialMappingsCreate)}
            />,
            <Resource
              name={resources.MATERIALISE_EXCEPTIONS}
              icon={Warning}
              list={ifRole(auth, "ROLE_ADMIN", MaterialiseExceptionsList)}
            />,
            <Resource name={resources.MATERIALISE_TECHNOLOGIES} />,
            <Resource
              name={resources.PROPERTIES}
              icon={Settings}
              list={ifRole(auth, "ROLE_ADMIN", PropertiesList)}
              edit={ifRole(auth, "ROLE_ADMIN", PropertiesEdit)}
            />,
            <Resource name={resources.ORDERS} />,
            <Resource name={resources.SUBTASKS} />,
            <Resource name={resources.MODELS} />,
            <Resource name={resources.PRICE_ESTIMATES} />,
            <Resource name={resources.ADDRESSES} />,
            <Resource name={resources.ART_JOBS} />,
            <Resource name={resources.ASSIGNED_USERS} />,
            <Resource name={resources.USER_DETAILS} />,
            <Resource
              name={resources.MODEL_STATUS_UPDATES}
              icon={GraphicEq}
              list={ifRole(auth, "ROLE_ADMIN", ModelStatusUpdatesList)}
            />,
            <Resource
              name={resources.AUTO_ASSIGN_LOGS}
              icon={Assignment}
              list={
                ifRole(auth, Roles.ROLE_ADMIN.key, AutoAssignLogsList) ||
                (auth && auth.username === "h.rüffert" ? AutoAssignLogsList : null)
                // this is terrible and should either be handled by giving all sales users access to this resource
                // or this user a custom role for accessing this resource
              }
              show={
                ifRole(auth, Roles.ROLE_ADMIN.key, AutoAssignLogsShow) ||
                (auth && auth.username === "h.rüffert" ? AutoAssignLogsShow : null)
              }
            />,
            <Resource name={resources.COMMENTS} />,
            <Resource name={resources.USER_DETAILS} />,
            <Resource name={resources.FILTER_PARTS} />,
            <Resource name={resources.ARCHIVED_AUTO_ASSIGN_LOGS} icon={Assignment} />,
            <Resource name={resources.ARCHIVED_COMMENTS} />,
            <Resource name={resources.ARCHIVED_DOCUMENTS} icon={InsertDriveFile} />,
            <Resource
              name={resources.ARCHIVED_JOBS}
              icon={Assignment}
              list={ifRole(auth, ["ROLE_SALES", "ROLE_ADMIN"], ArchivedJobsList)}
            />,
            <Resource name={resources.ARCHIVED_MATERIALISE_EXCEPTIONS} icon={Warning} />,
            <Resource name={resources.ARCHIVED_MODELS} />,
            <Resource name={resources.ARCHIVED_NOTIFICATIONS} icon={Notifications} options={{ hidden: true }} />,
            <Resource name={resources.ARCHIVED_ORDERS} />,
            <Resource name={resources.ARCHIVED_PRICE_ESTIMATES} />,
            <Resource
              name={resources.ARCHIVED_SHIPMENTS}
              icon={LocalMall}
              list={ifRole(auth, ["ROLE_SALES", "ROLE_ADMIN"], ArchivedShipmentsList)}
              show={ifRole(auth, ["ROLE_SALES", "ROLE_ADMIN"], ArchivedShipmentShow)}
            />,
            <Resource name={resources.ARCHIVE_SUBTASKS} />,
            <Resource
              name={resources.PRINT_JOBS}
              icon={Print}
              list={ifRole(auth, ["ROLE_SALES", "ROLE_ADMIN"], PrintJobsList)}
              edit={ifRole(auth, ["ROLE_SALES", "ROLE_ADMIN"], PrintJobEdit)}
              create={ifRole(auth, ["ROLE_SALES", "ROLE_ADMIN"], PrintJobCreate)}
            />,
            <Resource
              name={resources.ARCHIVED_PRINT_JOBS}
              icon={Print}
              list={ifRole(auth, ["ROLE_SALES", "ROLE_ADMIN"], ArchivedPrintJobsList)}
            />,
            <Resource name={resources.PRINTERS} />,
            <Resource name={resources.BUILD_UNITS} />,
            <Resource name={resources.MODEL_PARTS_DEFECTS} />,
            <Resource name={resources.MODEL_STATUS_UPDATES} />,
            <Resource name={resources.PRINT_JOB_MODELS} />,
            <Resource name={resources.ARCHIVED_PRINT_JOB_MODELS} />,
            <Resource
              name={resources.PRINT_PREPARATIONS}
              icon={Print}
              list={ifRole(auth, ["ROLE_SALES", "ROLE_ADMIN"], PrintPreparationsList)}
            />,
            <Resource name={resources.WAREHOUSE_COMPARTMENTS} />,
            <Resource
              name={resources.POSTPROCESSING_JOB}
              list={ifRole(auth, ["ROLE_SALES", "ROLE_ADMIN"], PostProcessingJobsList)}
              edit={ifRole(auth, ["ROLE_SALES", "ROLE_ADMIN"], PostProcessingEdit)}
            />,
            <Resource name="debug" list={ifRole(auth, "ROLE_ADMIN", DebugView)} />,
          ]}
        </Admin>
        <div
          style={{
            position: "fixed",
            right: 0,
            bottom: 0,
            fontSize: "0.5em",
            color: "#fff",
            backgroundColor: "rgba(0, 0, 0, 0.3)",
            zIndex: 10000,
          }}
        >
          {config.build.version}&nbsp;{config.build.buildNumber}
        </div>
      </Fragment>
    );
  }
}

export default App;
