/**
 * @returns {boolean}
 */
const isPushNotificationSupported = () => {
  return "serviceWorker" in navigator && "PushManager" in window;
};

/**
 * @returns {boolean}
 */
export const hasNotificationPermission = () => {
  return Notification.permission === "granted";
};

/**
 * @returns {Promise<"default"|"denied"|"granted">}
 */
export const askNotificationPermission = async () => {
  return await Notification.requestPermission();
};

/**
 * @returns {Promise<ServiceWorkerRegistration>}
 */
const getServiceWorkerRegistration = async () => {
  if (!isPushNotificationSupported()) {
    throw Error("Service Worker not supported!");
  }
  return await navigator.serviceWorker.ready;
};

/**
 * @returns {Promise<ServiceWorkerRegistration.showNotification>}
 */
export const initPush = async () => {
  const serviceWorker = await getServiceWorkerRegistration();

  let hasPermission = hasNotificationPermission();

  if (!hasPermission) {
    const askResult = await askNotificationPermission();
    hasPermission = askResult === "granted";
  }

  if (hasPermission) {
    return serviceWorker.showNotification.bind(serviceWorker);
  } else {
    throw Error("Notification permission denied!");
  }
};

/**
 * @param listener {EventListener}
 * @param [options] {boolean | AddEventListenerOptions}
 * @returns {Promise<void>}
 */
export const addNotificationClickListener = async (listener, options) => {
  if (!isPushNotificationSupported()) {
    return;
  }

  return navigator.serviceWorker.addEventListener(
    "message",
    evt => {
      if (evt.data.event === "notificationclick") {
        listener(evt.data.payload);
      }
    },
    options
  );
};
