import { call, put, takeEvery } from "redux-saga/effects";
import config from "../../config/index";
import fetchService from "../../lib/fetchService";
import { RESTART_REQUEST, restartSuccess, restartError } from "./action";
import { handleSagaError } from "../../dataproviders/errorResponseHandler";

const requestSaga = function*() {
  const url = `${config.urls.BACKEND}/restart`;

  const response = yield call(fetchService.post, url);

  for (const action of yield call(handleSagaError, response, restartError)) {
    yield put(action);
    return;
  }

  try {
    if (response.ok) {
      const responseData = yield call(() => response.json());
      yield put(restartSuccess(responseData));
    } else {
      const statusCode = response.status;
      yield put(restartError(new Error(`Request failed with error ${statusCode}!`)));
    }
  } catch (e) {
    yield put(restartError(e));
  }
};

export default function*() {
  yield takeEvery(RESTART_REQUEST, requestSaga);
}
