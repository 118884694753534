import React from "react";
import {
  translate,
  TextField,
  BooleanField,
  Datagrid,
  List,
  TopToolbar,
  NumberField,
  EditButton,
  Pagination,
} from "react-admin";
import get from "lodash/get";
import RestartButton from "./RestartButton";
import paginationOptions from "../../config/PaginationOptions";

const GenericField = props => {
  const type = get(props, "record.targetType");
  switch (type) {
    case "java.lang.Integer":
    case "java.lang.Long":
      return <NumberField {...props} />;
    default:
      return <TextField {...props} />;
  }
};

const Actions = props => (
  <TopToolbar>
    <RestartButton />
  </TopToolbar>
);

const PropertiesList = ({ translate, ...props }) => (
  <List {...props} bulkActionButtons={false} actions={<Actions />} pagination={<Pagination {...paginationOptions} />}>
    <Datagrid>
      <TextField source="key" />
      <GenericField source="value" />
      <BooleanField source="envUpdated" />
      <EditButton />
    </Datagrid>
  </List>
);

export default translate(PropertiesList);
