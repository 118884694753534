import React from "react";
import {
  AutocompleteInput,
  Edit,
  ReferenceInput,
  regex,
  required,
  SimpleForm,
  TextInput,
  translate,
} from "react-admin";
import resources from "../../config/resources";
import MaterialiseMaterialMappingAside from "./MaterialiseMaterialMappingAside";

const internalMaterialValidation = required();
const uuidValidation = [
  required(),
  regex(
    /[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/,
    () => "materialiseReferenceError"
  ),
];

const MaterialiseMaterialMappingsEdit = ({ translate, ...props }) => (
  <div style={{ display: "flex", justifyContent: "center" }}>
    <Edit {...props} undoable={false} aside={<MaterialiseMaterialMappingAside {...props} />}>
      <SimpleForm initialValues={{ enabled: true }} style={{ width: 600, maxWidth: "40vw" }} redirect="list">
        <ReferenceInput
          source="internalMaterial"
          reference={resources.MATERIALS}
          perPage={1000}
          filterToQuery={searchText => ({ autocomplete: searchText })}
          validate={internalMaterialValidation}
          fullWidth
        >
          <AutocompleteInput
            optionText={({ name, methodKey, methodName, method }) =>
              `${methodKey || methodName || method || "?"} (${name})`
            }
            optionValue="id"
            disabled
          />
        </ReferenceInput>
        <TextInput source="materialiseTechnology" validate={uuidValidation} fullWidth />
        <TextInput source="materialiseMaterial" validate={uuidValidation} fullWidth />
        <TextInput source="materialiseFinishing" validate={uuidValidation} fullWidth />
        <TextInput source="materialiseColor" validate={uuidValidation} fullWidth />
      </SimpleForm>
    </Edit>
  </div>
);

export default translate(MaterialiseMaterialMappingsEdit);
