import React from "react";
import PropTypes from "prop-types";
import { Edit, SelectInput, SimpleForm, TextInput, translate } from "react-admin";
import TemplateTypes from "../../config/TemplateTypes";
import { withStyles } from "@material-ui/core/styles";
import CustomRichTextField from "../customFields/CustomRichTextField";
import Title from "../customFields/Title";
import resources from "../../config/resources";

const styles = () => ({
  richtTextBody: {
    msTextSizeAdjust: "100%",
    webkitTextSizeAdjust: "100%",
    '& div[style*="margin: 16px 0"]': {
      margin: "0 important!",
    },
    "& table": {
      msoTableLspace: "0pt !important",
      msoTableRspace: "0pt !important",
      borderSpacing: "0 !important",
      borderCollapse: "collapse !important",
      tablelayout: "fixed !important",
      margin: "0 auto !important",
    },
    "& td": {
      msoTableLspace: "0pt !important",
      msoTableRspace: "0pt !important",
    },
    "& img": {
      msInterpolationMode: "bicubic",
    },
    "& a": {
      textDecoration: "none",
    },
    "& a[x-apple-data-detectors], .unstyle-auto-detected-links a, .aBn": {
      borderBottom: "0 !important",
      cursor: "default !important",
      color: "inherit !important",
      textDecoration: "none !important",
      fontSize: "inherit !important",
      fontFamily: "inherit !important",
      fontWeight: "inherit !important",
      lineHeight: "inherit !important",
    },
    "& .im": {
      color: "inherit !important",
    },
    "& .a6S": {
      display: "none !important",
      opacity: "0.01 !important",
    },
    "& img.g-img + div": {
      display: "none !important",
    },
    "@media only screen and (min-device-width: 320px) and (max-device-width: 4375px)": {
      "& u ~ div .email-container": {
        minWidth: "320px !important",
      },
    },
    "@media only screen and (min-device-width: 375px) and (max-device-width: 413px)": {
      "& u ~ div .email-container": {
        minWidth: "375px !important",
      },
    },
    "@media only screen and (min-device-width: 414px)": {
      "& u ~ div .email-container": {
        minWidth: "414px !important",
      },
    },
    "& .button-td, .button-a": {
      transition: "all 100ms ease-in",
    },
    "& button-td-primary:hover, button-a-primary:hover": {
      background: "#555555 !important",
      borderColor: "#555555 !important",
    },
    "@media screen and (max-width: 480px)": {
      "& .stack-column, .stack-column-center": {
        display: "block !important",
        width: "100% !important",
        maxWidth: "100% !important",
        direction: "ltr !important",
      },
      "& .stack-column-center": {
        textAlign: "center",
      },
      "& .center-on-narrow": {
        textAlign: "center !important",
        display: "block !important",
        marginLeft: "auto !important",
        marginRight: "auto !important",
        float: "none !important",
      },
      "& table.center-on-narrow": {
        display: "inline-block !important",
      },
      "& .email-container p": {
        fontSize: "17px !important",
      },
    },
  },
});

const TemplateEdit = ({ translate, classes, ...props }) => (
  <div>
    <Edit title={<Title resource={resources.TEMPLATES} />} undoable={false} {...props}>
      <SimpleForm initialValues={{ enabled: true }} variant="standard" margin="normal">
        <TextInput source="name" />
        <SelectInput
          source="type"
          choices={Object.values(TemplateTypes)}
          optionValue="key"
          optionText={({ translation }) => translate(translation)}
        />
        <TextInput source="subject" />
        <CustomRichTextField source="body" />
      </SimpleForm>
    </Edit>
  </div>
);

TemplateEdit.propTypes = {
  classes: PropTypes.object.isRequired,
  translate: PropTypes.func.isRequired,
};

export default withStyles(styles)(translate(TemplateEdit));
