import React from "react";
import {
  BooleanInput,
  Edit,
  FormDataConsumer,
  ReferenceField,
  ReferenceInput,
  required,
  SelectInput,
  SimpleForm,
  TextField,
  translate,
} from "react-admin";
import resources from "../../config/resources";
import NotificationTypes from "../../config/NotificationTypes";
import NotificationStyles from "../../config/NotificationStyles";
import Title from "../customFields/Title";

const requiredValidation = required();

const NotificationConfigEdit = ({ translate, permissions, ...props }) =>
  permissions && (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <Edit title={<Title resource={resources.NOTIFICATION_CONFIGS} />} undoable={false} {...props}>
        <SimpleForm redirect="list">
          <ReferenceField source="owner" reference={resources.USERS} link={false}>
            <TextField source="displayName" />
          </ReferenceField>
          <SelectInput
            source="notificationType"
            choices={Object.values(NotificationTypes)}
            optionText="translation"
            optionValue="key"
            validate={requiredValidation}
          />
          <SelectInput
            source="notificationStyle"
            choices={Object.values(NotificationStyles)}
            optionText={({ translation, color }) => (
              <span>
                <span
                  style={{
                    display: "inline-block",
                    width: 12,
                    height: 12,
                    borderRadius: "50%",
                    backgroundColor: color,
                    marginRight: 8,
                  }}
                />
                {translate(translation)}
              </span>
            )}
            optionValue="key"
            validate={requiredValidation}
          />
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              formData.notificationType && (
                <ReferenceInput
                  {...rest}
                  source="template"
                  reference={resources.TEMPLATES}
                  filter={{
                    type: NotificationTypes[formData.notificationType].templateType,
                  }}
                  allowEmpty
                >
                  <SelectInput optionText="name" allowEmpty resettable />
                </ReferenceInput>
              )
            }
          </FormDataConsumer>
          <BooleanInput source="push" defaultValue={true} />
          <BooleanInput source="disabled" defaultValue={false} />
        </SimpleForm>
      </Edit>
    </div>
  );

export default translate(NotificationConfigEdit);
