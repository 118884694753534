import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { BooleanField, Datagrid, ListContextProvider, translate, useRefresh, crudGetOne } from "react-admin";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import resources from "../../config/resources";
import config from "../../config";
import stringReplace from "../../lib/stringReplace";
import { getKey } from "../../lib/i18nUtils";
import UploadButton from "../customFields/buttons/UploadButton";
import { uploadFileVersionRequest } from "../../redux/storageService/uploadFile/action";
import {
  getFileVersionUploadFinished,
  getFileVersionUploadIsUploading,
  getFileVersionUploadProgress,
} from "../../redux/storageService/uploadFile/selector";
import DateField from "../customFields/DateField";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import red from "@material-ui/core/colors/red";
import FixModelDataButton from "../models/FixModelDataButton";
import green from "@material-ui/core/colors/green";
import { fixedModelData } from "../../redux/model/fixedModelData/action";
import FileNameField from "../customFields/FileNameField";
import DownloadIcon from "@material-ui/icons/CloudDownload";

const styles = theme => ({
  uploadGroup: {
    padding: theme.spacing(1),
    textAlign: "right",
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  fixButton: {
    backgroundColor: green[500],
    color: theme.palette.getContrastText(green[500]),
    "&:hover": {
      backgroundColor: green[600],
    },
  },
});

const downloadUrl = ({ uuid, accessToken }) =>
  `${config.urls.STORAGE_SERVICE}${stringReplace(config.urls.STORAGE_SERVICE_DOWNLOAD, {
    fileVersion: uuid,
    token: accessToken,
  })}`;

const DownloadField = translate(({ record, label, translate }) => (
  <Button variant="text" color="primary" onClick={() => window.open(downloadUrl(record), "_blank")}>
    {translate(label)}
  </Button>
));

const ModelFilesDialog = ({
  translate,
  classes,
  isOpen,
  onClose,
  loaded,
  isUploading,
  uploadFinished,
  uploadProgress,
  upload,
  errorLabel,
  activeFileHasError,
  record,
  resource,
  fileToken,
  fileUuid,
  basePath,
  dispatchGetModel,
  dispatchGetPriceEstimatesForModelId,
  ...props
}) => {
  const rowStyle = (record, index) => {
    if (activeFileHasError && record.activeVersion) {
      return {
        backgroundColor: red[100],
      };
    }
    return {};
  };
  const refresh = useRefresh();

  const listContext = {
    data: props.data,
    ids: props.ids,
    currentSort: { field: "uuid", order: "asc" },
    resource: resources.FILE_VERSIONS,
    selectedIds: [],
  };

  const { id } = record;

  return (
    <Dialog maxWidth="md" open={isOpen} onClose={onClose} aria-labelledby="assign-dialog-title">
      <DialogTitle id="assign-dialog-title">{translate("screens.modelFileVersions")}</DialogTitle>
      {!errorLabel ? null : <SnackbarContent className={classes.error} message={errorLabel} />}
      <ListContextProvider value={listContext}>
        <Datagrid loaded={loaded} resource={resources.FILE_VERSIONS} rowStyle={rowStyle}>
          <BooleanField source="activeVersion" textAlign="left" />
          <FileNameField source="name" maxLength={42} placeholder="[...]" download={downloadUrl} />
          <DateField source="dateCreated" textAlign="right" />
          <DownloadField label={<DownloadIcon />} textAlign="right" />
        </Datagrid>
      </ListContextProvider>
      <div className={classes.uploadGroup}>
        <UploadButton
          variant="contained"
          color="primary"
          acceptsMultiple={false}
          isUploading={isUploading && !uploadFinished}
          uploadProgress={uploadProgress < 99 && isUploading ? uploadProgress : null}
          onStartUpload={upload}
          onFinishUpload={() => {
            dispatchGetModel(id);
            refresh();
            onClose();
          }}
        >
          {translate(getKey("upload", resources.FILE_VERSIONS))}
        </UploadButton>
      </div>
      <DialogActions>
        {activeFileHasError && (
          <FixModelDataButton
            variant="contained"
            className={classes.fixButton}
            label={translate(getKey("markAsFixed", resources.MODELS))}
            record={record}
            resource={resource}
            basePath={basePath}
            {...props}
          />
        )}
        <Button variant="outlined" onClick={onClose}>
          {translate("ra.action.back")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ModelFilesDialog.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  data: PropTypes.object,
  ids: PropTypes.arrayOf(PropTypes.any),
  loaded: PropTypes.bool,
  fileUuid: PropTypes.string,
  fileToken: PropTypes.string,
  errorLabel: PropTypes.any,
  activeFileHasError: PropTypes.bool,
  record: PropTypes.object,
  resource: PropTypes.string,
  basePath: PropTypes.string.isRequired,
};

export default connect(
  state => ({
    isUploading: getFileVersionUploadIsUploading(state),
    uploadProgress: getFileVersionUploadProgress(state) * 100,
    uploadFinished: getFileVersionUploadFinished(state),
  }),
  (dispatch, { fileUuid, fileToken, record, resource, activeFileHasError }) => ({
    upload: file => {
      if (activeFileHasError && record && !isNaN(record.__id) && resource === resources.MODELS) {
        dispatch(uploadFileVersionRequest(file, fileUuid, fileToken, record.__id, fixedModelData(record.__id)));
      } else {
        dispatch(uploadFileVersionRequest(file, fileUuid, fileToken, record.__id));
      }
    },
    dispatchGetModel: modelId => {
      const action = crudGetOne(resources.MODELS, modelId);
      dispatch({
        ...action,
        meta: {
          ...action.meta,
          uncache: true,
        },
      });
    },
  })
)(withStyles(styles)(translate(ModelFilesDialog)));
