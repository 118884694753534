import React from "react";
import Badge from "@material-ui/core/Badge";
import CommentIcon from "@material-ui/icons/ChatBubble";
import NoCommentIcon from "@material-ui/icons/ChatBubbleOutline";
import yellow from "@material-ui/core/colors/yellow";
import { withStyles } from "@material-ui/core/styles";
import get from "lodash/get";

const styles = theme => ({
  badge: {
    top: 10,
    right: 12,
  },
});

const CommentCountField = ({ classes, record, source }) => {
  const count = get(record, source, 0);
  if (count > 0) {
    return (
      <Badge badgeContent={count} classes={{ badge: classes.badge }}>
        <span style={{ color: yellow[800], textAlign: "center" }}>
          <CommentIcon />
        </span>
      </Badge>
    );
  } else {
    return (
      <span style={{ color: "#DDDDDD", textAlign: "center" }}>
        <NoCommentIcon />
      </span>
    );
  }
};

export default withStyles(styles)(CommentCountField);
