import React from "react";
import {
  translate,
  TextField,
  Datagrid,
  List,
  ReferenceField,
  Pagination,
  Filter,
  TextInput,
  SelectInput,
  NumberInput,
} from "react-admin";
import resources from "../../config/resources";
import DateField from "../customFields/DateField";
import PriceField from "../customFields/PriceField";
import paginationOptions from "../../config/PaginationOptions";
import RohStatus from "../../config/RohStatus";
import TransactionStatus from "../../config/TransactionStatus";
import { getKey } from "../../lib/i18nUtils";
import CardActions from "@material-ui/core/CardActions";

const ModelStatusUpdatesFilters = ({ translate, ...props }) => (
  <Filter {...props}>
    <SelectInput
      source="transaction"
      choices={Object.values(TransactionStatus)}
      optionValue="key"
      optionText={({ translationKey }) => translate(translationKey)}
      allowEmpty={true}
      resettable
    />
    <TextInput
      fullWidth
      source="user#username"
      label={translate(getKey("username", resources.MODEL_STATUS_UPDATES))}
      resettable
    />
    <NumberInput fullWidth source="model#rapidshopId" label={translate(getKey("rapidshopId", resources.MODELS))} />
    <SelectInput
      source="status"
      choices={Object.values(RohStatus)}
      optionValue="key"
      optionText={({ translationKey }) => translate(translationKey)}
      allowEmpty={false}
      resettable
    />
    <TextInput
      fullWidth
      source="job#externalId"
      label={translate(getKey("job", resources.MODEL_STATUS_UPDATES))}
      resettable
    />
  </Filter>
);

const ModelStatusUpdatesActions = ({ basePath, displayedFilters, filters, filterValues, resource, showFilter }) => (
  <CardActions>
    {filters &&
      React.cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: "button",
      })}
  </CardActions>
);

const ModelStatusUpdatesList = ({ translate, ...props }) => (
  <List
    {...props}
    bulkActionButtons={false}
    filters={<ModelStatusUpdatesFilters translate={translate} />}
    sort={{ field: "time", order: "DESC" }}
    actions={<ModelStatusUpdatesActions />}
    pagination={<Pagination {...paginationOptions} />}
  >
    <Datagrid>
      <TextField source="__id" />
      <DateField source="time" displayFormat="DD. MMMM YYYY HH:mm:ss" />
      <TextField source="transaction" />
      <TextField source="username" />
      <ReferenceField source="model" reference={resources.MODELS} link={false} allowEmpty={true}>
        <TextField source="rapidshopId" />
      </ReferenceField>
      <ReferenceField reference={resources.JOBS} source="job" link={false}>
        <TextField source="externalId" />
      </ReferenceField>
      <PriceField currencySource="orderTotalPriceCurrency" valueSource="orderTotalPriceAmount" />
    </Datagrid>
  </List>
);

export default translate(ModelStatusUpdatesList);
