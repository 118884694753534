import React, { Component } from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import FormHelperText from "@material-ui/core/FormHelperText";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import Chip from "@material-ui/core/Chip";
import Checkbox from "@material-ui/core/Checkbox";
import { withStyles, createStyles } from "@material-ui/core/styles";
import compose from "recompose/compose";
import classnames from "classnames";
import { addField, translate, Button } from "react-admin";
import ClearIcon from "@material-ui/icons/Clear";
import { getKey } from "./lib/i18nUtils";

const sanitizeRestProps = ({
  addLabel,
  allowEmpty,
  basePath,
  choices,
  className,
  component,
  crudGetMatching,
  crudGetOne,
  defaultValue,
  filter,
  filterToQuery,
  formClassName,
  initializeForm,
  input,
  label,
  locale,
  meta,
  onChange,
  options,
  optionValue,
  optionText,
  perPage,
  record,
  reference,
  resource,
  setFilter,
  setPagination,
  setSort,
  sort,
  source,
  textAlign,
  translate,
  translateChoice,
  validation,
  ...rest
}) => rest;

const styles = theme =>
  createStyles({
    root: {
      marginTop: theme.spacing(),
      marginBottom: theme.spacing(0.5),
      minWidth: "200px",
      backgroundColor: "rgba(0, 0, 0, 0.09)",
    },
    chips: {
      display: "flex",
      flexWrap: "wrap",
    },
    chip: {
      marginTop: theme.spacing(1) / 4,
      marginLeft: theme.spacing(1) / 4,
      marginRight: theme.spacing(1) / 4,
    },
    select: {
      height: "auto",
      overflow: "auto",
    },
    container: {},
    inputLabel: {
      marginLeft: theme.spacing(1),
    },
  });

/**
 * An Input component for a select box allowing multiple selections, using an array of objects for the options
 *
 * Pass possible options as an array of objects in the 'choices' attribute.
 *
 * By default, the options are built from:
 *  - the 'id' property as the option value,
 *  - the 'name' property an the option text
 * @example
 * const choices = [
 *    { id: 'programming', name: 'Programming' },
 *    { id: 'lifestyle', name: 'Lifestyle' },
 *    { id: 'photography', name: 'Photography' },
 * ];
 * <SelectArrayInput source="tags" choices={choices} />
 *
 * You can also customize the properties to use for the option name and value,
 * thanks to the 'optionText' and 'optionValue' attributes.
 * @example
 * const choices = [
 *    { _id: 123, full_name: 'Leo Tolstoi', sex: 'M' },
 *    { _id: 456, full_name: 'Jane Austen', sex: 'F' },
 * ];
 * <SelectArrayInput source="authors" choices={choices} optionText="full_name" optionValue="_id" />
 *
 * `optionText` also accepts a function, so you can shape the option text at will:
 * @example
 * const choices = [
 *    { id: 123, first_name: 'Leo', last_name: 'Tolstoi' },
 *    { id: 456, first_name: 'Jane', last_name: 'Austen' },
 * ];
 * const optionRenderer = choice => `${choice.first_name} ${choice.last_name}`;
 * <SelectArrayInput source="authors" choices={choices} optionText={optionRenderer} />
 *
 * `optionText` also accepts a React Element, that will be cloned and receive
 * the related choice as the `record` prop. You can use Field components there.
 * @example
 * const choices = [
 *    { id: 123, first_name: 'Leo', last_name: 'Tolstoi' },
 *    { id: 456, first_name: 'Jane', last_name: 'Austen' },
 * ];
 * const FullNameField = ({ record }) => <span>{record.first_name} {record.last_name}</span>;
 * <SelectArrayInput source="authors" choices={choices} optionText={<FullNameField />}/>
 *
 * The choices are translated by default, so you can use translation identifiers as choices:
 * @example
 * const choices = [
 *    { id: 'programming', name: 'myroot.tags.programming' },
 *    { id: 'lifestyle', name: 'myroot.tags.lifestyle' },
 *    { id: 'photography', name: 'myroot.tags.photography' },
 * ];
 */
class SelectLongArrayInput extends Component {
  /*
   * Using state to bypass a redux-form comparison but which prevents re-rendering
   * @see https://github.com/erikras/redux-form/issues/2456
   */
  constructor(props) {
    super(props);

    this.state = {
      value: this.props.input.value || [],
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.input.value !== prevProps.input.value) {
      this.setState({ value: this.props.input.value || [] });
    }
  }

  handleChange = event => {
    this.props.input.onChange(event.target.value);
    // HACK: For some reason, redux-form does not consider this input touched without calling onBlur manually
    this.props.input.onBlur(event.target.value);
    this.setState({ value: event.target.value });
  };

  handleReset = () => {
    this.props.input.onChange([]);
    // HACK: For some reason, redux-form does not consider this input touched without calling onBlur manually
    this.props.input.onBlur([]);
    this.setState({ value: [] });
  };

  renderMenuItemOption = choice => {
    const { optionText, translate, translateChoice } = this.props;
    if (React.isValidElement(optionText))
      return React.cloneElement(optionText, {
        record: choice,
      });
    const choiceName = typeof optionText === "function" ? optionText(choice) : get(choice, optionText);
    return translateChoice ? translate(choiceName, { _: choiceName }) : choiceName;
  };

  renderMenuItem = choice => {
    const { optionValue } = this.props;
    const { value } = this.state;
    const id = get(choice, optionValue);
    return (
      <MenuItem key={id} value={id}>
        <Checkbox checked={(value || []).includes(id)} />
        {this.renderMenuItemOption(choice)}
      </MenuItem>
    );
  };

  renderSelected = selected => {
    const { choices, optionValue, translate, classes } = this.props;
    const selectedItems = selected
      .slice(0, selected.length === 2 ? 2 : 1)
      .map(item => choices.find(choice => choice[optionValue] === item))
      .map(item => (
        <Chip
          size="small"
          key={get(item, optionValue)}
          label={this.renderMenuItemOption(item)}
          className={classes.chip}
        />
      ));
    const nMore = selected.length - 2;

    return (
      <div className={classes.chips}>
        {selectedItems}
        {nMore <= 0 ? null : (
          <Chip
            size="small"
            key="nmore"
            label={translate("format.nMore", { smart_count: nMore })}
            className={classes.chip}
          />
        )}
      </div>
    );
  };

  render() {
    const {
      choices,
      classes,
      className,
      label,
      meta,
      options,
      resource,
      source,
      optionText,
      optionValue,
      translate,
      resettable,
      ...rest
    } = this.props;
    if (typeof meta === "undefined") {
      throw new Error(
        "The SelectInput component wasn't called within a redux-form <Field>. Did you decorate it and forget to add the addField prop to your component? See https://marmelab.com/react-admin/Inputs.html#writing-your-own-input-component for details."
      );
    }
    const { touched, error, helperText = false } = meta;

    const showResetButton = resettable && (Array.isArray(this.state.value) && this.state.value.length > 0);

    const inputLabel = label || (source && resource && translate(getKey(source, resource)));

    return (
      <FormControl
        className={classnames(classes.root, className)}
        error={!!(touched && error)}
        variant="filled"
        {...sanitizeRestProps(rest)}
        fullWidth
      >
        <Grid container>
          <Grid item xs={showResetButton ? 11 : 12}>
            {inputLabel && <InputLabel className={classes.inputLabel}>{inputLabel}</InputLabel>}
            <Select
              autoWidth
              multiple
              input={<Input id={source} />}
              value={this.state.value}
              error={!!(touched && error)}
              renderValue={this.renderSelected}
              {...options}
              onChange={this.handleChange}
              style={{ width: "100%" }}
            >
              {choices.map(this.renderMenuItem)}
            </Select>
          </Grid>
          {showResetButton && (
            <Grid item xs={1} style={{ textAlign: "center" }}>
              <Button
                size="small"
                style={{
                  minWidth: 0,
                  padding: 0,
                  height: "100%",
                  marginTop: 8,
                }}
                onClick={() => this.handleReset()}
              >
                <ClearIcon />
              </Button>
            </Grid>
          )}
        </Grid>
        {touched && error && <FormHelperText error>{error}</FormHelperText>}
        {helperText && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    );
  }
}

SelectLongArrayInput.propTypes = {
  choices: PropTypes.arrayOf(PropTypes.object),
  classes: PropTypes.object,
  className: PropTypes.string,
  children: PropTypes.node,
  input: PropTypes.object,
  label: PropTypes.string,
  meta: PropTypes.object,
  options: PropTypes.object,
  optionText: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.element]).isRequired,
  optionValue: PropTypes.string.isRequired,
  resource: PropTypes.string,
  source: PropTypes.string,
  translate: PropTypes.func.isRequired,
  translateChoice: PropTypes.bool,
};

SelectLongArrayInput.defaultProps = {
  classes: {},
  choices: [],
  options: {},
  optionText: "name",
  optionValue: "id",
  translateChoice: true,
};

const EnhancedSelectLongArrayInput = compose(
  addField,
  translate,
  withStyles(styles)
)(SelectLongArrayInput);

export default EnhancedSelectLongArrayInput;
