import React from "react";
import {
  ChipField,
  Datagrid,
  Filter,
  FunctionField,
  List,
  ReferenceField,
  ReferenceManyField,
  ShowButton,
  SingleFieldList,
  TextField,
  TextInput,
  translate,
} from "react-admin";
import resources from "../../config/resources";
import { withStyles } from "@material-ui/core/styles";
import StatusField from "../customFields/StatusField";
import { getKey } from "../../lib/i18nUtils";
import DateField from "../customFields/DateField";
import blue from "@material-ui/core/colors/lightBlue";
import PriceField from "../customFields/PriceField";
import PortfolioButton from "../documents/PortfolioButton";

const styles = theme => ({
  field: {
    display: "block",
  },
  inlineField: {
    display: "inline-block",
  },
  fullWidthField: {
    width: "100%",
  },
  titleText: {
    padding: theme.spacing(0.5),
    marginRight: theme.spacing(2),
  },
  progress: {
    maxWidth: 120,
    marginTop: 0,
  },
});

const Filters = ({ translate, ...props }) => (
  <Filter {...props}>
    <TextInput source="externalId" alwaysOn resettable />
    <TextInput
      source="models#shipment#order#rapidshopId"
      label={translate(getKey("contactorRapidshopId", resources.ORDERS))}
      alwaysOn={true}
      resettable
    />
  </Filter>
);

const ContractorJobList = ({ classes, translate, permissions, ...props }) => (
  <List
    {...props}
    bulkActionButtons={false}
    actions={null}
    filters={<Filters translate={translate} />}
    filter={{
      active: "true",
      hasActiveModels: "true",
    }}
    sort={{ field: "externalId" }}
  >
    <Datagrid rowClick="show">
      <TextField source="externalId" />
      <ReferenceManyField
        target="shipments#models#job#id"
        reference={resources.ORDERS}
        label={translate(getKey("contactorRapidshopId", resources.ORDERS))}
      >
        <SingleFieldList linkType={false}>
          <ChipField source="rapidshopId" style={{ fontWeight: 700, backgroundColor: blue[100] }} />
        </SingleFieldList>
      </ReferenceManyField>
      <StatusField isContractorView={true} source="status" useChip={true} />
      <TextField source="modelAmount" label={translate(getKey("models", resources.JOBS))} />
      <PriceField label={translate(getKey("priceValue", resources.JOBS))} source="price" />
      <DateField source="dateEstimate" />
      <FunctionField
        render={record => (
          <ReferenceField record={record} {...props} source="contractor" reference={resources.CONTRACTORS} link={false}>
            <FunctionField
              render={contractor =>
                contractor && contractor.contractorType === "INTERNAL" && <PortfolioButton record={record} {...props} />
              }
            />
          </ReferenceField>
        )}
      />
      <ShowButton />
    </Datagrid>
  </List>
);

export default withStyles(styles)(translate(ContractorJobList));
