import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { translate, Filter, TextInput, SelectInput, BooleanInput } from "react-admin";
import CustomerTypes from "../../config/CustomerTypes";
import resources from "../../config/resources";
import { getKey } from "../../lib/i18nUtils";

const styles = theme => ({
  container: {
    display: "grid !important",
    width: "100%",

    gridTemplateColumns: "repeat(18, 1fr)",
    [theme.breakpoints.down("md")]: {
      gridTemplateColumns: "repeat(9, 1fr)",
    },
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "repeat(6, 1fr)",
    },
    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "repeat(3, 1fr)",
    },

    "& > *": {
      gridColumnEnd: "span 3",
    },
  },
});

const CustomerFilters = ({ translate, classes, ...props }) => (
  <Filter {...props} classes={{ form: classes.container }}>
    <TextInput source="debitorNumber" alwaysOn fullWidth resettable />
    <TextInput source="prename" fullWidth resettable />
    <TextInput source="lastname" alwaysOn fullWidth resettable />
    <TextInput source="companyName" alwaysOn fullWidth resettable />
    <TextInput source="phone" fullWidth resettable />
    <TextInput source="phoneMobile" fullWidth resettable />
    <TextInput source="email" fullWidth resettable />
    <TextInput source="emailInvoice" fullWidth resettable />
    <TextInput source="fax" fullWidth resettable />
    <SelectInput
      source="customerType"
      choices={Object.values(CustomerTypes)}
      fullWidth
      optionValue="key"
      optionText={({ translation }) => translate(translation)}
      allowEmpty={false}
    />
    <BooleanInput
      source="allowNewsletter"
      fullWidth
      label={translate(getKey("allowNewsletter", resources.CUSTOMERS))}
    />
    <BooleanInput
      source="allowNotifications"
      fullWidth
      label={translate(getKey("allowNotifications", resources.CUSTOMERS))}
    />
    <BooleanInput source="active" fullWidth label={translate(getKey("active", resources.CUSTOMERS))} />
  </Filter>
);

CustomerFilters.propTypes = {
  classes: PropTypes.object.isRequired,
  translate: PropTypes.func.isRequired,
};

export default withStyles(styles)(translate(CustomerFilters));
