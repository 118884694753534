import { SET_ROW_EXPANDED } from "./action";

export default (state = {}, { type, payload } = {}) => {
  if (type === SET_ROW_EXPANDED) {
    return {
      ...state,
      [payload.basePath]: payload.row,
    };
  } else {
    return state;
  }
};
