import React from "react";
import PropTypes from "prop-types";
import { translate } from "react-admin";
import { withStyles, withTheme } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import Typography from "@material-ui/core/Typography";
import grey from "@material-ui/core/colors/grey";
import classnames from "classnames";

const styles = theme => ({
  root: {
    width: 120,
    position: "relative",
    flex: "1 1 auto",
    margin: theme.spacing(1),

    "&:hover a": {
      display: "flex",
    },
  },
  titleContainer: {
    backgroundColor: grey[100],
    paddingBottom: `${theme.spacing(1)}px !important`,
    padding: theme.spacing(1),
  },
  subtitle: {
    color: grey[600],
  },
  overlayContainer: {
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    width: "100%",
    height: "100%",
    backgroundColor: grey[200],
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textDecoration: "none",
    color: "inherit",
    cursor: "pointer",
    display: "none",
  },
  actionContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  previewIcon: {
    fontSize: 32,
  },
  previewIconContainer: {
    height: 100,
  },
  cutText: {
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textAlign: "center",
  },
  overlayTitle: {
    wordWrap: "break-word",
    textAlign: "center",
    fontSize: theme.typography.body1.fontSize,
    width: "calc(100% - 10px)",
  },
  disabled: {
    position: "absolute",
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
    backgroundColor: "gray",
    zIndex: 3,
    opacity: 0.25,
  },
});

const PreviewCard = ({
  title,
  subtitle,
  downloadUrl,
  onClick,
  previewUrl,
  previewIcon,
  style = {},
  disabled,
  theme,
  translate,
  classes,
}) => {
  const targetProps = onClick
    ? { onClick, style: { backgroundColor: theme.palette.primary.main } }
    : downloadUrl
    ? {
        href: downloadUrl,
        target: downloadUrl,
        download: true,
      }
    : {
        style: { cursor: "default" },
      };

  const textStyle = onClick ? { color: "#fff" } : {};
  const containerStyle = onClick
    ? {
        backgroundColor: theme.palette.primary.light,
        color: "#fff",
        ...style,
      }
    : {};

  return (
    <Card className={classes.root} style={containerStyle}>
      {disabled && <div className={classes.disabled}></div>}
      {previewUrl ? (
        <CardMedia component="img" alt="Preview Image" height={100} image={previewUrl} />
      ) : (
        <Grid container justify="center" alignItems="center" className={classes.previewIconContainer}>
          <Grid item>
            {previewIcon ? (
              React.cloneElement(previewIcon, {
                className: classes.previewIcon,
              })
            ) : (
              <InsertDriveFileIcon fontSize="inherit" className={classes.previewIcon} />
            )}
          </Grid>
        </Grid>
      )}

      <CardContent
        className={classes.titleContainer}
        style={onClick ? { backgroundColor: theme.palette.primary.main } : {}}
      >
        <Typography variant="body2" className={classes.cutText} style={textStyle}>
          {title}
        </Typography>
      </CardContent>

      {!disabled && (
        <a className={classes.overlayContainer} {...targetProps}>
          <Typography variant="body2" className={classes.overlayTitle} style={textStyle}>
            {title}
          </Typography>
          {subtitle && (
            <Typography variant="body2" className={classnames(classes.subtitle, classes.overlayTitle)}>
              {subtitle}
            </Typography>
          )}
          {!subtitle && downloadUrl && (
            <Typography variant="body2" className={classnames(classes.subtitle, classes.overlayTitle)}>
              {translate("ra.action.download")}
            </Typography>
          )}
          {downloadUrl && (
            <div className={classes.actionContainer}>
              <CloudDownloadIcon />
            </div>
          )}
          {onClick && previewIcon && <div className={classes.actionContainer}>{previewIcon}</div>}
        </a>
      )}
    </Card>
  );
};

PreviewCard.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  downloadUrl: PropTypes.string,
  previewUrl: PropTypes.string,
  previewIcon: PropTypes.node,
  style: PropTypes.object,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,

  theme: PropTypes.object.isRequired,
  translate: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withTheme(translate(PreviewCard)));
