import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { translate } from "react-admin";
import AutoAssignLogStatus from "../../config/AutoAssignLogStatus";
import RohStatus from "../../config/RohStatus";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import red from "@material-ui/core/colors/red";
import green from "@material-ui/core/colors/green";
import grey from "@material-ui/core/colors/grey";

const styles = theme => ({
  root: {
    display: "inline-block",
    boxSizing: "content-box",
    paddingLeft: "4px",
    paddingRight: "4px",
    backgroundColor: grey[100],
  },
  icon: {
    display: "inline-block",
    boxSizing: "border-box",
    height: `${theme.spacing(1)}px`,
    width: `${theme.spacing(1)}px`,
    marginBottom: `${theme.spacing(0.5)}px`,
    marginRight: `${theme.spacing(0.5)}px`,
    borderRadius: "50%",
    verticalAlign: "bottom",
  },
  text: {
    fontSize: `${theme.spacing(1.5)}px`,
    lineHeight: `${theme.spacing(2)}px`,
  },
});

const AutoAssignJobStatusField = ({ rohStatus, logStatus, translate, classes }) => {
  const hasAutoAssignError = logStatus !== AutoAssignLogStatus.SUCCESS.key;
  if (hasAutoAssignError && rohStatus !== RohStatus.UNASSIGNED.key) {
    return null;
  }

  return (
    <div className={classes.root}>
      <Typography variant="body2" className={classes.text}>
        <span
          className={classes.icon}
          style={{
            backgroundColor: hasAutoAssignError ? red[400] : green[400],
          }}
        ></span>
        {hasAutoAssignError ? translate("autoAssignJobStatus.error") : translate("autoAssignJobStatus.success")}
      </Typography>
    </div>
  );
};

AutoAssignJobStatusField.propTypes = {
  rohStatus: PropTypes.string.isRequired,
  logStatus: PropTypes.string.isRequired,
  translate: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(translate(AutoAssignJobStatusField));
