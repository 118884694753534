import React from "react";
import { connect } from "react-redux";
import { Button } from "react-admin";
import resources from "../../config/resources";
import sanitizeButton from "../sanitizeButton";
import { intransportJob } from "../../redux/job/intransportJob/action";
import Icon from "@material-ui/icons/LocalShipping";
import { noop } from "../../lib/util";

const IntransportJobButton = ({ record, resource, intransportJob, onClick = noop, ...props }) => {
  const handleClick = e => {
    if (resource === resources.JOBS && record) {
      intransportJob(record.__id);
    }
    onClick(e);
  };

  return <Button startIcon={<Icon />} {...sanitizeButton(props)} onClick={handleClick} />;
};

export default connect(
  undefined,
  {
    intransportJob,
  }
)(IntransportJobButton);
