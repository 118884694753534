import createReducer, { mergeReducers } from "../../lib/createReducer";
import { CHECK_PRINTJOB_TIMES, CHECK_PRINTJOB_TIMES_ERROR, CHECK_PRINTJOB_TIMES_SUCCESS } from "./action";

const init = {
  isFetching: false,
  fetchSuccess: false,
  hasError: false,

  payload: null,
  error: null,
};

const requestReducer = createReducer(CHECK_PRINTJOB_TIMES, () => ({ isFetching: true }));

const successReducer = createReducer(CHECK_PRINTJOB_TIMES_SUCCESS, ({ response }) => ({
  isFetching: false,
  fetchSuccess: true,
  hasError: false,
  payload: response,
  error: null,
}));

const errorReducer = createReducer(CHECK_PRINTJOB_TIMES_ERROR, ({ error }) => ({
  isFetching: false,
  fetchSuccess: false,
  hasError: true,
  payload: null,
  error: error,
}));

export default mergeReducers([requestReducer, successReducer, errorReducer], init);
