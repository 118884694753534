import { call, put, takeEvery } from "redux-saga/effects";
import resources from "../../config/resources";
import config from "../../config/index";
import fetchService from "../../lib/fetchService";
import { ANALYSE_WALLTHICKNESS, analyseWallthicknessError, analyseWallthicknessSuccess } from "./action";

const requestSaga = function*({ payload }) {
  const { reference, fileUuid, criticalArea, thickness } = payload;

  const url = `${config.urls.BACKEND}/${resources.ART_JOBS}/autocreate`;
  const data = {};
  const body = {
    reference,
    actions: ["MODEL_WALLTHICKNESS"],
    file: fileUuid,
    criticalAreaInPercent: criticalArea,
    thickness,
  };

  try {
    const response = yield call(fetchService.post, url, data, body);

    if (response.ok) {
      const responseData = yield call(() => response.json());
      yield put(analyseWallthicknessSuccess(responseData));
    } else {
      const statusCode = response.status;
      yield put(analyseWallthicknessError(new Error(`Request failed with error ${statusCode}!`)));
    }
  } catch (e) {
    yield put(analyseWallthicknessError(e));
  }
};

export default function*() {
  yield takeEvery(ANALYSE_WALLTHICKNESS, requestSaga);
}
