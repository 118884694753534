import React from "react";
import { Datagrid, List, Pagination, TextField, translate } from "react-admin";
import resources from "../../config/resources";
import { withStyles } from "@material-ui/core/styles";
import ArchivedJobPanel from "./ArchivedJobPanel";
import { getKey } from "../../lib/i18nUtils";
import DateField from "../customFields/DateField";
import ArchivedJobFilters from "./ArchivedJobFilters";
import MultiStatusField from "../customFields/MultiStatusField";
import ExpansionDatagridBody from "../ExpansionDatagrid/ExpansionDatagridBody";
import paginationOptions from "../../config/PaginationOptions";
import CommentCountField from "../customFields/CommentCountField";
import CommentIcon from "@material-ui/icons/Chat";
import PriceField from "../customFields/PriceField";

const styles = theme => ({
  field: {
    display: "block",
  },
  inlineField: {
    display: "inline-block",
  },
  fullWidthField: {
    width: "100%",
  },
  titleText: {
    padding: theme.spacing(0.5),
    marginRight: theme.spacing(2),
  },
  progress: {
    maxWidth: 120,
    marginTop: 0,
  },
});

const ArchivedJobsList = ({ classes, translate, permissions, ...props }) => (
  <List
    {...props}
    bulkActionButtons={false}
    actions={null}
    filters={<ArchivedJobFilters permissions={permissions} />}
    pagination={<Pagination {...paginationOptions} />}
    sort={{ field: "id", order: "DESC" }}
  >
    <Datagrid
      expand={<ArchivedJobPanel permissions={permissions} />}
      rowClick="expand"
      body={<ExpansionDatagridBody />}
    >
      <TextField source="externalId" sortBy="id" />
      <TextField
        source="contractorName"
        sortBy="contractor.contractorName"
        label={translate(getKey("contractor", resources.ARCHIVED_JOBS))}
      />
      <MultiStatusField source="status" useChip={true} />
      <CommentCountField source="commentsCount" label={<CommentIcon fontSize="small" />} />
      <TextField source="modelAmount" label={translate(getKey("models", resources.ARCHIVED_JOBS))} textAlign="right" />
      <PriceField label={translate(getKey("priceValue", resources.ARCHIVED_JOBS))} textAlign="right" source="price" />
      <DateField source="dateEstimate" textAlign="right" />
    </Datagrid>
  </List>
);

export default withStyles(styles)(translate(ArchivedJobsList));
