import get from "lodash/get";

/**
 * Cause of our new api schema for react admin (id is now __id and id is the uri-path)
 * We need to switch the ids sometimes (we need the __id as id),
 * because i.e. the delete button just takes a record and automatically chooses 'id' in order to delete the item
 */

export default record => {
  const id = get(record, "__id");
  return {
    ...record,
    id,
  };
};
