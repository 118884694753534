import React from "react";
import PropTypes from "prop-types";
import { translate } from "react-admin";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import red from "@material-ui/core/colors/red";
import resources from "../../config/resources";
import { getKey } from "../../lib/i18nUtils";

const styles = () => ({
  iconPaddingStyle: {
    paddingRight: "0.5rem",
  },
  messageStyle: {
    color: red[900],
    marginTop: 8,
  },
});

const PaymentWarningDialog = ({ isOpen, onClose, translate, classes }) => (
  <Dialog fullWidth={true} maxWidth="md" open={isOpen} onClose={() => onClose(false)}>
    <DialogTitle>{translate(getKey("paymentWarningTitle", resources.SHIPMENTS))}</DialogTitle>
    <DialogContent>
      <Typography variant="subtitle1" className={classes.messageStyle}>
        {translate(getKey("paymentWarningMessage", resources.SHIPMENTS))}
      </Typography>
    </DialogContent>
    <DialogActions>
      <Button onClick={() => onClose(false)}>
        <CloseIcon className={classes.iconPaddingStyle} />
        {translate("ra.action.cancel")}
      </Button>
      <Button onClick={() => onClose(true)} variant="contained" color="secondary">
        {translate("ra.action.confirm")}
      </Button>
    </DialogActions>
  </Dialog>
);

PaymentWarningDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,

  translate: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

PaymentWarningDialog.defaultProps = {
  classes: {},
  isOpen: false,
};

export default translate(withStyles(styles)(PaymentWarningDialog));
