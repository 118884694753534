import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { Query, GET_ONE } from "react-admin";
import resources from "../../config/resources";
import SystemPushNotification from "./SystemPushNotification";
import moment from "moment";

const JobDocumentAddedNotification = ({ notification, shouldPushToSystem }) =>
  notification.document && (
    <Query type={GET_ONE} resource={resources.DOCUMENTS} payload={{ id: notification.documentId }}>
      {({ data, loading, error }) => {
        if (loading) {
          return <i>L&auml;dt...</i>;
        }
        if (error) {
          return <b>Einem Ihenen zugewiesenen Sub. Auftrag wurde ein Dokument hinzugefügt.</b>;
        }
        return (
          <div style={{ display: "inline-block", maxWidth: 400 }}>
            <p>Einem Ihenen zugewiesenen Sub. Auftrag wurde das Dokument "{data.name}" hinzugefügt.</p>
            <p>
              {data.downloadUrl && (
                <Fragment>
                  <a href={data.downloadUrl} target="_blank" rel="noopener noreferrer">
                    Herunterladen
                  </a>
                  &nbsp;
                </Fragment>
              )}
              <Link to={`/${resources.JOBS}?filter=%7B"id"%3A"${data.jobId}"%7D&order=DESC&page=1&perPage=10&sort=id`}>
                Zum Sub. Auftrag
              </Link>
            </p>
            {shouldPushToSystem && (
              <SystemPushNotification
                open={true}
                subject={`Sub. Auftrag Dokument hinzugefügt`}
                body={`Einem Ihenen zugewiesenen Sub. Auftrag wurde das Dokument "${data.name}" hinzugefügt.`}
                timestamp={moment(notification.createdAt).unix()}
                tag={`notify-${notification.id}`}
                actions={[
                  {
                    action: "show",
                    title: "Zum Sub. Auftrag",
                    redirectUrl: `/${resources.JOBS}?filter=%7B"id"%3A"${data.jobId}"%7D&order=DESC&page=1&perPage=10&sort=id`,
                  },
                ]}
              />
            )}
          </div>
        );
      }}
    </Query>
  );

export default JobDocumentAddedNotification;
