import createReducer, { mergeReducers } from "../../../lib/createReducer";
import { FIXED_MODEL_DATA_ERROR, FIXED_MODEL_DATA_REQUEST, FIXED_MODEL_DATA_SUCCESS } from "./action";

const init = {
  model: null,
  reason: null,
  message: null,

  isFetching: false,
  fetchSuccess: false,
  hasError: false,
  payload: null,
  error: null,
};

const fixedModelDataReducer = createReducer(
  FIXED_MODEL_DATA_REQUEST,
  ({ model, reason, message }) => ({
    ...init,
    model,
    reason,
    message,
    isFetching: true,
  }),
  true
);

const fixedModelDataSuccessReducer = createReducer(FIXED_MODEL_DATA_SUCCESS, ({ response }) => ({
  isFetching: false,
  fetchSuccess: true,
  payload: response,
}));

const fixedModelDataErrorReducer = createReducer(FIXED_MODEL_DATA_ERROR, ({ error }) => ({
  isFetching: false,
  hasError: true,
  error,
}));

export default mergeReducers([fixedModelDataReducer, fixedModelDataSuccessReducer, fixedModelDataErrorReducer], init);
