import createAction from "../../lib/createAction";

export const INIT_NOTIFICATION_TIMER = "INIT_NOTIFICATION_TIMER";
export const initNotificationTimer = createAction(INIT_NOTIFICATION_TIMER);

export const INIT_NOTIFICATION_TIMER_SUCCESS = "INIT_NOTIFICATION_TIMER_SUCCESS";
export const initNotificationTimerSuccess = createAction(INIT_NOTIFICATION_TIMER_SUCCESS, "timer");

export const DESTROY_NOTIFICATION_TIMER = "DESTROY_NOTIFICATION_TIMER";
export const destroyNotificationTimer = createAction(DESTROY_NOTIFICATION_TIMER);

export const DESTROY_NOTIFICATION_TIMER_SUCCESS = "DESTROY_NOTIFICATION_TIMER_SUCCESS";
export const destroyNotificationTimerSuccess = createAction(DESTROY_NOTIFICATION_TIMER_SUCCESS);

export const NOTIFICATION_TIMER_TICK = "NOTIFICATION_TIMER_TICK";
export const notificationTimerTick = createAction(NOTIFICATION_TIMER_TICK);

export const WINDOW_FOCUSED = "WINDOW_FOCUSED";
export const windowFocused = createAction(WINDOW_FOCUSED);

export const WINDOW_FOCUS_LOST = "WINDOW_FOCUS_LOST";
export const windowFocusLost = createAction(WINDOW_FOCUS_LOST);

export const PUSH_NOTIFICATION = "PUSH_NOTIFICATION";
export const pushNotification = createAction(PUSH_NOTIFICATION, "notification");

export const HIDE_NOTIFICATION = "HIDE_NOTIFICATION";
export const hideNotification = createAction(HIDE_NOTIFICATION, "id");
