import createReducer, { mergeReducers } from "../../../lib/createReducer";
import {
  JOB_BULK_OPERATION_ERROR,
  JOB_BULK_OPERATION_REQUEST,
  JOB_BULK_OPERATION_RESULT_CLEAR,
  JOB_BULK_OPERATION_SUCCESS,
} from "./action";

const init = {
  jobIds: [],
  bulkAction: null,

  isLoading: false,
  fetchSuccess: false,
  hasError: false,
  payload: null,
  error: null,
};

const requestReducer = createReducer(
  JOB_BULK_OPERATION_REQUEST,
  ({ jobIds, bulkAction }) => ({
    ...init,
    jobIds,
    bulkAction,
    isLoading: true,
  }),
  true
);

const successReducer = createReducer(JOB_BULK_OPERATION_SUCCESS, ({ response }) => ({
  isLoading: false,
  fetchSuccess: true,
  payload: response,
}));

const errorReducer = createReducer(JOB_BULK_OPERATION_ERROR, ({ error }) => ({
  isLoading: false,
  hasError: true,
  error,
}));

const clearReducer = createReducer(JOB_BULK_OPERATION_RESULT_CLEAR, () => init);

export default mergeReducers([requestReducer, successReducer, errorReducer, clearReducer], init);
