import React from "react";
import resources from "../../config/resources";
import { FunctionField, ReferenceArrayField, ReferenceField, TextField, translate } from "react-admin";
import Table from "@material-ui/core/Table";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import yellow from "@material-ui/core/colors/yellow";
import { withStyles } from "@material-ui/core/styles";
import PlainShow from "../PlainShow";
import DateField from "../customFields/DateField";
import { getKey } from "../../lib/i18nUtils";
import DownloadAllModelsButton from "../models/DownloadAllModelsButton";
import LabeledField from "../customFields/LabeledField";
import PreviewCard from "../PreviewCard";
import uniq from "lodash/uniq";
import ArchivedModelDatagrid from "../archivedModels/ArchivedModelDatagrid";

const styles = theme => ({
  infoBox: {
    marginBottom: theme.spacing(2),
  },
  modelsBox: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  table: {
    overflowX: "auto",
  },
  inlineButton: {
    display: "inline",
    marginBottom: 0,
    marginTop: "-" + theme.typography.body1.fontSize,
    height: theme.typography.body1.fontSize,
  },
  negMarginButton: {
    marginTop: `-${theme.spacing(2)}px`,
    marginBottom: `-${theme.spacing(2)}px`,
  },
  panel: {},
  subtasksBox: {
    padding: theme.spacing(2),
  },
  subtaskActionRow: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: theme.spacing(2),
  },
  customerNoteWrapper: {
    padding: theme.spacing(2),
    backgroundColor: yellow[50],
  },
  customerNoteHeading: {
    paddingBottom: theme.spacing(1),
  },
});

const ListTableCell = withStyles(theme => ({
  head: {
    ...theme.typography.body1,
    verticalAlign: "text-top",
    padding: "12px",
  },
}))(TableCell);

const OrderOverview = ({ parentProps, translate, classes, ...props }) => (
  <Paper className={classes.infoBox}>
    <Table className={classes.table}>
      <TableHead>
        <TableRow style={{ height: "15px" }}>
          <ListTableCell>
            <LabeledField
              {...props}
              source="orderRapidshopId"
              label={translate(getKey("rapidshopId", resources.ARCHIVED_ORDERS))}
            />
          </ListTableCell>
          <ListTableCell>
            <LabeledField {...props} source="numberPayment" />
          </ListTableCell>
          <ListTableCell>
            <ReferenceField {...props} source="customer" reference={resources.CUSTOMERS} linkType={false}>
              <LabeledField source="debitorNumber" />
            </ReferenceField>
          </ListTableCell>
          <ListTableCell>
            <LabeledField {...props} source="dateInvoice" type={<DateField />} />
          </ListTableCell>
          <ListTableCell />
        </TableRow>
        <TableRow style={{ height: "15px" }}>
          {props.record && props.record.originalDateShipping ? (
            <ListTableCell>
              <LabeledField {...props} source="originalDateShipping" type={<DateField displayFormat="DD.MM.YY" />} />
            </ListTableCell>
          ) : null}
          <ListTableCell>
            <LabeledField {...props} source="dateShipping" type={<DateField displayFormat="DD.MM.YY" />} />
          </ListTableCell>
          <ListTableCell>
            <TextField {...props} source="modelAmount" style={{ display: "inline" }} />
            &nbsp;{translate(getKey("models", resources.SHIPMENTS))}
          </ListTableCell>
          <ListTableCell>
            <FunctionField
              {...props}
              render={({ materials }) => uniq(materials).length}
              source="materialAmount"
              style={{ display: "inline" }}
            />
            &nbsp;
            {translate(getKey("materials", resources.SHIPMENTS))}
          </ListTableCell>
          <ListTableCell>
            <ReferenceArrayField {...props} perPage={1000} source="models" reference={resources.ARCHIVED_MODELS}>
              <DownloadAllModelsButton
                variant="contained"
                color="default"
                className={classes.negMarginButton}
                uuidSource="storageServiceUuid"
                tokenSource="storageServiceToken"
                legacyUrlSource="modelFileUrl"
              />
            </ReferenceArrayField>
          </ListTableCell>
        </TableRow>
      </TableHead>
    </Table>
  </Paper>
);

const ArchivedShipmentPanel = ({ translate, classes, override, permissions, ...props }) => (
  <PlainShow {...props} title="" location="" {...override}>
    <div className={classes.panel}>
      <OrderOverview {...props} translate={translate} classes={classes} />
      <ReferenceField allowEmpty={true} {...props} source="customer" reference={resources.CUSTOMERS}>
        <FunctionField
          render={({ note }) =>
            note && (
              <Paper className={classes.customerNoteWrapper}>
                <Typography variant="title" className={classes.customerNoteHeading}>
                  {translate(getKey("note", resources.CUSTOMERS))}
                </Typography>
                <Typography>{note}</Typography>
              </Paper>
            )
          }
        />
      </ReferenceField>
      <div style={{ margin: "0px -8px", display: "flex", flexDirection: "row" }}>
        <FunctionField
          {...props}
          render={({ orderConfirmationPdfUrl }) =>
            orderConfirmationPdfUrl && (
              <PreviewCard
                title={translate(getKey("orderConfirmationPdfUrl", resources.ARCHIVED_SHIPMENTS))}
                downloadUrl={orderConfirmationPdfUrl}
                style={{ marginRight: 8 }}
              />
            )
          }
        />
        <FunctionField
          {...props}
          render={({ invoicePdfUrl, paymentComplete }) =>
            invoicePdfUrl &&
            (paymentComplete ? (
              <PreviewCard
                title={translate(getKey("invoicePdfUrl", resources.ARCHIVED_SHIPMENTS))}
                downloadUrl={invoicePdfUrl}
              />
            ) : (
              <ReferenceField {...props} source="order" reference={resources.ARCHIVED_ORDERS} linkType={false}>
                <FunctionField
                  render={({ waitForPaymentComplete }) =>
                    !waitForPaymentComplete && (
                      <PreviewCard
                        title={translate(getKey("invoicePdfUrl", resources.ARCHIVED_SHIPMENTS))}
                        downloadUrl={invoicePdfUrl}
                      />
                    )
                  }
                />
              </ReferenceField>
            ))
          }
        />
        <FunctionField
          {...props}
          render={({ deliveryNotePdfUrl }) =>
            deliveryNotePdfUrl && (
              <PreviewCard
                title={translate(getKey("deliveryNotePdfUrl", resources.ARCHIVED_SHIPMENTS))}
                downloadUrl={deliveryNotePdfUrl}
                style={{ marginRight: 8 }}
              />
            )
          }
        />
        <FunctionField
          {...props}
          render={({ creditNotePdfUrl }) =>
            creditNotePdfUrl && (
              <PreviewCard
                title={translate(getKey("creditNotePdfUrl", resources.ARCHIVED_SHIPMENTS))}
                downloadUrl={creditNotePdfUrl}
                style={{ marginRight: 8 }}
              />
            )
          }
        />
      </div>
      <Paper className={classes.modelsBox}>
        <ReferenceArrayField {...props} reference={resources.ARCHIVED_MODELS} source="models">
          <ArchivedModelDatagrid
            {...props}
            base={props.basePath}
            permissions={permissions}
            paymentComplete={props.record.paymentComplete}
          />
        </ReferenceArrayField>
      </Paper>
    </div>
  </PlainShow>
);

export default translate(withStyles(styles)(ArchivedShipmentPanel));
