import { RESET_FORM } from "./action";

const initialState = {
  "select-filtergraph-form": {
    reset: false,
  },
};

export default (state = initialState, { type, payload } = {}) => {
  const { id } = payload || {};
  if (type === RESET_FORM) {
    return {
      ...state,
      [id]: {
        ...state[id],
        reset: true,
      },
    };
  }
  return state;
};
