import React from "react";
import { Labeled, RichTextField, TextField, translate } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper/Paper";
import Button from "@material-ui/core/Button";
import ClearIcon from "@material-ui/icons/Clear";
import PropTypes from "prop-types";

import StatusField from "../customFields/StatusField";
import { getKey } from "../../lib/i18nUtils";
import resources from "../../config/resources";

const useStyles = makeStyles(theme => ({
  hidden: {
    display: "none",
  },
  root: {
    display: "flex",
    flexDirection: "column",
  },
  container: {
    padding: theme.spacing(2),
  },
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    paddingTop: theme.spacing(1),
  },
  action: {
    position: "absolute",
    top: 0,
    right: 0,
  },
  label: {
    width: "auto",
    flexGrow: 1,
  },
}));

const SubtaskShow = ({ record, translate, onSubmit, ...props }) => {
  const classes = useStyles(props);
  return (
    <Paper className={classes.container}>
      <div className={classes.root}>
        <Button className={classes.action} onClick={onSubmit}>
          <ClearIcon />
        </Button>
        <div className={classes.row}>
          <Labeled {...props} label={translate("resources.subtasks.fields.title")} className={classes.label}>
            <TextField record={record} source="title" />
          </Labeled>
          <StatusField record={record} source="rohStatus" />
        </div>
      </div>
      <Labeled {...props} label={translate(getKey("description", resources.SUBTASKS))}>
        <RichTextField source="description" record={record} style={{ overflowWrap: "normal" }} />
      </Labeled>
    </Paper>
  );
};

SubtaskShow.propTypes = {
  classes: PropTypes.object,
  record: PropTypes.object,
  translate: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default translate(SubtaskShow);
