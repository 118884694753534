import { call, put, takeEvery } from "redux-saga/effects";
import resources from "../../../config/resources";
import config from "../../../config/index";
import fetchService from "../../../lib/fetchService";
import { SEND_JOB_OFFER_REQUEST, sendJobOfferError, sendJobOfferSuccess } from "./action";
import { crudGetOne } from "react-admin";
import { handleSagaError } from "../../../dataproviders/errorResponseHandler";

const requestSaga = function*({ payload }) {
  const { job } = payload;

  const url = `${config.urls.BACKEND}/v2/${resources.JOBS}/${encodeURIComponent(job)}/send_offer`;

  const response = yield call(fetchService.post, url);

  for (const action of yield call(handleSagaError, response, sendJobOfferError)) {
    yield put(action);
    return;
  }

  try {
    if (response.ok) {
      yield put(sendJobOfferSuccess());
      yield put(crudGetOne(resources.JOBS, job));
    } else {
      const statusCode = response.status;
      yield put(sendJobOfferError(new Error(`Request failed with error ${statusCode}!`)));
    }
  } catch (e) {
    yield put(sendJobOfferError(e));
  }
};

export default function*() {
  yield takeEvery(SEND_JOB_OFFER_REQUEST, requestSaga);
}
