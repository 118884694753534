export const JobBulkOperationsType = {
  ORDER: {
    key: "ORDER",
  },
  SET_IN_TRANSPORT: {
    key: "SET_IN_TRANSPORT",
  },
  SET_RECEIVED: {
    key: "SET_RECEIVED",
  },
  ACCEPT: {
    key: "ACCEPT",
  },
};
