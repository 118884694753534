import React, { cloneElement, memo } from "react";
import PropTypes from "prop-types";
import TableBody from "@material-ui/core/TableBody";
import classnames from "classnames";
import isEqual from "lodash/isEqual";
import { connect } from "react-redux";

import ExpansionDatagridRow, { PureExpansionDatagridRow } from "./ExpansionDatagridRow";
import { setRowExpanded } from "../../redux/expand/action";

const ExpansionDatagridBody = ({
  basePath,
  children,
  classes,
  className,
  data,
  expand,
  hasBulkActions,
  hover,
  ids,
  onToggleItem,
  resource,
  row,
  rowClick,
  rowStyle,
  selectedIds,
  styles,
  version,
  isRowSelectable,
  isLoading,
  expandedRowId,
  setExpandedRowId,
  ...rest
}) => {
  return (
    <TableBody className={classnames("datagrid-body", className)} {...rest}>
      {ids.map((id, rowIndex) =>
        cloneElement(
          row,
          {
            basePath,
            classes,
            className: classnames(classes.row, {
              [classes.rowEven]: rowIndex % 2 === 0,
              [classes.rowOdd]: rowIndex % 2 !== 0,
              [classes.clickableRow]: rowClick,
            }),
            expand,
            hasBulkActions,
            hover,
            id,
            key: id,
            onToggleExpand: rowId => setExpandedRowId(rowId),
            expandedRowId,
            record: data[id],
            resource,
            rowClick,
            selectable: !isRowSelectable || isRowSelectable(data[id]),
            selected: selectedIds.includes(id),
            onToggleItem,
            style: rowStyle ? rowStyle(data[id], rowIndex) : null,
          },
          children
        )
      )}
    </TableBody>
  );
};

ExpansionDatagridBody.propTypes = {
  basePath: PropTypes.string,
  classes: PropTypes.object,
  className: PropTypes.string,
  children: PropTypes.node,
  data: PropTypes.object.isRequired,
  expand: PropTypes.oneOfType([PropTypes.element, PropTypes.elementType]),
  hasBulkActions: PropTypes.bool.isRequired,
  hover: PropTypes.bool,
  ids: PropTypes.arrayOf(PropTypes.any).isRequired,
  onToggleItem: PropTypes.func,
  resource: PropTypes.string,
  row: PropTypes.element,
  rowClick: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  rowStyle: PropTypes.func,
  selectedIds: PropTypes.arrayOf(PropTypes.any),
  styles: PropTypes.object,
  isRowSelectable: PropTypes.func,
  version: PropTypes.number,
};

ExpansionDatagridBody.defaultProps = {
  data: {},
  hasBulkActions: false,
  ids: [],
  row: <ExpansionDatagridRow />,
};
// trick material-ui Table into thinking this is one of the child type it supports
ExpansionDatagridBody.muiName = "TableBody";

const areEqual = (prevProps, nextProps) => {
  const { children: _, ...prevPropsWithoutChildren } = prevProps;
  const { children: __, ...nextPropsWithoutChildren } = nextProps;
  return isEqual(prevPropsWithoutChildren, nextPropsWithoutChildren);
};

export const PureExpansionDatagridBody = memo(ExpansionDatagridBody, areEqual);
// trick material-ui Table into thinking this is one of the child type it supports
PureExpansionDatagridBody.muiName = "TableBody";
PureExpansionDatagridBody.defaultProps = {
  row: <PureExpansionDatagridRow />,
};

export default connect(
  (state, { basePath }) => ({ expandedRowId: state.expand[basePath] }),
  (dispatch, { basePath }) => ({
    setExpandedRowId: id => dispatch(setRowExpanded(basePath, id)),
  })
)(ExpansionDatagridBody);
