import resources from "./resources";

export const FilterOperations = {
  LIKE: "like",
  EQUAL: "equals",
  NOT_EQUAL: "neq",
  CONTAINS_ONE: "contains",
  /**
   * These are specified by the api but not used:
   * LESS_THAN
   * GREATER_THAN
   * CONTAINS_ALL
   */
};

export const FilterResources = {
  ADDITION: {
    key: resources.ADDITIONS,
    translationKey: `resources.${resources.ADDITIONS}.name`,
  },
  ADDRESS: {
    key: resources.ADDRESSES,
    translationKey: `resources.${resources.ADDRESS}.name`,
  },
  ASSIGNED_USER: {
    key: "assignedUsers",
    translationKey: `resources.${resources.ASSIGNED_USER}.name`,
  },
  CONTRACTOR: {
    key: "contractors",
    translationKey: `resources.${resources.CONTRACTOR}.name`,
  },
  CUSTOMER: {
    key: "customers",
    translationKey: `resources.${resources.CUSTOMER}.name`,
  },
  DOCUMENT: {
    key: "documents",
    translationKey: `resources.${resources.DOCUMENT}.name`,
  },
  DOCUMENT_TYPE: {
    key: "documentTypes",
    translationKey: `resources.${resources.DOCUMENT_TYPE}.name`,
  },
  FORMULA: {
    key: "formulas",
    translationKey: `resources.${resources.FORMULA}.name`,
  },
  JOB: {
    key: "jobs",
    translationKey: `resources.${resources.JOB}.name`,
  },
  MATERIAL: {
    key: "materials",
    translationKey: `resources.${resources.MATERIAL}.name`,
  },
  MATERIALISE_EXCEPTION: {
    key: "materialiseExceptions",
    translationKey: `resources.${resources.MATERIALISE_EXCEPTION}.name`,
  },
  MODEL: {
    key: "models",
    translationKey: `resources.${resources.MODEL}.name`,
  },
  MODEL_STATUS_UPDATE: {
    key: "modelStatusUpdates",
    translationKey: `resources.${resources.MODEL_STATUS_UPDATE}.name`,
  },
  ORDER: {
    key: "orders",
    translationKey: `resources.${resources.ORDER}.name`,
  },
  PRICE_ESTIMATE: {
    key: "price_estimates",
    translationKey: `resources.${resources.PRICE_ESTIMATE}.name`,
  },
  SHIPMENT: {
    key: "shipments",
    translationKey: `resources.${resources.SHIPMENT}.name`,
  },
  SUBTASK: {
    key: "subtasks",
    translationKey: `resources.${resources.SUBTASK}.name`,
  },
  SUPPORTED_MATERIAL: {
    key: "supportedmaterials",
    translationKey: `resources.${resources.SUPPORTED_MATERIAL}.name`,
  },
  TEMPLATE: {
    key: "templates",
    translationKey: `resources.${resources.TEMPLATE}.name`,
  },
  USER: {
    key: "users",
    translationKey: `resources.${resources.USER}.name`,
  },
  WAREHOUSE_LOCATION: {
    key: "warehouseLocations",
    translationKey: `resources.${resources.WAREHOUSE_LOCATION}.name`,
  },
  COMMENT: {
    key: "comments",
    translationKey: `resources.${resources.COMMENT}.name`,
  },
  USER_DETAILS: {
    key: "userDetails",
    translationKey: `resources.${resources.USER_DETAILS}.name`,
  },
};

export const FilterGraphTypes = {
  USER_CREATED: {
    key: "USER_CREATED",
    translationKey: "filterGraphType.USER_CREATED",
  },
};
