import qs from "qs";
import { Logger } from "./logger";

const log = Logger("REQUEST");

const qsOptions = { encodeValuesOnly: true, indices: false };

/**
 * FetchService for doing requests
 * @type {{post: (function(string, *=, *=, *=): Promise<Response>), get: (function(string, *=, *=): Promise<Response>)}}
 */
const fetchService = {
  __traceIdHeader: "X-B3-TraceId",
  __spanIdHeader: "X-B3-SpanId",

  __defaultHeaders: {
    "Cache-Control": "no-cache, no-store, must-revalidate",
    Accept: "application/json",
    "Content-Type": "application/json",
  },

  __getTraceId: () =>
    new Array(16)
      .fill(0)
      .map(() => (~~(Math.random() * 16)).toString(16))
      .join(""),

  __interceptResponse: (url, params, traceId, response) => {
    if (!response.ok) {
      log.warn({
        url,
        status: response.status,
        requestParams: params,
        requestTraceId: traceId,
        responseHeaders: response.headers,
      });
    }
    return response;
  },

  /**
   * Do a GET request
   * @param url {string} - Request url
   * @param params {Object.<string, *>} - QS params
   * @param [additionalHeaders] {Object.<string, string>} - Additional HTTP Headers
   * @returns {Promise<Response>} - Response promise
   * @deprecated Use fetchService#fetch instead
   */
  get: (url, params, additionalHeaders) => fetchService.fetch("get", url, params, undefined, additionalHeaders),

  /**
   * @param url {string} - Request url
   * @param params {Object.<string, *>} - QS params
   * @param data {*} - Post body serialized as JSON
   * @param [additionalHeaders] {Object.<string, string>} - Additional HTTP Headers
   * @returns {Promise<Response>} - Response promise
   * @deprecated Use fetchService#fetch instead
   */
  post: (url, params, data, additionalHeaders) => fetchService.fetch("post", url, params, data, additionalHeaders),

  /**
   *
   * @param httpMethod {"get" | "post" | "put" | "patch" | "delete"}
   * @param url {string} - Request url
   * @param params {Object.<string, *>} - QS params
   * @param data {*} - Post body serialized as JSON
   * @param [additionalHeaders] {Object.<string, string>} - Additional HTTP Headers
   * @returns {Promise<Response>} - Response promise
   */
  fetch: (httpMethod, url, params, data, additionalHeaders = {}) => {
    const token = localStorage.getItem("token");
    const traceId = fetchService.__getTraceId();
    return fetch(`${url}?${qs.stringify(params, qsOptions)}`, {
      method: httpMethod.toUpperCase(),
      body:
        data && httpMethod.toUpperCase() !== "GET"
          ? data instanceof FormData
            ? data
            : JSON.stringify(data)
          : undefined,
      mode: "cors",
      credentials: "include",
      cache: "no-cache",
      headers: {
        ...(!(data instanceof FormData) && { ...fetchService.__defaultHeaders }),
        [fetchService.__traceIdHeader]: traceId,
        [fetchService.__spanIdHeader]: traceId,
        Authorization: token ? `Bearer ${token}` : undefined,
        ...additionalHeaders,
      },
    }).then(response => fetchService.__interceptResponse(url, params, traceId, response));
  },
};
export default fetchService;
