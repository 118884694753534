import { timerSetupSaga } from "./notifications/saga";
import acceptJobSaga from "./job/acceptJob/saga";
import activeUsersSaga from "./activeUsers/saga";
import applyNoticesSaga from "./model/applyAllNotices/saga";
import assignModelsSaga from "./model/assignModels/saga";
import authCheckSaga from "./auth/check/saga";
import cancelJobSaga from "./job/cancelJob/saga";
import changePasswordSaga from "./auth/changePassword/saga";
import checkAutoAssignSaga from "./checkAutoAssign/saga";
import confirmOrderConfirmation from "./job/confirmOrderConfirmation/saga";
import customReferenceFetchSaga from "./customReferenceFetch/saga";
import declineJobModelSaga from "./job/declineJobModel/saga";
import downloadProductionPortfolio from "./job/downloadProductionPortfolio/saga";
import downloadSaga from "./download/saga";
import entityUpdatesSaga from "./entityUpdates/saga";
import exportReportSaga from "./errorReport/saga";
import fetchFilesSaga from "./storageService/fetchFiles/saga";
import fetchFileVersionsSaga from "./storageService/fetchFileVersions/saga";
import fileVersionsOfFileSaga from "./storageService/fetchFileVersionsOfFile/saga";
import fixedModelDataSaga from "./model/fixedModelData/saga";
import fixReferenceSaga from "./fetch/fixReferenceSaga";
import initWebsocketSaga from "./websocket/saga";
import intransportJobSaga from "./job/intransportJob/saga";
import jobBulkOperationSaga from "./job/bulkOperation/saga";
import modelActionsSaga from "./model/modelActions/saga";
import noteToContractorSaga from "./model/noteToContractor/saga";
import pingSaga from "./ping/saga";
import pushNotificationsSaga from "./pushNotifications/saga";
import receivedJobSaga from "./job/receivedJob/saga";
import reestimatePricesSaga from "./model/reestimatePrices/saga";
import reestimatePricesStatusSaga from "./model/reestimatePricesStatus/saga";
import restartNotificationSaga from "./notifications/restart/saga";
import restartSaga from "./restart/saga";
import sendJobOfferSaga from "./job/sendJobOffer/saga";
import shareFilterGraphSaga from "./filterGraph/shareFilterGraph/saga";
import stsPingSaga from "./storageService/ping/saga";
import subtasksSaga from "./subtasks/saga";
import syncModelsSaga from "./model/syncModels/saga";
import unassignModelSaga from "./model/unassignModel/saga";
import uncacheSage from "./cache/sideEffects/saga";
import uploadDocumentSaga from "./uploadDocument/saga";
import uploadFileSaga from "./storageService/uploadFile/saga";
import wallthicknessSuiteSaga from "./wallthicknessSuite/saga";
import assignWarehouseSaga from "./assignWarehouseCompartment/saga";
import checkPrintJobTimesSaga from "./printJob/saga";

export default [
  acceptJobSaga,
  activeUsersSaga,
  applyNoticesSaga,
  assignModelsSaga,
  authCheckSaga,
  cancelJobSaga,
  changePasswordSaga,
  checkAutoAssignSaga,
  confirmOrderConfirmation,
  customReferenceFetchSaga,
  declineJobModelSaga,
  downloadProductionPortfolio,
  downloadSaga,
  entityUpdatesSaga,
  exportReportSaga,
  fetchFilesSaga,
  fetchFileVersionsSaga,
  fileVersionsOfFileSaga,
  fixedModelDataSaga,
  fixReferenceSaga,
  initWebsocketSaga,
  intransportJobSaga,
  jobBulkOperationSaga,
  modelActionsSaga,
  noteToContractorSaga,
  pingSaga,
  pushNotificationsSaga,
  receivedJobSaga,
  reestimatePricesSaga,
  reestimatePricesStatusSaga,
  restartNotificationSaga,
  restartSaga,
  sendJobOfferSaga,
  shareFilterGraphSaga,
  stsPingSaga,
  subtasksSaga,
  syncModelsSaga,
  timerSetupSaga,
  unassignModelSaga,
  uncacheSage,
  uploadDocumentSaga,
  uploadFileSaga,
  wallthicknessSuiteSaga,
  assignWarehouseSaga,
  checkPrintJobTimesSaga,
];
