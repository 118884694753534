import { createMuiTheme } from "@material-ui/core/styles";
import roBlue from "./colors/roBlue";

export const theme = createMuiTheme({
  palette: {
    type: "light",
    primary: {
      main: roBlue["500"],
    },
    secondary: {
      main: roBlue["500"],
    },
    contrastThreshold: 3,
  },
  sidebar: {
    width: 240,
  },
  overrides: {
    RaEdit: {
      noActions: {
        marginTop: "0 !important",
      },
    },
    RaCreate: {
      noActions: {
        marginTop: "0 !important",
      },
    },
    RaShow: {
      noActions: {
        marginTop: "0 !important",
      },
    },
    RaPaginationActions: {
      currentPageButton: {
        fontWeight: 800,
        fontSize: "1.1em",
        color: roBlue["500"],
        textDecoration: "underline",
      },
    },
    MuiTableCell: {
      sizeSmall: {
        // the original has a massive 24px padding
        padding: "6px 6px 6px 16px",
      },
    },
  },
});
export default theme;
