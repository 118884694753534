import React from "react";
import PropTypes from "prop-types";
import { ShowController } from "react-admin";

const ShowView = ({
  actions,
  aside,
  children,
  classes,
  className,
  defaultTitle,
  hasEdit,
  hasList,
  isLoading,
  record,
  resource,
  title,
  version,
  ...rest
}) =>
  !!record
    ? React.cloneElement(children, {
        resource,
        record,
        version,
      })
    : null;

const PlainShow = props => (
  <ShowController {...props}>{controllerProps => <ShowView {...props} {...controllerProps} />}</ShowController>
);

PlainShow.propTypes = {
  actions: PropTypes.element,
  aside: PropTypes.node,
  children: PropTypes.element,
  classes: PropTypes.object,
  className: PropTypes.string,
  hasCreate: PropTypes.bool,
  hasEdit: PropTypes.bool,
  hasList: PropTypes.bool,
  hasShow: PropTypes.bool,
  id: PropTypes.any.isRequired,
  resource: PropTypes.string.isRequired,
  title: PropTypes.any,
};

export default PlainShow;
