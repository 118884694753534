import createAction from "../../lib/createAction";

export const FETCH_PING = "FETCH_PING";
export const fetchPing = createAction(FETCH_PING);

export const FETCH_PING_SUCCESS = "FETCH_PING_SUCCESS";
export const fetchPingSuccess = createAction(FETCH_PING_SUCCESS, "response");

export const FETCH_PING_ERROR = "FETCH_PING_ERROR";
export const fetchPingError = createAction(FETCH_PING_ERROR, "error");
