export const PrintJobStatus = {
  NEW: { key: "NEW", color: "#ffb133", translation: "printJobStatus.NEW" },
  PACKED: { key: "PACKED", color: "#66d6ff", translation: "printJobStatus.PACKED" },
  LOADED: { key: "LOADED", color: "#66d6ff", translation: "printJobStatus.LOADED" },
  PRINTING: { key: "PRINTING", color: "rgba(158, 158, 158, 0.158)", translation: "printJobStatus.PRINTING" },
  CANCELED: { key: "CANCELED", color: "#f44336", translation: "printJobStatus.CANCELED" },
  COOLING: { key: "COOLING", color: "#66d6ff", translation: "printJobStatus.COOLING" },
  UNPACKED: { key: "UNPACKED", color: "#9CCC65", translation: "printJobStatus.UNPACKED" },
  CLEANED: { key: "CLEANED", color: "#9CCC65", translation: "printJobStatus.CLEANED" },
  SORTED: { key: "SORTED", color: "#9CCC65", translation: "printJobStatus.SORTED" },
};
