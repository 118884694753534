export const TransactionStatus = {
  ASSIGN: {
    key: "ASSIGN",
    translationKey: "transactionStatus.ASSIGN",
  },
  ORDER: {
    key: "ORDER",
    translationKey: "transactionStatus.ORDER",
  },
  DECLINE: {
    key: "DECLINE",
    translationKey: "transactionStatus.DECLINE",
  },
  FIX_DATA: {
    key: "FIX_DATA",
    translationKey: "transactionStatus.FIX_DATA",
  },
};

export default TransactionStatus;
