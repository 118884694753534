import createReducer, { mergeReducers } from "../../../lib/createReducer";
import { APPLY_ALL_NOTICES_ERROR, APPLY_ALL_NOTICES_REQUEST, APPLY_ALL_NOTICES_SUCCESS } from "./action";

const init = {
  models: [],

  isFetching: false,
  fetchSuccess: false,
  hasError: false,
  payload: null,
  error: null,
};

const requestReducer = createReducer(
  APPLY_ALL_NOTICES_REQUEST,
  ({ models }) => ({
    ...init,
    models,
    isFetching: true,
  }),
  true
);

const successReducer = createReducer(APPLY_ALL_NOTICES_SUCCESS, ({ response }) => ({
  isFetching: false,
  fetchSuccess: true,
  payload: response,
}));

const errorReducer = createReducer(APPLY_ALL_NOTICES_ERROR, ({ error }) => ({
  isFetching: false,
  hasError: true,
  error: error,
}));

export default mergeReducers([requestReducer, successReducer, errorReducer], init);
