import get from "lodash/get";
import pickBy from "lodash/pickBy";
import includes from "lodash/includes";

// selectors for custom subtasks
export const getAllSubtasks = state => get(state, "subtasks.subtasks.data");
export const isAllSubtasksFetching = state => get(state, "subtasks.subtasks.isFetching", false);
export const isSubtaskFetching = subtaskId => state =>
  get(state, `subtasks.subtasks.data.${subtaskId}.isFetching`, false);
export const getSubtasksError = state => get(state, "subtasks.subtasks.error");
export const getSubtasksTimeFetched = state => get(state, "subtasks.subtasks.timeFetched");
export const isSubtaskUpdating = subtaskId => state =>
  get(state, `subtasks.subtasks.data.${subtaskId}.isUpdating`, false);
export const wasSubtaskUpdateSuccessful = subtaskId => state =>
  get(state, `subtasks.subtasks.data.${subtaskId}.updateSuccessful`, false);
export const isCreatingSubtask = state => get(state, "subtasks.subtasks.isCreating", false);
export const wasSubtaskCreationSuccessful = state => get(state, "subtasks.subtasks.creationSuccess", false);

export const getModelSubtaskIds = modelId => state => get(state, `subtasks.models.${modelId}`, []);
export const getModelSubtasks = modelId => state =>
  pickBy(getAllSubtasks(state), (value, key) => includes(getModelSubtaskIds(modelId)(state), key));
export const getModelSubtasksArray = modelId => state => Object.values(getModelSubtasks(modelId)(state));
