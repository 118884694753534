import createAction, { createActionWithMeta } from "../../../lib/createAction";

export const FIXED_MODEL_DATA_REQUEST = "FIXED_MODEL_DATA_REQUEST";
export const fixedModelData = createAction(FIXED_MODEL_DATA_REQUEST, "model", "reason", "message");

export const FIXED_MODEL_DATA_SUCCESS = "FIXED_MODEL_DATA_SUCCESS";
export const fixedModelDataSuccess = createActionWithMeta(
  FIXED_MODEL_DATA_SUCCESS,
  { uncache: true, refresh: true },
  "response"
);

export const FIXED_MODEL_DATA_ERROR = "FIXED_MODEL_DATA_ERROR";
export const fixedModelDataError = createActionWithMeta(
  FIXED_MODEL_DATA_ERROR,
  { uncache: true, refresh: true },
  "error"
);
