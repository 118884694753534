import { call, put, takeLatest } from "redux-saga/effects";
import get from "lodash/get";

import config from "../../config/index";
import fetchService from "../../lib/fetchService";
import {
  CUSTOM_REF_FETCH_REQUEST,
  CUSTOM_SELECTED_REF_FETCH_REQUEST,
  customRefFetchFailure,
  customRefFetchSuccess,
  customSelectedRefFetchFailure,
  customSelectedRefFetchSuccess,
} from "./action";

/**
 * Saga for fetching all references for our custom referenceArrayInput
 */
// TODO: ADD SORT TO PARAMETERS!
const customReferenceFetchSaga = function*({ payload }) {
  const { occurrence, reference, filter } = payload;

  const url = `${config.urls.BACKEND}/v2/${reference}`;

  const parameters = { ...filter };

  try {
    const response = yield call(fetchService.get, url, parameters);

    if (response.ok) {
      const responseData = yield call(() => response.json());
      const extractedContent = get(responseData, "_embedded");
      yield put(customRefFetchSuccess(occurrence, extractedContent));
    } else {
      yield put(customRefFetchFailure(occurrence, response));
    }
  } catch (e) {
    yield put(customRefFetchFailure(occurrence, e));
  }
};

/**
 * Saga for fetching all already selected references for our custom referenceArrayInput
 * (this will only be called if our CustomRefArrayInput is provided with a record and a source)
 *
 * We only store the ids of the selected items!
 */
// TODO: ADD SORT TO PARAMETERS!
const customSelectedReferenceFetchSaga = function*({ payload }) {
  const { occurrence, reference, filter } = payload;

  const url = `${config.urls.BACKEND}/v2/${reference}`;

  const parameters = { ...filter };

  try {
    const response = yield call(fetchService.get, url, parameters);

    if (response.ok) {
      const responseData = yield call(() => response.json());
      const extractedResponse = get(responseData, `_embedded`);
      const extractedContent = get(extractedResponse, Object.keys(extractedResponse)[0]);
      const ids = extractedContent.map(({ id }) => id);
      yield put(customSelectedRefFetchSuccess(occurrence, ids));
    } else {
      yield put(customSelectedRefFetchFailure(occurrence, response));
    }
  } catch (e) {
    yield put(customSelectedRefFetchFailure(occurrence, e));
  }
};

export default function*() {
  yield takeLatest(CUSTOM_REF_FETCH_REQUEST, customReferenceFetchSaga);
  yield takeLatest(CUSTOM_SELECTED_REF_FETCH_REQUEST, customSelectedReferenceFetchSaga);
}
