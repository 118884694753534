export default {
  MODEL_PRICE_FORMULA: {
    key: "MODEL_PRICE_FORMULA",
    translation: "formulaType.MODEL_PRICE_FORMULA",
  },
  AUTO_ASSIGN_FORMULA: {
    key: "AUTO_ASSIGN_FORMULA",
    translation: "formulaType.AUTO_ASSIGN_FORMULA",
  },
};
