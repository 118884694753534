import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { Button, showNotification, translate } from "react-admin";
import get from "lodash/get";
import ModelFilesDialog from "./ModelFilesDialog";
import { fetchFileVersionsOfFile } from "../../redux/storageService/fetchFileVersionsOfFile/action";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  getByUuidWithUuidAsId,
  hasUuid as hasFileUuid,
  isFetching as isFetchingFile,
} from "../../redux/storageService/fetchFileVersionsOfFile/selector";
import sanitizeButton from "../sanitizeButton";
import Icon from "@material-ui/icons/Healing";

const styles = theme => ({
  button: {},
});

class ModelFilesDialogButton extends Component {
  state = {
    isOpen: false,
    loaded: false,
  };

  handleClick = (...args) => {
    if (!this.state.isOpen) {
      this.setState({ isOpen: true }, () => this.fetchData());
    }

    if (typeof this.props.onClick === "function") this.props.onClick(...args);
  };

  handleDialogClose = () => {
    this.setState({ isOpen: false });
  };

  fetchData = ({ fetchFile, uuid, token, record, showNotification } = this.props) => {
    if (!uuid || !token) {
      showNotification("ra.notification.item_doesnt_exist", "warning");
      this.handleDialogClose();
      return;
    }

    fetchFile(uuid, token);
  };

  componentDidUpdate(prevProps, prevState) {
    const { loaded } = prevState;

    if (!loaded && this.props.fetchComplete) {
      this.setState({ loaded: true });
    }
  }

  render() {
    const {
      className,
      classes,
      label,
      addLabel,
      disabled,
      alignIcon,
      color,
      size,
      loaded,
      record,
      resource,
      uuidSource,
      tokenSource,
      isFetching,
      data,
      uuid,
      token,
      translate,
      errorLabel,
      activeFileHasError,
      showNotification,
      fetchFile,
      fetchComplete,
      children,
      basePath,
      ...props
    } = this.props;

    const { isOpen, loaded: selfLoadedOnce } = this.state;

    const isDisabled = disabled || isFetching || props.loaded === false || !uuid || !token;

    return (
      <Fragment>
        <Button
          {...sanitizeButton(props)}
          label={label}
          disabled={isDisabled}
          onClick={isDisabled ? undefined : this.handleClick}
          className={classes.button}
          alignIcon="left"
          color={isFetching ? "default" : color}
        >
          {isFetching ? <CircularProgress size={20} thickness={2} color="primary" /> : children}
        </Button>
        <ModelFilesDialog
          basePath={basePath}
          isOpen={isOpen && fetchComplete}
          loaded={selfLoadedOnce}
          ids={data && Object.keys(data)}
          data={data}
          record={record}
          resource={resource}
          fileUuid={uuid}
          fileToken={token}
          onClose={this.handleDialogClose}
          errorLabel={errorLabel}
          activeFileHasError={activeFileHasError}
        />
      </Fragment>
    );
  }
}

ModelFilesDialogButton.propTypes = {
  /** User Props **/
  uuidSource: PropTypes.string,
  tokenSource: PropTypes.string,
  label: PropTypes.string,
  addLabel: PropTypes.bool,
  disabled: PropTypes.bool,

  /** Internal Props **/
  loaded: PropTypes.bool,
  record: PropTypes.object,
  resource: PropTypes.string,
  basePath: PropTypes.string,
  isFetching: PropTypes.bool,
  data: PropTypes.object,
  uuid: PropTypes.string,
  token: PropTypes.string,
  translate: PropTypes.func,
  errorLabel: PropTypes.any,
  activeFileHasError: PropTypes.bool,
};

ModelFilesDialogButton.defaultProps = {
  children: <Icon />,
};

export default connect(
  (state, { uuidSource, tokenSource, record }) => {
    const uuid = get(record, uuidSource);
    const token = get(record, tokenSource);
    const isFetching = isFetchingFile(uuid)(state);
    return {
      uuid,
      token,
      isFetching,
      fetchComplete: !isFetching && hasFileUuid(uuid)(state),
      data: getByUuidWithUuidAsId(uuid)(state),
    };
  },
  {
    fetchFile: fetchFileVersionsOfFile,
    showNotification,
  }
)(withStyles(styles)(translate(ModelFilesDialogButton)));
