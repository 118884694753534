import React from "react";
import { translate } from "react-admin";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import get from "lodash/get";

const CustomDropdownField = ({
  record,
  dropdownOptionsObject,
  translate,
  source,
  idField,
  changeHandler,
  isLoading,
  inputLabel = "",
}) => {
  const value = get(record, source);
  return (
    <>
      <InputLabel>{inputLabel}</InputLabel>
      <Select
        disabled={isLoading}
        defaultValue={value}
        onChange={e => changeHandler(record, e.target.value, idField, source)}
      >
        {Object.keys(dropdownOptionsObject).map((singleStatus, index) => (
          <MenuItem value={dropdownOptionsObject[singleStatus].key} key={index}>
            {translate(dropdownOptionsObject[singleStatus].translation)}
          </MenuItem>
        ))}
      </Select>
    </>
  );
};

export default translate(CustomDropdownField);
