import React from "react";
import {
  translate,
  TextField,
  Datagrid,
  List,
  ReferenceField,
  FunctionField,
  Filter,
  ShowButton,
  NumberInput,
  TopToolbar,
  Pagination,
} from "react-admin";
import resources from "../../config/resources";
import DateField from "../customFields/DateField";
import { getKey } from "../../lib/i18nUtils";
import paginationOptions from "../../config/PaginationOptions";

const Filters = ({ translate, ...props }) => (
  <Filter {...props}>
    <NumberInput
      source="model#rapidshopId"
      label={translate(getKey("model", resources.MATERIALISE_EXCEPTIONS))}
      alwaysOn={true}
      fullWidth={true}
    />
    <NumberInput
      source="model#shipment#rapidshopId"
      label={translate(getKey("shipment", resources.MODELS))}
      alwaysOn={true}
      fullWidth={true}
    />
  </Filter>
);

const ListActions = () => <TopToolbar />;

const MaterialiseExceptionsList = ({ translate, ...props }) => (
  <List
    {...props}
    bulkActionButtons={false}
    sort={{ field: "timestamp", order: "DESC" }}
    filters={<Filters translate={translate} />}
    actions={<ListActions />}
    pagination={<Pagination {...paginationOptions} />}
  >
    <Datagrid>
      <DateField source="timestamp" displayFormat="DD. MMMM YYYY HH:mm:ss" />
      <FunctionField source="code" render={({ code }) => translate(`errorCodes.${code}`)} />
      <TextField source="message" />
      <ReferenceField source="model" reference={resources.MODELS} link={false} allowEmpty={true}>
        <TextField source="rapidshopId" />
      </ReferenceField>
      <ReferenceField source="model" label={getKey("shipment", resources.MODELS)} reference={resources.MODELS}>
        <ReferenceField source="shipment" reference={resources.SHIPMENTS} link={false} allowEmpty={true}>
          <FunctionField
            render={record =>
              record && <ShowButton record={record} basePath={`/${resources.SHIPMENTS}`} label={record.rapidshopId} />
            }
          />
        </ReferenceField>
      </ReferenceField>
    </Datagrid>
  </List>
);

export default translate(MaterialiseExceptionsList);
