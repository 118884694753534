import createAction from "../../lib/createAction";

export const ASSIGN_WAREHOUSE_COMPARTMENT_REQUEST = "ASSIGN_WAREHOUSE_COMPARTMENT_REQUEST";
export const assignWarehouseCompartmentRequest = createAction(
  ASSIGN_WAREHOUSE_COMPARTMENT_REQUEST,
  "compartment",
  "model",
  "shipment"
);

export const ASSIGN_WAREHOUSE_COMPARTMENT_SUCCESS = "ASSIGN_WAREHOUSE_COMPARTMENT_SUCCESS";
export const assignWarehouseCompartmentSuccess = createAction(ASSIGN_WAREHOUSE_COMPARTMENT_SUCCESS, "response");

export const ASSIGN_WAREHOUSE_COMPARTMENT_ERROR = "ASSIGN_WAREHOUSE_COMPARTMENT_ERROR";
export const assignWarehouseCompartmentError = createAction(ASSIGN_WAREHOUSE_COMPARTMENT_ERROR, "error");
