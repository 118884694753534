import createAction, { createActionWithMeta } from "../../../lib/createAction";

export const SHARE_FILTER_GRAPH_REQUEST = "ROH/SHARE_FILTER_GRAPH_REQUEST";
export const shareFilterGraphRequest = createAction(SHARE_FILTER_GRAPH_REQUEST, "id", "sharedWith", "authorities");

export const SHARE_FILTER_GRAPH_SUCCESS = "ROH/SHARE_FILTER_GRAPH_SUCCESS";
export const shareFilterGraphSuccess = createActionWithMeta(
  SHARE_FILTER_GRAPH_SUCCESS,
  {
    uncache: true,
    notification: {
      body: "ra.notification.updated",
      level: "info",
    },
    refresh: true,
  },
  "response"
);

export const SHARE_FILTER_GRAPH_ERROR = "ROH/SHARE_FILTER_GRAPH_ERROR";
export const shareFilterGraphError = createActionWithMeta(
  SHARE_FILTER_GRAPH_ERROR,
  (error = {}) => ({
    notification: {
      body: error.message || "ra.message.error",
      level: "warning",
    },
  }),
  "error"
);
