import React from "react";
import { translate } from "react-admin";
import PropTypes from "prop-types";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import CommenItem from "./CommenItem";
import Typography from "@material-ui/core/Typography";

const CommentsGrid = ({ ids, data, basePath, translate, permissions }) => (
  <div
    style={{
      width: "100%",
      maxHeight: 500,
      overflowY: "auto",
      padding: 8,
      display: "flex",
      flexDirection: "column-reverse",
    }}
  >
    {Array.isArray(ids) && ids.length > 0 ? (
      ids
        .sort((a, b) => b - a)
        .map(id => (
          <div key={id} style={{ width: "100%", marginTop: 16 }}>
            <CommenItem record={data[id]} basePath={basePath} permissions={permissions} />
          </div>
        ))
    ) : (
      <div>
        <Typography variant="body1">{translate("resources.comments.noComments")}</Typography>
      </div>
    )}
    <ListItem />
    <Divider />
  </div>
);

CommentsGrid.propTypes = {
  ids: PropTypes.arrayOf(PropTypes.number),
  data: PropTypes.object,
  basePath: PropTypes.string.isRequired,

  translate: PropTypes.func.isRequired,
};

export default translate(CommentsGrid);
