export const TOPICS = {
  //PING: "/ping",
  ACTIVE_USER: "/active_users",
  RESTARTING: "/restarting",
  RESOURCE_UPDATES: "/resource_updates",
  NOTIFICATIONS: "/notifications",
};
export default TOPICS;

export const USER_PREFIX = "/user/";

export const USER_TOPICS = [TOPICS.NOTIFICATIONS];
