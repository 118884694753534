import React from "react";
import PropTypes from "prop-types";
import shouldUpdate from "recompose/shouldUpdate";
import TableBody from "@material-ui/core/TableBody";
import classnames from "classnames";
import { DatagridBody } from "react-admin";
import forIn from "lodash/forIn";
import GroupingDatagridRow from "./GroupingDataGridRow";
import { isEmpty } from "lodash";

const GroupingDatagridBody = ({ groupBy, ...props }) => {
  if (!groupBy || (Array.isArray(groupBy) && groupBy.length === 0)) return <DatagridBody {...props} />;

  const groupByFields = Array.isArray(groupBy) ? groupBy : [groupBy];

  const {
    basePath,
    children,
    classes,
    className,
    data,
    expand,
    hasBulkActions,
    hover,
    ids,
    loading,
    onToggleItem,
    resource,
    rowClick,
    rowStyle,
    selectedIds,
    styles,
    version,
    multiplyRowSpan,
    customFilter,
    // remove from rest props to avoid warnings
    currentSort,
    defaultTitle,
    displayedFilters,
    filterValues,
    hasCreate,
    hideFilter,
    onUnselectItems,
    perPage,
    setFilters,
    setPage,
    setPerPage,
    setSort,
    showFilter,
    isRowSelectable,
    ...rest
  } = props;

  let groupMap = {};
  if (data) {
    ids.forEach(id => {
      const row = data[id];
      if (!row) return;

      if (customFilter && !isEmpty(customFilter)) {
        for (const [key, value] of Object.entries(customFilter)) {
          if (!row[key] === value) {
            return;
          }
        }
      }

      const field = groupByFields.map(it => row[it]);
      if (field === undefined) return;

      if (groupMap[field] === undefined) groupMap[field] = {};

      groupMap[field][id] = row;
    });
  }

  const rows = [];
  Object.entries(groupMap).forEach(([, group], groupIndex) => {
    const itemCount = Object.keys(group).length;
    const rowSpan = {};
    groupByFields.forEach(field => (rowSpan[field] = itemCount));
    let rowIndex = 0;

    forIn(group, (record, id) => {
      rows.push(
        <GroupingDatagridRow
          basePath={basePath}
          classes={classes}
          className={classnames(classes.row, {
            [classes.rowEven]: rowIndex % 2 === 0,
            [classes.rowOdd]: rowIndex % 2 !== 0,
            [classes.clickableRow]: rowClick,
          })}
          expand={expand}
          hasBulkActions={hasBulkActions}
          hover={hover}
          id={id}
          key={id}
          onToggleItem={onToggleItem}
          record={record}
          resource={resource}
          rowClick={rowClick}
          selected={Array.isArray(selectedIds) && selectedIds.includes(id)}
          style={rowStyle ? rowStyle(data[id], rowIndex) : null}
          hideField={rowIndex === 0 ? undefined : groupByFields}
          rowSpan={rowSpan}
          group={group}
          multiplyRowSpan={multiplyRowSpan}
          groupIndex={groupIndex}
        >
          {children}
        </GroupingDatagridRow>
      );
      rowIndex++;
    });
  });

  return (
    <TableBody className={classnames("datagrid-body", className)} {...rest}>
      {rows}
    </TableBody>
  );
};

GroupingDatagridBody.propTypes = {
  basePath: PropTypes.string,
  classes: PropTypes.object,
  className: PropTypes.string,
  children: PropTypes.node,
  data: PropTypes.object.isRequired,
  expand: PropTypes.node,
  hasBulkActions: PropTypes.bool.isRequired,
  hover: PropTypes.bool,
  ids: PropTypes.arrayOf(PropTypes.any).isRequired,
  loading: PropTypes.bool,
  onToggleItem: PropTypes.func,
  resource: PropTypes.string,
  row: PropTypes.element,
  rowClick: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  rowStyle: PropTypes.func,
  selectedIds: PropTypes.arrayOf(PropTypes.any).isRequired,
  styles: PropTypes.object,
  version: PropTypes.number,
  groupBy: PropTypes.arrayOf(PropTypes.string),
};

GroupingDatagridBody.defaultProps = {
  data: {},
  hasBulkActions: false,
  ids: [],
};

const areArraysEqual = (arr1, arr2) => arr1.length === arr2.length && arr1.every((v, i) => v === arr2[i]);

const PureGroupingDatagridBody = shouldUpdate(
  (props, nextProps) =>
    props.version !== nextProps.version ||
    nextProps.loading === false ||
    !areArraysEqual(props.ids, nextProps.ids) ||
    props.data !== nextProps.data ||
    props.customFilter !== nextProps.customFilter
)(GroupingDatagridBody);

// trick material-ui Table into thinking this is one of the child type it supports
PureGroupingDatagridBody.muiName = "TableBody";

export default PureGroupingDatagridBody;
