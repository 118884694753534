import React from "react";
import {
  Datagrid,
  FunctionField,
  List,
  Pagination,
  ReferenceManyField,
  TextField,
  useRefresh,
  useTranslate,
} from "react-admin";

import resources from "../../config/resources";
import CommentIcon from "@material-ui/icons/Chat";
import JobPanel from "./JobPanel";
import { getKey } from "../../lib/i18nUtils";
import SendOfferButton from "./SendOfferButton";
import RohStatus from "../../config/RohStatus";
import DateField from "../customFields/DateField";
import JobFilters from "./JobFilters";
import ReceivedJobButton from "./ReceivedJobButton";
import MultiStatusField from "../customFields/MultiStatusField";
import IntransportJobButton from "./IntransportJobButton";
import ExpansionDatagridBody from "../ExpansionDatagrid/ExpansionDatagridBody";
import paginationOptions from "../../config/PaginationOptions";
import CancelJobButton from "./CancelJobButton";
import AssignUserButton from "../customFields/buttons/AssignUserButton";
import CommentCountField from "../customFields/CommentCountField";
import PriceField from "../customFields/PriceField";
import PortfolioButton from "../documents/PortfolioButton";
import ReferenceManyMapper from "../ReferenceManyMapper";
import OrderConfirmationConfirmedButton from "./OrderConfirmationConfirmedButton";

const ActionField = ({ ...props }) => {
  const translate = useTranslate();
  const { record } = props;
  const refresh = useRefresh();

  const buttons = [];

  const onButtonClick = e => {
    e.stopPropagation();
    refresh();
  };

  if (record.status === RohStatus.NEW.apiValue) {
    buttons.push(
      <ReferenceManyField {...props} record={props.record} reference={resources.MODELS} target="job#id">
        <ReferenceManyMapper>
          {({ data }) => (
            <SendOfferButton {...props} variant="contained" color="primary" models={data} onConfirm={refresh} />
          )}
        </ReferenceManyMapper>
      </ReferenceManyField>
    );
  }

  if (record.status === RohStatus.NEEDS_UPDATE.apiValue) {
    buttons.push(
      <CancelJobButton
        {...props}
        onClick={onButtonClick}
        style={buttons.length > 0 ? { marginTop: "10px" } : {}}
        variant="contained"
        color="default"
        label={translate(getKey("cancel", resources.JOBS))}
      />
    );
  }

  if (record.status === RohStatus.IN_PRODUCTION.apiValue) {
    buttons.push(
      <IntransportJobButton
        {...props}
        onClick={onButtonClick}
        style={buttons.length > 0 ? { marginTop: "10px" } : {}}
        variant="contained"
        color="default"
        label={translate(getKey("setInTransport", resources.JOBS))}
      />
    );
  }

  if (record.status === RohStatus.IN_TRANSPORT.apiValue) {
    buttons.push(
      <ReceivedJobButton
        {...props}
        onClick={onButtonClick}
        style={buttons.length > 0 ? { marginTop: "10px" } : {}}
        variant="contained"
        color="default"
        label={translate(getKey("setReceived", resources.JOBS))}
      />
    );
  }

  if (record.checkOrderConfirmationOutstanding) {
    buttons.push(
      <OrderConfirmationConfirmedButton
        {...props}
        onClick={onButtonClick}
        style={buttons.length > 0 ? { marginTop: "10px" } : {}}
        variant="contained"
        color="default"
        label={translate(getKey("confirmOC", resources.JOBS))}
      />
    );
  }

  return buttons;
};

const JobsList = ({ permissions, ...props }) => {
  const translate = useTranslate();
  return (
    <List
      {...props}
      bulkActionButtons={false}
      actions={null}
      filters={<JobFilters permissions={permissions} />}
      filter={{
        active: "true",
        hasActiveModels: "true",
      }}
      pagination={<Pagination {...paginationOptions} />}
      sort={{ field: "id", order: "DESC" }}
    >
      <Datagrid expand={<JobPanel permissions={permissions} />} rowClick="expand" body={<ExpansionDatagridBody />}>
        <TextField source="externalId" sortBy="id" />
        <TextField
          source="contractorName"
          sortBy="contractor.contractorName"
          label={translate(getKey("contractor", resources.JOBS))}
        />
        <MultiStatusField source="status" useChip={true} />
        <CommentCountField source="commentsCount" label={<CommentIcon fontSize="small" />} />
        <TextField source="modelAmount" label={translate(getKey("models", resources.JOBS))} textAlign="right" />
        <PriceField label={translate(getKey("priceValue", resources.JOBS))} textAlign="right" source="price" />
        <DateField source="dateEstimate" textAlign="right" />
        <FunctionField
          render={props =>
            permissions && permissions.authorities.includes("ROLE_SALES") && <PortfolioButton {...props} />
          }
        />
        <AssignUserButton size={30} permissions={permissions} source="assignee" textAlign="right" />
        <ActionField label={translate(getKey("action", resources.JOBS))} textAlign="right" translate={translate} />
      </Datagrid>
    </List>
  );
};

export default JobsList;
