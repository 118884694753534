import get from "lodash/get";

/**
 * List of all available resources (entity types)
 *
 * When a data provider tries to fetch a resource, it'll use the value property in the URL.
 *
 * Example:
 *   FOO: "bar"
 *   dataProvider will fetch from "[BASEURL]/bar"
 *
 * @type {Object.<string, string>}
 */
export const resources = {
  ADDITIONS: "additions",
  ADDRESSES: "addresses",
  ARCHIVED_AUTO_ASSIGN_LOGS: "archiveAutoAssignJobLogs",
  ARCHIVED_COMMENTS: "archiveComments",
  ARCHIVED_DOCUMENTS: "archiveDocuments",
  ARCHIVED_JOBS: "archiveJobs",
  ARCHIVED_MATERIALISE_EXCEPTIONS: "archiveMaterialiseExceptions",
  ARCHIVED_MODELS: "archiveModels",
  ARCHIVED_NOTIFICATIONS: "archiveNotifications",
  ARCHIVED_ORDERS: "archiveOrders",
  ARCHIVED_PRICE_ESTIMATES: "archivePriceEstimates",
  ARCHIVED_PRINT_JOBS: "archivePrintJobs",
  ARCHIVED_PRINT_JOB_MODELS: "archivePrintJobModels",
  ARCHIVED_SHIPMENTS: "archiveShipments",
  ARCHIVE_SUBTASKS: "archiveSubtasks",
  ART_JOBS: "art/jobs",
  ASSIGNED_USERS: "assignedUsers",
  AUTO_ASSIGN_LOGS: "autoAssignJobLogs",
  BUILD_UNITS: "buildUnits",
  COMMENTS: "comments",
  CONTRACTORS: "contractors",
  CUSTOMERS: "customers",
  DOCUMENT_TYPES: "documentTypes",
  DOCUMENTS: "documents",
  FILE_VERSIONS: "fileVersions",
  FILES: "files",
  FILTER_GRAPHS: "filterGraphs",
  FILTER_PARTS: "filterParts",
  FORMULAS: "formulas",
  JOBS: "jobs",
  MATERIALISE_EXCEPTIONS: "materialiseExceptions",
  MATERIALISE_MATERIAL_MAPPING: "materialiseMaterialMappings",
  MATERIALISE_TECHNOLOGIES: "materialiseapi/technologies",
  MATERIALS: "materials",
  MODEL_PARTS_DEFECTS: "modelPartsDefects",
  MODEL_STATUS_UPDATES: "modelStatusUpdates",
  MODELS: "models",
  NOTIFICATION_CONFIGS: "notificationConfigurations",
  NOTIFICATIONS: "notifications",
  ORDERS: "orders",
  PRICE_ESTIMATES: "priceEstimates",
  PRINT_JOB_MODELS: "printJobModels",
  PRINT_JOBS: "printJobs",
  PRINTERS: "printers",
  PROPERTIES: "properties",
  SHIPMENTS: "shipments",
  SUBTASKS: "subtasks",
  SUPPORTED_MATERIALS: "supportedMaterials",
  TEMPLATES: "templates",
  USER_DETAILS: "userDetails",
  USERS: "users",
  WAREHOUSE_LOCATIONS: "warehouseLocations",
  PRINT_PREPARATIONS: "printPreparations",
  WAREHOUSE_COMPARTMENTS: "warehouseCompartments",
  POSTPROCESSING_JOB: "postProcessingJobs",
};
export default resources;

/**
 * List of all available data sources
 *
 * @type {Object.<string, string>}
 */
export const sources = {
  BACKEND: "BACKEND",
  BACKEND_OLD: "BACKEND_OLD",
};

/**
 * Maps each resource to a specific data provider.
 *
 * key: string - Reference to resources.js/resources
 * value: string - Reference to dataProvider.js/dataSources
 *
 * @type {Object.<string, string>}
 */
export const dataSource = {
  [resources.ADDITIONS]: sources.BACKEND,
  [resources.ADDRESSES]: sources.BACKEND,
  [resources.ARCHIVE_SUBTASKS]: sources.BACKEND,
  [resources.ARCHIVED_AUTO_ASSIGN_LOGS]: sources.BACKEND,
  [resources.ARCHIVED_COMMENTS]: sources.BACKEND,
  [resources.ARCHIVED_DOCUMENTS]: sources.BACKEND,
  [resources.ARCHIVED_JOBS]: sources.BACKEND,
  [resources.ARCHIVED_MATERIALISE_EXCEPTIONS]: sources.BACKEND,
  [resources.ARCHIVED_MODELS]: sources.BACKEND,
  [resources.ARCHIVED_NOTIFICATIONS]: sources.BACKEND,
  [resources.ARCHIVED_ORDERS]: sources.BACKEND,
  [resources.ARCHIVED_PRICE_ESTIMATES]: sources.BACKEND,
  [resources.ARCHIVED_PRINT_JOBS]: sources.BACKEND,
  [resources.ARCHIVED_PRINT_JOB_MODELS]: sources.BACKEND,
  [resources.ARCHIVED_SHIPMENTS]: sources.BACKEND,
  [resources.ART_JOBS]: sources.BACKEND,
  [resources.ASSIGNED_USERS]: sources.BACKEND,
  [resources.AUTO_ASSIGN_LOGS]: sources.BACKEND,
  [resources.BUILD_UNITS]: sources.BACKEND,
  [resources.COMMENTS]: sources.BACKEND,
  [resources.CONTRACTORS]: sources.BACKEND,
  [resources.CUSTOMERS]: sources.BACKEND,
  [resources.DOCUMENT_TYPES]: sources.BACKEND,
  [resources.DOCUMENTS]: sources.BACKEND,
  [resources.FILTER_GRAPHS]: sources.BACKEND,
  [resources.FILTER_PARTS]: sources.BACKEND,
  [resources.FORMULAS]: sources.BACKEND,
  [resources.JOBS]: sources.BACKEND,
  [resources.MATERIALISE_EXCEPTIONS]: sources.BACKEND,
  [resources.MATERIALISE_MATERIAL_MAPPING]: sources.BACKEND,
  [resources.MATERIALISE_TECHNOLOGIES]: sources.BACKEND_OLD,
  [resources.MATERIALS]: sources.BACKEND,
  [resources.MODEL_PARTS_DEFECTS]: sources.BACKEND,
  [resources.MODEL_STATUS_UPDATES]: sources.BACKEND,
  [resources.MODELS]: sources.BACKEND,
  [resources.NOTIFICATION_CONFIGS]: sources.BACKEND,
  [resources.NOTIFICATIONS]: sources.BACKEND,
  [resources.ORDERS]: sources.BACKEND,
  [resources.PRICE_ESTIMATES]: sources.BACKEND,
  [resources.PRINT_JOB_MODELS]: sources.BACKEND,
  [resources.PRINT_JOBS]: sources.BACKEND,
  [resources.PRINTERS]: sources.BACKEND,
  [resources.PRINT_PREPARATIONS]: sources.BACKEND,
  [resources.PROPERTIES]: sources.BACKEND,
  [resources.SHIPMENTS]: sources.BACKEND,
  [resources.SUBTASKS]: sources.BACKEND,
  [resources.SUPPORTED_MATERIALS]: sources.BACKEND,
  [resources.TEMPLATES]: sources.BACKEND,
  [resources.USER_DETAILS]: sources.BACKEND,
  [resources.USERS]: sources.BACKEND,
  [resources.WAREHOUSE_LOCATIONS]: sources.BACKEND,
  [resources.WAREHOUSE_COMPARTMENTS]: sources.BACKEND,
  [resources.POSTPROCESSING_JOB]: sources.BACKEND,
};

const LIFETIME = {
  SHORT: 10,
  MID: 60,
  LONG: 3600,
};
/**
 * Cache lifetime per resource in seconds.
 * @type {Object.<string, number>}
 */
export const cacheLifetime = {
  __DEFAULT: 0,
  [resources.USERS]: LIFETIME.LONG,
  [resources.MATERIALS]: LIFETIME.MID,
  [resources.CONTRACTORS]: LIFETIME.MID,
  [resources.SUPPORTED_MATERIALS]: LIFETIME.MID,
  [resources.FORMULAS]: LIFETIME.MID,
  [resources.TEMPLATES]: LIFETIME.MID,
  [resources.MATERIALISE_MATERIAL_MAPPING]: LIFETIME.MID,
  [resources.MATERIALISE_TECHNOLOGIES]: LIFETIME.LONG,
  [resources.PROPERTIES]: LIFETIME.MID,
  [resources.ADDITIONS]: LIFETIME.MID,
  [resources.USER_DETAILS]: LIFETIME.LONG,
};

export const defaultSort = {
  __DEFAULT: { field: "id", order: "desc" },
  [resources.COMMENTS]: { field: "createdAt", order: "asc" },
};

export const getCacheValidUntil = resource => {
  const ms = get(cacheLifetime, resource, cacheLifetime.__DEFAULT) * 1000;
  if (ms === 0) {
    return undefined;
  }
  return new Date(Date.now() + ms);
};
