import WarehouseCompartmentSelectDialog from "./WarehouseCompartmentSelectDialog";
import React, { useState } from "react";
import { Button, useRefresh, useTranslate } from "react-admin";
import WarehouseLocationIcon from "@material-ui/icons/BorderOuter";

const AssignShipmentWarehouseCompartmentButton = ({ record }) => {
  const translate = useTranslate();
  const refresh = useRefresh();

  const [dialogOpen, setDialogOpen] = useState(false);

  const handleOpen = () => setDialogOpen(true);
  const handleClose = () => {
    setDialogOpen(false);
    refresh();
  };

  return (
    <>
      <Button
        variant="contained"
        color="default"
        label={translate("changeCompartment")}
        onClick={handleOpen}
        style={{ marginLeft: "10px" }}
      >
        <WarehouseLocationIcon />
      </Button>
      <WarehouseCompartmentSelectDialog
        shipment={record}
        open={dialogOpen}
        onClose={handleClose}
        allowNonEmpty={true}
      />
    </>
  );
};

export default AssignShipmentWarehouseCompartmentButton;
