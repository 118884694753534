import acceptJobReducer from "./job/acceptJob/reducer";
import activeUsersReducer from "./activeUsers/reducer";
import applyAllNoticesReducer from "./model/applyAllNotices/reducer";
import assignModelsReducer from "./model/assignModels/reducer";
import cancelJobReducer from "./job/cancelJob/reducer";
import changePasswordReducer from "./auth/changePassword/reducer";
import checkAutoAssignReducer from "./checkAutoAssign/reducer";
import confirmOrderConfirmationReducer from "./job/confirmOrderConfirmation/reducer";
import customReferenceFetchReducer from "./customReferenceFetch/reducer";
import declineJobModelReducer from "./job/declineJobModel/reducer";
import downloadProductionPortfolioReducer from "./job/downloadProductionPortfolio/reducer";
import downloadReducer from "./download/reducer";
import expandReducer from "./expand/reducer";
import exportReportReducer from "./errorReport/reducer";
import fetchFilesReducer from "./storageService/fetchFiles/reducer";
import fetchFileVersionsReducer from "./storageService/fetchFileVersions/reducer";
import fileVersionsOfFileReducer from "./storageService/fetchFileVersionsOfFile/reducer";
import fixedModelDataReducer from "./model/fixedModelData/reducer";
import handleFormReducer from "./handleForms/reducer";
import intransportJobReducer from "./job/intransportJob/reducer";
import jobBulkOperationReducer from "./job/bulkOperation/reducer";
import modelActionReducer from "./model/modelActions/reducer";
import noteToContractorReducer from "./model/noteToContractor/reducer";
import notificationTimerReducer from "./notifications/reducer";
import pingReducer from "./ping/reducer";
import receivedJobReducer from "./job/receivedJob/reducer";
import reestimatePricesReducer from "./model/reestimatePrices/reducer";
import reestimatePricesStatusReducer from "./model/reestimatePricesStatus/reducer";
import restartNotificationReducer from "./notifications/restart/reducer";
import restartReducer from "./restart/reducer";
import sendJobOfferReducer from "./job/sendJobOffer/reducer";
import shareFilterGraphReducer from "./filterGraph/shareFilterGraph/reducer";
import stsPingReducer from "./storageService/ping/reducer";
import subtasksReducer from "./subtasks/reducer";
import syncModelsReducer from "./model/syncModels/reducer";
import unassignModelReducer from "./model/unassignModel/reducer";
import uploadDocumentReducer from "./uploadDocument/reducer";
import uploadFileReducer from "./storageService/uploadFile/reducer";
import wallthicknessSuiteReducer from "./wallthicknessSuite/reducer";

import assignWarehouseCompartmentReducer from "./assignWarehouseCompartment/reducer";
import checkPrintJobTimesReducer from "./printJob/reducer";

export default {
  acceptJob: acceptJobReducer,
  activeUsers: activeUsersReducer,
  applyAllNotices: applyAllNoticesReducer,
  assignModels: assignModelsReducer,
  cancelJob: cancelJobReducer,
  changePassword: changePasswordReducer,
  checkAutoAssign: checkAutoAssignReducer,
  confirmOrderConfirmation: confirmOrderConfirmationReducer,
  customReferenceFetch: customReferenceFetchReducer,
  declineJobModel: declineJobModelReducer,
  download: downloadReducer,
  downloadProductionPortfolio: downloadProductionPortfolioReducer,
  expand: expandReducer,
  exportReport: exportReportReducer,
  files: fetchFilesReducer,
  fileVersions: fetchFileVersionsReducer,
  fileVersionsOfFile: fileVersionsOfFileReducer,
  fixedModelData: fixedModelDataReducer,
  handleForms: handleFormReducer,
  intransportJob: intransportJobReducer,
  jobBulkOperation: jobBulkOperationReducer,
  modelActions: modelActionReducer,
  noteToContractor: noteToContractorReducer,
  notificationTimer: notificationTimerReducer,
  ping: pingReducer,
  receivedJob: receivedJobReducer,
  reestimatePrices: reestimatePricesReducer,
  reestimatePricesStatus: reestimatePricesStatusReducer,
  restart: restartReducer,
  restartNotification: restartNotificationReducer,
  sendJobOffer: sendJobOfferReducer,
  shareFilterGraph: shareFilterGraphReducer,
  stsPing: stsPingReducer,
  subtasks: subtasksReducer,
  syncModels: syncModelsReducer,
  unassignModel: unassignModelReducer,
  uploadDocument: uploadDocumentReducer,
  uploadFile: uploadFileReducer,
  wallthicknessSuite: wallthicknessSuiteReducer,
  assignWarehouseCompartment: assignWarehouseCompartmentReducer,
  checkPrintJobTimes: checkPrintJobTimesReducer,
};
