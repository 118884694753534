import React from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
import moment from "moment";
import Typography from "@material-ui/core/Typography";
import { translate } from "react-admin";

const sanitizeRestProps = ({
  addLabel,
  allowEmpty,
  basePath,
  cellClassName,
  className,
  formClassName,
  headerClassName,
  label,
  link,
  locale,
  record,
  resource,
  sortable,
  sortBy,
  source,
  textAlign,
  translateChoice,
  ...rest
}) => rest;

const DateField = ({
  translate,
  className,
  classes = {},
  source,
  record = {},
  useChip = false,
  sourceFormat,
  displayFormat,
  staticValue,
  ...rest
}) => {
  const value = staticValue || get(record, source);
  let time;
  if (!value) {
    time = null;
  } else if (typeof value === "object") {
    const { second, minute, hour, dayOfMonth, monthValue, year } = value;
    time = moment([year, monthValue - 1, dayOfMonth, hour, minute, second]);
  } else {
    time = moment(value, sourceFormat);
  }

  return (
    <Typography component="span" variant="body2" className={className} {...sanitizeRestProps(rest)}>
      {!time ? translate("format.date.isNull") : time.format(displayFormat)}
    </Typography>
  );
};

DateField.propTypes = {
  translate: PropTypes.func,
  addLabel: PropTypes.bool,
  basePath: PropTypes.string,
  className: PropTypes.string,
  cellClassName: PropTypes.string,
  headerClassName: PropTypes.string,
  label: PropTypes.string,
  record: PropTypes.object,
  sortBy: PropTypes.string,
  source: PropTypes.string,
  sourceFormat: PropTypes.string,
  displayFormat: PropTypes.string,
  staticValue: PropTypes.string,
};

DateField.displayName = "DateField";

DateField.defaultProps = {
  addLabel: true,
  displayFormat: "DD. MMMM YYYY",
};

export default translate(DateField);
