import de from "../i18n/de";
import polyglotI18nProvider from "ra-i18n-polyglot";
import moment from "moment";
import * as momDe from "moment/locale/de";

export const defaultLocale = "de";

const translation = {
  de: de,
};

export const translationProvider = polyglotI18nProvider(locale => {
  let loadedLocale = null;
  if (defaultLocale === "de") {
    loadedLocale = moment.updateLocale("de", momDe);
  }
  if (loadedLocale && loadedLocale._abbr !== defaultLocale) {
    console.warn(`Couldn't load specified locale "${loadedLocale}". Current locale is: "${loadedLocale}".`);
  }

  return translation[defaultLocale || locale];
});

export default translationProvider;
