import createAction, { createActionWithMeta } from "../../../lib/createAction";

export const APPLY_ALL_NOTICES_REQUEST = "APPLY_ALL_NOTICES_REQUEST";
export const applyAllNotices = createAction(APPLY_ALL_NOTICES_REQUEST, "models");

export const APPLY_ALL_NOTICES_SUCCESS = "APPLY_ALL_NOTICES_SUCCESS";
export const applyAllNoticesSuccess = createActionWithMeta(
  APPLY_ALL_NOTICES_SUCCESS,
  {
    uncache: true,
    notification: {
      body: "ra.notification.updated",
      level: "info",
    },
    refresh: true,
  },
  "response"
);

export const APPLY_ALL_NOTICES_ERROR = "APPLY_ALL_NOTICES_ERROR";
export const applyAllNoticesError = createActionWithMeta(
  APPLY_ALL_NOTICES_ERROR,
  {
    uncache: true,
    notification: {
      body: "ra.notification.bad_item",
      level: "warning",
    },
    refresh: true,
  },
  "error"
);
