import React, { Children, memo } from "react";
import { connect } from "react-redux";
import { translate, CardContentInner, FormInput, Toolbar } from "react-admin";
import { useField, Form } from "react-final-form";
import arrayMutators from "final-form-arrays";
import { Chip } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import get from "lodash/get";
import filter from "lodash/filter";
import includes from "lodash/includes";
import set from "lodash/set";
import isEqual from "lodash/isEqual";

import { getKey } from "../../lib/i18nUtils";
import resources from "../../config/resources";
import TemplateTypes from "../../config/TemplateTypes";
import CustomRefArrayInput from "../customReferenceFetch/CustomRefArrayInput";
import refFetchOccurrences from "../../config/CustomRefFetchOccurrences";
import {
  getCustomReferenceFetchedItemData,
  getCustomSelectedReferenceFetchedItemData,
} from "../../redux/customReferenceFetch/selectors";

/**
 * Our custom Array Input, in order to get rid of the ugly workarounds (see fixReferenceSaga.js)
 */
const _EmailTemplatesInput = ({ record, translate, ...rest }) => {
  const { input } = useField("templates");
  return (
    <CustomRefArrayInput
      occurrence={refFetchOccurrences.contractorForm}
      reference={resources.TEMPLATES}
      record={record}
      source="templates"
      sort={{ field: "id", order: "ASC" }}
      filter={{
        type: Object.values(TemplateTypes)
          .map(it => it.key)
          .filter(it => it !== TemplateTypes.PRODUCTION_PORTFOLIO.key),
      }}
    >
      <Autocomplete
        {...input}
        {...rest}
        onChange={(event, value) => input.onChange(value)}
        fullWidth
        multiple
        autoHighlight
        renderInput={params => <TextField {...params} label={translate(getKey("templates", resources.CONTRACTORS))} />}
        getOptionLabel={({ id, name, type } = {}) =>
          id && name && type
            ? `#${id} - ${name} (${translate(get(TemplateTypes, `[${type}].translation`, ""))})`
            : translate(getKey("templateLabelError", resources.CONTRACTORS))
        }
        renderTags={(value, getTagProps) =>
          value.map(({ id, name }, index) => (
            <Chip
              size="small"
              label={id && name ? `#${id} - ${name}` : translate(getKey("templateLabelError", resources.CONTRACTORS))}
              {...getTagProps({ index })}
            />
          ))
        }
      />
    </CustomRefArrayInput>
  );
};

export const EmailTemplatesInput = translate(_EmailTemplatesInput);

const memoCompare = (prevProps, nextProps) => {
  if (!isEqual(get(prevProps, "initialTemplateValues"), get(nextProps, "initialTemplateValues"))) {
    return false;
  }
  if (!isEqual(get(prevProps, "record"), get(nextProps, "record"))) {
    return false;
  }
  return true;
};
/**
 * NOT YET ADJUSTED FOR GENERAL USE! (only used in contractors edit/create for now)
 * Our custom form container, similar to react admins <SimpleForm /> implementation
 * But we need adjustments for our custom form input
 *
 * CARE: For use in ContractorsEdit and Create, we need to adjust the values before submitting!
 *
 * We have to use React.memo to avoid rerenders caused by the pings which reset the form to its initial values!
 */
const CustomForm = memo(
  ({ classes, save, basePath, children, record, resource, initialTemplateValues, ...props }) => (
    <Form
      className={classes.root}
      initialValues={{ ...record, templates: initialTemplateValues }}
      onSubmit={values => {
        set(values, "templates", get(values, "templates", []).map(t => `/templates/${get(t, "id")}`));
        // TODO: FIX AND REMOVE - Workaround for empty autoAssignUser (somehow the whole attribute is removed when selecting the empty option..)
        if (!get(values, "autoAssignUser", false) && !!get(values, "autoAssignUser_S", false)) {
          set(values, "autoAssignUser", "");
        }
        save(values, props.redirect);
      }}
      mutators={arrayMutators}
      render={({ handleSubmit, form, submitting, pristine, values }) => (
        <form onSubmit={handleSubmit} className={classes.root}>
          <CardContentInner>
            {Children.map(
              children,
              input =>
                input && (
                  <FormInput
                    basePath={basePath}
                    input={input}
                    record={record}
                    resource={resource}
                    variant="standard"
                    margin="normal"
                    fullWidth
                  />
                )
            )}
          </CardContentInner>
          <Toolbar
            basePath={basePath}
            handleSubmit={handleSubmit}
            record={record}
            resource={resource}
            pristine={pristine}
          />
        </form>
      )}
    />
  ),
  memoCompare
);

const mapStateToProps = state => ({
  initialTemplateValues: filter(
    get(getCustomReferenceFetchedItemData(refFetchOccurrences.contractorForm)(state), resources.TEMPLATES, []),
    itm =>
      includes(getCustomSelectedReferenceFetchedItemData(refFetchOccurrences.contractorForm)(state), get(itm, "id"))
  ),
});

export default connect(mapStateToProps)(CustomForm);
