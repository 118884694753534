import { takeEvery } from "redux-saga/effects";
import get from "lodash/get";
import { cache } from "../../../dataproviders/cacheProvider";
import forIn from "lodash/forIn";
import resources from "../../../config/resources";

const saga = function(action) {
  let uncache = get(action, "meta.uncache", false);
  if (uncache) {
    if (typeof uncache === "string") uncache = [uncache];
    if (Array.isArray(uncache)) {
      uncache.forEach(val => cache.deleteAll(val));
    } else if (typeof uncache === "object") {
      forIn(uncache, (ids, resource) => cache.deleteMany(resource)(ids));
    } else if (uncache === true) {
      Object.values(resources).forEach(val => cache.deleteAll(val));
    }
  }
};

export default function*() {
  yield takeEvery("*", saga);
}
