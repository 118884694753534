import React, { useEffect, useState } from "react";
import { EditButton, useDataProvider } from "react-admin";
import { makeStyles, LinearProgress } from "@material-ui/core";
import isEmpty from "lodash/isEmpty";

import resources from "../../config/resources";

const useStyles = makeStyles(theme => ({
  button: {
    marginTop: theme.spacing(1),
  },
}));

const PostProcessingJobEditButton = ({ record }) => {
  const dataProvider = useDataProvider();
  const classes = useStyles();
  const [addition, setAddition] = useState({});

  const getAddition = async link => {
    const response = await dataProvider.getOne(resources.ADDITIONS, { id: link });
    setAddition(response.data);
  };

  useEffect(() => {
    getAddition(record.addition_S);
  }, []);

  return (
    <>
      {isEmpty(addition) ? (
        <LinearProgress />
      ) : (
        <EditButton
          label={addition && addition.colorName ? `${addition?.name} ${addition?.colorName}` : `${addition?.name}`}
          basePath={`/${resources.POSTPROCESSING_JOB}`}
          record={record}
          variant="contained"
          className={classes.button}
          style={{ backgroundColor: addition?.colorCode }}
        />
      )}
    </>
  );
};

export default PostProcessingJobEditButton;
