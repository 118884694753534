import { JobBulkOperationsType } from "../../../config/BulkOperationTypes";
import fetchService from "../../../lib/fetchService";
import { call, put, takeEvery } from "redux-saga/effects";
import { handleSagaError } from "../../../dataproviders/errorResponseHandler";
import config from "../../../config/index";
import { JOB_BULK_OPERATION_REQUEST, jobBulkOperationSuccess, jobBulkOperationError } from "./action";

const requestSaga = function*({ payload }) {
  const { bulkAction } = payload;
  const { jobIds } = payload;

  const url = `${config.urls.BACKEND}/v2/jobBulkOperations`;

  const body =
    Array.isArray(jobIds) && Object.values(JobBulkOperationsType).includes(bulkAction)
      ? {
          jobIds,
          bulkAction: bulkAction.key,
        }
      : {};

  try {
    const response = yield call(fetchService.post, url, "", body);

    for (const action of yield call(handleSagaError, response, jobBulkOperationError)) {
      yield put(action);
      return;
    }

    if (response.ok) {
      const responseData = yield call(() => response.json());
      yield put(jobBulkOperationSuccess(responseData));
    } else {
      const statusCode = response.status;
      yield put(jobBulkOperationError(new Error(`Request failed with error ${statusCode}`)));
    }
  } catch (e) {
    yield put(jobBulkOperationError(e));
  }
};

export default function*() {
  yield takeEvery(JOB_BULK_OPERATION_REQUEST, requestSaga);
}
