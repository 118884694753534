import createReducer, { mergeReducers } from "../../lib/createReducer";
import {
  CHECK_AUTO_ASSIGN_ERROR,
  CHECK_AUTO_ASSIGN_SUCCESS,
  CHECK_AUTO_ASSIGN_REQUEST,
  CHECK_AUTO_ASSIGN_RESULT_CLEAR,
} from "./action";

const init = {
  formula: null,
  model: null,

  isFetching: false,
  fetchSuccess: false,
  hasError: false,
  payload: null,
  error: null,
};

const requestReducer = createReducer(
  CHECK_AUTO_ASSIGN_REQUEST,
  ({ formula, model }) => ({
    ...init,
    formula,
    model,
    isFetching: true,
  }),
  true
);

const successReducer = createReducer(CHECK_AUTO_ASSIGN_SUCCESS, ({ response }) => ({
  isFetching: false,
  fetchSuccess: true,
  payload: response,
}));

const errorReducer = createReducer(CHECK_AUTO_ASSIGN_ERROR, ({ error }) => ({
  isFetching: false,
  hasError: true,
  error,
}));

const clearReducer = createReducer(CHECK_AUTO_ASSIGN_RESULT_CLEAR, () => init);

export default mergeReducers([requestReducer, successReducer, errorReducer, clearReducer], init);
