import createReducer, { mergeReducers } from "../../../lib/createReducer";
import { REESTIMATE_PRICES_REQUEST, REESTIMATE_PRICES_SUCCES, REESTIMATE_PRICES_ERROR } from "./action";

const init = {
  isFetching: false,
  fetchSucces: false,
  hasError: false,
  payload: null,
  error: null,
};

const requestReducer = createReducer(
  REESTIMATE_PRICES_REQUEST,
  () => ({
    ...init,
    isFetching: true,
  }),
  true
);

const succesReducer = createReducer(REESTIMATE_PRICES_SUCCES, ({ response }) => ({
  isFetching: false,
  fetchSucces: true,
  payload: response,
}));

const errorReducer = createReducer(REESTIMATE_PRICES_ERROR, ({ error }) => ({
  isFetching: false,
  hasError: true,
  error,
}));

export default mergeReducers([requestReducer, succesReducer, errorReducer], init);
