import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { addField, Labeled } from "react-admin";
import Editor from "react-simple-code-editor";
import red from "@material-ui/core/colors/red";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import map from "lodash/map";
import Typography from "@material-ui/core/Typography";
import { highlight, validate } from "../lib/codeFormatting";

class JsCodeInput extends Component {
  state = {
    start: 0,
    end: 0,
  };

  handleBlur = eventOrValue => {
    this.props.onBlur(eventOrValue);
    this.props.input.onBlur(eventOrValue);
  };

  handleFocus = event => {
    this.props.onFocus(event);
    this.props.input.onFocus(event);
  };

  handleChange = content => {
    this.props.onChange(content);
    this.props.input.onChange(content);
    this.setState({
      start: this.ref._input.selectionStart,
      end: this.ref._input.selectionEnd,
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.state === prevState && typeof this.ref._input.setSelectionRange === "function") {
      this.ref._input.setSelectionRange(this.state.start, this.state.end);
    }
  }

  render() {
    const {
      input,
      isRequired,
      label,
      meta,
      resource,
      source,
      basePath,
      showParameters,
      codeValidation: { hasError, errorLineNumber, error, result },
      codeParameters = {},
      parametersLabel,
      resultLabel = "",
    } = this.props;

    if (typeof meta === "undefined") {
      throw new Error(
        "The TextInput component wasn't called within a redux-form <Field>. Did you decorate it and forget to add the addField prop to your component? See https://marmelab.com/react-admin/Inputs.html#writing-your-own-input-component for details."
      );
    }

    return (
      <Labeled
        basePath={basePath}
        isRequired={isRequired}
        label={label}
        meta={meta}
        resource={resource}
        source={source}
        fullWidth={true}
      >
        <div style={{ width: "800px", maxWidth: "calc(100vw - 300px)" }}>
          <Editor
            value={input.value}
            onValueChange={this.handleChange}
            highlight={highlight({ hasError, errorLineNumber, codeParameters })}
            padding={10}
            style={{
              fontFamily: '"Fira code", "Fira Mono", monospace',
              backgroundColor: "#202428",
              color: "#FFFFFF",
              width: "100%",
              minHeight: "10em",
              fontSize: "1em",
              lineHeight: "1.6em",
            }}
            ref={ref => {
              this.ref = ref;
            }}
          />
          {showParameters ? (
            <div
              style={{
                backgroundColor: "#202428",
                width: "calc(100% - 32px)",
                fontSize: "0.85em",
                lineBreak: true,
                padding: 16,
                borderTop: "solid 1px #FFFFFF",
              }}
            >
              {parametersLabel && (
                <Typography variant="body1" style={{ color: "#FFFFFF" }}>
                  {parametersLabel}:
                </Typography>
              )}
              {map(codeParameters, (value, key) => (
                <span
                  style={{
                    backgroundColor: "#FFFFFF",
                    borderRadius: "3px",
                    padding: 2,
                    color: "#000000",
                    marginRight: 4,
                    marginTop: 4,
                    display: "inline-block",
                    fontFamily: '"Fira code", "Fira Mono", monospace',
                  }}
                  key={key}
                >
                  {typeof value !== "function" && key}
                  {!["undefined", "object", "function"].includes(typeof value) && "="}
                  {value && value.toString()}
                </span>
              ))}
            </div>
          ) : null}
          {resultLabel && result ? (
            <SnackbarContent
              message={resultLabel + result}
              style={{
                width: "calc(100%-48px)",
                maxWidth: "none",
                backgroundColor: "#202428",
                borderTop: "solid 1px #FFFFFF",
                boxShadow: "none",
              }}
            />
          ) : null}
          {error ? (
            <SnackbarContent
              message={error.toLocaleString()}
              style={{
                width: "calc(100%-48px)",
                maxWidth: "none",
                backgroundColor: red[800],
              }}
            />
          ) : null}
        </div>
      </Labeled>
    );
  }
}

JsCodeInput.propTypes = {
  className: PropTypes.string,
  input: PropTypes.object,
  isRequired: PropTypes.bool,
  label: PropTypes.string,
  meta: PropTypes.object,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  options: PropTypes.object,
  resource: PropTypes.string,
  source: PropTypes.string,
  type: PropTypes.string,
  basePath: PropTypes.string,
  codeParameters: PropTypes.object,
  showParameters: PropTypes.bool,
  parametersLabel: PropTypes.string,
  resultLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.exact(false)]),
};

JsCodeInput.defaultProps = {
  onBlur: () => {},
  onChange: () => {},
  onFocus: () => {},
  options: {},
  type: "text",
};

export default addField(
  connect((state, { input = {}, codeParameters = {} }) => ({
    codeValidation: validate(input.value, codeParameters),
  }))(JsCodeInput)
);
