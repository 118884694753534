import fetchService from "../lib/fetchService";
import {
  CREATE,
  DELETE,
  DELETE_MANY,
  GET_LIST,
  GET_MANY,
  GET_MANY_REFERENCE,
  GET_ONE,
  HttpError,
  UPDATE,
  UPDATE_MANY,
} from "react-admin";
import get from "lodash/get";
import each from "lodash/each";

import handleError from "./errorResponseHandler";
import resources, { getCacheValidUntil } from "../config/resources";
import difference from "../lib/difference";
import { sanitizeUrl } from "../lib/util";

export const HAL_LINK = "HAL_LINK";
export const HAL_REFERENCE = "HAL_REFERENCE";

const HttpMethod = {
  GET: "GET",
  POST: "POST",
  PUT: "PUT",
  PATCH: "PATCH",
  DELETE: "DELETE",
};

export default (baseUrl, httpClient = fetchService) => {
  //const httpClient = initHttpClient.withDefaultHeaders({"Accept": "application/hal+json"});

  const getResourceId = (idOrPath, resource) => {
    if (typeof idOrPath === "number") {
      return idOrPath;
    } else if (typeof idOrPath !== "string") {
      throw new HttpError("ra.message.invalid_form", 400);
    } else if (idOrPath.match(/^[0-9]+$/)) {
      return parseInt(idOrPath);
    } else {
      const cleanPath = sanitizeUrl(baseUrl, idOrPath);
      const match = new RegExp(`/${resource}/([0-9]+)`).exec(cleanPath);
      if (!match) {
        throw new HttpError("ra.message.invalid_form", 400);
      } else {
        return parseInt(match[1]);
      }
    }
  };

  /**
   *
   * @param {object} entityData
   * @param {string} resource
   * @param {object} [additionalLinks]
   * @returns {{__id: *, id: *}}
   */
  const enhance = (entityData = {}, resource, additionalLinks = {}) => {
    const links = {
      ...(entityData["_links"] || {}),
      ...additionalLinks,
    };
    const selfId = sanitizeUrl(baseUrl, get(links, "self.href", `/${resource}/${entityData.id}`));
    const resultData = { ...entityData, id: selfId, __id: entityData.id };
    Object.entries(links).forEach(([key, { href }]) => {
      if (key === "self") {
        return;
      }
      const val = sanitizeUrl(baseUrl, href);
      if (key.endsWith("s")) {
        resultData[key] = [val];
      } else {
        resultData[key] = val;
      }
      resultData[key + "_S"] = val;
      resultData[key + "_A"] = [val];
    });
    return resultData;
  };

  const prepareFilters = filter => {
    const enhancedFilters = {};
    Object.entries(filter).forEach(([key, val]) => {
      enhancedFilters[key.replaceAll("#", ".")] = val;
    });
    return enhancedFilters;
  };

  const convertRequestToHttp = (type, resource, params) => {
    let url;
    const options = {
      method: HttpMethod.GET,
      qs: {},
      body: {},
    };

    // Fill Body
    if (type === CREATE) {
      const { data } = params;
      options.body = { ...data, id: data.__id };
    } else if (type === UPDATE) {
      const { data, previousData } = params;
      options.body = { ...difference(previousData, data), id: data.__id };
    }

    // Fill Url
    if (type === GET_LIST || type === GET_MANY || type === GET_MANY_REFERENCE) {
      options.method = HttpMethod.GET;
      url = `${baseUrl}/${resource}`;
    } else if (type === GET_ONE) {
      options.method = HttpMethod.GET;
      const idParam = params.id;
      if (typeof idParam === "string" && idParam.startsWith("/")) {
        url = `${baseUrl}${idParam}`;
      } else {
        url = `${baseUrl}/${resource}/${params.id}`;
      }
    } else if (type === CREATE) {
      options.method = HttpMethod.POST;
      url = `${baseUrl}/${resource}`;
    } else if (type === UPDATE) {
      const id = getResourceId(params.id, resource);
      if (resources.FILTER_GRAPHS !== resource) {
        url = `${baseUrl}/${resource}/${id}`;
        options.method = HttpMethod.PATCH;
      } else {
        url = `${baseUrl}/${resource}/${id}/update`;
        options.method = HttpMethod.POST;
      }
    } else if (type === DELETE) {
      options.method = HttpMethod.DELETE;
      const id = getResourceId(params.id, resource);
      url = `${baseUrl}/${resource}/${id}`;
    }

    // Fill QS
    if (type === GET_LIST || type === GET_MANY_REFERENCE) {
      const {
        pagination: { page = 1, perPage = 10 },
        sort: { field: sortField, order: sortOrder = "asc" },
        filter = {},
      } = params;
      options.qs.sort = `${sortField},${sortOrder.toLowerCase()}`;
      options.qs.page = page - 1;
      options.qs.size = perPage;
      options.qs = { ...prepareFilters(filter), ...options.qs };
    } else if (type === GET_MANY) {
      const { ids = [] } = params;
      options.qs.id = ids;
      options.qs.page = 0;
      options.qs.size = ids.length;
    }

    if (type === GET_MANY_REFERENCE) {
      const { target, id } = params;
      let realId = id;
      if (typeof id === "string" && id.startsWith("/")) {
        const match = id.match(/\/([0-9]+)/);
        realId = match ? match[1] : realId;
      }

      if (target && realId) {
        options.qs = { ...options.qs, ...prepareFilters({ [target]: realId }) };
      } else if (realId) {
        options.qs = { ...options.qs, ...prepareFilters({ id: realId }) };
      } else {
        throw HttpError("errorCodes.0-00-409");
      }
    }

    if (type === HAL_LINK) {
      options.method = HttpMethod.GET;
      url = baseUrl + params.link;
    }

    return { url, options };
  };

  /**
   * @param {Object} body HTTP Response body
   * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
   * @param {String} resource Name of the resource to fetch, e.g. 'posts'
   * @param {Object} params The data request params, depending on the type
   * @param {Response} response HTTP response from fetch()
   * @returns {Object} Data response
   */
  const convertHttpResponse = (body, type, resource, params, response) => {
    if (type === GET_LIST || type === GET_MANY) {
      const embeddedItems = [];
      Object.values(get(body, "_embedded", {})).forEach(items => embeddedItems.push(...items));
      return {
        data: embeddedItems.map(it => enhance(it, resource)),
        total: get(body, `page.totalElements`),
        validUntil: getCacheValidUntil(resource),
      };
    } else if (type === GET_ONE) {
      return {
        data: enhance(body, resource),
        validUntil: getCacheValidUntil(resource),
      };
    } else if (type === CREATE || type === UPDATE) {
      return {
        data: enhance(body, resource),
      };
    } else if (type === DELETE) {
      return { data: params.previousData };
    } else if (type === GET_MANY_REFERENCE) {
      const {
        pagination: { page = 1, perPage = 10 },
      } = params;
      const content = get(body, `_embedded.${resource}`, [body.content]);
      const paginationOffset = (page - 1) * perPage;
      return {
        data: content.slice(paginationOffset, paginationOffset + perPage).map(it => enhance(it, resource)),
        total: content.length,
      };
    } else if (type === HAL_LINK) {
      const res = get(body, `_embedded.${resource}`, body.content ? [body.content] : [body]);
      return res.map(it => enhance(it, resource, get(it, "_links", get(body, `_links`))));
    }
  };

  const main = async (type, resource, params) => {
    if (type === HAL_REFERENCE) {
      return { data: await main(HAL_LINK, resource, params) };
    } else if (type === UPDATE_MANY) {
      const { ids = [], data } = params;
      return Promise.all(ids.map(id => main(UPDATE, resource, { id, data }))).then(() => ids);
    } else if (type === DELETE_MANY) {
      const { ids } = params;
      const results = [];
      each(ids, async id => {
        const res = await main(DELETE, resource, { id });
        results.push(get(res, "data"));
      });
      return {
        data: results,
      };
    } else if (type === GET_MANY) {
      const { ids = [] } = params;
      if (typeof ids[0] === "string") {
        console.log("V2 Performing HAL Fetch for", resource, ids);
        const results = [];
        for (const link of ids) {
          const res = await main(HAL_LINK, resource, { link });
          results.push(...res.map(it => ({ ...it, id: link })));
        }
        return {
          data: results,
          validUntil: getCacheValidUntil(resource),
        };
      }
    } else if (type === GET_ONE) {
      const { idVal } = params;
      const id = Array.isArray(idVal) ? idVal[0] : idVal;
      if (typeof id === "string") {
        console.log("V2 Performing HAL Fetch for", resource, id);
        const res = await main(HAL_LINK, resource, { link: id });
        return {
          data: { ...res[0], id },
          validUntil: getCacheValidUntil(resource),
        };
      }
    }

    const {
      url,
      options: { method, qs, body },
    } = convertRequestToHttp(type, resource, params);

    const response = await httpClient.fetch(method, url, qs, body, {
      Accept: "application/hal+json",
    });

    if (type === HAL_LINK && response.status === 404) {
      return [];
    }

    await handleError(response); // Throws custom exception for error responses.
    let json;
    if (type !== DELETE) {
      json = await response.json().catch(() => Promise.reject(new HttpError("ra.notification.http_error")));
    }

    return convertHttpResponse(json, type, resource, params, response);
  };

  return main;
};
