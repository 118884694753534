import React from "react";
import {
  BooleanField,
  ChipField,
  CreateButton,
  Datagrid,
  EditButton,
  FunctionField,
  List,
  Pagination,
  ReferenceField,
  ReferenceManyField,
  SingleFieldList,
  TextField,
  TopToolbar,
  translate,
} from "react-admin";
import Chip from "@material-ui/core/Chip";
import { withStyles } from "@material-ui/core/styles";
import { getKey } from "../../lib/i18nUtils";
import resources from "../../config/resources";
import ContractorFilters from "./ContractorFilters";
import ContractorTypes from "../../config/ContractorTypes";
import Tooltip from "@material-ui/core/Tooltip";
import NoUserIcon from "@material-ui/icons/Warning";
import red from "@material-ui/core/colors/red";
import paginationOptions from "../../config/PaginationOptions";
import CustomDeleteButton from "../customFields/buttons/CustomDeleteButton";

const styles = () => ({
  actionButton: {
    display: "flex",
    justifyContent: "flex-end",
    float: "right",
  },
});

const Actions = ({ basePath, hasCreate }) => (
  <TopToolbar>{hasCreate && <CreateButton basePath={basePath} />}</TopToolbar>
);

const EditContractor = ({ permissions = {}, ...props }) =>
  permissions.authorities === "ROLE_ADMIN" ? (
    <EditButton {...props} />
  ) : Array.isArray(permissions.authorities) && permissions.authorities.includes("ROLE_ADMIN") ? (
    <EditButton {...props} />
  ) : null;

const DeleteContractor = ({ permissions = {}, ...props }) =>
  permissions.authorities === "ROLE_ADMIN" ||
  (Array.isArray(permissions.authorities) && permissions.authorities.includes("ROLE_ADMIN")) ? (
    <CustomDeleteButton {...props} />
  ) : null;

const TypeField = translate(({ record, translate }) => (
  <span>
    {record.contractorType ? <Chip label={translate(ContractorTypes[record.contractorType].translation)} /> : null}
  </span>
));

const ContractorsList = ({ translate, permissions = {}, classes, ...props }) => (
  <List
    {...props}
    actions={<Actions />}
    bulkActionButtons={false}
    filters={<ContractorFilters permissions={permissions} />}
    pagination={<Pagination {...paginationOptions} />}
  >
    <Datagrid>
      <TextField source="__id" sortBy="id" />
      <TextField source="contractorName" />
      <FunctionField
        render={({ emailAddress }) => (Array.isArray(emailAddress) ? emailAddress.join(", ") : emailAddress)}
      />
      <ReferenceManyField
        label={translate(getKey("templates", resources.CONTRACTORS))}
        reference={resources.TEMPLATES}
        target="contractors#id"
      >
        <SingleFieldList linkType="show">
          <ChipField source="name" />
        </SingleFieldList>
      </ReferenceManyField>
      <TypeField label={translate(getKey("contractorType", resources.CONTRACTORS))} />
      <BooleanField source="needsQualityControl" />
      <TextField source="jobAmount" sortBy="jobs.length" label={translate(getKey("jobs", resources.CONTRACTORS))} />
      <BooleanField source="forceOrderConfirmation" />
      <BooleanField source="checkOrderConfirmation" />
      <TextField
        source="supportedMaterialAmount"
        sortBy="supportedMaterials.length"
        label={translate(getKey("supportedMaterials", resources.CONTRACTORS))}
      />
      <FunctionField
        source="autoOrderTime"
        render={({ autoOrderTime }) =>
          autoOrderTime === null || autoOrderTime === ""
            ? translate("autoOrderTime.listGlobal")
            : autoOrderTime < 0
            ? translate("autoOrderTime.listOff")
            : translate("autoOrderTime.listFixed", {
                smart_count: autoOrderTime,
              })
        }
      />
      {permissions && (permissions.authorities || []).includes("ROLE_ADMIN") && (
        <ReferenceField reference={resources.USERS} source="autoAssignUser" allowEmpty={true} linkType={false}>
          <TextField source="username" />
        </ReferenceField>
      )}
      {permissions && (permissions.authorities || []).includes("ROLE_ADMIN") && (
        <FunctionField
          label=" "
          render={({ users, contractorType }) =>
            ContractorTypes[contractorType].requiresUser &&
            (!users || users.length === 0) && (
              <Tooltip title={<span style={{ fontSize: 16 }}>{translate("contractorUserMissing")}</span>}>
                <NoUserIcon style={{ color: red[800] }} />
              </Tooltip>
            )
          }
        />
      )}
      <BooleanField source="materialPartition" />
      <EditContractor permissions={permissions || {}} className={classes.actionButton} />
      <DeleteContractor permissions={permissions || {}} className={classes.actionButton} undoable={false} />
    </Datagrid>
  </List>
);

export default withStyles(styles)(translate(ContractorsList));
