import React from "react";
import {
  Datagrid,
  List,
  Pagination,
  ReferenceManyField,
  TextField,
  useTranslate,
  Button,
  SingleFieldList,
} from "react-admin";
import resources from "../../config/resources";
import { makeStyles } from "@material-ui/core/styles";
import DateField from "../customFields/DateField";
import ShipmentFilters from "./ShipmentFilters";
import UnpaidPrepaymentIcon from "@material-ui/icons/MoneyOff";
import PaidIcon from "@material-ui/icons/AttachMoney";
import { getKey } from "../../lib/i18nUtils";
import Tooltip from "@material-ui/core/Tooltip";
import ExpressIcon from "@material-ui/icons/ArrowUpward";
import NotExpressIcon from "@material-ui/icons/Close";
import red from "@material-ui/core/colors/red";
import green from "@material-ui/core/colors/green";
import yellow from "@material-ui/core/colors/yellow";
import paginationOptions from "../../config/PaginationOptions";
import AssignUserButton from "../customFields/buttons/AssignUserButton";
import CommentCountField from "../customFields/CommentCountField";
import CustomerNameField from "../customers/CustomerNameField";
import GroupedModelMultiStatusField from "../customFields/GroupedModelMultiStatusField";
import CommentIcon from "@material-ui/icons/Chat";
import Avatar from "@material-ui/core/Avatar";
import { linkToRecord } from "ra-core";
import ImageEye from "@material-ui/icons/RemoveRedEye";

const useStyles = makeStyles(theme => ({
  field: {
    display: "block",
  },
  inlineField: {
    display: "inline-block",
  },
  fullWidthField: {
    width: "100%",
  },
  titleText: {
    padding: theme.spacing(0.5),
    marginRight: theme.spacing(2),
  },
  card: {
    paddingTop: theme.spacing(2),
  },
}));

const SymbolField = ({ record }) => {
  const translate = useTranslate();
  if (!record) {
    return null;
  }
  const { expressPrint, paymentComplete, waitForPaymentComplete } = record;
  let paymentIcon, paymentTooltip;
  if (paymentComplete) {
    paymentIcon = <PaidIcon style={{ color: green[700] }} />;
    paymentTooltip = "paymentComplete";
  } else if (waitForPaymentComplete) {
    paymentIcon = (
      <Avatar style={{ backgroundColor: red[600] }}>
        <UnpaidPrepaymentIcon />
      </Avatar>
    );
    paymentTooltip = "prepaymentNotComplete";
  } else {
    paymentIcon = <PaidIcon style={{ color: "AAAAAA" }} />;
    paymentTooltip = "prepaymentNotComplete";
  }

  let expressIcon = expressPrint ? (
    <ExpressIcon style={{ color: yellow[900] }} />
  ) : (
    <NotExpressIcon style={{ color: "#AAAAAA" }} />
  );

  return (
    <div style={{ display: "flex", alignItems: "baseline" }}>
      <Tooltip title={<span style={{ fontSize: 16 }}>{translate(getKey(paymentTooltip, resources.MODELS))}</span>}>
        {paymentIcon}
      </Tooltip>
      {expressIcon}
      <CommentCountField source="commentsCount" record={record} />
    </div>
  );
};

const SymbolFieldLabel = () => {
  const translate = useTranslate();
  return (
    <div style={{ display: "flex", alignItems: "baseline", justifyContent: "space-evenly" }}>
      <Tooltip title={<span style={{ fontSize: 16 }}>{translate(getKey("payment", resources.SHIPMENTS))}</span>}>
        <PaidIcon />
      </Tooltip>
      <Tooltip title={<span style={{ fontSize: 16 }}>{translate(getKey("express", resources.SHIPMENTS))}</span>}>
        <ExpressIcon />
      </Tooltip>
      <Tooltip title={<span style={{ fontSize: 16 }}>{translate(getKey("comments", resources.SHIPMENTS))}</span>}>
        <CommentIcon />
      </Tooltip>
    </div>
  );
};

const ShowInNewTabButton = ({ basePath = "", label = "ra.action.show", record, icon = <ImageEye />, ...rest }) => {
  const target = `#${linkToRecord(basePath, record && record.id)}/show`;

  const onClick = e => {
    e.stopPropagation();
    window.open(target, "_blank");
  };

  return (
    <Button label={label} onClick={onClick} {...rest}>
      {icon}
    </Button>
  );
};

const ShipmentsList = ({ permissions, hasList, hasEdit, hasShow, hasCreate, ...props }) => {
  const classes = useStyles();
  const translate = useTranslate();
  return (
    <List
      {...props}
      bulkActionButtons={false}
      filters={<ShipmentFilters />}
      classes={{ card: classes.card }}
      actions={null}
      hasList={hasList}
      hasShow={hasShow}
      hasEdit={hasEdit}
      hasCreate={hasCreate}
      filter={{
        active: true,
      }}
      /*filterDefaultValues={{"modelStatus#equals": [DetailedShopStatus.READY_FOR_PRODUCTION.apiKey]}}*/
      /*filterDefaultValues={{"models#status": [2, 6, 9, 10, 13, 16, 23, 24, 25, 26, 27, 28, 29, 30]}}*/
      sort={{ field: "dateShipping", order: "ASC" }}
      pagination={<Pagination {...paginationOptions} />}
    >
      <Datagrid>
        <ShowInNewTabButton />
        <TextField
          source="orderRapidshopId"
          sortBy="order.rapidshopId"
          label={translate(getKey("rapidshopId", resources.ORDERS))}
        />
        <ReferenceManyField
          label="Status"
          reference={resources.MODELS}
          allowEmpty={true}
          sortable={false}
          target="shipment.id"
        >
          <GroupedModelMultiStatusField source="rohStatus" />
        </ReferenceManyField>
        <SymbolField {...props} sortBy="expressPrint" label={<SymbolFieldLabel />} />

        <ReferenceManyField
          reference={resources.CUSTOMERS}
          source="__id"
          label={translate(getKey("customer", resources.ORDERS))}
          target="orders#shipments#id"
          link={false}
        >
          <SingleFieldList>
            <CustomerNameField />
          </SingleFieldList>
        </ReferenceManyField>

        <DateField source="dateShipping" displayFormat="DD.MM.YY" />
        <DateField source="dateOrdered" sortBy="order.dateOrdered" displayFormat="DD.MM.YY HH:mm" />
        <AssignUserButton size={30} source="assignee" />
      </Datagrid>
    </List>
  );
};

export default ShipmentsList;
