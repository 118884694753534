import React from "react";
import {
  BooleanInput,
  Create,
  FormDataConsumer,
  Loading,
  ReferenceInput,
  required,
  SelectInput,
  SimpleForm,
  translate,
  usePermissions,
} from "react-admin";
import resources from "../../config/resources";
import NotificationTypes from "../../config/NotificationTypes";
import NotificationStyles from "../../config/NotificationStyles";

const requiredValidation = required();

const NotificationConfigCreate = ({ translate, ...props }) => {
  const { loading, permissions } = usePermissions();

  if (loading) {
    return <Loading />;
  } else if (!permissions) {
    return null;
  }

  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <Create {...props}>
        <SimpleForm initialValues={{ owner: `/${resources.USERS}/${permissions.id}` }} redirect="list">
          <SelectInput
            source="notificationType"
            choices={Object.values(NotificationTypes)}
            optionText="translation"
            optionValue="key"
            validate={requiredValidation}
          />
          <SelectInput
            source="notificationStyle"
            choices={Object.values(NotificationStyles)}
            optionText={({ translation, color }) => (
              <span>
                <span
                  style={{
                    display: "inline-block",
                    width: 12,
                    height: 12,
                    borderRadius: "50%",
                    backgroundColor: color,
                    marginRight: 8,
                  }}
                />
                {translate(translation)}
              </span>
            )}
            optionValue="key"
            validate={requiredValidation}
          />
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              formData.notificationType && (
                <ReferenceInput
                  {...rest}
                  source="template"
                  reference={resources.TEMPLATES}
                  filter={{
                    type: NotificationTypes[formData.notificationType].templateType,
                  }}
                  allowEmpty
                >
                  <SelectInput optionText="name" allowEmpty resettable />
                </ReferenceInput>
              )
            }
          </FormDataConsumer>
          <BooleanInput source="push" defaultValue={true} />
          <BooleanInput source="disabled" defaultValue={false} />
        </SimpleForm>
      </Create>
    </div>
  );
};

export default translate(NotificationConfigCreate);
