import createAction, { createActionWithMeta } from "../../../lib/createAction";

export const SYNC_MODELS_REQUEST = "SYNC_MODELS_REQUEST";
export const syncModels = createAction(SYNC_MODELS_REQUEST);

export const SYNC_MODELS_SUCCESS = "SYNC_MODELS_SUCCESS";
export const syncModelsSuccess = createActionWithMeta(
  SYNC_MODELS_SUCCESS,
  { uncache: true, refresh: true },
  "response"
);

export const SYNC_MODELS_ERROR = "SYNC_MODELS_ERROR";
export const syncModelsError = createActionWithMeta(SYNC_MODELS_ERROR, { uncache: true, refresh: true }, "error");
