import { call, put, takeEvery } from "redux-saga/effects";
import { REESTIMATE_PRICES_STATUS_REQUEST, reestimatePricesStatusError, reestimatePricesStatusSuccess } from "./action";
import { handleSagaError } from "../../../dataproviders/errorResponseHandler";
import fetchService from "../../../lib/fetchService";
import config from "../../../config/index";
import resources from "../../../config/resources";

const reestimatePricesStatusSaga = function*() {
  const url = `${config.urls.BACKEND}/v2/${resources.MODELS}/reestimate`;

  try {
    const response = yield call(fetchService.get, url);

    for (const action of yield call(handleSagaError, response, reestimatePricesStatusError)) {
      yield put(action);
      return;
    }

    if (response.ok) {
      const responseData = yield call(() => response.json());

      yield put(reestimatePricesStatusSuccess(responseData));
    } else {
      const statusCode = response.status;
      yield put(reestimatePricesStatusError(new Error(`Request failed with error ${statusCode}!`)));
    }
  } catch (e) {
    yield put(reestimatePricesStatusError(e));
  }
};

export default function*() {
  yield takeEvery(REESTIMATE_PRICES_STATUS_REQUEST, reestimatePricesStatusSaga);
}
