import { mergeReducers } from "../../../lib/createReducer";
import { FETCH_FILE_VERSIONS_ERROR, FETCH_FILE_VERSIONS_REQUEST, FETCH_FILE_VERSIONS_SUCCESS } from "./action";
import cloneDeep from "lodash/cloneDeep";

const init = {
  data: {},
  fetching: [],

  hasError: false,
  error: null,
};

const requestReducer = (state = init, { type, payload }) => {
  if (type !== FETCH_FILE_VERSIONS_REQUEST) return state;

  const { fileUuid } = payload;

  const newState = cloneDeep(state);
  newState.hasError = false;
  newState.error = null;
  newState.fetching.push(fileUuid);

  return newState;
};

const successReducer = (state = init, { type, payload }) => {
  if (type !== FETCH_FILE_VERSIONS_SUCCESS) return state;

  const { fileUuid, response } = payload;

  const newState = cloneDeep(state);
  newState.data[fileUuid] = response;
  newState.fetching.splice(state.fetching.indexOf(fileUuid), 1);

  return newState;
};

const errorReducer = (state = init, { type, payload }) => {
  if (type !== FETCH_FILE_VERSIONS_ERROR) return state;

  const { fileUuid, error } = payload;

  const newState = cloneDeep(state);
  newState.fetching.splice(state.fetching.indexOf(fileUuid), 1);
  newState.hasError = true;
  newState.error = error;

  return newState;
};

export default mergeReducers([requestReducer, successReducer, errorReducer], init);
