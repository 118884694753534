import createAction from "../../lib/createAction";

export const INIT_WEBSOCKET_REQUEST = "INIT_WEBSOCKET_REQUEST";
export const initWebsocket = createAction(INIT_WEBSOCKET_REQUEST, "username");

export const DESTROY_WEBSOCKET = "DESTROY_WEBSOCKET";
export const destroyWebsocket = createAction(DESTROY_WEBSOCKET);

export const WEB_SOCKET_ACTION = "WEB_SOCKET_ACTION";
export const webSocketAction = createAction(WEB_SOCKET_ACTION, "event", "data");

export const WEB_SOCKET_MESSAGE_RECEIVED = "WEB_SOCKET_MESSAGE_RECEIVED";
export const webSocketMessageReceived = createAction(WEB_SOCKET_MESSAGE_RECEIVED, "topic", "body");
