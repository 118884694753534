import React from "react";
import { useTranslate } from "react-admin";
import { useDispatch } from "react-redux";

import { getKey } from "../../lib/i18nUtils";
import resources from "../../config/resources";
import { downloadFile } from "../../redux/download/action";
import fetchService from "../../lib/fetchService";
import DownloadButton from "../customFields/buttons/DownloadButton";

const ModelDownloadButton = ({ id, record, ids, noIdsInFileName = false }) => {
  const translate = useTranslate();
  const dispatch = useDispatch();

  let jobIds = [];
  if (Array.isArray(ids) && !!ids.length) {
    jobIds = ids;
  } else if (id) {
    jobIds = [id];
  } else if (record) {
    jobIds = [record.__id];
  }

  const onClick = () =>
    dispatch(
      downloadFile({
        downloadPath: "/zip/jobs",
        // if the ids are in url format, shorten them to get the int values only
        body: { jobIds: jobIds.map(it => (Number.isInteger(it) ? it : it.substring(it.lastIndexOf("/") + 1))) },
        fetch: fetchService.post,
        headers: { accept: "application/zip" },
        fileName: translate(getKey("downloadFileName", resources.MODELS), {
          id: noIdsInFileName ? "" : jobIds.join("-"),
        }),
      })
    );

  return <DownloadButton dispatchOverride={onClick} label={translate(getKey("threeDData", resources.JOBS))} />;
};

export default ModelDownloadButton;
