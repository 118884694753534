import React, { useState } from "react";
import { useTranslate } from "react-admin";
import { useDataProvider } from "react-admin";
import { Event, Close } from "@material-ui/icons";
import {
  Button,
  Dialog,
  Box,
  makeStyles,
  IconButton,
  DialogTitle,
  Typography,
  DialogContent,
  CircularProgress,
} from "@material-ui/core";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import moment from "moment";
import MomentUtils from "@date-io/moment";

import resources from "../../config/resources";
import DonePostProcessingJobsList from "./DonePostProcessingJobsList";
import { getKey } from "../../lib/i18nUtils";

const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(2),
  },
  picker: {
    margin: theme.spacing(2),
  },
  horizontalInputsContainer: {
    display: "flex",
    justifyContent: "space-between",
    gap: `${theme.spacing()}px`,
    padding: theme.spacing(2),
  },
  dialogHeading: {
    display: "grid",
    gridTemplateColumns: "7fr 1fr",
    justifyItems: "end",
  },
  dialogTitle: {
    color: "primary",
    alignSelf: "center",
    justifySelf: "center",
  },
  progress: {
    marginRight: theme.spacing(5),
    marginLeft: theme.spacing(5),
  },
}));

const CustomButtonWithModals = ({ label, selectedIds, propToUpdate, permission }) => {
  const classes = useStyles();
  const translate = useTranslate();
  const dataProvider = useDataProvider();

  const [openList, setOpenList] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [open, setOpen] = useState(false);
  const [date, setDate] = useState(new Date());

  const handleChange = value => {
    setDate(value);
  };

  const onSubmit = async () => {
    setIsFetching(true);
    const models = await dataProvider.getList(resources.MODELS, {
      pagination: { perPage: 1000 },
      sort: { field: "id", order: "desc" },
      filter: {
        "job.id": selectedIds,
      },
    });

    const modelsIds = Object.values(models)[0].map(item => item.__id);
    const postProcessingJobs = await dataProvider.getList(resources.POSTPROCESSING_JOB, {
      pagination: { page: 1, perPage: 100 },
      sort: { field: "id", order: "desc" },
      filter: {
        "model.id": modelsIds,
      },
    });
    const postProcessingJobsIds = postProcessingJobs.data.map(item => item.__id);

    await dataProvider.updateMany(resources.POSTPROCESSING_JOB, {
      ids: postProcessingJobsIds,
      data: { [propToUpdate]: date.toISOString() },
    });
    if (propToUpdate !== "end") {
      setIsFetching(false);
      setOpen(false);
    } else {
      await dataProvider.updateMany(resources.JOBS, {
        ids: selectedIds,
        data: { status: "RECEIVED" },
      });
      setIsFetching(false);
      setOpen(false);
      setOpenList(true);
    }
  };

  return (
    <>
      <Button
        startIcon={<Event />}
        variant="contained"
        color="primary"
        className={classes.button}
        onClick={() => {
          setOpen(true);
        }}
      >
        {label}
      </Button>
      <Dialog
        fullWidth
        maxWidth="xs"
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      >
        <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
          <KeyboardDatePicker
            label={label}
            autoOk
            value={date}
            format={translate("format.date.dateFormat")}
            onChange={date => handleChange(date)}
            className={classes.picker}
          />
        </MuiPickersUtilsProvider>
        <Box className={classes.horizontalInputsContainer}>
          <Button
            onClick={() => {
              setOpen(false);
            }}
            color="primary"
            variant="contained"
          >
            {translate("ra.action.cancel")}
          </Button>
          {isFetching ? (
            <CircularProgress className={classes.progress} />
          ) : (
            <Button onClick={onSubmit} color="primary" variant="contained">
              {translate("ra.action.save")}
            </Button>
          )}
        </Box>
      </Dialog>
      {propToUpdate === "end" && (
        <Dialog
          fullWidth
          maxWidth="md"
          open={openList}
          onClose={() => {
            setOpenList(false);
          }}
          className={classes.dialog}
        >
          <DialogTitle>
            <Box className={classes.dialogHeading}>
              <Typography variant="h5" className={classes.dialogTitle}>
                {translate(getKey("received", resources.JOBS))}
              </Typography>
              <IconButton
                aria-label="close"
                onClick={() => {
                  setOpenList(false);
                }}
              >
                <Close />
              </IconButton>
            </Box>
          </DialogTitle>
          <DialogContent>
            <DonePostProcessingJobsList selectedIds={selectedIds} />
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};

export default CustomButtonWithModals;
