import set from "lodash/set";

const devEnv = process.env.NODE_ENV === "development";

const getGlobal = key => (global.ROH && global.ROH[key]) || process.env["ROH_GLOBAL_" + key];

const config = {
  build: {
    version: "2.16.0",
    buildNumber: getGlobal("BUILD_NUMBER") !== "REPLACE_WITH_BUILD_NUMBER" ? getGlobal("BUILD_NUMBER") : "DEBUG",
  },
  oauth: {
    CLIENT_ID: "webclient",
    CLIENT_SECRET: "test",
  },
  urls: {
    BACKEND:
      getGlobal("API_BASEURL") !== "REPLACE_WITH_APIBASEURL"
        ? getGlobal("API_BASEURL")
        : "https://staging.roh-backend.rapidobject.com",
    STORAGE_SERVICE:
      getGlobal("STORAGE_SERVICE_BASEURL") !== "REPLACE_WITH_STSBASEURL"
        ? getGlobal("STORAGE_SERVICE_BASEURL")
        : "https://staging.filestorage.rapidobject.com",
    STORAGE_SERVICE_DOWNLOAD: "/fileversions/{fileVersion}/download?token={token}",
  },
  isDevelopmentEnv: devEnv,
  isProdInstance: getGlobal("INSTANCE") !== "REPLACE_WITH_INSTANCE" ? getGlobal("INSTANCE") === "PROD" : false,

  logger: {
    localstorageKey: "log",
    logSize: 200,
  },

  notificationInterval: 10000,
};

if (window && typeof window === "object") {
  window.config = { ...config, oauth: "__HIDDEN__" };
  if (devEnv) window.setConfig = (path, value) => set(config, path, value);
}

export default config;
