import React from "react";
import { Link } from "react-router-dom";
import resources from "../../config/resources";
import SystemPushNotification from "./SystemPushNotification";
import moment from "moment";

const PrepaymentPayedNotification = ({ notification, shouldPushToSystem }) => (
  <span>
    Eine Ihnen zugewiesene Bestellung auf Vorkasse wurde bezahlt.&nbsp;
    <Link to={`/${resources.SHIPMENTS}/%2F${resources.SHIPMENTS}%2F${notification.shipmentId}/show`}>
      Zur Bestellung
    </Link>
    {shouldPushToSystem && (
      <SystemPushNotification
        open={true}
        subject="Vorkasse-Bestellung bezahlt"
        body="Eine Ihnen zugewiesene Bestellung auf Vorkasse wurde bezahlt."
        timestamp={moment(notification.createdAt).unix()}
        tag={`notify-${notification.id}`}
        actions={[
          {
            action: "show",
            title: "Zur Bestellung",
            redirectUrl: `/${resources.SHIPMENTS}/%2F${resources.SHIPMENTS}%2F${notification.shipmentId}/show`,
          },
        ]}
      />
    )}
  </span>
);

export default PrepaymentPayedNotification;
