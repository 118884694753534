import createAction from "../../lib/createAction";

export const EXPORT_REPORT = "EXPORT_REPORT";
export const exportReport = createAction(EXPORT_REPORT);

export const EXPORT_REPORT_SUCCESS = "EXPORT_REPORT_SUCCESS";
export const exportReportSuccess = createAction(EXPORT_REPORT_SUCCESS);

export const EXPORT_REPORT_ERROR = "EXPORT_REPORT_ERROR";
export const exportReportError = createAction(EXPORT_REPORT_ERROR);
