import React, { Component } from "react";
import PropTypes from "prop-types";
import { Button } from "react-admin";
import resources from "../../config/resources";
import get from "lodash/get";
import config from "../../config";
import sanitizeButton from "../sanitizeButton";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";

class DownloadAllModelsButton extends Component {
  handleClick = () => {
    const { data, ids, resource, uuidSource, tokenSource, legacyUrlSource } = this.props;

    if (!data || !Array.isArray(ids) || resource !== resources.MODELS) return;

    const legacyDl = [];

    const qs = ids
      .reduce((acc, id) => {
        const uuid = get(data, `${id}.${uuidSource}`);
        const token = get(data, `${id}.${tokenSource}`);
        if (!uuid || !token) {
          legacyDl.push(id);
          return acc;
        }
        return `${acc}&fileUuids=${encodeURIComponent(uuid)}&token=${encodeURIComponent(token)}`;
      }, "")
      .substr(1);

    if (qs !== "") {
      const url = `${config.urls.STORAGE_SERVICE}/package?${qs}`;
      window.open(url, "_blank");
    }

    if (!legacyUrlSource) return;

    legacyDl.forEach(id => {
      const url = get(data, `${id}.${legacyUrlSource}`);
      if (!!url) window.open(url, "_blank");
    });
  };

  areAllDownloadsSameSource = () => {
    const { data, ids, resource, uuidSource, tokenSource } = this.props;

    if (!data || !Array.isArray(ids) || resource !== resources.MODELS) return;

    const legacyDl = [];

    const qs = ids
      .reduce((acc, id) => {
        const uuid = get(data, `${id}.${uuidSource}`);
        const token = get(data, `${id}.${tokenSource}`);
        if (!uuid || !token) {
          legacyDl.push(id);
          return acc;
        }
        return `${acc}&fileUuids=${encodeURIComponent(uuid)}&token=${encodeURIComponent(token)}`;
      }, "")
      .substr(1);

    if (legacyDl.length === ids.length && ids.length > 1) {
      return false;
    }

    return !(qs !== "" && legacyDl.length !== 0);
  };

  render() {
    const altProps = this.areAllDownloadsSameSource()
      ? this.props
      : { ...this.props, disabled: true, label: "unsynchronized" };

    return <Button {...sanitizeButton(altProps)} onClick={this.handleClick} />;
  }
}

DownloadAllModelsButton.propTypes = {
  ...Button.propTypes,
  uuidSource: PropTypes.string.isRequired,
  tokenSource: PropTypes.string.isRequired,
  legacyUrlSource: PropTypes.string,
};

DownloadAllModelsButton.defaultProps = {
  label: "downloadAll",
  children: <CloudDownloadIcon />,
};

export default DownloadAllModelsButton;
