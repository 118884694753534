import React, { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import LinearProgress from "@material-ui/core/LinearProgress";
import { withStyles } from "@material-ui/core/styles";
import MultiChip from "../MultiChip";
import RohStatus from "../../config/RohStatus";
import { translate } from "react-admin";
import get from "lodash/get";
import resources from "../../config/resources";
import { getKey } from "../../lib/i18nUtils";

const styles = {
  root: { display: "flex", flexWrap: "wrap" },
};

const sanitizeRestProps = ({
  currentSort,
  setSort,
  loading,
  loaded,
  useChip,
  showFilter,
  setPerPage,
  setPage,
  setFilters,
  selectedIds,
  perPage,
  hideFilter,
  hasCreate,
  filterValues,
  displayedFilters,
  defaultTitle,
  ...props
}) => props;

class MultiStatusField extends Component {
  render() {
    const {
      classes = {},
      translate,
      className,
      ids,
      data,
      loaded,
      resource,
      basePath,
      children,
      source,
      record,
      ...rest
    } = this.props;

    if (loaded === false) {
      return <LinearProgress style={{ maxWidth: 100 }} />;
    }

    const addedKeys = [];
    const chipData = [];

    if (record && source) {
      const key = get(record, source, undefined);
      if (key === undefined) return;
      (Array.isArray(key) ? key : [key]).forEach(it => {
        if (addedKeys.indexOf(it) >= 0) {
          return;
        } else {
          addedKeys.push(it);
        }

        const rohStatus = RohStatus[it] || RohStatus[null];
        chipData.push({
          label: translate(rohStatus.translationKey),
          color: rohStatus.textColor,
          backgroundColor: rohStatus.primaryColor,
          borderColor: rohStatus.borderColor,
          importance: rohStatus.importance,
        });
      });
    }

    if (record && resources.JOBS === resource && record.checkOrderConfirmationOutstanding) {
      chipData.push({
        label: translate(getKey("orderConfirmationCheckOutstanding", resources.JOBS)),
        color: RohStatus.NEEDS_UPDATE.textColor,
        backgroundColor: RohStatus.NEEDS_UPDATE.primaryColor,
        borderColor: RohStatus.NEEDS_UPDATE.borderColor,
        importance: 10000,
      });
    }

    if (Array.isArray(ids)) {
      ids.forEach(id => {
        const key = get(data, `${id}.${source}`, undefined);
        if (key === undefined) return;

        if (addedKeys.indexOf(key) >= 0) {
          return;
        } else {
          addedKeys.push(key);
        }

        const rohStatus = RohStatus[key];
        if (!rohStatus) return;

        chipData.push({
          label: translate(rohStatus.translationKey),
          color: rohStatus.textColor,
          backgroundColor: rohStatus.primaryColor,
          borderColor: rohStatus.borderColor,
          importance: rohStatus.importance,
        });
      });
    }

    const sorted = chipData.sort(({ importance: a }, { importance: b }) => a - b);

    return (
      <div className={classnames(classes.root, className)} {...sanitizeRestProps(rest)}>
        <MultiChip values={sorted} />
      </div>
    );
  }
}

MultiStatusField.propTypes = {
  translate: PropTypes.func.isRequired,
  basePath: PropTypes.string,
  classes: PropTypes.object,
  className: PropTypes.string,
  data: PropTypes.object,
  ids: PropTypes.array,
  resource: PropTypes.string,
  source: PropTypes.string,
  record: PropTypes.object,
};

MultiStatusField.defaultProps = {
  classes: {},
};

export default translate(withStyles(styles)(MultiStatusField));
