import React from "react";
import { Dialog, TableCell, TableHead, TableRow } from "@material-ui/core";
import {
  ChipField,
  Datagrid,
  List,
  NumberField,
  Pagination,
  ReferenceManyField,
  TextField,
  useRefresh,
  useTranslate,
  useResourceContext,
} from "react-admin";
import resources from "../../config/resources";
import { getKey } from "../../lib/i18nUtils";
import DistinctSingleFieldList from "../customFields/relations/DistinctSingleFieldList";
import WarehouseCompartmentSetButton from "./WarehouseCompartmentSetButton";
import ActionDatagridBody from "../ActionDatagrid/ActionDatagridBody";

const DatagridHeader = ({ children, ...props }) => {
  const translate = useTranslate();
  const resource = useResourceContext(props);

  return (
    <TableHead>
      <TableRow>
        <TableCell></TableCell>
        {children.map(child => (
          <TableCell key={child.props.source}>
            {child.props.label ? child.props.label : translate(getKey(child.props.source, resource))}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

const WarehouseCompartmentSelectDialog = ({
  open,
  filter = {},
  unselectableIds = [],
  onClose = () => {},
  allowNonEmpty = false,
  model,
  shipment,
}) => {
  const translate = useTranslate();
  const refresh = useRefresh();

  const isRowSelectable = record =>
    !(unselectableIds.includes(record.id) || (record.modelAmount > 0 && !allowNonEmpty));

  const onAssign = () => {
    onClose();
    refresh();
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md">
      <List
        basePath={"/" + resources.WAREHOUSE_COMPARTMENTS}
        resource={resources.WAREHOUSE_COMPARTMENTS}
        bulkActionButtons={false}
        actions={false}
        filter={filter}
        pagination={<Pagination />}
        sort={{ field: "id", order: "ASC" }}
        syncWithLocation={false}
        perPage={25}
      >
        <Datagrid
          isRowSelectable={isRowSelectable}
          header={<DatagridHeader />}
          body={
            <ActionDatagridBody
              onAction={onAssign}
              rowAction={<WarehouseCompartmentSetButton model={model} shipment={shipment} />}
            />
          }
          size="small"
        >
          <TextField source="name" />
          <ReferenceManyField
            sortable={false}
            reference={resources.MODELS}
            target="warehouseCompartment#id"
            label={translate(getKey("externalId", resources.JOBS))}
          >
            <DistinctSingleFieldList collapseOn="jobExtId">
              <ChipField source="jobExtId" />
            </DistinctSingleFieldList>
          </ReferenceManyField>
          <NumberField source="modelAmount" sortable={false} />
        </Datagrid>
      </List>
    </Dialog>
  );
};

export default WarehouseCompartmentSelectDialog;
