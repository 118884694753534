// in src/MyMenu.js
import React from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { DashboardMenuItem, getResources, MenuItemLink, Responsive, useTranslate } from "react-admin";
import { withRouter } from "react-router-dom";
import { makeStyles, LinearProgress } from "@material-ui/core";
import DefaultIcon from "@material-ui/icons/ViewList";
import inflection from "inflection";
import clsx from "clsx";

import ExportErrorButton from "../../components/customFields/buttons/ExportErrorButton";

const useStyles = makeStyles(theme => ({
  root: {}, // Style applied to the MenuItem from material-ui
  active: { fontWeight: "bold" }, // Style applied when the menu item is the active one
  icon: {}, // Style applied to the icon,
  main: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  open: {
    width: 240,
  },
  closed: {
    width: 55,
  },
}));

const translatedResourceName = (resource, translate) =>
  translate(`resources.${resource.name}.name`, {
    smart_count: 2,
    _:
      resource.options && resource.options.label
        ? translate(resource.options.label, {
            smart_count: 2,
            _: resource.options.label,
          })
        : inflection.humanize(inflection.pluralize(resource.name)),
  });

const CustomMenu = ({ resources, onMenuClick, logout, hasDashboard, dense, loading, open }) => {
  const translate = useTranslate();
  const classes = useStyles();

  return (
    <div className={clsx(classes.main, { [classes.open]: open, [classes.closed]: !open })}>
      <div style={{ height: 5 }}>{loading > 0 && <LinearProgress color="secondary" />}</div>
      <div style={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
        {hasDashboard && <DashboardMenuItem onClick={onMenuClick} />}
        {resources
          .filter(({ hasList, name, options = {} }) => hasList && !options.hidden)
          .map(resource => (
            <MenuItemLink
              key={resource.name}
              to={`/${resource.name}`}
              primaryText={translatedResourceName(resource, translate)}
              leftIcon={resource.icon ? <resource.icon /> : <DefaultIcon />}
              classes={{
                root: classes.root,
                active: classes.active,
                icon: classes.icon,
              }}
              onClick={onMenuClick}
              dense={dense}
            />
          ))}
        <MenuItemLink
          to={"/jobBulkOperations"}
          primaryText={translate("bulkOps.jobMenuItem")}
          leftIcon={<DefaultIcon />}
        />
        <Responsive
          small={logout}
          medium={null} // Pass null to render nothing on larger devices
        />
        <ExportErrorButton />
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  open: state.admin.ui.sidebarOpen,
  resources: getResources(state),
  pathname: state.router.location.pathname, // used to force redraw on navigation
  loading: state.admin.loading,
});

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    {}, // Avoid connect passing dispatch in props,
    null,
    {
      areStatePropsEqual: (prev, next) =>
        prev.resources.every(
          (value, index) => value === next.resources[index] // shallow compare resources
        ) &&
        prev.pathname === next.pathname &&
        prev.open === next.open &&
        prev.loading === next.loading,
    }
  )
)(CustomMenu);
