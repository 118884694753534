import createReducer, { mergeReducers } from "../../../lib/createReducer";
import { ACCEPT_JOB_ERROR, ACCEPT_JOB_REQUEST, ACCEPT_JOB_SUCCESS } from "./action";

const init = {
  job: null,

  isFetching: false,
  fetchSuccess: false,
  hasError: false,
  payload: null,
  error: null,
};

const requestReducer = createReducer(
  ACCEPT_JOB_REQUEST,
  ({ job }) => ({
    ...init,
    job,
    isFetching: true,
  }),
  true
);

const successReducer = createReducer(ACCEPT_JOB_SUCCESS, ({ response }) => ({
  isFetching: false,
  fetchSuccess: true,
  payload: response,
}));

const errorReducer = createReducer(ACCEPT_JOB_ERROR, ({ error }) => ({
  isFetching: false,
  hasError: true,
  error,
}));

export default mergeReducers([requestReducer, successReducer, errorReducer], init);
