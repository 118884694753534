import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import get from "lodash/get";
import { translate } from "react-admin";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import MUITextField from "@material-ui/core/TextField";
import CloseIcon from "@material-ui/icons/ErrorOutline";
import SaveIcon from "@material-ui/icons/Save";
import resources from "../../config/resources";
import { getKey } from "../../lib/i18nUtils";
import { fixedModelData } from "../../redux/model/fixedModelData/action";

const styles = theme => ({
  iconPaddingStyle: {
    paddingRight: "0.5em",
  },
  noteField: {
    width: "100%",
    marginTop: theme.spacing(2),
  },
  noteFieldRoot: {
    padding: 0,
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  noteFieldInput: {
    borderRadius: 4,
    backgroundColor: theme.palette.common.white,
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "10px 12px",
    width: "calc(100% - 24px)",
  },
  noteFieldLabel: {
    fontSize: 20,
  },
});

class FixModelDataDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      contractorIssueResolveNote: get(props, "job.contractorIssueResolveNote") || "",
    };
  }

  setContractorIssueResolveNote = e => {
    this.setState({
      contractorIssueResolveNote: e.target.value,
    });
  };

  fixModel() {
    const { fixedModelData, record, resource } = this.props;
    if (resource !== resources.MODELS || !record || !isFinite(record.__id)) {
      return;
    }

    fixedModelData(record.__id, null, this.state.contractorIssueResolveNote);
  }

  render() {
    const { isOpen, onClose, isFetching, translate, classes } = this.props;
    const emptyNote = this.state.contractorIssueResolveNote.length === 0;

    return (
      <Dialog fullWidth maxWidth="md" open={isOpen} onClose={onClose}>
        <DialogTitle>{translate(getKey("markAsFixed", resources.MODELS))}</DialogTitle>
        <DialogContent>
          <MUITextField
            label={translate(getKey("contractorIssueResolveNote", resources.JOBS))}
            multiline
            rows={5}
            value={this.state.contractorIssueResolveNote}
            onChange={this.setContractorIssueResolveNote}
            className={classes.noteField}
            InputProps={{
              disableUnderline: true,
              classes: {
                root: classes.noteFieldRoot,
                input: classes.noteFieldInput,
              },
            }}
            InputLabelProps={{
              shrink: true,
              className: classes.noteFieldLabel,
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>
            <CloseIcon className={classes.iconPaddingStyle} />
            {translate("ra.action.cancel")}
          </Button>
          <Button
            color={emptyNote ? "default" : "secondary"}
            variant="contained"
            onClick={() => this.fixModel()}
            disabled={isFetching}
          >
            {isFetching ? (
              <CircularProgress size={20} thickness={2} color="primary" />
            ) : (
              <SaveIcon className={classes.iconPaddingStyle} />
            )}
            {emptyNote ? translate("ra.action.confirm_empty") : translate("ra.action.confirm")}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

FixModelDataDialog.propTypes = {
  translate: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  job: PropTypes.object.isRequired,
  record: PropTypes.object.isRequired,
  isFetching: PropTypes.bool.isRequired,

  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

FixModelDataDialog.defaultProps = {
  isOpen: false,
  isFetching: false,
  classes: {},
};

const mapStateToProps = state => ({
  isFetching: state.fixedModelData.isFetching,
});

export default connect(
  mapStateToProps,
  { fixedModelData }
)(withStyles(styles)(translate(FixModelDataDialog)));
