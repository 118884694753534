import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { usePermissions } from "react-admin";
import Avatar from "@material-ui/core/Avatar";
import * as colors from "@material-ui/core/colors";
import withTheme from "@material-ui/core/styles/withTheme";
import Tooltip from "@material-ui/core/Tooltip";
import { getActiveUserIds } from "../../redux/activeUsers/selectors";

const mainColors = Object.values(colors);

const _UserAvatarInitials = ({ style, size, theme, displayName = "", displayStyle, user }) => {
  const splitName = displayName.split(" ");
  const { loading, permissions } = usePermissions();
  if (loading) {
    return null;
  }
  let initials = "?";
  if (splitName.length === 1) {
    initials = displayName.substr(0, 3);
  } else if (splitName.length > 1) {
    initials = splitName[0].charAt(0) + splitName.pop().charAt(0);
  }

  const colorIndex = Math.floor((displayStyle / 255.0) * mainColors.length);
  const colorStop = [300, 500, "A700"][(displayStyle % mainColors.length) % 3];
  let bgColor;
  if (permissions && ((user && user === permissions.id) || (displayName && permissions.displayName === displayName))) {
    bgColor = "#EC4801";
  } else {
    try {
      bgColor = mainColors[colorIndex][colorStop];
    } catch (e) {}
  }
  bgColor = bgColor || "#000000";
  const fontColor = theme.palette.getContrastText(bgColor);

  return loading ? null : (
    <Avatar
      style={{
        fontWeight: 700,
        fontSize: size / initials.length,
        backgroundColor: bgColor,
        color: fontColor,
        ...style,
      }}
    >
      {initials.toUpperCase()}
    </Avatar>
  );
};

const UserAvatarInitials = withTheme(_UserAvatarInitials);

const makeStyle = (size, active, styleOverrides = {}) => {
  const style = styleOverrides;

  if (size) {
    style.width = size;
    style.height = size;
  }

  if (active) {
    style.boxShadow = `0 0 8px ${colors.green[700]}`;
  }

  return style;
};

const getOnlineString = ({ user, activeIds = [] }) => {
  if (user && activeIds.includes(user)) {
    return " (Online)";
  } else {
    return "";
  }
};

const UserAvatarWithoutTooltip = ({
  size = 20,
  style,
  displayName,
  avatarUrl,
  displayStyle,
  tooltipPlacement,
  user,
  activeIds = [],
  permissions,
}) =>
  avatarUrl ? (
    <Avatar src={avatarUrl} style={makeStyle(size, activeIds.includes(user), style)} />
  ) : (
    <UserAvatarInitials
      size={size}
      style={makeStyle(size, activeIds.includes(user), style)}
      user={user}
      displayName={displayName}
      displayStyle={displayStyle}
      permissions={permissions}
    />
  );

const UserAvatarWithTooltip = ({
  size = 20,
  style,
  displayName,
  avatarUrl,
  displayStyle,
  tooltipPlacement,
  user,
  activeIds = [],
  permissions,
}) => (
  <Tooltip
    title={
      <span style={{ fontSize: 16 }}>
        {displayName}
        {getOnlineString({ user, activeIds })}
      </span>
    }
    placement={tooltipPlacement}
  >
    <div>
      <UserAvatarWithoutTooltip
        size={size}
        style={style}
        displayName={displayName}
        avatarUrl={avatarUrl}
        displayStyle={displayStyle}
        user={user}
        activeIds={activeIds}
        permissions={permissions}
      />
    </div>
  </Tooltip>
);

const userIdLinkToId = idLink => (typeof idLink === "number" ? idLink : idLink ? /\d+/.exec(idLink)[0] : null);

const UserAvatar = ({ useTooltip, ...rest }) =>
  useTooltip ? (
    <UserAvatarWithTooltip {...rest} user={userIdLinkToId(rest.user)} />
  ) : (
    <UserAvatarWithoutTooltip {...rest} user={userIdLinkToId(rest.user)} />
  );

UserAvatar.propTypes = {
  size: PropTypes.number,
  style: PropTypes.object,
  displayName: PropTypes.string,
  avatarUrl: PropTypes.string,
  displayStyle: PropTypes.number,
  useTooltip: PropTypes.bool,
};

UserAvatar.defaultProps = {
  useTooltip: true,
};

export default connect(state => ({ activeIds: getActiveUserIds(state) }))(UserAvatar);
