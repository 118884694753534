import get from "lodash/get";
import resources, { defaultSort } from "../../config/resources";

/**
 * @type {Object.<string, RegExp>}
 */
let referenceRegexMap = {};
for (const resource of Object.values(resources)) {
  referenceRegexMap[resource] = new RegExp("^[a-zA-Z]_" + resource + "@.*").compile();
}

let referenceMap = {};
for (const resource of Object.values(resources)) {
  for (const reference of Object.values(resources)) {
    referenceMap[`${resource}_${reference}`] = { resource, reference };
  }
}

const firstRefPartRegex = new RegExp("^([^@]+)@");
const idRefPartRegex = new RegExp("([0-9]+)$");
const linkPartRegex = new RegExp("(/\\w+/\\d+(/\\w+)?)$");

export const getOneToManyReferences = state => get(state, "admin.references.oneToMany", {});

export const getOneToManyReferencesForResource = forReference => state => {
  const refs = getOneToManyReferences(state);
  let result = [];
  for (const ref in refs) {
    if (typeof ref !== "string" || ref.includes("?")) continue;

    const matches = firstRefPartRegex.exec(ref);
    if (!matches) continue;
    const match = matches[1];

    const { resource, reference } = referenceMap[match];
    if (reference !== forReference) continue;

    const idMatches = idRefPartRegex.exec(ref);
    const linkMatches = linkPartRegex.exec(ref);
    if (!idMatches && !linkMatches) continue;

    const sort = get(defaultSort, resource, defaultSort.__DEFAULT);

    if (idMatches) {
      const idMatch = idMatches[1];
      const pathMatches = new RegExp("^" + match + "@(.+)_" + idMatch + "$").exec(ref);

      if (pathMatches)
        result.push({
          name: ref,
          resource,
          reference,
          id: idMatch,
          target: pathMatches,
          sort: sort,
        });
    }
    if (linkMatches) {
      const linkMatch = linkMatches[1];
      const pathMatches = new RegExp("^" + match + "@(.+)_" + linkMatch + "$").exec(ref)[1];
      if (pathMatches)
        result.push({
          name: ref,
          resource,
          reference,
          id: linkMatch,
          target: pathMatches,
          sort: sort,
        });
    }
  }
  return result;
};
