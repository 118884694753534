import React, {
  cloneElement,
  createElement,
  Fragment,
  isValidElement,
  memo,
  useCallback,
  useEffect,
  useState,
} from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { TableCell, Checkbox } from "@material-ui/core";
import TableRow from "@material-ui/core/TableRow";
import { linkToRecord } from "ra-core";
import isEqual from "lodash/isEqual";
import { DatagridCell } from "react-admin";

import ExpandRowButton from "./ExpandRowButton";

const computeNbColumns = (expand, children, hasBulkActions) =>
  expand
    ? 1 + React.Children.toArray(children).filter(child => !!child).length // show expand button // non-null children
    : 0; // we don't need to compute columns if there is no expand panel;

const defaultClasses = {};

const ExpansionDatagridRow = ({
  basePath,
  children,
  classes = defaultClasses,
  className,
  expand,
  hover,
  id,
  record,
  resource,
  rowClick,
  selected,
  style,
  selectable,
  history,
  onToggleExpand,
  expandedRowId,
  hasBulkActions,
  onToggleItem,
  ...rest
}) => {
  const [expanded, setExpanded] = useState(expandedRowId === id);
  const [nbColumns, setNbColumns] = useState(computeNbColumns(expand, children));
  const handleToggleSelection = useCallback(
    event => {
      if (!selectable) return;
      onToggleItem(id, event);
      event.stopPropagation();
    },
    [id, onToggleItem, selectable]
  );
  useEffect(() => {
    if (expandedRowId && expandedRowId !== id) {
      setExpanded(false);
    }

    // Fields can be hidden dynamically based on permissions;
    // The expand panel must span over the remaining columns
    // So we must recompute the number of columns to span on
    const newNbColumns = computeNbColumns(expand, children);
    if (newNbColumns !== nbColumns) {
      setNbColumns(newNbColumns);
    }
  }, [expand, nbColumns, children, expandedRowId, id]);

  const handleToggleExpand = useCallback(
    event => {
      if (!expanded) {
        onToggleExpand(id);
        setExpanded(true);
      } else {
        onToggleExpand(null);
        setExpanded(false);
      }
      event.stopPropagation();
    },
    [expanded, id, onToggleExpand]
  );

  const handleClick = useCallback(
    async event => {
      if (!rowClick) return;
      event.persist();

      const effect = typeof rowClick === "function" ? await rowClick(id, basePath, record) : rowClick;
      switch (effect) {
        case "edit":
          history.push(linkToRecord(basePath, id));
          return;
        case "show":
          history.push(linkToRecord(basePath, id, "show"));
          return;
        case "expand":
          handleToggleExpand(event);
          return;
        default:
          if (effect) history.push(effect);
          return;
      }
    },
    [basePath, history, handleToggleExpand, id, record, rowClick]
  );

  return (
    <Fragment>
      <TableRow className={className} key={id} style={style} hover={hover} onClick={handleClick} {...rest}>
        {expand && (
          <TableCell padding="none" className={classes.row}>
            <ExpandRowButton
              classes={classes}
              expanded={expanded}
              onClick={handleToggleExpand}
              expandContentId={`${id}-expand`}
            />
          </TableCell>
        )}
        {hasBulkActions && (
          <TableCell padding="checkbox">
            {selectable && (
              <Checkbox
                color="primary"
                className={`select-item ${classes.checkbox}`}
                checked={selected}
                onClick={handleToggleSelection}
              />
            )}
          </TableCell>
        )}
        {React.Children.map(children, (field, index) =>
          isValidElement(field) ? (
            <DatagridCell
              key={`${id}-${field.props.source || index}`}
              className={classnames(`column-${field.props.source}`, classes.rowCell)}
              record={record}
              {...{ field, basePath, resource }}
            />
          ) : null
        )}
      </TableRow>
      {expand && expanded && (
        <TableRow key={`${id}-expand`} id={`${id}-expand`} style={{ boxShadow: "inset 0 0 10px rgba(0,0,0,0.5)" }}>
          <TableCell colSpan={nbColumns}>
            {isValidElement(expand)
              ? cloneElement(expand, {
                  record,
                  basePath,
                  resource,
                  id: String(id),
                })
              : createElement(expand, {
                  record,
                  basePath,
                  resource,
                  id: String(id),
                })}
          </TableCell>
        </TableRow>
      )}
    </Fragment>
  );
};

ExpansionDatagridRow.propTypes = {
  basePath: PropTypes.string,
  children: PropTypes.node,
  classes: PropTypes.object,
  className: PropTypes.string,
  expand: PropTypes.oneOfType([PropTypes.element, PropTypes.elementType]),
  hover: PropTypes.bool,
  id: PropTypes.any,
  record: PropTypes.object.isRequired,
  resource: PropTypes.string,
  rowClick: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  selected: PropTypes.bool,
  style: PropTypes.object,
  selectable: PropTypes.bool,
  onToggleExpand: PropTypes.func,
  expandedRowId: PropTypes.number,
  onToggleItem: PropTypes.func,
};

ExpansionDatagridRow.defaultProps = {
  hover: true,
  record: {},
  selected: false,
  selectable: true,
};

const areEqual = (prevProps, nextProps) => {
  const { children: _, ...prevPropsWithoutChildren } = prevProps;
  const { children: __, ...nextPropsWithoutChildren } = nextProps;
  return isEqual(prevPropsWithoutChildren, nextPropsWithoutChildren);
};

export const PureExpansionDatagridRow = memo(ExpansionDatagridRow, areEqual);

PureExpansionDatagridRow.displayName = "PureExpansionDatagridRow";

export default ExpansionDatagridRow;
