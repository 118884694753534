import createAction, { createActionWithMeta } from "../../../lib/createAction";

export const DOWNLOAD_PRODUCTION_PORTFOLIO = "DOWNLOAD_PRODUCTION_PORTFOLIO";
export const download = createAction(DOWNLOAD_PRODUCTION_PORTFOLIO, "jobId");

export const DOWNLOAD_PRODUCTION_PORTFOLIO_SUCCESS = "DOWNLOAD_PRODUCTION_PORTFOLIO_SUCCESS";
export const downloadSuccess = createActionWithMeta(DOWNLOAD_PRODUCTION_PORTFOLIO_SUCCESS, {
  uncache: true,
  refresh: true,
});

export const DOWNLOAD_PRODUCTION_PORTFOLIO_ERROR = "DOWNLOAD_PRODUCTION_PORTFOLIO_ERROR";
export const downloadError = createActionWithMeta(
  DOWNLOAD_PRODUCTION_PORTFOLIO_ERROR,
  { uncache: true, refresh: true },
  "error"
);
