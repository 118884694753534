import uniq from "lodash/uniq";
import red from "@material-ui/core/colors/red";
import Typography from "@material-ui/core/Typography";
import resources from "../../config/resources";
import React from "react";

const ErrorsList = ({ data = {}, ids = [], translate, modelIds = [] }) => {
  const models = [];
  const codes = [];
  ids = ids.sort();
  for (let i = ids.length - 1; i >= 0; i--) {
    if (data[ids[i]]) {
      const { modelId, code } = data[ids[i]];
      if (!models.includes(modelId) && modelIds.includes(modelId)) {
        models.push(modelId);
        codes.push(code);
      }
    }
  }

  if (codes.length === 0) return null;

  const strings = uniq(codes).map(it => translate(`errorCodes.${it}`));
  return (
    <p
      style={{
        borderRadius: 5,
        border: `solid 2px ${red[800]}`,
        padding: 8,
        backgroundColor: red[50],
      }}
    >
      <Typography variant="subtitle1">
        {translate(`resources.${resources.MATERIALISE_EXCEPTIONS}.name`, {
          smart_count: strings.length,
        })}
      </Typography>
      {strings.map((it, key) => (
        <p key={key}>
          <Typography variant="body2">- {it}</Typography>
        </p>
      ))}
    </p>
  );
};

export default ErrorsList;
