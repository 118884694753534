import resources from "../../config/resources";
import { CREATE, CRUD_CREATE } from "react-admin";

const assign = (assignKey, resource) => (id, userId, callback) => ({
  type: CRUD_CREATE,
  payload: { data: { [assignKey]: id, user: userId } },
  meta: {
    resource: resources.ASSIGNED_USERS,
    fetch: CREATE,
    onSuccess: {
      notification: {
        body: "assignUserSuccess",
        level: "info",
      },
    },
    callback,
  },
});

export const assignToShipment = assign("shipment", resources.SHIPMENTS);
export const assignToJob = assign("job", resources.JOBS);
