import React, { Fragment } from "react";
import FormulaInput from "./FormulaInput";
import { CheckboxGroupInput, ReferenceArrayInput, required } from "react-admin";
import { validate } from "../../lib/codeFormatting";
import FormulaParameters from "../../config/FormulaParameters";
import { getKey } from "../../lib/i18nUtils";
import resources from "../../config/resources";

const validatePriceFormula = [required(), value => validate(value, FormulaParameters).hasError];

const assignFormulaParams = () => {
  let setKeepOrientation = keepOrientation => {};
  setKeepOrientation.toString = () => "function SET_KEEP_ORIENTATION(keepOrientation) {}";

  let getProductionGoal = contractor => {};
  getProductionGoal.toString = () => "function GET_PRODUCTION_GOAL(contractor) { return 1; }";

  let compareProductionGoal = (contractor1, contractor2) => 1;
  compareProductionGoal.toString = () => "function COMPARE_PRODUCTION_GOAL(contractor1, contractor2) { return 1; }";

  let bestProductionGoal = () => 1;
  bestProductionGoal.toString = () => "function BEST_PRODUCTION_GOAL() { return 1; }";

  let getPrice = contractor => 12.34;
  getPrice.toString = () => "function GET_PRICE(contractor) { return 12.34; }";

  let comparePrice = (contractor1, contractor2) => 1;
  comparePrice.toString = () => "function COMPARE_PRICE(contractor1, contractor2) { return 1; }";

  let bestPrice = () => 1;
  bestPrice.toString = () => "function BEST_PRICE() { return 1; }";

  let availableContractors = () => [1, 2, 3];
  availableContractors.toString = () => "function AVAILABLE_CONTRACTORS() { return [1, 2, 3]; }";

  let assign = contractor => {};
  assign.toString = () => "function ASSIGN(contractor) {}";

  return {
    SET_KEEP_ORIENTATION: setKeepOrientation,
    GET_PRODUCTION_GOAL: getProductionGoal,
    COMPARE_PRODUCTION_GOAL: compareProductionGoal,
    BEST_PRODUCTION_GOAL: bestProductionGoal,
    GET_PRICE: getPrice,
    COMPARE_PRICE: comparePrice,
    BEST_PRICE: bestPrice,
    AVAILABLE_CONTRACTORS: availableContractors,
    ASSIGN: assign,
    DATE_DUE: new Date().getTime() + 10000,
    MODEL_HAS_NOTE: true,
    CUSTOMER_HAS_NOTE: true,
    ORDER_HAS_NOTE: true,
    ADDITION_NAME: "Veredelung X",
    MODEL_SHELL_COUNT: 1,
    ORDER_MAX_SHELL_COUNT: 1,
    MINI_SERIES: true,
  };
};

const validateAssignFormula = [required(), value => validate(value, assignFormulaParams()).hasError];

export const PriceCalculationFormula = ({ translate, ...props }) => (
  <FormulaInput
    {...props}
    source="formulaStr"
    codeParameters={{
      PART_DIMENSION_Z: parseInt(Math.random() * 20) + 1,
      PART_DIMENSION_X: parseInt(Math.random() * 20) + 1,
      PART_DIMENSION_Y: parseInt(Math.random() * 20) + 1,
      PART_VOLUME: parseInt(Math.random() * 90) + 1,
      MODEL_AMOUNT: 1,
      AREA: parseInt(Math.random() * 40) + 1,
      SHOP_PRICE: parseInt(Math.random() * 80) + 1,
    }}
    showParameters={true}
    parametersLabel={translate(getKey("formulaParameters", resources.FORMULAS))}
    resultLabel={translate(getKey("formulaResult", resources.FORMULAS))}
    validate={validatePriceFormula}
  />
);

const MaterialReferenceField = ({ record }) => (
  <span style={{ color: record.autoAssignFormulaName ? "#aaa" : "#000" }}>
    {record.name} ({record.methodKey})
  </span>
);

export const AssignScriptFormula = ({ translate, ...props }) => (
  <Fragment>
    <FormulaInput
      {...props}
      source="formulaStr"
      codeParameters={assignFormulaParams()}
      showParameters={true}
      parametersLabel={translate(getKey("formulaParameters", resources.FORMULAS))}
      resultLabel={false}
      validate={validateAssignFormula}
    />
    <br />
    <ReferenceArrayInput
      {...props}
      source="autoAssignMaterials"
      reference={resources.MATERIALS}
      perPage={500}
      sort={{ field: "name", order: "ASC" }}
      fullWidth={true}
    >
      <CheckboxGroupInput
        optionValue="id"
        optionText={<MaterialReferenceField />}
        row={true}
        style={{
          maxHeight: 300,
          height: 300,
          overflowY: "auto",
        }}
      />
    </ReferenceArrayInput>
  </Fragment>
);
