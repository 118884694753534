import React, { useState, Fragment } from "react";
import { Button, ReferenceField, FunctionField } from "react-admin";
import Icon from "@material-ui/icons/Healing";
import resources from "../../config/resources";
import sanitizeButton from "../sanitizeButton";
import FixModelDataDialog from "./FixModelDataDialog";

const sanitizeRestProps = ({ variant, className, label, ...rest }) => rest;

const FixModelDataButton = ({ ...props }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <ReferenceField reference={resources.JOBS} source="job" {...sanitizeRestProps(props)} link={false}>
      <FunctionField
        render={job => (
          <Fragment>
            <FixModelDataDialog
              {...sanitizeRestProps(props)}
              isOpen={isOpen}
              onClose={() => setIsOpen(false)}
              job={job}
            />
            <Button onClick={() => setIsOpen(true)} {...sanitizeButton(props)} />
          </Fragment>
        )}
      />
    </ReferenceField>
  );
};

FixModelDataButton.defaultProps = {
  children: <Icon />,
};

export default FixModelDataButton;
