export const MODEL_DEFECT_LOCATION = {
  HP_A: {
    key: "HP_A",
    translation: "failureReasons.local.HP_A",
  },
  HP_B: {
    key: "HP_B",
    translation: "failureReasons.local.HP_B",
  },
  HP_C: {
    key: "HP_C",
    translation: "failureReasons.local.HP_C",
  },
  HP_D: {
    key: "HP_D",
    translation: "failureReasons.local.HP_D",
  },
  SLS_A: {
    key: "SLS_A",
    translation: "failureReasons.local.SLS_A",
  },
  OTHER_PRINTER: {
    key: "OTHER_PRINTER",
    translation: "failureReasons.local.OTHER_PRINTER",
  },
  INDEPENDENT_PRINTER: {
    key: "INDEPENDENT_PRINTER",
    translation: "failureReasons.local.INDEPENDENT_PRINTER",
  },
};

export const MODEL_DEFECT_TYPE = {
  ELEPHANT_SKIN: {
    key: "ELEPHANT_SKIN",
    translation: "failureReasons.type.ELEPHANT_SKIN",
  },
  THERMAL_BLEED: {
    key: "THERMAL_BLEED",
    translation: "failureReasons.type.THERMAL_BLEED",
  },
  PRINTING_STRIPS: {
    key: "PRINTING_STRIPS",
    translation: "failureReasons.type.PRINTING_STRIPS",
  },
  BUBBLES: {
    key: "BUBBLES",
    translation: "failureReasons.type.BUBBLES",
  },
  DIMENSIONAL_DEVIATION: {
    key: "DIMENSIONAL_DEVIATION",
    translation: "failureReasons.type.DIMENSIONAL_DEVIATION",
  },
  DELAY: {
    key: "DELAY",
    translation: "failureReasons.type.DELAY",
  },
  OTHERS_MACHINE_DEFECTS: {
    key: "OTHERS_MACHINE_DEFECTS",
    translation: "failureReasons.type.OTHERS_MACHINE_DEFECTS",
  },
  DURING_BLASTING_SUPPORTING: {
    key: "DURING_BLASTING_SUPPORTING",
    translation: "failureReasons.type.DURING_BLASTING_SUPPORTING",
  },
  OTHER_DEFECTS: {
    key: "OTHER_DEFECTS",
    translation: "failureReasons.type.OTHER_DEFECTS",
  },
  LOST_UNKNOWN: {
    key: "LOST_UNKNOWN",
    translation: "failureReasons.type.LOST_UNKNOWN",
  },
};

export const MODEL_DEFECT_ORIGIN_STATES = {
  printingCount: "PRINTING",
  receivedCount: "RECEIVED",
  postProcessingCount: "POSTPROCESSING",
  qaCount: "QA",
  readyForShippingCount: "READY_FOR_SHIPPING",
};
