import { call, put, takeLatest, takeEvery } from "redux-saga/effects";
import get from "lodash/get";

import config from "../../config/index";
import fetchService from "../../lib/fetchService";
import {
  CUSTOM_FETCH_MODEL_SUBTASKS_REQUEST,
  customFetchModelSubtasksSuccess,
  customFetchModelSubtasksFailure,
  CUSTOM_UPDATE_SUBTASK_REQUEST,
  customUpdateSubtaskSuccess,
  customUpdateSubtaskFailure,
  customFetchSubtask,
  CUSTOM_FETCH_SUBTASK_REQUEST,
  customFetchSubtaskFailure,
  customFetchSubtaskSuccess,
  CUSTOM_CREATE_SUBTASK_REQUEST,
  customCreateSubtaskSuccess,
  customCreateSubtaskFailure,
} from "./action";
import { enhanceEntity } from "../../lib/util";
import { resources } from "../../config/resources";

/**
 * Saga for fetching all subtasks for a specific model
 */
const customFetchModelSubtasksSaga = function*({ payload }) {
  const { modelId } = payload;

  const url = `${config.urls.BACKEND}/v2${modelId}/subtasks`;

  try {
    const response = yield call(fetchService.get, url, {});

    if (response.ok) {
      const responseData = yield call(() => response.json());
      const extractedContent = get(responseData, "_embedded.subtasks");
      const enhancedData = Array.isArray(extractedContent)
        ? extractedContent.map(item => enhanceEntity(item, resources.SUBTASKS, `${config.urls.BACKEND}/v2`))
        : [];

      const subtaskIds = enhancedData.map(item => get(item, "id"));
      const dataObject = enhancedData.reduce((obj, item) => ((obj[item.id] = item), obj), {});
      yield put(customFetchModelSubtasksSuccess(dataObject, modelId, subtaskIds));
    } else {
      yield put(customFetchModelSubtasksFailure(response));
    }
  } catch (e) {
    yield put(customFetchModelSubtasksFailure(e));
  }
};

/**
 * Saga for updating a subtask
 */
const customUpdateSubtaskSaga = function*({ payload }) {
  const { subtaskId, data } = payload;

  const url = `${config.urls.BACKEND}/v2${subtaskId}`;

  try {
    const response = yield call(fetchService.fetch, "patch", url, {}, data);

    if (response.ok) {
      yield put(customUpdateSubtaskSuccess(subtaskId));
      yield call(afterUpdateSubtaskSaga, { subtaskId });
    } else {
      yield put(customUpdateSubtaskFailure(subtaskId));
    }
  } catch (e) {
    yield put(customUpdateSubtaskFailure(subtaskId));
  }
};

/**
 * Saga for triggering additional events after successful update
 */
const afterUpdateSubtaskSaga = function*({ subtaskId }) {
  yield put(customFetchSubtask(subtaskId));
};

/**
 * Saga for fetching a single subtask
 */
const fetchSingleSubtaskSaga = function*({ payload }) {
  const { subtaskId } = payload;

  const url = `${config.urls.BACKEND}/v2${subtaskId}`;

  try {
    const response = yield call(fetchService.get, url, {});

    if (response.ok) {
      const responseData = yield call(() => response.json());
      const enhancedData = enhanceEntity(responseData, resources.SUBTASKS, `${config.urls.BACKEND}/v2`);
      yield put(customFetchSubtaskSuccess(enhancedData, subtaskId));
    } else {
      yield put(customFetchSubtaskFailure(response));
    }
  } catch (e) {
    yield put(customFetchSubtaskFailure(e));
  }
};

/**
 * Saga for creating a new subtask
 */
const createSubtaskSaga = function*({ payload }) {
  const { data } = payload;

  const url = `${config.urls.BACKEND}/v2/${resources.SUBTASKS}`;

  const relatedModelId = get(data, "model");

  try {
    const response = yield call(fetchService.post, url, {}, data);

    if (response.ok) {
      const responseData = yield call(() => response.json());
      const enhancedData = enhanceEntity(responseData, resources.SUBTASKS, `${config.urls.BACKEND}/v2`);
      yield put(customCreateSubtaskSuccess(enhancedData, get(enhancedData, "id"), relatedModelId));
    } else {
      yield put(customCreateSubtaskFailure(response));
    }
  } catch (e) {
    yield put(customCreateSubtaskFailure(e));
  }
};

export default function*() {
  yield takeEvery(CUSTOM_FETCH_MODEL_SUBTASKS_REQUEST, customFetchModelSubtasksSaga);
  yield takeLatest(CUSTOM_UPDATE_SUBTASK_REQUEST, customUpdateSubtaskSaga);
  yield takeLatest(CUSTOM_FETCH_SUBTASK_REQUEST, fetchSingleSubtaskSaga);
  yield takeLatest(CUSTOM_CREATE_SUBTASK_REQUEST, createSubtaskSaga);
}
