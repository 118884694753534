import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { translate, List, Datagrid, TextField, BooleanField, TopToolbar, ShowButton, Pagination } from "react-admin";
import CustomerFilters from "./CustomerFilters";
import paginationOptions from "../../config/PaginationOptions";

const styles = () => ({
  showButton: {
    display: "flex",
    justifyContent: "flex-end",
  },
});

const Actions = ({ basePath, displayedFilters, filters, filterValues, resource, showFilter }) => (
  <TopToolbar>
    {filters &&
      React.cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: "button",
      })}
  </TopToolbar>
);

const CustomersList = ({ translate, classes, ...props }) => (
  <List
    {...props}
    bulkActionButtons={false}
    actions={<Actions />}
    filters={<CustomerFilters />}
    pagination={<Pagination {...paginationOptions} />}
  >
    <Datagrid>
      <TextField source="debitorNumber" />
      <TextField source="prename" />
      <TextField source="lastname" />
      <TextField source="companyName" />
      <BooleanField source="allowNewsletter" />
      <BooleanField source="allowNotifications" />
      <BooleanField source="active" />
      <ShowButton className={classes.showButton} />
    </Datagrid>
  </List>
);

Actions.propTypes = {
  basePath: PropTypes.string,
  displayedFilters: PropTypes.object,
  filters: PropTypes.object,
  filterValues: PropTypes.object,
  resource: PropTypes.string,
  showFilter: PropTypes.func,
};

CustomersList.propTypes = {
  classes: PropTypes.object.isRequired,
  translate: PropTypes.func.isRequired,
};

export default withStyles(styles)(translate(CustomersList));
