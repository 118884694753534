import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { FunctionField } from "react-admin";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import get from "lodash/get";

const shortenFileName = (fileName, maxLength, placeholder = "...", separator = ".") => {
  if (typeof fileName !== "string" || fileName.length <= maxLength) {
    return fileName;
  }

  const len = fileName.length;
  let remainingLen = maxLength - placeholder.length;
  const lastSeparatorPos = fileName.lastIndexOf(separator);

  let suffixLen = 0;

  if (lastSeparatorPos > 0) {
    const realSuffixLen = len - lastSeparatorPos;
    suffixLen = Math.floor(remainingLen * 0.35) + realSuffixLen;
  } else {
    suffixLen = Math.ceil(remainingLen / 2.0);
  }

  remainingLen -= suffixLen;

  return (
    <Fragment>
      <span>{fileName.substr(0, remainingLen)}</span>
      <span style={{ opacity: 0.4 }}>{placeholder}</span>
      <span>{fileName.substr(len - suffixLen, suffixLen)}</span>
    </Fragment>
  );
};

const FileNameField = ({
  source,
  record,
  maxLength = 20,
  placeholder,
  separator,
  tooltip = { placement: "bottom-start" },
  downloadSource,
  download,
}) => {
  const fileName = get(record, source, "");

  if (downloadSource) {
    download = get(record, downloadSource);
  }
  if (typeof download === "function") {
    download = download(record);
  }

  let content = shortenFileName(fileName, maxLength, placeholder, separator);

  if (download) {
    content = (
      <a href={download} target="_blank" rel="noopener noreferrer">
        {content}
      </a>
    );
  }

  return (
    <Tooltip {...tooltip} title={fileName}>
      <Typography variant="body2">{content}</Typography>
    </Tooltip>
  );
};

FileNameField.propTypes = {
  ...(FunctionField.propTypes || {}),
  source: PropTypes.string.isRequired,
  maxLength: PropTypes.number,
  placeholder: PropTypes.string,
  separator: PropTypes.string,
  tooltip: PropTypes.shape(Tooltip.propTypes || {}),
  downloadSource: PropTypes.string,
  download: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
};

export default FileNameField;
