import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import { translate } from "react-admin";
import resources from "../../../config/resources";
import SubtaskPopup from "../SubtaskPopup";
import SubtaskStatus from "../../../config/SubtaskStatus";
import SubtaskTypes from "../../../config/SubtaskTypes";
import FormActions from "../../../config/FormActions";
import ModelSubtaskList from "./ModelSubtaskList";
import { get } from "lodash";

const styles = theme => ({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
  },
  subtaskGrid: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    padding: 0,
  },
  button: {
    width: "100%",
  },
});

const enhance = cmp => withStyles(styles)(translate(cmp));

const _SubtaskGrid = ({ classes, ...props }) => (
  <div className={classes.subtaskGrid}>
    <ModelSubtaskList {...props} />
  </div>
);
const SubtaskGrid = enhance(_SubtaskGrid);

const SubtaskPanel = ({ classes, type, base, permissions, showAddTaskButton = true, ...props }) => {
  const subtaskRecord = {
    id: null,
    type: type,
    model: type === SubtaskTypes.MODEL.key ? props.record.id : null,
    status: SubtaskStatus.TODO.key,
    rohStatus: null,
    title: null,
    description: null,
    addition: null,
  };

  return (
    <Paper className={classes.wrapper} elevation={2}>
      {Array.isArray(props.record.subtasks) && props.record.subtasks.length > 0 ? (
        <SubtaskGrid {...props} base={base} type={type} />
      ) : null}
      {showAddTaskButton && (
        <SubtaskPopup
          {...props}
          resource={resources.SUBTASKS}
          source="subtasks"
          record={subtaskRecord}
          action={FormActions.CREATE}
          basePath={type === SubtaskTypes.MODEL.key ? base : props.basePath}
          fullWidth
          parentRecord={get(props, "record")}
        >
          <Button variant="contained" size="small" className={classes.button}>
            <AddIcon />
            &nbsp; Subtask hinzufügen
          </Button>
        </SubtaskPopup>
      )}
    </Paper>
  );
};

SubtaskPanel.propTypes = {
  classes: PropTypes.object,
  type: PropTypes.string,
  base: PropTypes.string,
  statusList: PropTypes.array,
};

export default withStyles(styles)(SubtaskPanel);
