import createAction, { createActionWithMeta } from "../../../lib/createAction";

export const ASSIGN_MODELS_REQUEST = "ASSIGN_MODELS_REQUEST";
export const assignPriceEstimates = createAction(ASSIGN_MODELS_REQUEST, "ids", "noteToContractor");

export const ASSIGN_MODELS_SUCCESS = "ASSIGN_MODELS_SUCCESS";
export const assignModelsSuccess = createActionWithMeta(
  ASSIGN_MODELS_SUCCESS,
  {
    uncache: true,
    notification: {
      body: "ra.notification.updated",
      level: "info",
    },
    refresh: true,
  },
  "response"
);

export const ASSIGN_MODELS_ERROR = "ASSIGN_MODELS_ERROR";
export const assignModelsError = createActionWithMeta(
  ASSIGN_MODELS_ERROR,
  {
    uncache: true,
    notification: {
      body: "ra.notification.bad_item",
      level: "warning",
    },
    refresh: true,
  },
  "error"
);
