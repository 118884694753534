import createAction from "../../../lib/createAction";

export const FETCH_STORAGESERVICE_PING = "FETCH_STORAGESERVICE_PING";
export const fetchStsPing = createAction(FETCH_STORAGESERVICE_PING);

export const FETCH_STORAGESERVICE_PING_SUCCESS = "FETCH_STORAGESERVICE_PING_SUCCESS";
export const fetchStsPingSuccess = createAction(FETCH_STORAGESERVICE_PING_SUCCESS, "response");

export const FETCH_STORAGESERVICE_PING_ERROR = "FETCH_STORAGESERVICE_PING_ERROR";
export const fetchStsPingError = createAction(FETCH_STORAGESERVICE_PING_ERROR, "error");
