import React, { useState } from "react";
import { MuiPickersUtilsProvider, DatePicker, DateTimePicker } from "@material-ui/pickers";
import { makeStyles } from "@material-ui/styles";
import moment from "moment";
import MomentUtils from "@date-io/moment";
import get from "lodash/get";
import { useMutation } from "react-admin";
import resources from "../../config/resources";

moment.locale("de");

const useStyles = makeStyles({
  input: {
    padding: "12px 10px",
    width: "fit-content",
  },
});

const EditableDateField = ({
  record,
  source,
  idField,
  className,
  inputClassName,
  format = "DD. MMMM YYYY",
  label,
  dateTime,
}) => {
  const classes = useStyles();
  const [mutate, { loading }] = useMutation();

  const value = get(record, source);
  const [date, setDate] = useState(value ? moment(value) : null);

  const PickerComponent = dateTime ? DateTimePicker : DatePicker;

  const handleSubmit = () => {
    mutate({
      type: "update",
      resource: resources.PRINT_JOBS,
      payload: {
        id: get(record, idField),
        data: { [source]: date },
      },
    });
  };

  return (
    <div className={className}>
      <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
        <PickerComponent
          label={label}
          className={inputClassName}
          InputProps={!label && { classes: { input: classes.input } }}
          value={date}
          onChange={setDate}
          disabled={loading}
          variant="inline"
          format={format}
          inputVariant="filled"
          ampm={false}
          onClose={handleSubmit}
        />
      </MuiPickersUtilsProvider>
    </div>
  );
};

export default EditableDateField;
