import React from "react";
import { useTranslate } from "react-admin";
import { makeStyles, Tooltip } from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import StiftIcon from "../../assets/Stift.svg";
import AngenommenIcon from "../../assets/Angenommen.svg";
import AusschussIcon from "../../assets/Ausschuss.svg";
import GedrucktIcon from "../../assets/Gedruckt.svg";
import GeliefertIcon from "../../assets/Geliefert.svg";
import GepacktIcon from "../../assets/Gepackt.svg";
import NacharbeitIcon from "../../assets/Nacharbeit.svg";
import InDruckIcon from "../../assets/InDruck.svg";
import VersandbereitIcon from "../../assets/Versandbereit.svg";
import WAPIcon from "../../assets/WAP.svg";

const useStyles = makeStyles({
  iconContainer: {
    height: 25,
    width: 25,
    margin: "auto",
  },
});

const modelPartsStatesIcons = {
  unassignedCount: <ClearIcon />,
  assignedCount: <img src={StiftIcon} alt="" width="25" />,
  packedCount: <img src={GepacktIcon} alt="" width="22" />,
  printingCount: <img src={InDruckIcon} alt="" width="25" />,
  printedCount: <img src={GedrucktIcon} alt="" width="25" />,
  receivedCount: <img src={AngenommenIcon} alt="" width="20" />,
  postProcessingCount: <img src={NacharbeitIcon} alt="" width="25" />,
  qaCount: <img src={WAPIcon} alt="" width="20" />,
  readyForShippingCount: <img src={VersandbereitIcon} alt="" width="22" />,
  shippedCount: <img src={GeliefertIcon} alt="" width="25" />,
  defectCount: <img src={AusschussIcon} alt="" width="22" />,
};

const ModelPartStateIcon = ({ record, name, withTooltip }) => {
  const translate = useTranslate();
  const classes = useStyles();

  const val = name ? name : record.name;
  if (withTooltip) {
    return (
      <Tooltip title={<span style={{ fontSize: 16 }}>{translate(`modelPartState.${val}`)}</span>}>
        <div className={classes.iconContainer}>{modelPartsStatesIcons[val]}</div>
      </Tooltip>
    );
  }

  return modelPartsStatesIcons[val];
};

export default ModelPartStateIcon;
