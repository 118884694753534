import React from "react";
import { BooleanInput, SimpleForm, TextInput, required, FormDataConsumer, translate } from "react-admin";

const requiredValidation = required();
const regexValidation = () => (value, allValues, { translate }) => {
  try {
    if (!value || typeof value !== "string") return undefined;
    const it = new RegExp(value);
    it.test("");
    return undefined;
  } catch (e) {
    return "ra.validation.regexFormat";
  }
};

const fileFormatRegexValidation = [required(), regexValidation()];

const DocumentTypeForm = props => (
  <SimpleForm {...props} initialValues={{ enabled: true }} redirect="list">
    <TextInput source="name" validate={requiredValidation} />
    <FormDataConsumer>
      {({ formData = {} }) => (
        <TextInput source="labelInternal" validate={requiredValidation} defaultValue={formData.name} fullWidth={true} />
      )}
    </FormDataConsumer>
    <FormDataConsumer>
      {({ formData = {} }) => (
        <TextInput source="labelExternal" validate={requiredValidation} defaultValue={formData.name} fullWidth={true} />
      )}
    </FormDataConsumer>
    <TextInput source="fileFormatRegex" defaultValue=".*\.pdf" validate={fileFormatRegexValidation} />
    <BooleanInput source="uploadableByContractor" />
    <BooleanInput source="uploadableBySales" />
    <BooleanInput source="updatableBySales" options={{ disabled: true }} defaultValue={false} />
    <BooleanInput source="multipleUploadsAllowed" options={{ disabled: true }} defaultValue={true} />
    <BooleanInput source="overwriteable" options={{ disabled: true }} defaultValue={true} />
    <BooleanInput source="orderConfirmation" />
  </SimpleForm>
);

export default translate(DocumentTypeForm);
