import React, { useEffect, useState } from "react";
import { downloadFile } from "../../../redux/download/action";
import { useDispatch, useSelector } from "react-redux";
import { isDownloadFileFetching } from "../../../redux/download/selector";
import { Button, CircularProgress } from "@material-ui/core";
import GetAppIcon from "@material-ui/icons/GetApp";

const DownloadButton = ({ id, record, label, downloadPath, fileName, dispatchOverride }) => {
  const dispatch = useDispatch();
  const [clicked, setClicked] = useState(false);
  const isFetching = useSelector(isDownloadFileFetching);

  useEffect(() => {
    if (!isFetching) {
      setClicked(false);
    }
  }, [isFetching]);

  // either any missing required dispatch arguments or no override given
  if (((!id && !record) || !downloadPath || !fileName) && !dispatchOverride) {
    return null;
  }

  const handleDownload = () => {
    setClicked(true);
    if (!!dispatchOverride) {
      dispatchOverride();
    } else {
      dispatch(
        downloadFile({
          id: id ?? record.__id,
          downloadPath: downloadPath,
          fileName: fileName,
        })
      );
    }
  };

  return (
    <Button
      startIcon={clicked && isFetching ? <CircularProgress size={20} /> : <GetAppIcon />}
      variant="contained"
      disabled={clicked && isFetching}
      onClick={handleDownload}
    >
      {label}
    </Button>
  );
};

export default DownloadButton;
