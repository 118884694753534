import transform from "lodash/transform";
import isEqual from "lodash/isEqual";
import isObject from "lodash/isObject";

/**
 * Deep diff between two object, using lodash
 * @param  {Object} oldValue Object compared
 * @param  {Object} newValue   Object to compare with
 * @return {Object}        Return a new object who represent the diff
 */
export function difference(oldValue, newValue) {
  if (Array.isArray(oldValue) && Array.isArray(newValue)) {
    return newValue;
  }
  if (oldValue == null && newValue == null) {
    return {};
  }
  if (oldValue != null && newValue == null) {
    return oldValue;
  } else if (oldValue == null && newValue != null) {
    return newValue;
  }
  return transform(newValue, (result, value, key) => {
    if (!isEqual(value, oldValue[key])) {
      result[key] = isObject(value) && isObject(oldValue[key]) ? difference(oldValue[key], value) : value;
    }
  });
}
export default difference;
