import get from "lodash/get";

export const sanitizeUrl = (baseUrl, url) => {
  const subStringIndex = baseUrl.startsWith("https") ? baseUrl.length - 1 : baseUrl.length;
  return url.startsWith(baseUrl) || url.replace("http", "https").startsWith(baseUrl) ? url.substr(subStringIndex) : url;
};

export const enhanceEntity = (entityData = {}, resource, baseUrl, additionalLinks = {}) => {
  const links = {
    ...(entityData["_links"] || {}),
    ...additionalLinks,
  };
  const selfId = sanitizeUrl(baseUrl, get(links, "self.href", `/${resource}/${entityData.id}`));
  const resultData = { ...entityData, id: selfId, __id: entityData.id };
  Object.entries(links).forEach(([key, { href }]) => {
    if (key === "self") {
      return;
    }
    const val = sanitizeUrl(baseUrl, href);
    if (key.endsWith("s")) {
      resultData[key] = [val];
    } else {
      resultData[key] = val;
    }
    resultData[key + "_S"] = val;
    resultData[key + "_A"] = [val];
  });
  return resultData;
};

export const noop = () => {};
