import React from "react";
import { Link } from "react-router-dom";
import resources from "../../config/resources";
import SystemPushNotification from "./SystemPushNotification";
import moment from "moment";

const JobCommentedNotification = ({ notification, shouldPushToSystem }) => (
  <span>
    Ein Ihnen zugewiesener Sub. Auftrag wurde kommentiert.&nbsp;
    <Link to={`/${resources.JOBS}?filter=%7B"id"%3A"${notification.jobId}"%7D&order=DESC&page=1&perPage=10&sort=id`}>
      Zum Sub. Auftrag
    </Link>
    {shouldPushToSystem && (
      <SystemPushNotification
        open={true}
        subject="Sub. Auftrag kommentiert"
        body="Ein Ihnen zugewiesener Sub. Auftrag wurde kommentiert."
        timestamp={moment(notification.createdAt).unix()}
        tag={`notify-${notification.id}`}
        actions={[
          {
            action: "show",
            title: "Zum Sub. Auftrag",
            redirectUrl: `/${resources.JOBS}?filter=%7B"id"%3A"${notification.jobId}"%7D&order=DESC&page=1&perPage=10&sort=id`,
          },
        ]}
      />
    )}
  </span>
);

export default JobCommentedNotification;
