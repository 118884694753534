import createReducer, { mergeReducers } from "../../../lib/createReducer";
import { DECLINE_JOB_MODEL_ERROR, DECLINE_JOB_MODEL_REQUEST, DECLINE_JOB_MODEL_SUCCESS } from "./action";

const init = {
  job: null,
  model: null,
  reason: null,
  message: null,

  isFetching: false,
  fetchSuccess: false,
  hasError: false,
  payload: null,
  error: null,
};

const requestReducer = createReducer(
  DECLINE_JOB_MODEL_REQUEST,
  ({ job, model, reason, message }) => ({
    ...init,
    job,
    model,
    reason,
    message,
    isFetching: true,
  }),
  true
);

const successReducer = createReducer(DECLINE_JOB_MODEL_SUCCESS, ({ response }) => ({
  isFetching: false,
  fetchSuccess: true,
  payload: response,
}));

const errorReducer = createReducer(DECLINE_JOB_MODEL_ERROR, ({ error }) => ({
  isFetching: false,
  hasError: true,
  error,
}));

export default mergeReducers([requestReducer, successReducer, errorReducer], init);
