import get from "lodash/get";

// selectors for all references
export const getCustomReferenceFetchedItemData = occurrence => state =>
  get(state, `customReferenceFetch.${occurrence}.all.data`);

export const isCustomReferenceFetchedItemFetching = occurrence => state =>
  get(state, `customReferenceFetch.${occurrence}.all.isFetching`, false);

export const getCustomReferenceFetchedItemError = occurrence => state =>
  get(state, `customReferenceFetch.${occurrence}.all.error`);

export const getCustomReferenceFetchedItemTimeFetched = occurrence => state =>
  get(state, `customReferenceFetch.${occurrence}.all.timeFetched`);

// selectors for selected references
export const getCustomSelectedReferenceFetchedItemData = occurrence => state =>
  get(state, `customReferenceFetch.${occurrence}.selected.data`);

export const isCustomSelectedReferenceFetchedItemFetching = occurrence => state =>
  get(state, `customReferenceFetch.${occurrence}.selected.isFetching`, false);

export const getCustomSelectedReferenceFetchedItemError = occurrence => state =>
  get(state, `customReferenceFetch.${occurrence}.selected.error`);

export const getCustomSelectedReferenceFetchedItemTimeFetched = occurrence => state =>
  get(state, `customReferenceFetch.${occurrence}.selected.timeFetched`);
