import createAction from "../../../lib/createAction";

export const FETCH_RESTART_NOTIFICATION = "FETCH_RESTART_NOTIFICATION";
export const fetchRestartNotification = createAction(FETCH_RESTART_NOTIFICATION);

export const FETCH_RESTART_NOTIFICATION_SUCCESS = "FETCH_RESTART_NOTIFICATION_SUCCESS";
export const fetchRestartNotificationSuccess = createAction(FETCH_RESTART_NOTIFICATION_SUCCESS, "response");

export const FETCH_RESTART_NOTIFICATION_ERROR = "FETCH_RESTART_NOTIFICATION_ERROR";
export const fetchRestartNotificationError = createAction(FETCH_RESTART_NOTIFICATION_ERROR, "error");
