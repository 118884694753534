import { call, put, takeEvery } from "redux-saga/effects";
import resources from "../../../config/resources";
import config from "../../../config/index";
import fetchService from "../../../lib/fetchService";
import { ASSIGN_MODELS_REQUEST, assignModelsError, assignModelsSuccess } from "./action";
import { crudGetMany } from "react-admin";
import { handleSagaError } from "../../../dataproviders/errorResponseHandler";

const requestSaga = function*({ payload }) {
  const { ids: priceEstimates } = payload;

  const url = `${config.urls.BACKEND}/v2/${resources.MODELS}/assign_contractor`;
  const data = {};
  const body = { ids: priceEstimates };

  try {
    const response = yield call(fetchService.post, url, data, body);

    for (const action of yield call(handleSagaError, response, assignModelsError)) {
      yield put(action);
      return;
    }

    if (response.ok) {
      const responseData = yield call(() => response.json());

      yield put(assignModelsSuccess());
      yield put(crudGetMany(resources.MODELS, (responseData[0] || {}).models || []));
    } else {
      const statusCode = response.status;
      yield put(assignModelsError(new Error(`Request failed with error ${statusCode}!`)));
    }
  } catch (e) {
    yield put(assignModelsError(e));
  }
};

export default function*() {
  yield takeEvery(ASSIGN_MODELS_REQUEST, requestSaga);
}
