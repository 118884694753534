import React, { useEffect, useState } from "react";
import { Datagrid, TextField, useTranslate, ReferenceManyField, FunctionField } from "react-admin";
import { useSelector } from "react-redux";

import MultiChip from "../../components/MultiChip";
import DistinctSingleFieldList from "../../components/customFields/relations/DistinctSingleFieldList";
import { getKey } from "../../lib/i18nUtils";
import resources from "../../config/resources";
import MultiStatusField from "../customFields/MultiStatusField";

const DonePostProcessingJobsList = ({ selectedIds, permissions, ...props }) => {
  const translate = useTranslate();
  const jobs = useSelector(state => state.admin.resources.jobs.data);
  const [updatedJobs, setUpdatedJobs] = useState({});

  useEffect(() => {
    const result = {};
    Object.values(jobs).forEach(item => {
      if (selectedIds.includes(item.__id)) {
        result[item.__id] = item;
        return result;
      }
    });
    setUpdatedJobs(result);
  }, []);

  return (
    <Datagrid basePath="" data={updatedJobs} ids={selectedIds} selectedIds={[]}>
      <TextField source="externalId" sortBy="id" />
      <MultiStatusField source="status" useChip={true} />
      <TextField source="modelAmount" label={translate(getKey("models", resources.JOBS))} textAlign="right" />
      <ReferenceManyField
        label={translate(`resources.${resources.WAREHOUSE_LOCATIONS}.name`, 1)}
        basePath={"/" + resources.WAREHOUSE_LOCATIONS}
        reference={resources.WAREHOUSE_LOCATIONS}
        target="warehouseCompartments#models#job#id"
      >
        <DistinctSingleFieldList>
          <FunctionField
            render={({ label, color }) => <MultiChip values={[{ label, backgroundColor: color, color: "primary" }]} />}
          />
        </DistinctSingleFieldList>
      </ReferenceManyField>
    </Datagrid>
  );
};

export default DonePostProcessingJobsList;
