import React, { isValidElement, Children } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter, matchPath } from "react-router-dom";
import { showSystemPushNotification } from "../../redux/pushNotifications/actions";
import { addNotificationClickListener } from "../../lib/PushService";

const sanitizeRestProps = ({
  children,
  showSystemPushNotification,
  subject,
  body,
  actions,
  badge,
  dir,
  icon,
  image,
  lang,
  renotify,
  requireInteraction,
  silent,
  tag,
  timestamp,
  vibrate,
  ...rest
}) => rest;

class SystemPushNotificationProvider extends React.Component {
  state = {
    ready: false,
  };

  doNotify = () => {
    const {
      showSystemPushNotification,
      subject,
      body,
      actions = [],
      badge,
      dir,
      icon,
      image,
      lang,
      renotify,
      requireInteraction,
      silent,
      tag,
      timestamp,
      vibrate,
    } = this.props;

    const sanitizedActions = actions.map(({ action, title, icon }) => ({ action, title, icon }));

    showSystemPushNotification(subject, {
      body,
      actions: sanitizedActions,
      badge,
      dir,
      icon,
      image,
      lang,
      renotify,
      requireInteraction,
      silent,
      tag,
      timestamp,
      vibrate,
    });
  };

  handleNotificationClick = evt => {
    const { actions = [], tag, history } = this.props;
    actions.forEach(({ action, onClick, redirectUrl, closeOnClick }) => {
      if (evt.notification.tag !== tag || evt.action !== action) {
        return;
      }

      if (typeof onClick === "function") {
        onClick(evt, sanitizeRestProps(this.props));
      }

      if (typeof redirectUrl === "string" && redirectUrl.length > 0) {
        const { pathname } = history.location;
        if (matchPath(pathname, { path: redirectUrl })) {
          window.focus();
        } else {
          history.push(redirectUrl);
        }
      }
    });
  };

  componentDidMount() {
    addNotificationClickListener(this.handleNotificationClick.bind(this))
      .then(() => this.setState({ ready: true }))
      .catch(e => console.error("Notification event handler registration failed!", e));
  }

  render() {
    if (!this.state.ready) {
      return null;
    }

    const { children } = this.props;

    if (isValidElement(children)) {
      const restProps = sanitizeRestProps(this.props);
      return Children.map(children, (child, idx) =>
        React.cloneElement(child, { key: idx, ...restProps, sendPushNotification: this.doNotify })
      );
    } else if (typeof children === "function") {
      return children(this.doNotify);
    } else {
      console.error("Expecting either a ReactEL or a function as child!");
      return null;
    }
  }
}

export const propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  subject: PropTypes.string.isRequired,
  body: PropTypes.string,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      action: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      onClick: PropTypes.func,
      redirectUrl: PropTypes.string,
      closeOnClick: PropTypes.bool,
      icon: PropTypes.string,
    })
  ),
  badge: PropTypes.string,
  dir: PropTypes.oneOf(["auto", "ltr", "rtl"]),
  icon: PropTypes.string,
  image: PropTypes.string,
  lang: PropTypes.string,
  renotify: PropTypes.bool,
  requireInteraction: PropTypes.bool,
  silent: PropTypes.bool,
  tag: PropTypes.string,
  timestamp: PropTypes.number,
  vibrate: PropTypes.arrayOf(PropTypes.number),
};

SystemPushNotificationProvider.propTypes = propTypes;

export default connect(
  undefined,
  { showSystemPushNotification }
)(withRouter(SystemPushNotificationProvider));
