import React from "react";
import { FunctionField } from "react-admin";
import AdditionIcon from "@material-ui/icons/AddCircle";
import { withStyles } from "@material-ui/core/styles";
import IconChipField from "../customFields/IconChipField";

const styles = theme => ({
  content: {
    display: "flex",
    alignItems: "center",
  },
  label: {
    paddingRight: theme.spacing(1),
  },
});

const AdditionField = ({ label, classes, ...props }) => (
  <FunctionField
    {...props}
    render={record => (
      <span className={classes.content}>
        {label && <span className={classes.label}>{label}</span>}
        <IconChipField
          label={`${record.name} ${record.colorName ? ` - ${record.colorName}` : ""}`}
          color={record.colorCode}
          icon={AdditionIcon}
        />
      </span>
    )}
  />
);

AdditionField.defaultProps = {
  addLabel: true,
};

export default withStyles(styles)(AdditionField);
