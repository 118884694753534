import React from "react";
import { IconButton, Table, TableBody, TableCell, TableHead, TableRow, TextField } from "@material-ui/core";
import classnames from "classnames";
import { Close } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import isEmpty from "lodash/isEmpty";

import resources from "../../config/resources";
import { getKey } from "../../lib/i18nUtils";
import { useTranslate } from "react-admin";

const useStyles = makeStyles(theme => ({
  deleteIcon: {
    cursor: "pointer",
    width: "auto",
  },
  table: {
    width: "100%",
  },
}));

export default function PrintJobModelsTable({ currentPrintJobModels = [], setCurrentPrintJobModels }) {
  const classes = useStyles();
  const translate = useTranslate();

  return (
    <>
      {!isEmpty(currentPrintJobModels) && (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{translate(getKey("jobExternalId", resources.PRINT_JOBS))}</TableCell>
              <TableCell>{translate(getKey("modelRapidshopId", resources.PRINT_JOBS))}</TableCell>
              <TableCell>{translate(getKey("modelPieces", resources.PRINT_JOBS))}</TableCell>
              <TableCell>{translate(getKey("modelNote", resources.PRINT_JOBS))}</TableCell>
              <TableCell> </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {currentPrintJobModels?.map((model, index) => (
              <TableRow key={index}>
                <TableCell>{model.externalId}</TableCell>
                <TableCell>{model.rapidshopId}</TableCell>
                <TableCell>
                  <TextField
                    onChange={e => {
                      setCurrentPrintJobModels(
                        currentPrintJobModels?.map(item => {
                          if (item.modelId === model.modelId) {
                            item.count = parseInt(e.target.value);
                          }
                          return item;
                        })
                      );
                    }}
                    type="number"
                    min={0}
                    max="100"
                    defaultValue={model.count || model.assignedCount || 0}
                    placeholder="0.00"
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    onChange={e => {
                      setCurrentPrintJobModels(
                        currentPrintJobModels?.map(item => {
                          if (item.modelId === model.modelId) {
                            item.note = e.target.value;
                          }
                          return item;
                        })
                      );
                    }}
                    placeholder={translate(getKey("notesPlaceholder", resources.PRINT_JOBS))}
                    defaultValue={model.note}
                    fullWidth
                    multiline
                    value={model.note}
                  />
                </TableCell>
                <TableCell>
                  <IconButton
                    onClick={() => {
                      setCurrentPrintJobModels(currentPrintJobModels?.filter(item => item.modelId !== model.modelId));
                    }}
                  >
                    <Close className={classnames(classes.deleteIcon)} />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </>
  );
}
