import React from "react";
import PropTypes from "prop-types";
import { addField, FieldTitle } from "react-admin";
import TextField from "@material-ui/core/TextField";
import withStyles from "@material-ui/core/styles/withStyles";
import { GithubPicker as Picker } from "react-color";

const styles = theme => ({
  popup: {
    position: "absolute",
    zIndex: 2,
  },
  cover: {
    position: "fixed",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
});

class ColorInputComponent extends React.Component {
  state = {
    show: false,
  };

  handleOpen = () => this.setState({ show: true });
  handleClose = () => this.setState({ show: false });
  handleChange = ({ hex }) => {
    this.props.input.onChange(hex);
    this.forceUpdate();
  };

  render() {
    const { label, source, meta, className, options, input, inputProps, resource, isRequired, classes } = this.props;

    const { touched, error } = meta;

    return (
      <div>
        <TextField
          {...input}
          {...inputProps}
          margin="normal"
          onFocus={this.handleOpen}
          label={<FieldTitle label={label} source={source} resource={resource} isRequired={isRequired} />}
          error={!!(touched && error)}
          helperText={touched && error}
          className={className}
        />
        {this.state.show ? (
          <div className={classes.popup}>
            <div className={classes.cover} onClick={this.handleClose} />
            <Picker {...options} color={input.value} onChange={this.handleChange} />
          </div>
        ) : null}
      </div>
    );
  }
}

ColorInputComponent.propTypes = {
  label: PropTypes.string,
  options: PropTypes.object,
  source: PropTypes.string,
  input: PropTypes.object,
  inputProps: PropTypes.object,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  className: PropTypes.string,
};

ColorInputComponent.defaultProps = {
  options: {
    disableAlpha: true,
  },
};

export const ColorInput = addField(withStyles(styles)(ColorInputComponent));
