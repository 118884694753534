import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Button, Confirm, showNotification } from "react-admin";
import { restart } from "../../redux/restart/action";
import get from "lodash/get";
import CircularProgress from "@material-ui/core/CircularProgress";
import sanitizeButton from "../sanitizeButton";
import Icon from "@material-ui/icons/PowerSettingsNew";
import moment from "moment";
import { getRestartScheduled } from "../../redux/notifications/restart/selector";

class RestartButton extends Component {
  state = {
    isOpen: false,
    awaitingResponse: false,
  };

  handleClick = e => {
    this.setState({ isOpen: true });
  };

  handleDialogClose = e => {
    this.setState({ isOpen: false });
  };

  handleConfirm = e => {
    const { restart } = this.props;
    restart();
    this.setState({ awaitingResponse: true, isOpen: false });
  };

  componentDidUpdate(prevProps, prevState) {
    const { awaitingResponse } = prevState;
    const { fetchSuccess, showNotification, payload } = this.props;
    const { fetchSuccess: oldSuccess } = prevProps;

    if (prevProps !== this.props && awaitingResponse && fetchSuccess && !oldSuccess) {
      const formattedDate = moment(payload.restartingAt).format("DD. MMMM YYYY HH:mm:ss");
      showNotification("restartingAt", "info", {
        autoHideDuration: 5000,
        messageArgs: { time: formattedDate },
      });
      this.setState({ awaitingResponse: false });
    }
  }

  render() {
    const { children, isFetching, disabled, isScheduled, ...rest } = this.props;
    return (
      <Fragment>
        <Button {...sanitizeButton(rest)} onClick={this.handleClick} disabled={disabled || isFetching || isScheduled}>
          {isFetching ? <CircularProgress size={20} thickness={2} color="primary" /> : children}
        </Button>
        <Confirm
          isOpen={this.state.isOpen}
          title="restartDialog.title"
          content="restartDialog.body1"
          onConfirm={this.handleConfirm}
          onClose={this.handleDialogClose}
        />
      </Fragment>
    );
  }
}

RestartButton.defaultProps = {
  children: <Icon />,
  label: "restart",
};

export default connect(
  state => ({
    isFetching: get(state, "restart.isFetching"),
    payload: get(state, "restart.payload"),
    fetchSuccess: get(state, "restart.fetchSuccess"),
    isScheduled: getRestartScheduled(state),
  }),
  {
    restart,
    showNotification,
  }
)(RestartButton);
