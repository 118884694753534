import createAction, { createActionWithMeta } from "../../lib/createAction";
import { isEmpty } from "lodash";

export const CHECK_PRINTJOB_TIMES = "CHECK_PRINTJOB_TIMES";
export const checkPrintjobTimes = createAction(CHECK_PRINTJOB_TIMES, "id");

export const CHECK_PRINTJOB_TIMES_SUCCESS = "CHECK_PRINTJOB_TIMES_SUCCESS";
export const checkPrintjobTimesSuccess = createActionWithMeta(
  CHECK_PRINTJOB_TIMES_SUCCESS,
  response => {
    if (isEmpty(response)) {
      return {};
    }

    return {
      notification: {
        body: "printJobTooLate",
        level: "warning",
        messageArgs: { order: response.join(", "), smart_count: response.length },
      },
    };
  },
  "response"
);

export const CHECK_PRINTJOB_TIMES_ERROR = "CHECK_PRINTJOB_TIMES_ERROR";
export const checkPrintjobTimesError = createAction(CHECK_PRINTJOB_TIMES_ERROR, "error");
