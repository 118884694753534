import React, { Component } from "react";
import PropTypes from "prop-types";
import { Button } from "react-admin";
import { connect } from "react-redux";
import { unassignModel } from "../../redux/model/unassignModel/action";
import resources from "../../config/resources";
import sanitizeButton from "../sanitizeButton";
import UnassignIcon from "@material-ui/icons/RemoveShoppingCart";
import get from "lodash/get";
import CircularProgress from "@material-ui/core/CircularProgress";

class UnassignModelButton extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const { isLoading } = prevState;

    if (isLoading && !this.isFetching) {
      this.setState({ isLoading: false });
    }
  }

  handleClick = e => {
    const { unassignModel, record, onClick } = this.props;

    this.setState({ isLoading: true }, () => unassignModel(record.__id));

    if (typeof onClick === "function") onClick(e);
  };

  render() {
    const { unassignModel, onClick, children, isFetching, disabled, ...props } = this.props;
    const { isLoading } = this.state;

    if (props.resource && props.resource !== resources.MODELS && props.resource !== resources.ARCHIVED_MODELS) {
      throw new Error("UnassignModelButton can only work in a model resource context.");
    }

    return (
      <Button {...sanitizeButton(props)} onClick={this.handleClick} disabled={isFetching || isLoading || disabled}>
        {isLoading ? <CircularProgress size={20} thickness={2} color="primary" /> : children}
      </Button>
    );
  }
}

UnassignModelButton.defaultProps = {
  children: <UnassignIcon />,
};

UnassignModelButton.propTypes = {
  addLabel: PropTypes.bool,
  basePath: PropTypes.string.isRequired,
  record: PropTypes.object.isRequired,
  resource: PropTypes.string.isRequired,
  label: PropTypes.string,
  unassignModel: PropTypes.func.isRequired,
  onClick: PropTypes.func,
};
export default connect(
  state => ({
    isFetching: get(state, "unassignModel.isFetching", false),
  }),
  {
    unassignModel,
  }
)(UnassignModelButton);
