import React from "react";
import { Link } from "react-router-dom";
import { GET_ONE, Query } from "react-admin";
import resources from "../../config/resources";
import SystemPushNotification from "./SystemPushNotification";
import moment from "moment";

const JobInDeliveryNotification = ({ notification, shouldPushToSystem }) => (
  <Query type={GET_ONE} resource={resources.JOBS} payload={{ id: notification.jobId }}>
    {({ data, loading, error }) => {
      if (loading) {
        return <i>L&auml;dt...</i>;
      }
      if (error) {
        return <i>Der Inhalt dieser Benachrichtigung konnte nicht geladen werden</i>;
      }
      return (
        <div style={{ display: "inline-block", maxWidth: 400 }}>
          Der Sub. Auftrag {data.externalId} ist in Anlieferung.&nbsp;
          <Link to={`/${resources.JOBS}?filter=%7B"id"%3A"${data.__id}"%7D&order=DESC&page=1&perPage=10&sort=id`}>
            Zum Sub. Auftrag
          </Link>
          {shouldPushToSystem && (
            <SystemPushNotification
              open={true}
              subject={`Sub. Auftrag ${data.externalId} in Anlieferung`}
              body={`Der Sub. Auftrag ${data.externalId} ist in Anlieferung.`}
              timestamp={moment(notification.createdAt).unix()}
              tag={`notify-${notification.id}`}
              actions={[
                {
                  action: "show",
                  title: "Zum Sub. Auftrag",
                  redirectUrl: `/${resources.JOBS}?filter=%7B"id"%3A"${data.__id}"%7D&order=DESC&page=1&perPage=10&sort=id`,
                },
              ]}
            />
          )}
        </div>
      );
    }}
  </Query>
);

export default JobInDeliveryNotification;
