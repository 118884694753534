import React, { useState } from "react";
import WarehouseCompartmentSelectDialog from "./WarehouseCompartmentSelectDialog";
import IconChipField from "../customFields/IconChipField";
import WarehouseLocationIcon from "@material-ui/icons/BorderOuter";
import { LinearProgress, useQuery } from "react-admin";
import { get } from "lodash/object";
import resources from "../../config/resources";

const WarehouseCompartmentChip = ({
  record,
  sourceResource,
  source,
  emptyLabel = "",
  emptyColor = "rgba(158, 158, 158, 0.158)",
}) => {
  const [dialogOpen, setDialogOpen] = useState(false);

  const ref = get(record, source);
  // useQuery instead of useGetOne to avoid useQueryWithStore, since the redux mapping blocks us from refreshing
  const { data, loading, refetch } = useQuery({
    type: "getOne",
    resource: resources.WAREHOUSE_COMPARTMENTS,
    payload: { id: ref },
  });

  if (loading) {
    return <LinearProgress />;
  }

  const handleOpen = () => setDialogOpen(true);
  const handleClose = () => setDialogOpen(false);

  const color = data?.color ?? emptyColor;
  const name = data?.name ?? emptyLabel;

  return (
    <>
      <IconChipField color={color} label={name} icon={WarehouseLocationIcon} onClick={handleOpen} />
      <WarehouseCompartmentSelectDialog
        model={sourceResource === resources.MODELS ? record : undefined}
        open={dialogOpen}
        onClose={() => {
          handleClose();
          refetch();
        }}
        unselectableIds={data ? [data.id] : []}
      />
    </>
  );
};

export default WarehouseCompartmentChip;
