import { call, put, takeEvery } from "redux-saga/effects";
import { REESTIMATE_PRICES_REQUEST, reestimatePricesError, reestimatePricesSuccess } from "./action";
import { handleSagaError } from "../../../dataproviders/errorResponseHandler";
import fetchService from "../../../lib/fetchService";
import config from "../../../config/index";
import resources from "../../../config/resources";

const reestimatePricesSaga = function*(action = {}) {
  const { modelId } = action.payload || {};
  const ulrId = typeof modelId === "number" ? `/${modelId}` : "";
  const url = `${config.urls.BACKEND}/v2/${resources.MODELS + ulrId}/reestimate`;

  try {
    const response = yield call(fetchService.post, url);

    for (const action of yield call(handleSagaError, response, reestimatePricesError)) {
      yield put(action);
      return;
    }

    if (response.ok) {
      yield put(reestimatePricesSuccess());
    } else {
      const statusCode = response.status;
      yield put(reestimatePricesError(new Error(`Request failed with error ${statusCode}!`)));
    }
  } catch (e) {
    yield put(reestimatePricesError(e));
  }
};

export default function*() {
  yield takeEvery(REESTIMATE_PRICES_REQUEST, reestimatePricesSaga);
}
