import createAction, { createActionWithMeta } from "../../../lib/createAction";

export const FETCH_FILE_VERSIONS_OF_FILE_REQUEST = "FETCH_FILE_VERSIONS_OF_FILE_REQUEST";
export const fetchFileVersionsOfFile = createAction(FETCH_FILE_VERSIONS_OF_FILE_REQUEST, "fileUuid", "fileToken");

export const FETCH_FILE_VERSIONS_OF_FILE_SUCCESS = "FETCH_FILE_VERSIONS_OF_FILE_SUCCESS";
export const fetchFileVersionsOfFileSuccess = createAction(FETCH_FILE_VERSIONS_OF_FILE_SUCCESS, "fileUuid", "response");

export const FETCH_FILE_VERSIONS_OF_FILES_ERROR = "FETCH_FILE_VERSIONS_OF_FILES_ERROR";
export const fetchFileVersionsOfFileError = createActionWithMeta(
  FETCH_FILE_VERSIONS_OF_FILES_ERROR,
  {
    notification: {
      body: "ra.message.error",
      level: "warning",
    },
  },
  "fileUuid",
  "error"
);
