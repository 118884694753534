import config from "../../config/index";
import fetchService from "../../lib/fetchService";
import { call, put, takeEvery } from "redux-saga/effects";
import { handleSagaError } from "../../dataproviders/errorResponseHandler";
import { CHECK_AUTO_ASSIGN_REQUEST, checkAutoAssignError, checkAutoAssignSuccess } from "./action";
import resources from "../../config/resources";

const requestSaga = function*({ payload }) {
  const { formula, model } = payload;

  const url = `${config.urls.BACKEND}/${resources.FORMULAS}/checkAutoAssign`;
  const body =
    typeof formula === "string" && typeof model === "string"
      ? {
          formula,
          model,
        }
      : {};

  try {
    const response = yield call(fetchService.post, url, {}, body);

    for (const action of yield call(handleSagaError, response, checkAutoAssignError)) {
      yield put(action);
      return;
    }

    if (response.ok) {
      const responseData = yield call(() => response.json());
      yield put(checkAutoAssignSuccess(responseData));
    } else {
      const statusCode = response.status;
      yield put(checkAutoAssignError(new Error(`Request failed with error ${statusCode}`)));
    }
  } catch (e) {
    yield put(checkAutoAssignError(e));
  }
};

export default function*() {
  yield takeEvery(CHECK_AUTO_ASSIGN_REQUEST, requestSaga);
}
