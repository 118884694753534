import config from "../config";

export const clearLog = () => localStorage.removeItem(config.logger.localstorageKey);

export const LoggingLevel = {
  DEBUG: "DEBUG",
  INFO: "INFO",
  WARN: "WARN",
  ERROR: "ERROR",
  REDUX_ACTION: "REDUX_ACTION",
};

export const logHandler = async function(level, args, filepath) {
  switch (level) {
    case LoggingLevel.DEBUG:
      console.debug(...args);
      break;
    case LoggingLevel.INFO:
      console.info(...args);
      break;
    case LoggingLevel.WARN:
      console.warn(...args);
      break;
    case LoggingLevel.ERROR:
      console.error(...args);
      break;
    default:
      return;
  }

  await persistLog(new Date().getTime(), level, args, filepath);
};

export const getLog = () => {
  try {
    const serial = localStorage.getItem(config.logger.localstorageKey);
    return serial ? JSON.parse(serial) : [];
  } catch (e) {
    console.warn(`Invalid LocalStorage value for key '${config.logger.localstorageKey}'! Clearing value!`);
    localStorage.removeItem(config.logger.localstorageKey);
    return getLog();
  }
};

const persistLog = async function(time = new Date().getTime(), level, data, filepath) {
  const log = getLog();

  log.push({
    time,
    level,
    filepath,
    data,
  });

  if (log.length > config.logger.logSize) {
    log.splice(0, log.length - config.logger.logSize);
  }

  const serial = JSON.stringify(log);

  localStorage.setItem(config.logger.localstorageKey, serial);
};

export const Logger = (filepath = "") => ({
  debug: (...args) => {
    logHandler(LoggingLevel.DEBUG, args, filepath);
  },
  info: (...args) => {
    logHandler(LoggingLevel.INFO, args, filepath);
  },
  warn: (...args) => {
    logHandler(LoggingLevel.WARN, args, filepath);
  },
  error: (...args) => {
    logHandler(LoggingLevel.ERROR, args, filepath);
  },
});
