import React, { useState } from "react";
import PropTypes from "prop-types";
import SystemPushNotificationProvider, {
  propTypes as SystemPushNotificationProviderPropTypes,
} from "./SystemPushNotificationProvider";

const EnhancedSystemPushNotification = ({ sendPushNotification, open }) => {
  const [wasOpen, setWasOpen] = useState(false);
  if (open && !wasOpen) {
    sendPushNotification();
    setWasOpen(true);
  }
  return null;
};

const SystemPushNotification = props => (
  <SystemPushNotificationProvider {...props}>
    <EnhancedSystemPushNotification />
  </SystemPushNotificationProvider>
);

SystemPushNotification.propTypes = {
  ...SystemPushNotificationProviderPropTypes,
  open: PropTypes.bool,
};

export default SystemPushNotification;
