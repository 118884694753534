import { Children, cloneElement } from "react";

const DistinctSingleFieldList = ({ ids = [], data, collapseOn = "id", children }) => {
  if (ids.length === 0) {
    return null;
  }

  return Object.values(data)
    .filter((value, index, self) => self.map(x => x[collapseOn]).indexOf(value[collapseOn]) === index)
    .map(it =>
      cloneElement(Children.only(children), {
        record: it,
        key: it.__id,
      })
    );
};

export default DistinctSingleFieldList;
