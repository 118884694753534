import createReducer, { mergeReducers } from "../../lib/createReducer";
import { ACTIVE_USERS_CHANGED } from "./actions";

const init = {
  active: [],
  loaded: false,
};

const reducer = createReducer(
  ACTIVE_USERS_CHANGED,
  ({ activeUsers }) => ({
    active: activeUsers,
    loaded: true,
  }),
  true
);

export default mergeReducers([reducer], init);
