import {
  NOTIFICATION_TIMER_TICK,
  DESTROY_NOTIFICATION_TIMER,
  INIT_NOTIFICATION_TIMER,
  DESTROY_NOTIFICATION_TIMER_SUCCESS,
  INIT_NOTIFICATION_TIMER_SUCCESS,
  PUSH_NOTIFICATION,
  HIDE_NOTIFICATION,
} from "./action";

const init = {
  timer: null,
  created: false,
  isCreating: false,
  isDestroying: false,
  lastTick: null,

  notifications: [],
};

export default (state = init, { type, payload = {} } = {}) => {
  if (type === INIT_NOTIFICATION_TIMER) {
    return {
      ...state,
      isCreating: true,
    };
  } else if (type === INIT_NOTIFICATION_TIMER_SUCCESS) {
    return {
      ...state,
      isCreating: false,
      created: true,
      timer: payload.timer,
    };
  } else if (type === DESTROY_NOTIFICATION_TIMER) {
    return {
      ...state,
      isDestroying: true,
    };
  } else if (type === DESTROY_NOTIFICATION_TIMER_SUCCESS) {
    return {
      ...state,
      created: false,
      isDestroying: false,
      timer: null,
    };
  } else if (type === NOTIFICATION_TIMER_TICK) {
    return {
      ...state,
      lastTick: new Date(),
    };
  } else if (type === PUSH_NOTIFICATION) {
    const { notification } = payload;
    return {
      ...state,
      notifications: [...state.notifications, notification],
    };
  } else if (type === HIDE_NOTIFICATION) {
    const notifications = state.notifications.filter(({ id }) => id !== payload.id);
    return {
      ...state,
      notifications,
    };
  } else return state;
};
