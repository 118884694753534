import React from "react";
import { useNotify } from "react-admin";
import PropTypes from "prop-types";
import get from "lodash/get";
import config from "../../config";
import Button from "@material-ui/core/Button";

const sanitize = ({
  color,
  component,
  disabled,
  disableFocusRipple,
  disableRipple,
  fullWidth,
  href,
  mini,
  size,
  type,
  variant,
}) => ({
  color,
  component,
  disabled,
  disableFocusRipple,
  disableRipple,
  fullWidth,
  href,
  mini,
  size,
  type,
  variant,
});

const DownloadModelButton = ({ children, record, uuidSource, tokenSource, legacyUrlSource, disabled, ...props }) => {
  const notify = useNotify();

  const handleClick = () => {
    const uuid = get(record, uuidSource);
    const token = get(record, tokenSource);

    if (uuid && token) {
      window.open(
        `${config.urls.STORAGE_SERVICE}/files/${encodeURIComponent(uuid)}/download?token=${encodeURIComponent(token)}`,
        "_blank"
      );
      return;
    }

    if (legacyUrlSource) {
      const url = get(record, legacyUrlSource);
      if (!!url) {
        window.open(url, "_blank");
        return;
      }
    }

    notify("modelFileNotAvailable", "warning");
  };

  const available = (get(record, uuidSource) && get(record, tokenSource)) || get(record, legacyUrlSource);

  return (
    <Button {...sanitize(props)} onClick={handleClick} disabled={disabled || !available}>
      {children}
    </Button>
  );
};

DownloadModelButton.propTypes = {
  ...Button.propTypes,
  uuidSource: PropTypes.string.isRequired,
  tokenSource: PropTypes.string.isRequired,
  legacyUrlSource: PropTypes.string,
  disabled: PropTypes.bool,
};

export default DownloadModelButton;
