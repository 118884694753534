import React from "react";
import PropTypes from "prop-types";
import get from "lodash/get";

const PriceField = ({
  record,
  source,
  valueSource = `${source}.amount`,
  currencySource = `${source}.currency`,
  prettySource = `${source}.formatted`,
  formatted = true,
  defaultCurrency = "EUR",
  style = {},
  ...props
}) => {
  if (formatted && source && prettySource) {
    return (
      <span style={style} {...props}>
        {get(record, prettySource)}
      </span>
    );
  }

  const value = get(record, valueSource);
  const currency = currencySource ? get(record, currencySource, defaultCurrency) : defaultCurrency;

  return (
    <span style={style}>
      {value} {currency}
    </span>
  );
};

PriceField.propTypes = {
  source: PropTypes.string,
  valueSource: PropTypes.string,
  currencySource: PropTypes.string,
  prettySource: PropTypes.string,
  defaultCurrency: PropTypes.string,
};

PriceField.defaultProps = {
  valueSource: "price.amount",
  currencySource: "price.currency",
};

export default PriceField;
