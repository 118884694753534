import React from "react";
import {
  Datagrid,
  SingleFieldList,
  ArrayField,
  BooleanField,
  ChipField,
  DateField,
  EmailField,
  NumberField,
  TextField,
  UrlField,
} from "react-admin";

export default {
  table: {
    component: props => <Datagrid rowClick="edit" {...props} />,
    representation: (_, children) => `        <Datagrid rowClick="edit">
${children.map(child => `            ${child.getRepresentation()}`).join("\n")}
        </Datagrid>`,
  },
  array: {
    component: ({ children, ...props }) => (
      <ArrayField {...props}>
        <SingleFieldList>
          <ChipField source={children.length > 0 && children[0].props.source} />
        </SingleFieldList>
      </ArrayField>
    ),
    representation: (props, children) =>
      `<ArrayField source="${props.source}"><SingleFieldList><ChipField source="${children.length > 0 &&
        children[0].getProps().source}" /></SingleFieldList></ArrayField>`,
  },
  boolean: {
    component: BooleanField,
    representation: props => `<BooleanField source="${props.source}" />`,
  },
  date: {
    component: DateField,
    representation: props => `<DateField source="${props.source}" />`,
  },
  email: {
    component: EmailField,
    representation: props => `<EmailField source="${props.source}" />`,
  },
  id: {
    component: TextField,
    representation: props => `<TextField source="${props.source}" />`,
  },
  number: {
    component: NumberField,
    representation: props => `<NumberField source="${props.source}" />`,
  },
  reference: undefined,
  referenceChild: undefined,
  referenceArray: undefined,
  referenceArrayChild: undefined,
  richText: undefined, // never display a rich text field in a datagrid
  string: {
    component: TextField,
    representation: props => `<TextField source="${props.source}" />`,
  },
  url: {
    component: UrlField,
    representation: props => `<UrlField source="${props.source}" />`,
  },
};
