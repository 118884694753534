import React from "react";
import PropTypes from "prop-types";
import { Datagrid, FunctionField, List, Pagination, TextField, TopToolbar, translate } from "react-admin";
import { getKey } from "../../lib/i18nUtils";
import resources from "../../config/resources";
import MaterialFilters from "./MaterialFilters";
import paginationOptions from "../../config/PaginationOptions";

const Actions = () => <TopToolbar></TopToolbar>;

const MaterialsList = ({ translate, permissions, ...props }) => (
  <List
    {...props}
    bulkActionButtons={false}
    actions={<Actions />}
    filters={<MaterialFilters permissions={permissions} />}
    pagination={<Pagination {...paginationOptions} />}
  >
    <Datagrid>
      <TextField source="__id" sortBy="id" />
      <TextField source="name" />
      <FunctionField
        render={({ methodKey, methodName }) => methodKey || methodName || ""}
        label={translate(getKey("method", resources.MATERIALS))}
        sortBy="method.key"
      />
      <TextField
        source="modelAmount"
        sortBy="models.length"
        label={translate(getKey("models", resources.MATERIALS))}
        sortable={false}
      />
      <TextField
        sortBy="supportedMaterials.length"
        source="supportedMaterialAmount"
        label={translate(getKey("supportedMaterials", resources.MATERIALS))}
        sortable={false}
      />
      <FunctionField
        render={({ materialiseMappings = [] }) =>
          materialiseMappings.length > 0 ? translate("ra.message.yes") : translate("ra.message.no")
        }
        label={translate(getKey("supportsMaterialise", resources.MATERIALS))}
        sortable={false}
      />
      <TextField
        source="autoAssignFormulaName"
        sortBy="autoAssignFormula.name"
        label={translate(getKey("autoAssignFormula", resources.MATERIALS))}
      />
    </Datagrid>
  </List>
);

Actions.propTypes = {
  basePath: PropTypes.string,
  displayedFilters: PropTypes.object,
  filters: PropTypes.object,
  filterValues: PropTypes.object,
  resource: PropTypes.string,
  showFilter: PropTypes.func,
};

MaterialsList.propTypes = {
  translate: PropTypes.func.isRequired,
};

export default translate(MaterialsList);
