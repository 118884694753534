import React from "react";
import { connect } from "react-redux";
import { Button } from "react-admin";
import { cancelJob } from "../../redux/job/cancelJob/action";
import resources from "../../config/resources";
import sanitizeButton from "../sanitizeButton";
import Icon from "@material-ui/icons/RemoveCircle";
import red from "@material-ui/core/colors/red";
import { withStyles } from "@material-ui/core/styles";
import classnames from "classnames";

const styles = theme => ({
  button: {
    backgroundColor: `${red[600]} !important`,
    color: theme.palette.getContrastText(red[600]),
  },
});

const CancelJobButton = ({
  acceptJob,
  classes = {},
  color,
  className,
  onClick,
  record,
  resource,
  cancelJob,
  ...props
}) => {
  const handleClick = e => {
    if (resource === resources.JOBS && record) {
      cancelJob(record.__id);
    }
    onClick(e);
  };

  return (
    <Button
      {...sanitizeButton(props)}
      color={color}
      startIcon={<Icon />}
      className={color === "default" ? classnames(classes.button, className) : className}
      onClick={handleClick}
    />
  );
};

export default connect(
  undefined,
  {
    cancelJob,
  }
)(withStyles(styles)(CancelJobButton));
