import CustomerTypes from "../../config/CustomerTypes";

const CustomerNameField = ({ record, useLastName = false }) => {
  if (useLastName && record.lastname) {
    return record.lastname;
  }

  if (record.customerType === CustomerTypes.PRIVATE.key && record.lastname) {
    return record.lastname.substring(0, 20);
  }
  if (record.customerType === CustomerTypes.COMMERCIAL.key && record.companyName) {
    return record.companyName.substring(0, 20);
  }
  // shouldn't happen, but just to be safe
  return null;
};
export default CustomerNameField;
