import React, { useState } from "react";
import { useFormState } from "react-final-form";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import CircularProgress from "@material-ui/core/CircularProgress";
import CloseIcon from "@material-ui/icons/Close";
import CheckIcon from "@material-ui/icons/Check";
import {
  translate,
  ReferenceInput,
  AutocompleteInput,
  SimpleForm,
  required,
  Datagrid,
  TextField,
  ListController,
} from "react-admin";
import Typography from "@material-ui/core/Typography";
import get from "lodash/get";
import resources from "../../config/resources";
import { checkAutoAssign, checkAutoAssignResultClear } from "../../redux/checkAutoAssign/action";

const styles = () => ({
  dialog: {
    zIndex: 2,
  },
  autocompleteContainer: {
    width: "100%",
    "& > *": {
      width: "100%",
    },
  },
  hidden: {
    display: "none",
  },
  iconPaddingStyle: {
    paddingRight: "0.5em",
  },
});

const FormActions = ({ close, classes, isFetching, translate, ...props }) => (
  <DialogActions>
    <Button onClick={close}>
      <CloseIcon className={classes.iconPaddingStyle} />
      {translate("ra.action.cancel")}
    </Button>
    <Button onClick={props.handleSubmit} disabled={isFetching} variant="contained" color="primary">
      {isFetching ? <CircularProgress size={18} /> : <CheckIcon className={classes.iconPaddingStyle} />}
      {translate("checkAutoAssign.check")}
    </Button>
  </DialogActions>
);

const CheckFormulaDialog = ({
  translate,
  classes,
  basePath,
  resource,
  record,
  dispatchCheckAutoAssign,
  isFetching,
  contractorIds,
  dispatchResultClear,
  ...props
}) => {
  const formState = useFormState();
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleDialogClose = () => {
    setDialogOpen(false);
    dispatchResultClear();
  };

  const handleSubmit = formData => {
    const model = get(formData, "model");
    const recordFormula = formState.values["formulaStr"];
    if (typeof recordFormula === "string" && typeof model === "string") {
      dispatchCheckAutoAssign(recordFormula, model);
    }
  };

  return (
    <div>
      <Button onClick={() => setDialogOpen(true)}>{translate("checkAutoAssign.title")}</Button>
      <Dialog open={dialogOpen} onClose={handleDialogClose} style={{ zIndex: 2 }}>
        <DialogTitle>{translate("checkAutoAssign.title")}</DialogTitle>
        <DialogContent className={classes.dialog}>
          <SimpleForm
            basePath={basePath}
            resource={resource}
            record={record}
            save={handleSubmit}
            toolbar={
              <FormActions close={handleDialogClose} classes={classes} isFetching={isFetching} translate={translate} />
            }
          >
            <ReferenceInput
              validate={[required()]}
              source="model"
              label={"Modell auswählen"}
              reference={resources.MODELS}
              perPage={1000}
              alwaysOn={true}
              fullWidth={true}
            >
              <AutocompleteInput
                optionText={({ rapidshopId, methodName }) => `${rapidshopId} ${methodName}`}
                optionValue="id"
                fullWidth={true}
                translateChoice={false}
                style={{ zIndex: 100, width: "100%" }}
                options={{ fullWidth: true }}
                className={classes.autocompleteContainer}
              />
            </ReferenceInput>
          </SimpleForm>
          {Array.isArray(contractorIds) ? (
            contractorIds.length > 0 ? (
              <ListController
                {...props}
                resource={resources.CONTRACTORS}
                location={"/" + resources.CONTRACTORS}
                record={{ contractorIds }}
                filter={{
                  id: contractorIds,
                }}
                basePath={basePath}
              >
                {listProps => (
                  <Datagrid {...listProps}>
                    <TextField label={translate("checkAutoAssign.results")} source="contractorName" sortable={false} />
                  </Datagrid>
                )}
              </ListController>
            ) : (
              <Typography>{translate("checkAutoAssign.noResults")}</Typography>
            )
          ) : null}
        </DialogContent>
      </Dialog>
    </div>
  );
};

const mapStateToProps = state => ({
  isFetching: state.checkAutoAssign.isFetching,
  contractorIds: state.checkAutoAssign.payload,
});

export default connect(
  mapStateToProps,
  {
    dispatchCheckAutoAssign: checkAutoAssign,
    dispatchResultClear: checkAutoAssignResultClear,
  }
)(withStyles(styles)(translate(CheckFormulaDialog)));
