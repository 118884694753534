import createReducer, { mergeReducers } from "../../../lib/createReducer";
import { SEND_JOB_OFFER_ERROR, SEND_JOB_OFFER_REQUEST, SEND_JOB_OFFER_SUCCESS } from "./action";

const init = {
  job: null,

  isFetching: false,
  fetchSuccess: false,
  hasError: false,
  payload: null,
  error: null,
};

const requestReducer = createReducer(
  SEND_JOB_OFFER_REQUEST,
  ({ job }) => ({
    ...init,
    job,
    isFetching: true,
  }),
  true
);

const successReducer = createReducer(SEND_JOB_OFFER_SUCCESS, response => ({
  isFetching: false,
  fetchSuccess: true,
  payload: response,
}));

const errorReducer = createReducer(SEND_JOB_OFFER_ERROR, ({ error }) => ({
  isFetching: false,
  hasError: true,
  error,
}));

export default mergeReducers([requestReducer, successReducer, errorReducer], init);
