import React from "react";
import {
  Datagrid,
  GET_LIST,
  List,
  Pagination,
  ReferenceManyField,
  TextField,
  useQuery,
  useTranslate,
} from "react-admin";
import { AddCircle, Chat } from "@material-ui/icons";
import { Box, Button, Chip, makeStyles } from "@material-ui/core";
import { Link } from "react-router-dom";

import SendToPrintJob from "./SendToPrintJob";
import JobPanel from "../jobs/JobPanel";
import ConfirmJobButton from "../jobs/ConfirmJobButton";
import { getKey } from "../../lib/i18nUtils";
import resources from "../../config/resources";
import paginationOptions from "../../config/PaginationOptions";
import DateField from "../customFields/DateField";
import MultiStatusField from "../customFields/MultiStatusField";
import ExpansionDatagridBody from "../ExpansionDatagrid/ExpansionDatagridBody";
import AssignUserButton from "../customFields/buttons/AssignUserButton";
import CommentCountField from "../customFields/CommentCountField";
import PriceField from "../customFields/PriceField";
import PortfolioButton from "../documents/PortfolioButton";
import ModelDownloadButton from "../documents/ModelDownloadButton";
import LinearProgress from "@material-ui/core/LinearProgress";
import ReferenceManyMapper from "../ReferenceManyMapper";
import SizeCategoryField from "../jobs/SizeCategoryField";
import PrintPreparationFilters from "./PrintPreparationFilters";

const useStyles = makeStyles(theme => ({
  listMain: {
    marginTop: theme.spacing(6),
  },
  button: {
    color: "default",
  },
  horizontalInputsContainer: {
    display: "flex",
    gap: `${theme.spacing()}px`,
  },
  progress: {
    marginRight: theme.spacing(4),
    marginLeft: theme.spacing(4),
  },
}));

const PostBulkActionButtons = ({ selectedIds, classes }) => {
  const translate = useTranslate();
  const ids = selectedIds.map(it => it.split(".")[2]);

  return (
    <Box className={classes.horizontalInputsContainer}>
      <ModelDownloadButton ids={ids} noIdsInFileName />
      <Button
        startIcon={<AddCircle />}
        variant="contained"
        component={Link}
        color="primary"
        to={{
          pathname: `/printJobs/create`,
          search: `?selectedIds=${ids.toString()}`,
          state: { fromDashboard: true },
        }}
      >
        {translate(getKey("create", resources.PRINT_JOBS))}
      </Button>
      <ConfirmJobButton
        label={translate(getKey("accept", resources.JOBS))}
        variant="contained"
        color="primary"
        selectedIds={ids}
      />
    </Box>
  );
};

const PrintJobsNumbers = ({ record }) => {
  const { data, loading } = useQuery({
    type: GET_LIST,
    resource: resources.PRINT_JOBS,
    payload: { pagination: { page: 1, perPage: 1000 }, sort: {}, filter: { job: [record.__id] } },
  });

  if (loading) {
    return <LinearProgress />;
  }

  return Object.values(data).map((item, idx) => <Chip label={item.__id} key={idx} />);
};

const PrintPreparationsList = ({ permissions, ...props }) => {
  const translate = useTranslate();
  const classes = useStyles();

  return (
    <>
      <List
        {...props}
        resource={resources.JOBS}
        bulkActionButtons={<PostBulkActionButtons classes={classes} />}
        actions={null}
        filters={<PrintPreparationFilters resetFilterOnLoad permissions={permissions} />}
        filter={{
          active: "true",
          hasActiveModels: "true",
        }}
        pagination={<Pagination {...paginationOptions} />}
        sort={{ field: "id", order: "DESC" }}
        basePath={"/printPreparation"}
        classes={{ main: classes.listMain }}
      >
        <Datagrid expand={<JobPanel permissions={permissions} />} body={<ExpansionDatagridBody />} {...props}>
          <TextField source="externalId" sortBy="id" />
          <MultiStatusField source="status" useChip={true} />
          <CommentCountField source="commentsCount" label={<Chat fontSize="small" />} />
          <ReferenceManyField
            label={translate(getKey("assignedModels", resources.JOBS))}
            textAlign="right"
            reference={resources.MODELS}
            source="__id"
            target="job#id"
            sortBy="models.assignedCount"
          >
            <ReferenceManyMapper>
              {({ data }) =>
                data
                  ? Object.values(data)
                      .map(it => it.assignedCount)
                      .reduce((prev, it) => prev + it, 0)
                  : null
              }
            </ReferenceManyMapper>
          </ReferenceManyField>
          <TextField source="modelAmount" label={translate(getKey("models", resources.JOBS))} textAlign="right" />
          <PrintJobsNumbers
            sortable={false}
            source={translate(`resources.${resources.PRINT_JOBS}.name`, 2)}
            resource={props.resource}
          />

          <PriceField label={translate(getKey("priceValue", resources.JOBS))} textAlign="right" source="price" />
          <DateField source="dateEstimate" textAlign="right" />
          <SizeCategoryField source="sizeCategory" textAlign="right" />
          <PortfolioButton {...props} />
          <ModelDownloadButton {...props} />
          <AssignUserButton size={30} permissions={permissions} source="assignee" textAlign="right" />
        </Datagrid>
      </List>
      <SendToPrintJob />
    </>
  );
};

export default PrintPreparationsList;
