import React from "react";
import { List, Datagrid, TextField, BooleanField, Pagination, TopToolbar, CreateButton, EditButton } from "react-admin";
import paginationOptions from "../../config/PaginationOptions";
import CustomDeleteButton from "../customFields/buttons/CustomDeleteButton";

const Actions = ({ basePath }) => (
  <TopToolbar>
    <CreateButton basePath={basePath} />
  </TopToolbar>
);

const DocumentTypesList = props => (
  <List {...props} actions={<Actions />} bulkActionButtons={false} pagination={<Pagination {...paginationOptions} />}>
    <Datagrid>
      <TextField source="name" />
      <TextField source="labelInternal" />
      <TextField source="labelExternal" />
      <TextField source="fileFormatRegex" />
      <BooleanField source="uploadableByContractor" />
      <BooleanField source="uploadableBySales" />
      <BooleanField source="updatableBySales" />
      <BooleanField source="multipleUploadsAllowed" />
      <BooleanField source="overwriteable" />
      <BooleanField source="orderConfirmation" />
      <EditButton />
      <CustomDeleteButton undoable={false} />
    </Datagrid>
  </List>
);

export default DocumentTypesList;
