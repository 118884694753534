import React from "react";
import {
  List,
  Datagrid,
  TextField,
  Pagination,
  TopToolbar,
  ReferenceField,
  ChipField,
  FileField,
  BulkDeleteButton,
} from "react-admin";
import paginationOptions from "../../config/PaginationOptions";
import DateField from "../customFields/DateField";
import resources from "../../config/resources";
import CustomDeleteButton from "../customFields/buttons/CustomDeleteButton";

const DocumentsList = props => (
  <List
    {...props}
    actions={<TopToolbar />}
    bulkActionButtons={<BulkDeleteButton undoable={false} />}
    pagination={<Pagination {...paginationOptions} />}
  >
    <Datagrid>
      <FileField source="downloadUrl" title="name" target="_blank" sortBy="name" />
      <DateField source="createdAt" />
      <TextField source="mimeType" />
      <ReferenceField source="documentType" reference={resources.DOCUMENT_TYPES}>
        <ChipField source="name" />
      </ReferenceField>
      <ReferenceField source="job" reference={resources.JOBS}>
        <ChipField source="externalId" />
      </ReferenceField>
      <ReferenceField source="originalUploader" reference={resources.USERS}>
        <ChipField source="username" />
      </ReferenceField>
      <CustomDeleteButton undoable={false} />
    </Datagrid>
  </List>
);

export default DocumentsList;
