import createAction, { createActionWithMeta } from "../../../lib/createAction";

export const CHANGE_PASSWORD_REQUEST = "CHANGE_PASSWORD_REQUEST";
export const changePassword = createAction(
  CHANGE_PASSWORD_REQUEST,
  "oldPassword",
  "newPassword",
  "newPasswordAgain",
  "username",
  "redirectTo"
);

export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const changePasswordSuccess = createActionWithMeta(
  CHANGE_PASSWORD_SUCCESS,
  (_, redirectTo) => ({
    redirectTo,
  }),
  "response",
  "redirectTo"
);

export const CHANGE_PASSWORD_ERROR = "CHANGE_PASSWORD_ERROR";
export const changePasswordError = createAction(CHANGE_PASSWORD_ERROR, "error");
