import React from "react";
import PropTypes from "prop-types";
import { translate, Filter, TextInput, SelectArrayInput } from "react-admin";
import { withStyles } from "@material-ui/core/styles";
import { getKey } from "../../lib/i18nUtils";
import resources from "../../config/resources";
import TemplateTypes from "../../config/TemplateTypes";

const styles = theme => ({
  container: {
    display: "grid !important",
    width: "100%",

    gridTemplateColumns: "repeat(18, 1fr)",
    [theme.breakpoints.down("md")]: {
      gridTemplateColumns: "repeat(9, 1fr)",
    },
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "repeat(6, 1fr)",
    },
    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "repeat(3, 1fr)",
    },

    "& > *": {
      gridColumnEnd: "span 3",
    },
  },
});

const TemplateFilters = ({ translate, classes, ...props }) => (
  <Filter {...props} classes={{ form: classes.container }}>
    <TextInput source="name" alwaysOn={true} fullWidth={true} resettable />
    <SelectArrayInput
      source="type"
      label={translate(getKey("type", resources.TEMPLATES))}
      choices={Object.values(TemplateTypes)}
      optionValue="key"
      optionText={({ translation }) => translate(translation)}
      fullWidth={true}
      translateChoice={false}
      alwaysOn={true}
    />
    <TextInput source="subject" alwaysOn={true} fullWidth={true} resettable />
  </Filter>
);

TemplateFilters.propTypes = {
  classes: PropTypes.object.isRequired,
  translate: PropTypes.func.isRequired,
};

export default withStyles(styles)(translate(TemplateFilters));
