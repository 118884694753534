import React from "react";
import { makeStyles } from "@material-ui/core";
import { BooleanField, ReferenceField, ReferenceManyField, SingleFieldList } from "react-admin";
import LabeledField from "../customFields/LabeledField";
import PostProcessingJobEditButton from "../postProcessingJobs/PostProcessingJobEditButton";
import resources from "../../config/resources";
import ModelPartsDisplay from "../modelParts/ModelPartsDisplay";
import LabeledCollapsableListField from "../customFields/LabeledCollapsableListField";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(1),
    width: theme.spacing(40),
  },
  buttonsBox: {
    padding: theme.spacing(2),
    display: "flex",
  },
}));

const DetailsColumn = ({ label, groupIndex, ...props }) => {
  const classes = useStyles();
  return (
    <div label="Details" className={classes.root}>
      <ReferenceField {...props} source="order" reference={resources.ORDERS} link={false} allowEmpty={true}>
        <LabeledField source="rapidshopId" />
      </ReferenceField>
      <LabeledField {...props} source="rapidshopId" />
      <LabeledField {...props} source="amount" />
      <ModelPartsDisplay {...props} withDialog modelAmount={props.record.amount} />

      <ReferenceManyField {...props} source="__id" target="model#id" reference={resources.POSTPROCESSING_JOB}>
        <SingleFieldList className={classes.buttonsBox}>
          <PostProcessingJobEditButton {...props} />
        </SingleFieldList>
      </ReferenceManyField>
      {props && props.record && props.record.miniSeriesActive && (
        <LabeledField {...props} source="miniSeriesActive" type={<BooleanField />} />
      )}
      {props && props.record && props.record.miniSeriesActive && <LabeledField {...props} source="miniSeriesNotice" />}
      {props && props.record && props.record.sameChecksumOrders && (
        <LabeledCollapsableListField {...props} source="sameChecksumOrders" />
      )}
    </div>
  );
};

export default DetailsColumn;
