import { buffers, END, eventChannel } from "redux-saga";
import { handleRequestError } from "../dataproviders/errorResponseHandler";

/**
 * Creates a channel to upload a file to a server
 * @param {string} endpoint - The URL to upload to
 * @param {File} file - The file to upload
 * @param {string} [method] - The request method. Default is POST
 * @param username
 * @param password
 * @returns {Channel<any>}
 */

export const createUploadFileChannel = (endpoint, data, { method = "POST", username = null, password = null } = {}) => {
  return eventChannel(emit => {
    const xhr = new XMLHttpRequest();

    const onProgress = e => {
      if (e.lengthComputable) {
        const progress = e.loaded / e.total;
        emit({ progress });
      }
    };

    const onFailure = () => {
      const responseText = xhr.responseText;
      let errorJson = null;
      try {
        errorJson = JSON.parse(responseText);
      } catch (e) {}

      if (errorJson) {
        handleRequestError(
          {
            status: xhr.status,
            headers: xhr.getAllResponseHeaders(),
            url: endpoint,
          },
          errorJson
        ).then(
          () => {
            emit({ error: new Error(xhr.responseText || "Upload failed") });
            emit(END);
          },
          error => {
            emit({ error });
            emit(END);
          }
        );
      } else {
        emit({ error: new Error(xhr.responseText || "Upload failed") });
        emit(END);
      }
    };

    xhr.upload.addEventListener("progress", onProgress);
    xhr.upload.addEventListener("error", onFailure);
    xhr.upload.addEventListener("abort", onFailure);
    xhr.upload.addEventListener("timeout", onFailure);

    xhr.onreadystatechange = () => {
      const { readyState, status } = xhr;
      if (readyState === XMLHttpRequest.DONE) {
        if (status === 200) {
          const response = { success: true };
          if (xhr.responseText) response["payload"] = xhr.responseText;

          emit(response);
          emit(END);
        } else {
          onFailure();
        }
      }
    };

    xhr.open(method, endpoint, true, username, password);
    xhr.setRequestHeader("Authorization", `Bearer ${localStorage.getItem("token")}`);
    xhr.send(data);

    return () => {
      xhr.upload.removeEventListener("progress", onProgress);
      xhr.upload.removeEventListener("error", onFailure);
      xhr.upload.removeEventListener("abort", onFailure);
      xhr.onreadystatechange = null;
      xhr.abort();
    };
  }, buffers.sliding(2));
};
