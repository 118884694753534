import createAction, { createActionWithMeta } from "../../../lib/createAction";

export const RECEIVED_JOB_REQUEST = "RECEIVED_JOB_REQUEST";
export const receivedJob = createAction(RECEIVED_JOB_REQUEST, "job");

export const RECEIVED_JOB_SUCCESS = "RECEIVED_JOB_SUCCESS";
export const receivedJobSuccess = createActionWithMeta(
  RECEIVED_JOB_SUCCESS,
  { uncache: true, refresh: true },
  "response"
);

export const RECEIVED_JOB_ERROR = "RECEIVED_JOB_ERROR";
export const receivedJobError = createActionWithMeta(RECEIVED_JOB_ERROR, { uncache: true, refresh: true }, "error");
