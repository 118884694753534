import React from "react";
import PropTypes from "prop-types";
import {
  BooleanField,
  CreateButton,
  Datagrid,
  EditButton,
  FunctionField,
  List,
  Pagination,
  ReferenceField,
  TextField,
  TopToolbar,
  translate,
} from "react-admin";
import resources from "../../config/resources";
import paginationOptions from "../../config/PaginationOptions";
import NotificationTypes from "../../config/NotificationTypes";
import NotificationStyles from "../../config/NotificationStyles";

const Actions = ({ basePath }) => (
  <TopToolbar>
    <CreateButton basePath={basePath} />
  </TopToolbar>
);

const NotificationsConfigList = ({ translate, permissions, ...props }) => (
  <List {...props} actions={<Actions />} bulkActionButtons={false} pagination={<Pagination {...paginationOptions} />}>
    <Datagrid>
      <ReferenceField reference={resources.USERS} source="owner" link={false}>
        <TextField source="displayName" />
      </ReferenceField>
      <FunctionField
        source="notificationType"
        render={({ notificationType }) =>
          notificationType && translate(NotificationTypes[notificationType].translation)
        }
      />
      <FunctionField
        source="notificationStyle"
        render={({ notificationStyle }) =>
          notificationStyle && translate(NotificationStyles[notificationStyle].translation)
        }
      />
      <BooleanField source="push" />
      <BooleanField source="disabled" />
      <ReferenceField reference={resources.TEMPLATES} source="template" allowEmpty>
        <TextField source="name" />
      </ReferenceField>
      <EditButton textAlign="right" />
    </Datagrid>
  </List>
);

NotificationsConfigList.propTypes = {
  translate: PropTypes.func.isRequired,
};

export default translate(NotificationsConfigList);
