import { takeLatest, put, select } from "redux-saga/effects";
import get from "lodash/get";
import uniq from "lodash/uniq";
import filter from "lodash/filter";
import some from "lodash/some";
import find from "lodash/find";
import { CRUD_GET_MANY_SUCCESS, CRUD_GET_ONE_SUCCESS } from "react-admin";

import resources, { getCacheValidUntil } from "../../config/resources";

// Regex to match path prefix
const ID_REGEX = /\/\w*\/\d+/;

// get the path for our id
const getId = url => url.match(ID_REGEX)[0];

// get all correct ids for our records
const getIds = (action, idField) => uniq(get(action, "payload.data", []).map(item => get(item, idField)));

// select the whole record from the store so we can only change the one field
const selectRecord = (resource, id) => state => get(state, `admin.resources.${resource}.data[${id}]`);

/**
 *
 * Custom Saga to work around the referenceInputs which are not working (i.e. in contractorsEdit)
 *
 * It does:
 * - get the id of the record that got changed (and that we like to adjust)
 * - get the actual state of that record
 * - dispatch the success action to trick react-admin into changing that one record
 * so we can put the correct ids where they belong
 */
const makeFixReferenceSaga = (resource, relationField, relatedIdField) =>
  function* fixReferenceSaga(action) {
    const id = getId(get(action, "requestPayload.ids[0]", ""));

    const oldState = yield select(selectRecord(resource, id));

    if (!!id) {
      yield put({
        type: CRUD_GET_ONE_SUCCESS,
        payload: {
          data: {
            ...oldState,
            [relationField]: getIds(action, relatedIdField),
          },
          validUntil: getCacheValidUntil(resource),
        },
        meta: {
          resource,
          fetchResponse: "GET_ONE",
          fetch: "UPDATE",
          fetchStatus: "RA/FETCH_END",
        },
      });
    }
  };

/**
 * REMOVE SUBTASK SAGAS AS SOON AS POSSIBLE, PLEASE!!!
 */
const getIdsForModelSubtasks = (action, idField, singleId) =>
  uniq(
    filter(get(action, "payload.data", []), item => get(item, "id", "").includes(singleId)).map(it => get(it, idField))
  );

// FIX MEE, THIS IS FUCKING DISGUSTING, PLEASE DO SOMETHING AGAINST IT
const makeFixReferenceModelSubtasksSaga = (resource, relationField, relatedIdField) =>
  function* fixReferenceSaga(action) {
    const ids = get(action, "requestPayload.ids", []).map(item => getId(item));

    for (const singleId of ids) {
      const oldState = yield select(selectRecord(resource, singleId));

      const idsPayload = getIdsForModelSubtasks(action, relatedIdField, singleId);

      yield put({
        type: CRUD_GET_ONE_SUCCESS,
        payload: {
          data: {
            ...oldState,
            [relationField]: idsPayload,
          },
          validUntil: getCacheValidUntil(resource),
        },
        meta: {
          resource,
          fetchResponse: "GET_ONE",
          fetch: "UPDATE",
          fetchStatus: "RA/FETCH_END",
        },
      });
    }
  };

// PLEASE REWRITE SHIPMENTS AND SUBTASKS WITHOUT REACT ADMIN AND REMOVE ME
const makeFixReferenceShipmentSubtasksSaga = (resource, relationField, relatedIdField) =>
  function* fixReferenceSaga(action) {
    const id = getId(find(get(action, "requestPayload.ids", ""), item => item.indexOf("shipments") !== -1));

    const oldState = yield select(selectRecord(resource, id));

    if (!!id) {
      yield put({
        type: CRUD_GET_ONE_SUCCESS,
        payload: {
          data: {
            ...oldState,
            [relationField]: getIds(action, relatedIdField),
          },
          validUntil: getCacheValidUntil(resource),
        },
        meta: {
          resource,
          fetchResponse: "GET_ONE",
          fetch: "UPDATE",
          fetchStatus: "RA/FETCH_END",
        },
      });
    }
  };

// matches the correct action for the reference input in formula edit (auto assign materials)
const formulaAutoAssignMaterialsFetch = action =>
  get(action, "type") === CRUD_GET_MANY_SUCCESS &&
  get(action, "meta.resource") === "materials" &&
  get(action, "requestPayload.ids[0]").indexOf("autoAssignMaterials") !== -1;

// matches the correct action for the subtasks
const shipmentSubtaskFetch = action =>
  get(action, "type") === CRUD_GET_MANY_SUCCESS &&
  get(action, "meta.resource") === "subtasks" &&
  get(action, "requestPayload.ids[0]").indexOf("subtasks") !== -1 &&
  some(get(action, "requestPayload.ids", []), item => item.indexOf("shipments") !== -1);

// matches actions for model subtasks
const modelSubtaskFetch = action =>
  get(action, "type") === CRUD_GET_MANY_SUCCESS &&
  get(action, "meta.resource") === "subtasks" &&
  get(action, "requestPayload.ids[0]").indexOf("models") !== -1 &&
  get(action, "requestPayload.ids[0]").indexOf("subtasks") !== -1;

// matches actions for model priceEstimates
const modelPriceEstimateFetch = action =>
  get(action, "type") === CRUD_GET_MANY_SUCCESS &&
  get(action, "meta.resource") === resources.PRICE_ESTIMATES &&
  get(action, "requestPayload.ids[0]").indexOf("models") !== -1 &&
  get(action, "requestPayload.ids[0]").indexOf("priceEstimates") !== -1;

export default function*() {
  yield takeLatest(
    formulaAutoAssignMaterialsFetch,
    makeFixReferenceSaga("formulas", "autoAssignMaterials", "material")
  );
  yield takeLatest(shipmentSubtaskFetch, makeFixReferenceShipmentSubtasksSaga("shipments", "subtasks", "subtask"));
  yield takeLatest(modelSubtaskFetch, makeFixReferenceModelSubtasksSaga("models", "subtasks", "subtask"));
  yield takeLatest(modelPriceEstimateFetch, makeFixReferenceSaga("models", "priceEstimates", "priceEstimate"));
}
