import { call, put, takeEvery } from "redux-saga/effects";
import resources from "../../../config/resources";
import config from "../../../config/index";
import fetchService from "../../../lib/fetchService";
import { UNASSIGN_MODEL_REQUEST, unassignModelError, unassignModelSuccess } from "./action";
import { crudGetOne } from "react-admin";
import { handleSagaError } from "../../../dataproviders/errorResponseHandler";

const unassignModelSaga = function*({ payload, meta }) {
  const { id } = payload;

  const url = `${config.urls.BACKEND}/v2/${resources.MODELS}/${encodeURIComponent(id)}/unassign_contractor`;
  const data = {};

  const response = yield call(fetchService.post, url, data);

  for (const action of yield call(handleSagaError, response, unassignModelError)) {
    yield put(action);
    return;
  }

  try {
    if (response.ok) {
      const defaultAction = unassignModelSuccess({});
      if (!meta || !meta.onSuccess) {
        yield put(defaultAction);
      } else {
        yield put({
          ...defaultAction,
          meta: meta.onSuccess,
        });
      }
      yield put(crudGetOne(resources.MODELS, id));
    } else {
      const statusCode = response.status;
      const defaultAction = unassignModelError(new Error(`Request failed with error ${statusCode}!`));
      if (!meta || !meta.onFailure) {
        yield put(defaultAction);
      } else {
        yield put({
          ...defaultAction,
          meta: meta.onFailure,
        });
      }
    }
  } catch (e) {
    const defaultAction = unassignModelError(e);
    if (!meta || !meta.onFailure) {
      yield put(defaultAction);
    } else {
      yield put({
        ...defaultAction,
        meta: meta.onFailure,
      });
    }
  }
};

export default function*() {
  yield takeEvery(UNASSIGN_MODEL_REQUEST, unassignModelSaga);
}
