import createReducer, { mergeReducers } from "../../../lib/createReducer";
import { ASSIGN_MODELS_ERROR, ASSIGN_MODELS_REQUEST, ASSIGN_MODELS_SUCCESS } from "./action";

const init = {
  isFetching: false,
  fetchSuccess: false,
  hasError: false,
  payload: null,
  error: null,
};

const requestReducer = createReducer(
  ASSIGN_MODELS_REQUEST,
  () => ({
    ...init,
    isFetching: true,
  }),
  true
);

const successReducer = createReducer(ASSIGN_MODELS_SUCCESS, ({ response }) => ({
  isFetching: false,
  fetchSuccess: true,
  payload: response,
}));

const errorReducer = createReducer(ASSIGN_MODELS_ERROR, ({ error }) => ({
  isFetching: false,
  hasError: true,
  error,
}));

export default mergeReducers([requestReducer, successReducer, errorReducer], init);
