import React from "react";
import PropTypes from "prop-types";
import { Field, Form } from "react-final-form";
import { connect } from "react-redux";
import compose from "recompose/compose";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import { createStyles, withStyles } from "@material-ui/core/styles";
import { translate, useTranslate } from "react-admin";
import { changePassword } from "../../redux/auth/changePassword/action";
import { usePermissions } from "react-admin";

const styles = () =>
  createStyles({
    form: {
      padding: "0 1em 1em 1em",
    },
    input: {
      marginTop: "1em",
    },
    button: {
      width: "100%",
    },
  });

// see http://redux-form.com/6.4.3/examples/material-ui/
const renderInput = ({
  meta: { touched, error } = { touched: false, error: "" }, // eslint-disable-line react/prop-types
  input: { ...inputProps }, // eslint-disable-line react/prop-types
  ...props
}) => <TextField error={!!(touched && error)} helperText={touched && error} {...inputProps} {...props} fullWidth />;

const ChangePasswordForm = ({ classes, isLoading, addOldPassword, changePassword, username }) => {
  const translate = useTranslate();
  const { permissions } = usePermissions();

  if (!username && permissions) {
    username = permissions.username;
  }

  const doChangePassword = ({ oldPassword, newPassword, newPasswordAgain }) =>
    changePassword(oldPassword, newPassword, newPasswordAgain, username, "/login");

  return (
    <Form
      validate={(values, props) => {
        const errors = {};

        if (!values.oldPassword) errors.oldPassword = translate("ra.validation.required");
        if (!values.newPassword) errors.newPassword = translate("ra.validation.required");
        if (!values.newPasswordAgain) errors.newPasswordAgain = translate("ra.validation.required");

        if (values.newPassword !== values.newPasswordAgain)
          errors.newPasswordAgain = translate("changePassword.notMatching");

        return errors;
      }}
      onSubmit={doChangePassword}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <div className={classes.form}>
            {addOldPassword && (
              <div className={classes.input}>
                <Field
                  autoFocus
                  id="oldPassword"
                  name="oldPassword"
                  component={renderInput}
                  label={translate("changePassword.oldPassword")}
                  type="password"
                  disabled={isLoading}
                />
              </div>
            )}
            <div className={classes.input}>
              <Field
                id="newPassword"
                name="newPassword"
                component={renderInput}
                label={translate("changePassword.newPassword")}
                type="password"
                disabled={isLoading}
              />
            </div>
            <div className={classes.input}>
              <Field
                id="newPasswordAgain"
                name="newPasswordAgain"
                component={renderInput}
                label={translate("changePassword.newPasswordAgain")}
                type="password"
                disabled={isLoading}
              />
            </div>
          </div>
          <CardActions>
            <Button variant="contained" type="submit" color="primary" disabled={isLoading} className={classes.button}>
              {isLoading && <CircularProgress size={25} thickness={2} />}
              {translate("changePassword.change")}
            </Button>
          </CardActions>
        </form>
      )}
    </Form>
  );
};

ChangePasswordForm.propTypes = {
  classes: PropTypes.object,
  redirectTo: PropTypes.string,
  addOldPassword: PropTypes.bool,
};

ChangePasswordForm.defaultProps = {
  addOldPassword: true,
};

const mapStateToProps = state => ({ isLoading: state.admin.loading > 0 });

const enhance = compose(
  withStyles(styles),
  translate,
  connect(
    mapStateToProps,
    { changePassword }
  )
);

export default enhance(ChangePasswordForm);
