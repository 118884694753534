import React from "react";
import { Create } from "react-admin";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import ContractorForm from "./ContractorForm";

const styles = () => ({
  root: {
    display: "flex",
    justifyContent: "center",
  },
});

const ContractorCreate = ({ classes, ...props }) => (
  <div className={classes.root}>
    <Create {...props}>
      <ContractorForm />
    </Create>
  </div>
);

ContractorCreate.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ContractorCreate);
