import React from "react";
import { List, TextField, Datagrid, ArrayField } from "react-admin";
import Typography from "@material-ui/core/Typography";
import resources from "../../config/resources";

const ColorPanel = props => (
  <div style={{ borderLeft: "solid 3px #bbbbbb" }}>
    <Typography variant="subtitle1" style={{ padding: 8 }}>
      Farben
    </Typography>
    <ArrayField {...props} source="colors">
      <Datagrid>
        <TextField source="name" />
        <TextField source="id" label="Farb-ID" />
      </Datagrid>
    </ArrayField>
  </div>
);

const FinishingPanel = props => (
  <div style={{ borderLeft: "solid 3px #bbbbbb" }}>
    <Typography variant="subtitle1" style={{ padding: 8 }}>
      Veredelungen
    </Typography>
    <ArrayField {...props} source="finishings">
      <Datagrid expand={<ColorPanel />}>
        <TextField source="name" />
        <TextField source="id" label="Veredelung-ID" />
      </Datagrid>
    </ArrayField>
  </div>
);

const MaterialsPanel = props => (
  <div style={{ borderLeft: "solid 3px #bbbbbb" }}>
    <Typography variant="subtitle1" style={{ padding: 8 }}>
      Materialien
    </Typography>
    <ArrayField {...props} source="materials">
      <Datagrid expand={<FinishingPanel />}>
        <TextField source="name" />
        <TextField source="id" label="Material-ID" />
      </Datagrid>
    </ArrayField>
  </div>
);

const MaterialiseMaterialMappingAside = props => (
  <div>
    <Typography variant="h6" style={{ padding: 8 }}>
      Materialise-Katalog: Technologien
    </Typography>
    <List
      {...props}
      bulkActionButtons={false}
      actions={null}
      resource={resources.MATERIALISE_TECHNOLOGIES}
      hasEdit={false}
      hasShow={false}
      hasCreate={false}
    >
      <Datagrid expand={<MaterialsPanel />}>
        <TextField source="name" />
        <TextField source="id" label="Technologie-ID" />
      </Datagrid>
    </List>
  </div>
);

export default MaterialiseMaterialMappingAside;
