import createReducer, { mergeReducers } from "../../lib/createReducer";
import { FETCH_PING, FETCH_PING_ERROR, FETCH_PING_SUCCESS } from "./action";

const init = {
  isFetching: false,
  fetchSuccess: false,
  hasError: false,
  payload: null,
  error: null,
};

const requestReducer = createReducer(FETCH_PING, () => ({
  isFetching: true,
}));

const successReducer = createReducer(FETCH_PING_SUCCESS, ({ response }) => ({
  isFetching: false,
  fetchSuccess: true,
  hasError: false,
  payload: response,
  error: null,
}));

const errorReducer = createReducer(FETCH_PING_ERROR, ({ error }) => ({
  isFetching: false,
  hasError: true,
  fetchSuccess: false,
  error,
  payload: null,
}));

export default mergeReducers([requestReducer, successReducer, errorReducer], init);
